/*
 * CreateCoursePage.js
 * 
 * Example: <CreateCoursePage />
 */
import React, { Component } from 'react';
import Button from '../Button';
import { request } from '../../util/api';
import { getQueryValueFromURL, isAdmin } from '../../util/util';
import { DEFAULT_COURSE_ORDER } from '../../config/constant-config';

const courseTitleStyle = {
  "fontSize": "23px",
  "fontWeight": "300",
  "lineHeight": "38px",
  "paddingBottom": "20px",
  "textAlign": "center",
};

const tileStyle = {
  "textAlign": "center",
};

const lessonTableStyle = {
  "textAlign": "center",
  "width": "100%",
};

const lessonTdStyle = {
  "padding": "20px",
};

const lessonCardStyle = {
  "borderRadius": "10px",
  "textAlign": "center",
  "minWidth": "150px",
};

const lessonTrStyle = {
  "display": "inline-block",
};

const projectImageStyle = {
  "textAlign": "center",
  "width": "35px",
  "height": "35px",
  "marginBottom": "10px",
};

const titleImageStyle = {
  "textAlign": "center",
  "width": "35px",
  "height": "35px",
};

const lessonButtonStyle = {
  base: {
    "padding": "10px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "500",
    "fontSize": "110%",
    "color": "white",
    "backgroundColor": "rgba(28, 175, 246, 1)",
    "width": "160px",
    "textAlign": "center",
    "border": "none",

    ':hover': {
      "backgroundColor": "rgba(62, 187, 247, 1)",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0"
    }
  }
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
}

const previewContainerStyle = {
  "width": "100%",
  "verticalAlign": "top",
  "textAlign": "left",
  "paddingLeft": "20px",
  "paddingTop": "20px",
}

const lockedLessonButtonStyle = {
  "marginTop": "5px",
  "padding": "10px",
  "display": "inline-block",
  "borderRadius": "2em",
  "fontWeight": "500",
  "color": "Black",
  "backgroundColor": "rgba(192, 192, 192, 1)",
  "width": "160px",
  "textAlign": "center",
  "border": "none",
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "15px",
    "marginBottom": "15px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "verticalAlign": "top",
};

const tableStyle = {
  // "border": "solid 1px",
  "width": "100%",
};

class CreateCoursePage extends Component {
  static defaultProps = {
    id: '',
    username: '',
    name: '',
    course: '',
    tags: '',
    title: '',
    description: '',
    order: '',
    hide: false,
  };

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onCourseChange = this.onCourseChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.onOrderChange = this.onOrderChange.bind(this);
    this.onHide = this.onHide.bind(this);

    this.id = getQueryValueFromURL('course?id=');
    if (!this.id) {
      this.id = this.props.id;
    }

    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;
    this.name = this.props.name ? this.props.name : 'TBD';
    this.course = this.props.course ? this.props.course :
'{ \n' +
'"title": "Turtle Gallery", \n' +
'"items": [ \n' +
'    [\n' +
'      { "type": "Lesson", "id": "jed40joj", "name": "Strings" }, \n' +
'      { "type": "Project", "id": "jeumsd06", "name": "Logo Design" } \n' +
'    ], \n' +
'    [\n' +
'      { "type": "Lesson", "id": "jefwntf3", "name": "Numbers" }, \n' +
'      { "type": "Lesson", "id": "jefxyftd", "name": "Variables", "lock": "true"} \n' +
'    ] \n' +
'] }';
    this.tags = this.props.tags ? this.props.tags : 'topic: TBD; level: TBD; concept: TBD';
    this.title = this.props.title ? this.props.title : 'TBD';
    this.description = this.props.description ? this.props.description : 'TBD';
    this.order = this.props.order ? this.props.order: DEFAULT_COURSE_ORDER;
  }

  componentDidMount() {
    this.setState({
      getCourseDone: true,
      courseObj: null,
      hide: this.props.hide ? this.props.hide : false,
    });

    if (this.id) {
      // edit existing item, wait till it's loaded
      this.setState({
        getCourseDone: false,
      });

      request({
        url: '/api/get_course',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error to get course!');
          return;
        }

        this.coursePacket = res.course;

        this.name = this.coursePacket.name;
        this.tags = this.coursePacket.tags;
        this.title = this.coursePacket.title;
        this.description = this.coursePacket.description;
        this.course = this.coursePacket.course;
        this.order = this.coursePacket.order;
        this.setState({
          hide: this.coursePacket.hide,
        });

        this.setState({
          courseObj: JSON.parse(this.course),
        });

        this.setState({
          getCourseDone: true,
        });
      }); 
    }
  }

  validateCourse() {
    if (!this.course) {
      alert('Please specify course.');
      return false;
    }

    try {
      var courseObj = JSON.parse(this.course);

      this.setState({
        courseObj: courseObj,
      });

      if (!courseObj.title) {
        alert('Course should contain a key called "title".');
        return false;
      }

      if (!courseObj.items) {
        alert('Course should contain a key called "items".');
        return false;
      }

      var result = true;
      courseObj.items.forEach(row => {
        row.forEach(entry => {
          if (!entry.id) {
            result = false;
            alert('Missing a "id" entry.');
          }
          if (!entry.type) {
            result = false;
            alert('Missing a "type" entry.');
          }
          if (entry.type !== 'Lesson' && entry.type !== 'Project' && entry.type != 'Test' && entry.type != 'Note') {
            result = false;
            alert('Please specify type as "Lesson", "Project", "Test", or "Note".');
          }
          if (entry.lock && entry.lock !== 'true' && entry.lock !== 'false') {
            result = false;
            alert('Value of "lock" should be either "true" or "false"');
          }
          // the "name" field is optional
          // if (!entry.name) {
          //   result = false;
          //   alert('Missing a "name" entry.');
          // }
        });
      });
    } catch (e) {
      result = false;
      alert('Please ensure the right JSON format.\n\n' + e.message);
    }

    return result;
  }

  onSave() {
    if (!this.username)
      return;

    if (!this.name) {
      alert('Please add the name for the course.');
      return;
    }

    if (!this.validateCourse())
      return;

    request({
      url: '/api/save_course',
      method: 'POST',
      payload: {
        id: this.id,
        username: this.username,
        name: this.name,
        course: this.course,
        tags: this.tags,
        title: this.title,
        description: this.description,
        order: this.order,
        hide: this.state.hide,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving the course.");
        return;
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }  

  onDelete() {
    var answer = confirm('This will delete course ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_course',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('Error to delete course!');
            return;
          }

        alert('Course ' + this.id + ' is deleted.');
      });
    } else {
      alert('Ok, no change is made.');
    }
  }

  /*
   * create the preview message 
   */
  onPreview() {
    this.validateCourse();
  }

  onNameChange(e) {
    this.name = e.target.value;
  }

  onTitleChange(e) {
    this.title = e.target.value;
  }

  onDescriptionChange(e) {
    this.description = e.target.value;
  }

  onCourseChange(e) {
    this.course = e.target.value;
  }

  onTagChange(e) {
    this.tags = e.target.value;
  }

  onOrderChange(e) {
    this.order = e.target.value;
  }

  onHide(e) {
    this.setState({
      hide: !this.state.hide,
    });
  }

  getSaveButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getDeleteButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
      Delete
      </Button>
    );
  }

  getPreviewButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onPreview}>
      Preview
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='courses'>
        <Button style={buttonSaveStyle} >
        View All
        </Button>
      </a>
    );
  }

  getButtons() {
    return (
      <div>
        { this.getPreviewButton() }
        { this.getSaveButton() }
        { this.getDeleteButton() }
        { this.getAllButton() }
      </div>
    );
  }

  getRow(entry) {
    var result = [];

    entry.forEach(item => {
      var newLink = '';

      switch(item.type) {
        case 'Note':
          newLink = '/note?id=' + item.id;

          var noteImg = '/img/note.svg';
          result.push(
            <td style={lessonTdStyle}>
              <div style={lessonCardStyle}>
                <div>
                  <img src={noteImg} style={projectImageStyle}></img>
                </div>
                <a href={ newLink }>
                  <Button style={lessonButtonStyle}> { item.name } </Button>
                </a>
              </div>
            </td>
          );
          break;

        case 'Project':
          newLink = '/project?id=' + item.id;

          var projectImg = '/img/project.svg';
          result.push(
            <td style={lessonTdStyle}>
              <div style={lessonCardStyle}>
                <div>
                  <img src={projectImg} style={projectImageStyle}></img>
                </div>
                <a href={ newLink }>
                  <Button style={lessonButtonStyle}> { item.name } </Button>
                </a>
              </div>
            </td>
          );
          break;

        case 'Test':
        case 'Lesson':
          var passedCount = 0;
          var skills = [];
          var totalCount = 0;

          newLink = '/lesson?id=' + item.id;
          
          var passedLessonMap = this.passedLessonMap;

          var image = '/img/battery1.svg';

          if (this.isLocked(item.lock === 'true' ? true : false)) {
            image = '/img/lock-1.svg';
            result.push(
              <td style={lessonTdStyle}>
                <div style={lessonCardStyle}>
                <div>
                  <img src={ image } style={titleImageStyle}></img>
                </div>
                  <div style={lockedLessonButtonStyle}> { item.name } </div>
                </div>
              </td>
            );
          }
          else {
            result.push(
              <td style={lessonTdStyle}>
                <div style={lessonCardStyle}>
                  <div>
                  <img src={ image } style={titleImageStyle}></img>
                  </div>
                  <a href={ newLink }>
                    <Button style={lessonButtonStyle}> { item.name } </Button>
                  </a>
                </div>
              </td>
            );
          }
        break;

        default:
          console.log('Error: unexpected type: ' + item.type);
          break;
      }

      this.count ++;
    });

    return <tr style={lessonTrStyle}> { result } </tr>
  }

  isLocked(lockForStudent) {
    if (this.count - this.current > 3 || lockForStudent) {
      return true;
    }

    return false;
  }

  getSkillsTiles(content) {
    var result = [];
    this.count = 0;
    this.current = 0;

    content.items.forEach((entry) => {
      result.push(this.getRow(entry));
      result.push(<br/>);
    });

    return result;
  }

  getSkills() {
    if (!this.state || !this.state.courseObj)
      return (<div></div>);

    return (
      <div>
        <div style={courseTitleStyle}> { this.state.courseObj.title } </div>

        <div style={tileStyle}>
          <table style={lessonTableStyle}>
            <tbody>
              { this.getSkillsTiles(this.state.courseObj) }
            </tbody>
          </table>
        </div>

      </div>
    );
  }

  render() {
    if (!this.state || !this.state.getCourseDone) {
      return (<div></div>);
    }

    if (!isAdmin(this.user)) {
      return (<div><h3>Sorry, you don't have permission to update the course.</h3></div>);
    }

    return (
      <div>

        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>

              <tr>
              <td style={columnStyle}> </td>
              <td>
                <div>
                  { this.getButtons() }
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Name </td>
              <td>
                <div>
                  <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
                  defaultValue={ this.name }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Title </td>
              <td>
                <div>
                  <textarea id="title" rows="1" cols="85" name="title" onChange={this.onTitleChange}
                  defaultValue={ this.title }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Description </td>
              <td>
                <div>
                  <textarea id="description" rows="5" cols="85" name="description" onChange={this.onDescriptionChange}
                  defaultValue={ this.description }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Course </td>
              <td>
                <div>
                  <textarea id="course" rows="30" cols="85" name="course" onChange={this.onCourseChange}
                  defaultValue={ this.course }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Tags </td>
              <td>
                <div>
                  <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
                  defaultValue={ this.tags }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Order </td>
              <td>
                <div>
                  <textarea id="order" rows="1" cols="85" name="order" onChange={this.onOrderChange}
                  defaultValue={ this.order }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Hide </td>
              <td>
                <div>
                  {
                    this.state && this.state.hide ? 
                    <input type="checkbox" name="hide" checked onChange={ this.onHide }></input>
                    :
                    <input type="checkbox" name="hide" onChange={ this.onHide }></input>
                  }
                </div>
              </td>
              </tr>

              </tbody>
            </table>
          </td>

          <td style={previewContainerStyle}>
            <div>
              { this.getSkills() }
            </div>
          </td>
        </tr>
        </tbody>
        </table>

      </div>


    );
  }
}

export default CreateCoursePage;
