/*
 * Footer.js
 */
import React from 'react';
import Link from '../Link';

const root = {
  "background": "#333",
  "color": "#fff",
};

const container = {
  "margin": "0 auto",
  "padding": "20px 15px",
  "maxWidth": "$max-content-width",
  "textAlign": "center",
};

const logoText = {
  "color": "rgba(255, 255, 255, )",
  "padding": "2px 5px",
  "fontSize": "1em",
  "whiteSpace": "nowrap",
};

const spacer = {
  "color": "rgba(255, 255, 255, 0.3)",
};

const navLink = {
  base: {
    "padding": "2px 5px",
    "transition": "0.2s ease-in-out",
    "fontSize": "1em",
    "color": "rgba(255, 255, 255, 0.6)",
    "textDecoration": "none",
    "whiteSpace": "nowrap",

    ':hover': {
      "color": "rgba(255, 255, 255, 1)",
      "textDecoration": "none",
    },

    ':active': {
      "color": "rgba(255, 255, 255, 0.6)",
    },
  },
};

export default function Footer() {
  return (
    <div style={root}>
      <div style={container}>
        <span style={logoText}>© CS Wonders</span>
        {
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/">Home</Link>
        }
        <span style={spacer}>·</span>
        <Link style={navLink} to="/about">About</Link>
        <span style={spacer}>·</span>
        <Link style={navLink} to="/gallery">Gallery</Link>
        <span style={spacer}>·</span>
        <Link style={navLink} to="/funfacts">Fun Facts</Link>
        <span style={spacer}>·</span>
        <Link style={navLink} to="/cheatsheet">Cheatsheet</Link>
        {
        // <Link style={navLink} to="/classes">Classes</Link>
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/learn">Courseware</Link>
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/curriculum">Tutorials</Link>
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/contact">Contact</Link>
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/nuggets">Nuggets</Link>
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/python-pad">CodePad</Link>
        // <span style={spacer}>·</span>
        // <Link style={navLink} to="/glossary">Glossary</Link>
        // <span style={spacer}>·</span>
        }
      </div>
    </div>
  );

}
