/* 
 * Nugget.js
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import Button from '../../components/Button';
import { joinJsonObjects, getDateTime } from '../../util/util';
import CreateNuggetPage from '../../components/CreateNuggetPage';
import { CircularProgressbar } from 'react-circular-progressbar';
import { isTeacher, isAdmin } from '../../util/util';
import { DEFAULT_NUGGET_LANGUAGE } from '../../config/constant-config';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const controlBarStyle = {
  "color": "white",
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "marginBottom": "30px",
};

const controlBarTableStyle = {
  "width": "100%",
};

const tdIconStyle = {
  "width": "70px",
  "paddingRight": "20px",
};

const progressContainerStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "width": "100px",
  "height": "100px",
  "borderRadius": "50%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "-20px",
  "marginBottom": "-20px",
  "paddingTop": "10px",
};

const progressStyle = {
  "width": "80px",
  "height": "80px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "2px solid white",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "98px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "backgroundColor": "hsla(0,0%,100%,.15)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "width": "500px",
  "textAlign": "center",
};

const rightTdStyle = {
  "width": "500px",
  "textAlign": "right",
};

const containerStyle = {
  "marginTop": "35px",
  "marginBottom": "35px",
  "backgroundColor": "#efefef",
  "maxWidth": "1240px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const tableStyle = {
  "marginLeft": "auto",
  "marginRight": "auto",
};

const smallFontStyle = {
  "fontSize": "75%",
};

const errorStyle = {
  "color": "red",
};

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginTop": "4px",
  "marginBottom": "2px",
  "color": "white",
};

const editorStyle = {
  "height": "250px",
  "width": "610px",
  // "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const previewContainerStyle = {
  "width": "610px",
  "verticalAlign": "top",
  "backgroundColor": "white",
  "padding": "20px",
};

const tdMiddleStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "minWidth": "20px",
};

const tdMiddleResultStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "minWidth": "10px",
};

const editorContainerStyle = {
  "verticalAlign": "top",
};

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "minWidth": "200px",
};

const commandsStyle = {
  "textAlign": "right",
};

const runTestsStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "padding": "10px",
  "fontSize": "small",
};

const outputStyle = {
  "minHeight": "250px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const resultStyle = {
  "minHeight": "250px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const correctMessageStyle = {
  "color" : "green",
  "fontWeight" : "600",
};

const incorrectMessageStyle = {
  "color": "red",
  "fontWeight" : "600",
};

const resultTableStyle = {
  "borderCollapse": "collapse",
};

const resultTDStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
};

const resultRedStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "red",
  "fontWeight": "600",
};

const resultGreenStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "green",
  "fontWeight": "600",
};

const buttonReportBugStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "60px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    "width": "570px",
    "marginLeft": "-20px",
    "marginRight": "-20px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-20px",
  "marginRight": "-20px",
};

const innerContainer = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "padding": "20px",
};

const outputContainerStyle = {
  "width": "300px",
  "verticalAlign": "top",
};

const middleTd = {
  "minWidth": "20px",
};

const messageStyle = {
  "margin": "30px 60px",
  "textAlign": "center",
  "fontSize": "150%",
  "backgroundColor": "wheat",
};

const infoIconStyle = {
  "width": "80px",
  "height": "80px",
  "paddingRight": "20px",
};

class Nugget extends Component {
  getProgressBar() {
    return (
      <div style={ controlBarStyle }>
        <table style={ controlBarTableStyle }>
          <tbody>
            <tr>
              <td style={leftTdStyle}>
              </td>
              <td>
                { this.getProgress() }
              </td>
              <td style={rightTdStyle}>
                { this.getContinueButton() }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // We use reat-circular-progressbar to display progress:
  // https://github.com/iqnivek/react-circular-progressbar
  // https://www.npmjs.com/package/react-circular-progressbar
  // color configuration can be controled with the CSS file in src/public/ace.css
  getProgress() {
    var lessonState = this.props.store.getState().auth.lessonState;
    var current = lessonState.currentSkillIndex;
    if (this.state && this.state.passed) {
      // if the current snippet is passed, we report progress right away
      current += 1;
    }

    var count = 0;
    if (lessonState.skillList) {
      count = lessonState.skillList.length;
    }

    var percentage = 0;
    if (count > 0) {
      percentage = current/count;
    }

    percentage = percentage*100;
    percentage = percentage.toFixed(0);

    return (
      <div style={progressContainerStyle}>
        <div style={progressStyle}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={12}/>
        </div>
      </div>
    );
  }

  getContinueButton() {
    var buttonText = 'Check';
    if (this.state && this.state.passed) {
      buttonText = 'Continue';
    }

    return (
      <span>
        { this.getEditButton() }
        { this.getDeleteButton() }
        { this.getSkipButton() }
        { this.getReportBug() }
        <Button style={buttonStyle} onClick={this.onRun}>
          { buttonText }
        </Button>
      </span>
    );
  }

  constructor(props) {
    super(props);

    this.onRun = this.onRun.bind(this);
    this.onHint = this.onHint.bind(this);
    this.onShowInsight = this.onShowInsight.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.iframeContent = this.iframeContent.bind(this);
    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;

    this.inSubmission = false;
  }

  initializeState() {
    this.setState({
      previewMessage: "",
      error: "",
      testResults: [],
      resultTableHeader: "",
      loaded: false,
      doEdit: false,
      passed: false,
    });
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    this.id = this.props.id;
    this.logs = this.props.store.getState().auth.lessonState.logs;
    this.canSkip = this.props.canSkip ? this.props.canSkip : false;

    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    var minCount = 60;
    editor.setOptions({
      fontSize: "8pt",
      minLines: minCount
    });
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      doHint: false,
      doInsight: false,
    });

    this.initializeState();

    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;
      if (!this.preference) {
        this.preference = {};
      }

      var settings = this.preference.editorSettings;
      if (settings) {
        editor.setTheme("ace/theme/" + settings.theme);

        if (settings.showLineNumbers) {
          editor.renderer.setShowGutter(true);
        } else {
          editor.renderer.setShowGutter(false);
        }

        if (settings.fontSize === 'small') {
          editor.setOptions({
            fontSize: "8pt",
          });
        }

        if (settings.fontSize === 'medium') {
          editor.setOptions({
            fontSize: "12pt",
          });
        }

        if (settings.fontSize === 'large') {
          editor.setOptions({
            fontSize: "16pt",
          });
        }

        if (settings.tabSize === '2') {
          editor.setOptions({
            tabSize: 2,
          });
        }

        if (settings.tabSize === '4') {
          editor.setOptions({
            tabSize: 4,
          });
        }

        if (settings.showInvisibles) {
          editor.setOption("showInvisibles", true);
        } else {
          editor.setOption("showInvisibles", false);
        }

        if (settings.keyBinding === '') {
          editor.setKeyboardHandler('');
        }

        if (settings.keyBinding === 'vim') {
          editor.setKeyboardHandler('ace/keyboard/vim');
        }

        if (settings.keyBinding === 'emacs') {
          editor.setKeyboardHandler('ace/keyboard/emacs');
        }
      }
      else {
        // no settings
        this.resetOriginal();
      }
    });

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 
      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }

    request({
      url: '/api/get_nugget',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error to get nugget!');
        return;
      }

      var nugget = res.nugget;

      if (!nugget)
        return;
      
      this.tests = nugget.tests;
      this.code = nugget.code;
      this.description = nugget.description;
      this.hint = nugget.hint;
      this.tags = nugget.tags;
      this.funcName = nugget.funcName;
      this.author = nugget.username;
      this.language = nugget.language;
      if (!this.language) {
        this.language = DEFAULT_NUGGET_LANGUAGE;
      }

      const editor = ace.edit(this.refs.root);
      editor.getSession().setMode("ace/mode/" + this.language);

      var initialCode = this.extractFunctionLine(this.code);

      // append the indentation from the next line as well
      if (this.language === 'java') {
        initialCode += '\n    \n';
      } else {
        initialCode += '\n    \n\n';
      }

      // add the first test case
      var testHarness = this.extractFirstTest();
      if (testHarness) {
        initialCode += testHarness;
      }

      this.state.editor.setValue(initialCode, -1);

      // var i = this.state.editor.session.getLength() - 1;
      // var currentLine = this.state.editor.session.getLine(i);

      var Range = ace.require("ace/range").Range;
      var range = new Range(1, 4, 1, 4);
      this.state.editor.selection.setSelectionRange(range, false);
      this.state.editor.focus();
      this.setState( { loaded: true });

      this.startTime = new Date().getTime(); // milliseconds
      this.duration = 0;
    }); 
    
    this.audioSuccess = new Audio('/sound/c2.mp3');
    this.audioFail = new Audio('/sound/pop.wav');

    this.averageDuration = 0;
    this.userSet = new Set();
    this.submissions = 0;
    this.failureCount = 0;
    this.errorCount = 0;
    this.errorCode = '';
    this.failureCode = '';
    this.insight = '';

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('ERROR: failed to get nugget activity');
        return;
      }

      this.activities = res.activities;
      console.log('Activities for nugget: ' + this.id);
      console.dir(this.activities);
      this.processActivities();
    });

  }

  processActivities() {
    this.setState({
      processActivitiesDone: false,
    });

    var totalDuration = 0;

    this.activities.forEach(item => {
      var activity = item.activity;

      this.submissions ++;
      totalDuration += activity.duration;
      this.userSet.add(item.username);
      if (activity.passed === false) {
        this.failureCount ++;

        if (activity.error === "") {
          this.failureCode += '[' + item.username + '] ';
          this.failureCode += activity.code + '<br/><br/>';
        }
      }
      if (activity.error !== "") {
        this.errorCount ++;
        this.errorCode += '[' + item.username + '] ';
        this.errorCode += activity.error + '<br/>';
        this.errorCode += activity.code + '<br/><br/>';
      }
    });

    if (this.submissions > 0) 
      this.averageDuration = totalDuration / this.submissions / 1000;

    this.insight = '<h3>Nugget ' + this.funcName + '</h3>' +
      'ID: <a href="run?id=' + this.id + '" target="_blank">' + this.id + '</a><br/>' +
      'Author: ' + this.author + '<br/>' +
      'Tags: ' + this.tags + '<br/><br/>' + 
      'Users: ' + this.userSet.size + '<br/>' +
      'Runs: ' + this.submissions + '<br/>' +
      'Failures: ' + this.failureCount  + ' (' + (this.failureCount*100/this.submissions).toFixed(0) + '%)' + '<br/>' +
      'Errors: ' + this.errorCount + '<br/>' +
      'Average duration: ' + this.averageDuration.toFixed(0) + ' secs' + '<br/>' +
      '<h3>Error Cases</h3>' + 
      this.errorCode + '<br/>' + 
      '<h3>Failure Cases</h3>' + 
      this.failureCode; 

    this.setState({
      processActivitiesDone: true,
    });
  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  runCode() {
    var prog = this.state.editor.getValue(); 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();
  }

  clearOutput() {
    var prog = ''; 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();
  }

  onRun() {
    if (this.inSubmission)
      return;

    if (!this.username)
      return;

    if (this.state.passed) {
      // when the snippet is passed, the button is in the "Continue" mode
      this.initializeState();
      this.clearOutput();

      var lessonState = this.props.store.getState().auth.lessonState;

      const userActivity = {
        type: 'Lesson',
        username: this.username,
        id: lessonState.lessonId,
        activity: {
          description: 'PASSED_NUGGET',
          nuggetId: this.id,
        },
      };
    
      var logs = this.logs;
      logs.push(userActivity);

      // advance the currentSkillIndex to the next one
      var newLessonState = {
        lessonId: lessonState.lessonId,
        lessonName: lessonState.lessonName,
        currentSkillIndex: lessonState.currentSkillIndex + 1,
        currentSkillPassed: false,
        skillList: lessonState.skillList,
        groupId: lessonState.groupId,
        logs: logs,
      };
      this.props.onContinue(newLessonState);

      return;
    }

    this.inSubmission = true;

    this.runCode();

    this.setState({ error: "", testResults: [], resultTableHeader: "" });

    var error = "";
    this.code = this.state.editor.getValue();
    this.extractFunctionName(this.code);

    this.duration = new Date().getTime() - this.startTime;

    // TODO: consider to use server side time for logging to be more consistent
    this.submissionTime = getDateTime();

    if (this.language === 'java') {
      var content = '<div style="color: grey">Crunching result...</div>';
      this.setState({
        content: content,
      });

      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
    }
      
    request({
      url: '/api/run_nugget',
      method: 'POST',
      payload: {
        username: this.username,
        code: this.code,
        funcName: this.funcName,
        description: this.description,
        hint: this.hint,
        tests: this.tests,
        tags: this.tags,
        language: this.language,
        id: this.id,
      }
    }, (err, res) => {
      this.inSubmission = false;

      if (this.language === 'java') {
        var content = '<div></div>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
      }
      
      if (err) {
        error = "Error: Oops! Something went wrong when running tests.";
      }

      if (res.status === 'MISSING_FUNCTION_NAME') {
        error = "Missing function name.";
      }

      if (res.status === 'MISSING_RETURN') {
        error = "Did you forget to have a return statement in your function?";
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({ error: error });
        return;
      }

      // var testResults = [ ["flip(True) → False", "False", "OK"], ["flip(False) → True", "True", "X"] ];
      this.setState({ testResults: res.results, resultTableHeader: "<tr><th>Expected</th><th>Run</th><th></th></tr>" });

      // log the nugget submission activity
      var totalCount = 0;
      var correctCount = 0;
      this.state.testResults.forEach(entry => {
        totalCount ++;
        if (entry[2] === "OK")
          correctCount ++;
      });
      this.setState({ totalCount: totalCount, correctCount: correctCount });

      if (totalCount === correctCount) {
        this.setState({
          passed: true,
        });

        this.props.onPass(this.props.store.getState().auth.lessonState);

        this.audioSuccess.play();
      } else {
        this.setState({
          passed: false,
        });

        this.props.onFail(this.props.store.getState().auth.lessonState);

        this.audioFail.play();
      }

      // TODO: Should we add to cache?
      
      // const activities = [{
      //   type: 'Nugget',
      //   username: this.username,
      //   id: this.id,
      //   activity: {
      //     name: this.funcName,
      //     passed: true,
      //     correctCount: correctCount,
      //     totalCount: totalCount,
      //     code: code,
      //     error: '',
      //     submissionTime: this.submissionTime,
      //     duration: new Date().getTime() - this.startTime,
      //   },
      //   time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      // }];

      // this.props.onAddActivities(activities);
    });
  }

  /*
   * save the nugget
   */
  onEdit() {
    this.setState({ doEdit: true });
  }

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  onShowInsight() {
    this.setState({
      doInsight: !this.state.doInsight, 
    });
  }

  /*
   * delete a nugget
   */
  onDelete() {
    var answer = confirm('This will delete nugget ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_nugget',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('error to delete nugget!');
            return;
          }

        alert('nugget ' + this.id + ' is deleted');
      });
    } else {
      alert('ok, no change is made');
    }
  }

  onSkip() {
    this.initializeState();
    this.clearOutput();

    // advance the currentSkillIndex to the next one
    var lessonState = this.props.store.getState().auth.lessonState;
    var newLessonState = {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex + 1,
      currentSkillPassed: false,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: lessonState.logs,
    };
    this.props.onContinue(newLessonState);
  }

  getEditButton() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        return null;
      }
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onEdit}>
      Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        return null;
      }
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
      Delete
      </Button>
    );
  }

  showSkipButton() {
    if (isTeacher(this.user))
      return true;

    if (this.username === this.author)
      return true;

    if (this.canSkip)
      return true;

    return false;
  }

  getSkipButton() {
    if (!this.showSkipButton()) 
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSkip}>
      Skip
      </Button>
    );
  }

  getReportBug() {
    var location = 'run?id=' + this.id;

    return(
      <a href={ 'contact?location=' + location } target='_blank'>
        <Button style={buttonReportBugStyle}>
          Send Feedback
        </Button>
      </a>
    );
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    var results = [];
    this.state.testResults.forEach(entry => {
      var colorStyle = entry[2]==="OK" ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  getHint()
  {
    if (!this.hint)
      return <div></div>;

    return (
      <div>
        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  // extract the function name from the last "def funcName()" pattern
  extractFunctionName(code) {
    var re = /def(\s+)(\w+)(\s*)\(/g;
    var result = code.match(re);

    if (!result)
      return;

    var name = result[result.length - 1];

    name = name.replace(/def(\s+)/, '');
    name = name.replace(/(\s*)\(/, '');

    this.funcName = name;
  }

  // extract the function line
  extractFunctionLine() {
    if (this.language === 'java') {
      return this.extractFunctionLineForJava();
    }

    // extract the function line from the last "def funcName()" pattern for Python
    var re = /def(\s+)(\w+)(\s*)\((.*)/g;
    var result = this.code.match(re);

    if (!result)
      return;

    return result[result.length - 1];
  }

  extractFunctionLineForJava() {
    var code = this.code;
    if (!code.match('public')) {
      code = 'public ' + code;
    }

    if (!code.match('static')) {
      code = code.replace('public', 'public static');
    }

    // extract the function line from the last "public static returnType funcName()" pattern for Java
    // TODO: improve regEx, may miss String [] myFunction()
    var re = /public(\s+)static(\s+)(\w+)(\s+)(\w+)(\s*)\((.*)/g;
    var result = code.match(re);
    
    if (!result) {
      // Match: public static int[] myFunction(int[] nums) {
      re = /public(\s+)static(\s+)(\w+)\[\](\s+)(\w+)(\s*)\(.*\)/g;
      result = code.match(re);

      if (!result)
        return;
    } 

    return result[result.length - 1];
  }

  extractFirstTest() {
    if (this.language === 'java') {
      // For Java, we'll not add initial test case
      return '}\n';
    }

    if (!this.tests)
      return undefined;

    var lines = this.tests.split(/\r\n|\r|\n|\n\r/g);

    if (!lines)
      return undefined;

    var line = lines[0];
    var items = line.split(";");
    
    if (items.length == 0)
      return undefined;

    var output = items[items.length - 1];

    items.pop();

    return 'print(' + this.funcName + '(' + items + '))';
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    var results = [];
    this.state.testResults.forEach(entry => {
      var colorStyle = entry[2]==="OK" ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  getNuggetsInfo() {
    if (!this.state)
      return;

    var results = [];
    var nuggets = this.state.nuggets;
    nuggets.forEach(nugget => {
      var link = "nugget?id=" + nugget.id;
      results.push(<div> <a href={link} > {nugget.id} </a> </div>);
    });

    return results;
  }

  getIdFromURL() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // e.g. http://www.cswonders.com/run?id=abcdefgh
    const href = window.location.href;
    const PATTERN = 'run?id=';
    const PATTERN_LEN = PATTERN.length;
    const index = href.indexOf(PATTERN);

    return href.substring(index + PATTERN_LEN);
  }

  getTitle() {
    return (
      <div>
      <table>
        <tbody>
          <tr>
            <td style={tdIconStyle}>
              {
                (this.language === 'java') ? 
                <img src='/img/java.svg' width='70px' height='70px'></img>
                :
                <img src='/img/python.svg' width='70px' height='70px'></img>
              }
            </td>
            <td>
              <h4>Define function <b> { this.funcName} </b></h4>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }

  isArrayType(output) {
    var re = /(\{)(.*)(\})/g;
    var matched = String(output).match(re);
    return matched;
  }

  getDescription() {
    var previewMessage = '';

    this.extractFunctionName(this.code);

    // previewMessage += "<h4>" + this.funcName + "</h4>";
    // previewMessage += "<div style='font-size: 75%'>author: " + (this.author == "admin" ? "jason" : this.author) + "; " + this.tags + "</div>";
    // previewMessage += "<hr style='display: black' />";

    previewMessage += "<hr>";
    previewMessage += "<h4>Problem</h4>";
    previewMessage += this.description;
    previewMessage += "<hr>";

    if (this.tests) {
      previewMessage += "<h4>Examples</h4>";

      var lines = this.tests.split(/\r\n|\r|\n|\n\r/g);

      // max number of test cases to show as part of description
      var maxNum= 3;
      for (var i = 0; i < lines.length && i < maxNum; i++) {
        var line = lines[i];
        var items = line.split(";");
        
        if (items.length == 0)
          continue;

        var output = items[items.length - 1];
        // skip empty lines
        if (output === '') {
          maxNum ++;
          continue;
        }

        items.pop();

        if (this.language == 'java' && this.isArrayType(items)) {
          previewMessage += items + " → " + output + "<br />";
        } else {
          previewMessage += this.funcName + "(" + items + ") → " + output + "<br />";
        }
      }

      previewMessage += "<br />";
    }

    return previewMessage;
  }

  canShowContentInsight() {
    if (!isAdmin(this.user))
      return false;

    if (!this.state)
      return false;

    if (!this.state.processActivitiesDone)
      return false;

    return true;
  }

  getContentInsight()
  {
    if (!this.canShowContentInsight())
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onShowInsight}>
          <h5> { 'Get Insight [Teacher Only]' } <span style={arrowStyle}>{ this.state.doInsight? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doInsight ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.insight }}></div> : <div></div>
        }

      </div>
    );
  }

  render() {
    if (!this.username) {
      return (
        <div style={containerStyle}>
          <div style={messageStyle}>
            <img src="/img/info.svg" style={infoIconStyle}></img>
            <span> Please <a href='login'>sign in</a> to track your progress. </span>
          </div>
        </div>
      );
    }

    // when the id is passed in, it means we want to edit the nugget
    // to preserve the original author, we pass in this.author as the username
    if (this.state && this.state.doEdit) {
      return (
        <CreateNuggetPage
          id={ this.id } 
          username={ this.author }
          funcName={ this.funcName }
          code={ this.code }
          description = { this.description }
          tests = { this.tests }
          hint={ this.hint }
          tags={ this.tags }
          language={ this.language }
          store={ this.props.store }
        />
      );
    }

    if (this.id !== this.props.id) {
      // the lesson state has switched to another nugget
      // fetch the nugget again
      this.id = this.props.id;

      request({
        url: '/api/get_nugget',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error to get nugget!');
          return;
        }

        var nugget = res.nugget;

        // TODO: BUGBUG: when skipping in the lesson about nugget, if the nugget doesn't exist, it would return null.
        if (!nugget)
          return;

        this.tests = nugget.tests;
        this.code = nugget.code;
        this.description = nugget.description;
        this.hint = nugget.hint;
        this.tags = nugget.tags;
        this.language = nugget.language;
        if (!this.language) {
          this.language = DEFAULT_NUGGET_LANGUAGE;
        }
        this.funcName = nugget.funcName;
        this.author = nugget.username;

        var initialCode = this.extractFunctionLine(this.code);

        // append the indentation from the next line as well
        initialCode += '\n    \n\n';

        // add the first test case
        var testHarness = this.extractFirstTest();
        if (testHarness) {
          initialCode += testHarness;
        }

        this.state.editor.setValue(initialCode, -1);

        // var i = this.state.editor.session.getLength() - 1;
        // var currentLine = this.state.editor.session.getLine(i);

        var Range = ace.require("ace/range").Range;
        var range = new Range(1, 4, 1, 4);
        this.state.editor.selection.setSelectionRange(range, false);
        this.state.editor.focus();
        this.setState( { loaded: true });

        this.startTime = new Date().getTime(); // milliseconds
        this.duration = 0;
      }); 

    }

    return (
      <div style={containerStyle}>

        { this.getProgressBar() }

        <div style={innerContainer}>
        <table>
        <tbody>
        <tr>
          <td style={previewContainerStyle}>
            <div style={previewStyle}>
              {
                this.state && this.state.loaded ?
                <div>
                  { this.getTitle() }
                  
                  <div dangerouslySetInnerHTML={{ __html: this.getDescription() }}></div>

                  { this.getHint() }

                  { this.getContentInsight() }

                </div>
                :
                <div></div>
              }
            </div>

          </td>

          <td style={middleTd}>
          </td>

          <td style={editorContainerStyle}>
            <div>
              <div ref="root" style={editorStyle}>
                {this.props.code}
              </div>
            </div>

            <table>
            <tbody>
            <tr>
              <td style={outputContainerStyle}>
                <div style={labelStyle}>
                  Output
                </div>
                <iframe ref="result" id="iframeResult" style={joinJsonObjects(outputStyle, this.props.style)}>
                </iframe>
              </td>

              <td style={tdMiddleResultStyle}>
              </td>

              <td style={outputContainerStyle}>
                <div style={labelStyle}>
                  Result
                </div>
                <div style={resultStyle}>
                  {
                    this.state && this.state.error ? 
                      <div style={errorStyle} dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                      :
                      <div></div>
                  }

                  {
                    this.state && this.state.testResults && this.state.testResults.length > 0 ?
                      <div style={runTestsStyle}>
                        {
                          this.state.correctCount === this.state.totalCount ?
                            <div style={ correctMessageStyle }> Hooray! You passed { this.state.correctCount } out of { this.state.totalCount } tests.</div>
                            :
                            <div style={ incorrectMessageStyle }> You passed { this.state.correctCount } out of { this.state.totalCount } tests.</div>
                        }
                        <div style={smallFontStyle}>Time: { (this.duration / 1000 / 60).toFixed(2) } minutes.</div>
                        <br />
                        <div>
                          <table style={resultTableStyle}>
                          <tbody>
                            <tr><th>Expected </th><th>Actual </th><th></th></tr>
                            { this.getResults() }
                          </tbody>
                          </table>
                        </div>
                      </div>
                      :
                      <div></div>
                  }
              </div>
              </td>
              </tr>
              </tbody>
              </table>


          </td>
        </tr>
        </tbody>
        </table>
        </div>
      </div>

    );
  }
}

export default Nugget;
