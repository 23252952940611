/*
 * Img.js
 *
 * Example: <Img style={myStyle} src={mySrc} />
 */
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Img extends Component {
  render() {
    var src = (this.props.src) ? this.props.src: "";

    return (
      <img style={[this.props.style.base]} src={src}>
        {this.props.children}
      </img> 
    );
  }
}

Img.propTypes = {
  style: PropTypes.object.isRequired
};

export default Radium(Img);