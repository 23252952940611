/* 
 * ProjectPage.js
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import Button from '../../components/Button';
import { joinJsonObjects, getDateTime, canUserViewSolution, isAdmin, isUserSignedIn } from '../../util/util';
import CreateProjectPage from '../../components/CreateProjectPage';
import { detectProgrammingLanguage, getUniqueFileName } from '../../util/util';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  language: 'python',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const resultLabelStyle = {
  "height": "30px",
  "fontSize": "120%",
  "fontWeight": "500",
};

const containerStyle = {
  "marginTop": "35px",
  "marginBottom": "35px",
  "backgroundColor": "#efefef",
  "maxWidth": "1240px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const tableStyle = {
  "width": "100%",
};

const errorStyle = {
  "color": "red",
};

const editorStyle = {
  "height": "800px",
  "width": "610px",
};

const editorStyleHidden = {
  "height": "800px",
  "width": "610px",
  "textIndent": "-9999px",
};

const descriptionTableStyle = {
  "width": "100%",
};

const tdIconStyle = {
  "width": "70px",
};

const instructionsStyle = {
  "marginTop": "-10px",
  "paddingBottom": "10px",
};

const descButtonStyle = {
  "textAlign": "left",
};

const projectTitleStyle = {
  "paddingLeft": "7px",
  "marginBottom": "-8px",
};

const descriptionStyle = {
  "backgroundColor": "white",
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "marginTop": "20px",
};

const resultStyle = {
  "height": "800px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "width": "100%",
  "border": "none",
  "marginBottom": "15px",
  "marginTop": "20px",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const resultNoneStyle = {
  "height": "800px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "width": "100%",
  "border": "none",
  "marginBottom": "15px",
  "marginTop": "20px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "display": "none",
};

const buttonReportBugStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "10px",
    "marginBottom": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "60px",
    "marginRight": "10px",
    "marginBottom": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const settingsIconStyle = {
  "width":"20px", 
  "height":"20px", 
  "verticalAlign":"sub",
  "marginTop": "4px",
  "marginRight": "5px",
  "float": "right",
};

const buttonSaveSettingsStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "10px",
    "marginBottom": "-40px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const selectorStyle = {
  // "borderRadius": "6px",
  "lineHeight": "1.5",
  "padding": "5px 10px",
  "width": "100%",
  "fontSize": "12px",
  "color": "black",
};

const settingContainerStyle = {
  "backgroundColor": "rgba(14, 98, 165, 1)",
  "color": "white",
  "height": "800px",
  "width": "100%",
  "border": "none",
  "marginBottom": "15px",
  "marginTop": "20px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "padding": "20px",
};

const buttonSettingsStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "10px",
    "marginBottom": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const instructionButtonStyle = {
  base: {
    "backgroundColor": "white",
    "verticalAlign": "top",
    "border": "none",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "rgba(134, 149, 153, 1)",
    "paddingRight": "20px",

    ':hover': {
      "color": "rgba(0, 0, 0, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-20px",
  "marginRight": "-20px",
};

const insightFontStyle = {
  "fontSize": "70%",
  "marginRight": "10px",
};

const insightContainer = {
  "textAlign": "-webkit-right",
};

const insightStyle = {
  "backgroundColor": "rgba(53, 61, 69, 1)",
  "marginLeft": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "paddingLeft": "10px",
  "color": "white",
  "width": "250px",
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    // "width": "110%",
    // "marginLeft": "-20px",
    "marginRight": "-20px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const messageStyle = {
  "margin": "30px 60px",
  "textAlign": "center",
  "fontSize": "150%",
  "backgroundColor": "wheat",
};

const infoIconStyle = {
  "width": "80px",
  "height": "80px",
  "paddingRight": "20px",
};

const checkBoxStyle = {
  "paddingLeft": "20px",
};

const radioStyle = {
  "paddingLeft": "20px",
};

const tdSettingsLeft = {
  "width": "150px",
};

const tdSettingsRight = {
};

class ProjectPage extends Component {
  constructor(props) {
    super(props);

    this.onRun = this.onRun.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveSolution = this.onSaveSolution.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onShowSolution = this.onShowSolution.bind(this);
    this.onShowInstructions = this.onShowInstructions.bind(this);
    this.onShowCode = this.onShowCode.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onSettings = this.onSettings.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onHint = this.onHint.bind(this);
    this.onSettingsChange = this.onSettingsChange.bind(this);
    this.onLanguageSettingsChange = this.onLanguageSettingsChange.bind(this);
    this.onShowLineNumbersChange = this.onShowLineNumbersChange.bind(this);
    this.onShowInvisiblesChange = this.onShowInvisiblesChange.bind(this);
    this.onResetToOriginal = this.onResetToOriginal.bind(this);
    this.onFontSizeChange = this.onFontSizeChange.bind(this);
    this.onTabSizeChange = this.onTabSizeChange.bind(this);
    this.onKeyBindingChange = this.onKeyBindingChange.bind(this);
    this.iframeContent = this.iframeContent.bind(this);
    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    this.canSkip = this.props.canSkip ? this.props.canSkip : false;
    this.backendOnly = this.props.backendOnly? this.props.backendOnly : false;

    this.id = this.props.id;
    if (!this.id) {
      this.id = this.getIdFromURL();
    }
    this.sharedUsernames = [];

    const node = this.refs.root;
    const editor = ace.edit(node);

    this.title = '';

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
   // editor.renderer.setShowGutter(false);

    var minCount = 60;
    editor.setOptions({
      // fontSize: "8pt",
      minLines: minCount
    });
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      doInstructions: true,
      doCode: true,
      previewMessage: '',
      error: '',
      loaded: false,
      doEdit: false,
      doHint: false,
      content: '',
      showSettings: false,
      processPreferenceDone: false,
    });

    this.setState( {
      currentTheme: DEFAULT_EDITOR_SETTINGS.theme,
      currentLanguage: DEFAULT_EDITOR_SETTINGS.language,
      currentFontSize: DEFAULT_EDITOR_SETTINGS.fontSize,
      currentTabSize: DEFAULT_EDITOR_SETTINGS.tabSize,
      currentShowLineNumbers: DEFAULT_EDITOR_SETTINGS.showLineNumbers,
      currentShowInvisibles: DEFAULT_EDITOR_SETTINGS.showInvisibles,
      currentKeyBinding: DEFAULT_EDITOR_SETTINGS.keyBinding,

      resetToOriginal: true,
    });

    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;
      if (!this.preference) {
        this.preference = {};
      }

      var settings = this.preference.editorSettings;
      if (settings) {
        this.setState( {
          currentTheme: settings.theme,
          currentLanguage: settings.language,
          currentFontSize: settings.fontSize,
          currentTabSize: settings.tabSize,
          currentShowLineNumbers: settings.showLineNumbers,
          currentShowInvisibles: settings.showInvisibles,
          currentKeyBinding: settings.keyBinding,
        });

        editor.setTheme("ace/theme/" + settings.theme);
        
        editor.getSession().setMode("ace/mode/" + settings.language);

        if (settings.showLineNumbers) {
          editor.renderer.setShowGutter(true);
        } else {
          editor.renderer.setShowGutter(false);
        }

        if (settings.fontSize === 'small') {
          editor.setOptions({
            fontSize: "8pt",
          });
        }

        if (settings.fontSize === 'medium') {
          editor.setOptions({
            fontSize: "12pt",
          });
        }

        if (settings.fontSize === 'large') {
          editor.setOptions({
            fontSize: "16pt",
          });
        }

        if (settings.tabSize === '2') {
          editor.setOptions({
            tabSize: 2,
          });
        }

        if (settings.tabSize === '4') {
          editor.setOptions({
            tabSize: 4,
          });
        }

        if (settings.showInvisibles) {
          editor.setOption("showInvisibles", true);
        } else {
          editor.setOption("showInvisibles", false);
        }

        if (settings.keyBinding === '') {
          editor.setKeyboardHandler('');
        }

        if (settings.keyBinding === 'vim') {
          editor.setKeyboardHandler('ace/keyboard/vim');
        }

        if (settings.keyBinding === 'emacs') {
          editor.setKeyboardHandler('ace/keyboard/emacs');
        }
      }
      else {
        // no settings
        this.resetOriginal();
      }

      this.setState({
        processPreferenceDone: true,
      });
    });

    request({
      url: '/api/get_project',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error to get project!');
        return;
      }

      var project = res.project;

      this.originalCode = project.code;
      this.code = project.code;
      this.name = project.name;
      this.title = project.title;
      this.instructions = project.instructions;
      this.input = project.input;
      this.output = project.output;
      this.hint = project.hint;
      this.tags = project.tags;
      this.author = project.username;
      this.projectCanSkip = project.canSkip;
      this.backendOnly = project.backendOnly;
      this.shareWith = project.shareWith;
      this.solution = project.solution;

      if (this.shareWith) {
        this.sharedUsernames = this.shareWith.split(/\s*;\s*/g);
        this.sharedUsernames = this.sharedUsernames.filter(item => item !== '');
      }

      this.fileName = getUniqueFileName(this.id, this.title, this.username);

      this.state.editor.setValue(this.code, -1);

      // var i = this.state.editor.session.getLength() - 1;
      // var currentLine = this.state.editor.session.getLine(i);

      var Range = ace.require("ace/range").Range;
      var range = new Range(1, 4, 1, 4);
      this.state.editor.selection.setSelectionRange(range, false);
      this.state.editor.focus();

      this.startTime = new Date().getTime(); // milliseconds
      this.duration = 0;
      this.setState({
        loaded: true,
      });
    }); 
    
    this.audioSuccess = new Audio('/sound/c2.mp3');
    this.audioFail = new Audio('/sound/pop.wav');

    this.setState({
      processActivitiesDone: false,
    });

    this.userSet = new Set();
    this.saves = 0;
    this.runs = 0;
    this.errors = 0;
    this.errorMessage = '';

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('ERROR: failed to get project activities');
        return;
      }

      this.activities = res.activities;
      this.processActivities();

      this.setState({
        processActivitiesDone: true,
      });
    });

  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processActivities() {
    this.activities.forEach(item => {
      this.userSet.add(item.username);

      var activity = item.activity;
      if (activity.description === 'Saved')
        this.saves ++;

      if (activity.description === 'Run')
        this.runs ++;

      if (activity.error !== "") {
        this.errors ++;
        this.errorMessage += '[' + item.username + ']';
        this.errorMessage += activity.error + '\n';
      }
    });
  }

  getInsights() {
    if (!this.state || !this.state.processActivitiesDone || !isAdmin(this.user))
      return (<div></div>);

    return (
      <div style={insightContainer}>
        <div style={insightStyle}>
          { this.userSet.size } <span style={insightFontStyle}>users</span>
          { this.saves } <span style={insightFontStyle}>saves</span>
          { this.runs } <span style={insightFontStyle}>runs</span>
          { this.errors } <span style={insightFontStyle}>errors</span>
        </div>
      </div>
    );
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  runCode() {
    if (this.backendOnly)
      return;
    
    var prog = this.state.editor.getValue(); 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);

    this.setState({
      content: content,
    });

    iframeDoc.write(content);
    iframeDoc.close();
  }

  /*
   * run project 
   */
  onRun() {
    if (!isUserSignedIn(this.user)) {
      alert('To run the project, please sign in.');
      return;
    }

    if (this.state.showSettings) {
      // this.setState({
      //   showSettings: false,
      // });
      this.onSettings();
    }

    this.setState({
      error: "",
    });

    this.code = this.state.editor.getValue();
    this.language = detectProgrammingLanguage(this.code);

    var content = '';
    if (this.language === 'python') {
      this.runCode();
    } else {
      content = '<div style="color: grey">Running...</div>';
      this.setState({
        content: content,
      });

      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
    }

    var error = "";

    this.duration = new Date().getTime() - this.startTime;

    // TODO: consider to use server side time for logging to be more consistent
    this.submissionTime = getDateTime();

    request({
      url: '/api/run_project',
      method: 'POST',
      payload: {
        id: this.id,
        username: this.username,
        name: this.name,
        title: this.title,
        code: this.code,
        instructions: this.instructions,
        hint: this.hint,
        input: this.input,
        output: this.output,
        tags: this.tags,
        backendOnly: this.backendOnly,
      }
    }, (err, res) => {
      if (err) {
        error = "Error: Oops! Something went wrong when running project.";
        // alert(error);
        console.log(error);
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_JAVA_WORDS') {
        error = 'Error: "' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'MISSING_INPUT') {
        error = "Did you forget to include <b>" + res.item + "</b> in your code?";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({ error: error });

        var content = '';
        if (this.language === 'python') {
          content = this.state.content + '<div style="color: red">' + this.state.error + '</div>';
        } else {
          content = '<div style="color: red"><pre>' + this.state.error + '</pre></div>';
        }

        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
        return;
      }

      // no errors
      if (this.language === 'java') {
        content = '<div><pre>' + res.output + '</pre></div>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
      }

      if (this.backendOnly && res && res.results && res.results.length > 0) {
        var content = '<pre>' + res.results[0] + '</pre>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();

        iframeDoc.write(content);
        iframeDoc.close();
      }
      // const activity = {
      //   type: 'Project',
      //   username: this.username,
      //   id: this.id,
      //   activity: {
      //     description: 'Run',
      //     name: this.name,
      //     title: this.title,
      //     code: '',
      //     error: error,
      //     startTime: this.startTime,
      //     duration: this.duration,
      //   },
      // };

      // this.logs.push(activity);
    });
  }

  onSave() {
    if (!this.username)
      return;

    var answer = confirm('Are you sure you want to save your code?\n(This will overwrite the last version.)');
    if (!answer) {
      alert('OK, code is not saved.');
      return;
    }

    this.file = this.state.editor.getValue();

    request({
      url: '/api/save_file',
      method: 'POST',
      payload: {
        username: this.username,
        file: this.file,
        fileName: this.fileName,
        projectName: this.name,
        projectTitle: this.title,
        id: this.id,
        type: 'Project',
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your code.");
        return;
      }

      var activities = [{
        type: 'Project',
        username: this.username,
        id: this.id,
        activity: {
          description: 'Saved',
          name: this.name,
          title: this.title,
          code: this.file,
          error: '',
          file: this.fileName,
        },
        time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      }]; 

      this.props.onAddActivities(activities);

      alert("Your code has been saved.");
    });
  }

  onSaveSolution() {
    if (!this.username)
      return;

    var answer = confirm('Are you sure you want to save the current code as solution?');
    if (!answer) {
      alert('OK, solution is not saved.');
      return;
    }

    this.solution = this.state.editor.getValue();

    request({
      url: '/api/save_project',
      method: 'POST',
      payload: {
        username: this.author,
        name: this.name,
        title: this.title,
        code : this.originalCode,
        instructions: this.instructions,
        hint: this.hint,
        input: this.input,
        output: this.output,
        tags: this.tags,
        canSkip: this.projectCanSkip,
        backendOnly: this.backendOnly,
        shareWith: this.shareWith,
        id: this.id,
        solution: this.solution,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your solution.");
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        alert('Error: "import ' + res.module + '" is not currently supported by the online editor.');
      }

      if (res.status === 'CONTAINS_EVAL') {
        alert("Error: eval is not supported by the online editor.");
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }

  onLoad() {
    if (!this.username)
      return;

    var answer = confirm('Are you sure you want to get the saved version?');
    if (!answer) {
      alert('OK');
      return;
    }

    request({
      url: '/api/load_file',
      method: 'GET',
      payload: {
        username: this.username,
        filename: this.fileName,
        random: Math.random(),
      }
    }, (err, res) => {
        if (err) {
          alert('Oops! Something went wrong when loading your code.');
          return;
        }

        if (!res.file) {
          alert('Your file is empty. Have you saved it?');
          return;
        }

        this.state.editor.setValue(res.file, -1);
    });
  }

  onShowSolution() {
    if (!this.solution) {
      alert('No solution file has been saved yet.');
      return;
    }

    var answer = confirm('Are you sure you want to load the solution?');
    if (!answer) {
      alert('OK');
      return;
    }

    this.state.editor.setValue(this.solution, -1);
  }

  onEdit() {
    this.setState({ doEdit: true });
  }

  onSettings() {
    this.setState({
      showSettings: !this.state.showSettings,
    });

    if (this.state.showSettings) {
      var editorSettings = {
        theme: this.state.currentTheme,
        language: this.state.currentLanguage,
        fontSize: this.state.currentFontSize,
        tabSize: this.state.currentTabSize,
        showLineNumbers: this.state.currentShowLineNumbers,
        showInvisibles: this.state.currentShowInvisibles,
        keyBinding: this.state.currentKeyBinding,
      }

      this.preference.editorSettings = editorSettings;

      request({
        url: '/post_user_preference',
        method: 'POST',
        payload: {
          username: this.username,
          preference: this.preference,
          settings: this.preference.editorSettings,
          type: 'EditorChange',
        }
      }, (err, res) => {
        if (err) {
          console.log("Oops! Something went wrong when saving user preference.");
        }
      });
    }
  }

  onShowInstructions() {
    this.setState({
      doInstructions: !this.state.doInstructions, 
    });
  }

  onShowCode() {
    this.setState({
      doCode: !this.state.doCode, 
    });
  }

  onDelete() {
    var answer = confirm('This will delete project ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_project',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('error to delete project');
            return;
          }

        alert('project ' + this.id + ' is deleted');
      });
    } else {
      alert('ok, no change is made');
    }
  }

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  onSettingsChange(e) {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + e.target.value);

    this.setState({
      currentTheme: e.target.value,
    });
  }

  onLanguageSettingsChange(e) {
    const editor = ace.edit(this.refs.root);
    editor.getSession().setMode("ace/mode/" + e.target.value);

    this.setState({
      currentLanguage: e.target.value,
    });
  }

  onShowLineNumbersChange(e) {
    this.setState({
      currentShowLineNumbers: !this.state.currentShowLineNumbers,
    });

    const editor = ace.edit(this.refs.root);

    if (!this.state.currentShowLineNumbers) {
      editor.renderer.setShowGutter(true);
    } else {
      editor.renderer.setShowGutter(false);
    }
  }

  onShowInvisiblesChange(e) {
    this.setState({
      currentShowInvisibles: !this.state.currentShowInvisibles,
    });

    const editor = ace.edit(this.refs.root);

    if (!this.state.currentShowInvisibles) {
      editor.setOption("showInvisibles", true);
    } else {
      editor.setOption("showInvisibles", false);
    }
  }

  onResetToOriginal(e) {
    this.setState({
      resetToOriginal: !this.state.resetToOriginal,
    });

    if (this.state.resetToOriginal) {
      this.resetOriginal();

      this.setState( {
        currentTheme: DEFAULT_EDITOR_SETTINGS.theme,
        currentFontSize: DEFAULT_EDITOR_SETTINGS.fontSize,
        currentTabSize: DEFAULT_EDITOR_SETTINGS.tabSize,
        currentShowLineNumbers: DEFAULT_EDITOR_SETTINGS.showLineNumbers,
        currentShowInvisibles: DEFAULT_EDITOR_SETTINGS.showInvisibles,
        currentKeyBinding: DEFAULT_EDITOR_SETTINGS.keyBinding,
      });
    }
  }

  onFontSizeChange(e) {
    const editor = ace.edit(this.refs.root);

    if (e.target.value === 'small') {
      editor.setOptions({
        fontSize: "8pt",
      });
    }

    if (e.target.value === 'medium') {
      editor.setOptions({
        fontSize: "12pt",
      });
    }

    if (e.target.value === 'large') {
      editor.setOptions({
        fontSize: "16pt",
      });
    }

    this.setState({
      currentFontSize: e.target.value,
    });
  }

  onTabSizeChange(e) {
    const editor = ace.edit(this.refs.root);

    if (e.target.value === '2') {
      editor.setOptions({
        tabSize: 2,
      });
    }

    if (e.target.value === '4') {
      editor.setOptions({
        tabSize: 4,
      });
    }

    this.setState({
      currentTabSize: e.target.value,
    });
  }

  onKeyBindingChange(e) {
    const editor = ace.edit(this.refs.root);

    if (e.target.value === '') {
      editor.setKeyboardHandler('');
    }

    if (e.target.value === 'vim') {
      editor.setKeyboardHandler('ace/keyboard/vim');
    }

    if (e.target.value === 'emacs') {
      editor.setKeyboardHandler('ace/keyboard/emacs');
    }

    this.setState({
      currentKeyBinding: e.target.value,
    });
  }

  getRunButton() {
    if (this.projectCanSkip)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onRun}>
        Run
      </Button>
    );
  }

  getSaveButton() {
    if (!this.username)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
        Save
      </Button>
    );
  }

  getLoadButton() {
    if (!this.username)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onLoad}>
        Load
      </Button>
    );
  }

  doDisplayShowSolution() {
    // for admin, we enable Save Solution, but not View Solution
    // since admin already has too many menu options
    if (isAdmin(this.user))
      return false;

    if (canUserViewSolution(this.user))
      return true;

    if (this.username != this.author) {
      if (!this.sharedUsernames.includes(this.user.username)) {
        return false;
      }
    }

    return true;
  }

  getShowSolutionButton() {
    if (!this.doDisplayShowSolution())
      return null;

    return(
      <Button style={buttonReportBugStyle} onClick={this.onShowSolution}>
        Show Solution
      </Button>
    );
  }

  doDisplaySaveSolution() {
    if (isAdmin(this.user))
      return true;

    return false;
  }

  getSaveSolutionButton() {
    if (!this.doDisplaySaveSolution())
      return null;

    return (
      <Button style={buttonReportBugStyle} onClick={this.onSaveSolution}>
        Save Solution
      </Button>
    );
  }

  doDisplaySendFeedback() {
    // We disable this for admin as the number of buttons are too many.
    // admin has Delete and Edit as well.
    if (isAdmin(this.user))
      return false;

    return true;
  }

  getReportBug() {
    if (!this.doDisplaySendFeedback())
      return null;

    var location = 'project?id=' + this.id;

    return(
      <a href={ 'contact?location=' + location } target='_blank'>
        <Button style={buttonReportBugStyle}>
          Send Feedback
        </Button>
      </a>
    );
  }

  canEdit() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        if (!this.sharedUsernames.includes(this.user.username)) {
          return false;
        }
      }
    }

    return true;
  }

  getEditButton() {
    if (!this.canEdit())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onEdit}>
        Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (!this.canEdit())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
      Delete
      </Button>
    );
  }

  getSettingsText() {
    if (!this.state || !this.state.showSettings) {
      return "Settings";
    }

    if (this.state.showSettings) {
      return "Save Settings";
    }

    return "Settings";
  }

  getSetttingsButton() {
    return (
      <Button style={buttonSettingsStyle} onClick={this.onSettings}>
         { this.getSettingsText() }
      </Button>
    );
  }

  getIdFromURL() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // e.g. http://www.cswonders.com/note?id=abcdefgh
    const href = window.location.href;
    const PATTERN = 'project?id=';
    const PATTERN_LEN = PATTERN.length;
    const index = href.indexOf(PATTERN);

    return href.substring(index + PATTERN_LEN);
  }

  getDescription() {
    return (
      <div style={descriptionStyle}> 
        <table style={descriptionTableStyle}>
          <tbody>
            <tr>
              <td style={tdIconStyle}>
              <img width="50px" height="50px" src='/img/project1.svg' ></img>
              </td>

              <td>
              <div style={projectTitleStyle}><h4> { "Project: " + this.title ? this.title : '' } </h4></div>

              <span style={descButtonStyle}>
                <Button style={instructionButtonStyle} onClick={this.onShowInstructions}>
                  <h5> 
                    { this.state && this.state.doInstructions ? 'Hide Instructions' : 'Show Instructions' } 
                    {
                      // <span style={arrowStyle}>{ this.state && this.state.doInstructions ? '▲' : '▼' }</span>
                    }
                  </h5>
                </Button>
              </span>

              <span style={descButtonStyle}>
                <Button style={instructionButtonStyle} onClick={this.onShowCode}>
                  <h5> 
                    { this.state && this.state.doCode ? 'Hide Code' : 'Show Code' } 
                  </h5>
                </Button>
              </span>
              </td>
              <td>
                { this.getInsights() }
              </td>
            </tr>

          </tbody>
        </table>

        {
          (this.state && this.state.doInstructions) ? 
          <div>
            <div style={instructionsStyle}>
              <hr></hr>
              <div dangerouslySetInnerHTML={{ __html: this.instructions }}></div>
            </div>
            { this.getHint() }
          </div>
          :
          <div></div>
        }
      </div>
    );
  }

  getSettingsIcon() {
    if (!this.state || !this.state.showSettings) {
      return (
        <img src='img/settings.svg' style={settingsIconStyle} onClick={this.onSettings}></img>
      );
    }

    if (this.state.showSettings) {
      return (
        <img src='img/close.svg' style={settingsIconStyle} onClick={this.onSettings}></img>
      );
    }

    return (
      <img src='img/settings.svg' style={settingsIconStyle} onClick={this.onSettings}></img>
    );
  }

  getRunComponents() {
    if (!this.state) {
      return (<div></div>);
    }

    return (
      <div>
        <span>
          { this.getRunButton() }
          { this.getSaveButton() }
          { this.getLoadButton() }
          { this.getDeleteButton() }
          { this.getEditButton() }
          { this.getShowSolutionButton() }
          { this.getSaveSolutionButton() }
          { this.getReportBug() }
        </span>

        <span style={{'float':'right'}}>
          { this.getSetttingsButton() }
        </span>

        {
          this.getSettingsIcon() 
        }
      </div>
    );
  }

  getHint()
  {
    if (!this.hint)
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  getKeyBindingChecked(option) {
    if (!this.state || !this.state.currentKeyBinding) {
      if (option === DEFAULT_EDITOR_SETTINGS.keyBinding)
        return 'checked';

      return '';
    }

    if (option === this.state.currentKeyBinding)
      return 'checked';

    return '';
  }

  getTabSizeChecked(option) {
    if (!this.state || !this.state.currentTabSize) {
      if (option === DEFAULT_EDITOR_SETTINGS.tabSize)
        return 'checked';

      return '';
    }

    if (option === this.state.currentTabSize)
      return 'checked';

    return '';
  }

  getFontSizeChecked(option) {
    if (!this.state || !this.state.currentFontSize) {
      if (option === DEFAULT_EDITOR_SETTINGS.fontSize)
        return 'checked';

      return '';
    }

    if (option === this.state.currentFontSize)
      return 'checked';

    return '';
  }

  getRestToOriginalChecked() {
    if (!this.state || this.state.resetToOriginal)
      return '';

    if (!this.state.resetToOriginal)
      return 'checked';

    return '';
  }

  getShowLineNumbersChecked() {
    if (!this.state || !this.state.currentShowLineNumbers)
      return '';

    if (this.state.currentShowLineNumbers)
      return 'checked';

    return '';
  }

  getShowInvisiblesChecked() {
    if (!this.state || !this.state.currentShowInvisibles)
      return '';

    if (this.state.currentShowInvisibles)
      return 'checked';

    return '';
  }

  getThemeSelected(option) {
    if (!this.state || !this.state.currentTheme) {
      if (option === DEFAULT_EDITOR_SETTINGS.theme)
        return 'selected';

      return '';
    }

    if (option === this.state.currentTheme)
      return 'selected';

    return '';
  }

  getLanguageSelected(option) {
    if (!this.state || !this.state.currentLanguage) {
      if (option === DEFAULT_EDITOR_SETTINGS.language)
        return 'selected';

      return '';
    }

    if (option === this.state.currentLanguage)
      return 'selected';

    return '';
  }

  displaySettings() {
    if (!this.state || !this.state.showSettings || !this.state.processPreferenceDone) {
      return (
        <div></div>
      );
    }

    return (
      <div style={settingContainerStyle}>
        <h3> { this.user.firstName + "'s dream editor" } </h3>

        <hr></hr>

        <h4>Theme</h4>

        <select style={selectorStyle} id="theme" name="theme" onChange={this.onSettingsChange}>
        <optgroup label='Dark'>
          <option value="ambiance" selected={this.getThemeSelected('ambiance')} >Ambiance</option>
          <option value="chaos" selected={this.getThemeSelected('chaos')} >Chaos</option>
          <option value="clouds_midnight" selected={this.getThemeSelected('clouds_midnight')} >Clouds Midnight</option>
          <option value="cobalt" selected={this.getThemeSelected('cobalt')} >Cobalt</option>
          <option value="idle_fingers" selected={this.getThemeSelected('idle_fingers')} >Idle Fingers</option>
          <option value="kr_theme" selected={this.getThemeSelected('kr_theme')} >Kr Theme</option>
          <option value="merbivore" selected={this.getThemeSelected('merbivore')} >Merbivore</option>
          <option value="mono_industrial" selected={this.getThemeSelected('mono_industrial')} >Mono Industrial</option>
          <option value="monokai" selected={this.getThemeSelected('monokai')} >Monokai</option>
          <option value="pastel_on_dark" selected={this.getThemeSelected('pastel_on_dark')} >Pastel on Dark</option>
          <option value="solarized_dark" selected={this.getThemeSelected('solarized_dark')} >Solarized Dark</option>
          <option value="terminal" selected={this.getThemeSelected('terminal')} >Terminal</option>
          <option value="tomorrow_night" selected={this.getThemeSelected('tomorrow_night')} >Tomorrow Night</option>
          <option value="tomorrow_night_blue" selected={this.getThemeSelected('tomorrow_night_blue')} >Tomorrow Night Blue</option>
          <option value="tomorrow_night_bright" selected={this.getThemeSelected('tomorrow_night_bright')} >Tomorrow Night Bright</option>
          <option value="tomorrow_night_eighties" selected={this.getThemeSelected('tomorrow_night_eighties')} >Tomorrow Night 80s</option>
          <option value="twilight" selected={this.getThemeSelected('twilight')} >Twilight</option>
          <option value="vibrant_ink" selected={this.getThemeSelected('vibrant_ink')} >Vibrant Ink</option>
        </optgroup>
        <optgroup label='bright'>
          <option value="chrome" selected={this.getThemeSelected('chrome')} >Chrome</option>
          <option value="clouds" selected={this.getThemeSelected('clouds')} >Clouds</option>
          <option value="crimson_editor" selected={this.getThemeSelected('crimson_editor')} >Crimson Editor</option>
          <option value="dawn" selected={this.getThemeSelected('dawn')} >Dawn</option>
          <option value="dreamweaver" selected={this.getThemeSelected('dreamweaver')} >Dreamweaver</option>
          <option value="eclipse" selected={this.getThemeSelected('eclipse')} >Eclipse</option>
          <option value="iplastic" selected={this.getThemeSelected('iplastic')} >IPlastic</option>
          <option value="solarized_light" selected={this.getThemeSelected('solarized_light')} >Solarized Light</option>
          <option value="textmate" selected={this.getThemeSelected('textmate')} >TextMate</option>
          <option value="tomorrow" selected={this.getThemeSelected('tomorrow')} >Tomorrow</option>
          <option value="xcode" selected={this.getThemeSelected('xcode')} >XCode</option>
          <option value="kuroir" selected={this.getThemeSelected('kuroir')} >Kuroir</option>
          <option value="katzenmilch" selected={this.getThemeSelected('katzenmilch')} >KatzenMilch</option>
          <option value="sqlserver" selected={this.getThemeSelected('sqlserver')} >SQL Server</option>
        </optgroup>
        </select>

        <br />

        <hr></hr>
        <h4>Basic editor settings</h4>

        <table>
          <tbody>
          <tr>
            <td style={tdSettingsLeft}>
              <span> Font size </span>
            </td>

            <td style={tdSettingsRight}>
              <span style={radioStyle} >
                <input type="radio" name="fontSize" id='fontSize' value={'small'} checked={this.getFontSizeChecked('small')} onChange={this.onFontSizeChange} />
                <span style={{'marginLeft': '5px'}}>Normal</span>
              </span>

              <span style={radioStyle} >
                <input type="radio" name="fontSize" id='fontSize' value={'medium'} checked={this.getFontSizeChecked('medium')} onChange={this.onFontSizeChange} />
                <span style={{'marginLeft': '5px'}}>Large</span>
              </span>

              <span style={radioStyle}>
                <input type="radio" name="fontSize" id='fontSize' value={'large'} checked={this.getFontSizeChecked('large')} onChange={this.onFontSizeChange} />
                <span style={{'marginLeft': '5px'}}>Huge</span>
              </span>
            </td>

          </tr>

          <tr>
            <td style={tdSettingsLeft}>
              <span> Tab size </span>
            </td>

            <td style={tdSettingsRight}>
              <span style={radioStyle} >
                <input type="radio" name="tabSize" id='tabSize' value={'2'} checked={this.getTabSizeChecked('2')} onChange={this.onTabSizeChange} />
                <span style={{'marginLeft': '5px'}}>2 spaces</span>
              </span>

              <span style={radioStyle} >
                <input type="radio" name="tabSize" id='tabSize' value={'4'} checked={this.getTabSizeChecked('4')} onChange={this.onTabSizeChange} />
                <span style={{'marginLeft': '5px'}}>4 spaces</span>
              </span>
            </td>

          </tr>

          <tr>
            <td style={tdSettingsLeft}>
              <span>
                Show line numbers
              </span>
            </td>

            <td style={tdSettingsRight}>
              <span style={checkBoxStyle}>
              {
                <input type="checkbox" name="lineNumbers" value="on" checked={this.getShowLineNumbersChecked()} onChange={this.onShowLineNumbersChange}></input>
              }
              </span>
            </td>

          </tr>

          <tr>
            <td style={tdSettingsLeft}>
              <span>
                Show invisibles
              </span>
            </td>

            <td style={tdSettingsRight}>
              <span style={checkBoxStyle}>
              {
                <input type="checkbox" name="invisibles" value="on" checked={this.getShowInvisiblesChecked()} onChange={this.onShowInvisiblesChange}></input>
              }
              </span>
            </td>
          </tr>

          </tbody>
        </table>

        <hr></hr>
        <h4>Advanced editor settings</h4>

        <table>
        <tbody>

          <tr>
            <td style={tdSettingsLeft}>
              <span> Key bindings </span>
            </td>

            <td style={tdSettingsRight}>
              <span style={radioStyle} >
                <input type="radio" name="keyBinding" id='keyBinding' value={''} checked={this.getKeyBindingChecked('')} onChange={this.onKeyBindingChange} />
                <span style={{'marginLeft': '5px'}}>Standard</span>
              </span>

              <span style={radioStyle} >
                <input type="radio" name="keyBinding" id='keyBinding' value={'vim'} checked={this.getKeyBindingChecked('vim')} onChange={this.onKeyBindingChange} />
                <span style={{'marginLeft': '5px'}}>Vim</span>
              </span>

              <span style={radioStyle}>
                <input type="radio" name="keyBinding" id='keyBinding' value={'emacs'} checked={this.getKeyBindingChecked('emacs')} onChange={this.onKeyBindingChange} />
                <span style={{'marginLeft': '5px'}}>Emacs</span>
              </span>
            </td>
          </tr>

        </tbody>
        </table>

        <hr></hr>
        <h4>Programming Language</h4>

        <select style={selectorStyle} id="theme" name="theme" onChange={this.onLanguageSettingsChange}>
          <option value="python" selected={this.getLanguageSelected('python')} >Python</option>
          <option value="java" selected={this.getLanguageSelected('java')} >Java</option>
        </select>

        <br />

        <hr></hr>
        <table>
        <tbody>

          <tr>
            <td style={tdSettingsLeft}>
              <span>
                Reset to original settings
              </span>
            </td>

            <td style={tdSettingsRight}>
              <span style={checkBoxStyle}>
                <input type="checkbox" name="resetToOriginal" value="on" checked={this.getRestToOriginalChecked()} onChange={this.onResetToOriginal}></input>
              </span>
            </td>
          </tr>

        </tbody>
        </table>

      </div>

    );
  }

  render() {
    if (!this.username) {
      return (
        <div style={containerStyle}>
          <div style={messageStyle}>
            <img src="/img/info.svg" style={infoIconStyle}></img>
            <span> Please <a href='login'>sign in</a> to track your progress. </span>
          </div>
        </div>
      );
    }

    if (this.state && this.state.doEdit) {
      return (
        <div style={containerStyle}>
          <CreateProjectPage
            id={ this.id } 
            username={ this.author }
            name={ this.name }
            title={ this.title }
            code={ this.code }
            instructions = { this.instructions }
            hint={ this.hint }
            input  ={ this.input }
            output ={ this.output }
            tags={ this.tags }
            canSkip={ this.projectCanSkip }
            backendOnly={ this.backendOnly }
            shareWith = { this.shareWith }
            solution = { this.solution }
            store={ this.props.store }
          />
        </div>
      );
    }

    return (
      <div>
        { this.getDescription() }

        <table style={tableStyle}>
          <tbody>
            <tr>
              <td width="50%">
                <div>
                  { this.getRunComponents() }
                </div>

                {
                  (this.state && !this.state.doCode) ? 
                    <div ref="root" style={editorStyleHidden}>
                      {this.props.code}
                    </div>
                    :
                    <div ref="root" style={editorStyle}>
                      {this.props.code}
                    </div>
                }
              </td>

              <td width="1%">
              </td>

              <td width="49%">
              <div style={resultLabelStyle}> </div>
                { 
                  this.displaySettings()
                }
                {
                  !this.state || !this.state.showSettings ? 
                    <div className="iframewrapper">
                      <iframe ref="result" id="iframeResult" style={joinJsonObjects(resultStyle, this.props.style)}>
                      </iframe>
                    </div>
                    :
                    <div className="iframewrapper">
                      <iframe ref="result" id="iframeResult" style={joinJsonObjects(resultNoneStyle, this.props.style)}>
                      </iframe>
                    </div>
                }
              </td>

            </tr>
          </tbody>
        </table>
      </div>

    );
  }
}

export default ProjectPage;
