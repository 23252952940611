/*
 * AdminPage.js
 */
import React, { Component } from 'react';
import { request, modifyDatabase } from '../../util/api';
import { getLocalTime, isAdmin } from '../../util/util';
import Editor from '../../components/Editor';
import Button from '../Button';
import Input from '../Input';
import { MAX_ACTIVITY_COUNT } from '../../config/constant-config';

const container = {
  "padding": "20px",
  "backgroundColor": "white",
  "borderRadius": "20px",
  "margin": "35px 35px",
};

const buttonStyle = {
  "marginRight": "20px",
}

const timeStyle = {
  "color": "grey",
  "fontSize": "75%",
  "marginLeft": "10px",
};

const smallFontStyle = {
  "fontSize": "70%",
};

const leftTdStyle = {
  "verticalAlign": "top",
};

const tableStyle = {
  // "border": "solid 1px",
  "width": "100%",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
};

const inputStyle = {
  base: {
    "marginBottom": "10px",
    "display": "block",
    "boxSizing": "border-box",
    "padding": "10px 16px",
    "width": "100%",
    "height": "40px",
    "outline": "0px",
    "borderWidth": "1px",
    "borderStyle": "solid",
    "borderColor": "#ccc",
    "borderRadius": "0px",
    "background": "#fff",
    "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
    "color": "#616161",
    "fontSize": "14px",
    "lineHeight": "1.3333333",
    "transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
     ":focus": {
       "borderColor": "#0074c2",
       "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 116, 194, 0.6)",
     }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "5px",
    "marginBottom": "5px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "10px",
    "width": "140px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

class AdminPage extends Component {
  constructor() {
    super();

    this.users = [];
    this.usernameMap = {};
    this.activities = [];
    this.userActivitiesMap = {};
    this.userArray = [];
    this.teacherUsername = '';
    this.usernameForProgressLock = '';
    this.usernameToChangePassword = '';
    this.newPassword = '';
    this.newPassword2 = '';
    this.adminPassword = '';

    this.state = {
      showActivities: false,
      showFiles: false,
      showConfigureTeacher: false,
      showConfigureLocking: false,
      showChangePassword: false,
      showUsers: false,
      processActivitiesDone: false,
      processUsersDone: false,
      processUserMapDone: false,
      canSkipProgressLockUserMap: new Map(),
      processFilesDone: false,
    };

    this.onResetDatabase = this.onResetDatabase.bind(this);
    this.onGetUsers = this.onGetUsers.bind(this);
    this.onGetAllUserActivities = this.onGetAllUserActivities.bind(this);
    this.onGetAllUserFiles = this.onGetAllUserFiles.bind(this);
    this.onModifyDatabaseData = this.onModifyDatabaseData.bind(this);
    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onAddUserForSkippingProgressLock = this.onAddUserForSkippingProgressLock.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onRemoveUserForSkippingProgressLock = this.onRemoveUserForSkippingProgressLock.bind(this);
    this.onAddRemoveTeacher = this.onAddRemoveTeacher.bind(this);
    this.onAddRemoveLocking = this.onAddRemoveLocking.bind(this);
    this.onShowChangePassword = this.onShowChangePassword.bind(this);
    this.onTeacherUsername = this.onTeacherUsername.bind(this);
    this.onChangeUsernameForProgressLocking = this.onChangeUsernameForProgressLocking.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onNewPassword2Change = this.onNewPassword2Change.bind(this);
    this.onAdminPasswordChange = this.onAdminPasswordChange.bind(this);
  }

  componentDidMount() {
    this.user = this.props.store.getState().auth.user;
    this.username = this.props.username;
    
    request({
      url: '/api/users',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get users');
        return;
      }

      this.users = res.users;
      this.users.reverse();

      this.processUsers();

      this.setState({
        processUsersDone: true,
      });
    });

    request({
      url: '/api/recent_activities',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get activities');
        return;
      }
      
      this.activities = res.activities;
      this.processActivities();
      this.setState({
        processActivitiesDone: true,
      });
    });

    request({
      url: '/api/allUserFiles',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user files');
        return;
      }
      
      this.files = res.files;

      this.processFiles();

      this.setState({
        processFilesDone: true,
      })
    });

  }

  processFiles() {

  }

  processUsers() {
    var canSkipProgressLockUserMap = this.state.canSkipProgressLockUserMap;
    this.users.forEach(user => {
      this.usernameMap[user.username] = user;
      if (user.access && user.access.canSkipProgressLock == 'true') {
        canSkipProgressLockUserMap.set(user.username, user);
      }
    });
    this.setState({
      canSkipProgressLockUserMap : canSkipProgressLockUserMap,
    });
  }

  processActivities() {
    this.activities.forEach(item => {
      var username = item.username;
      if (!this.userActivitiesMap[username]) {
        this.userActivitiesMap[username] = [];
      }
      this.userActivitiesMap[username].push(item);
    });
  }

  processUserMap() {
    if (!this.state.processUsersDone || !this.state.processActivitiesDone)
      return;

    this.userArray = []
    this.users.sort((x, y) => {
      return Date.parse(y.time) - Date.parse(x.time);
    });

    this.setState({
      processUserMapDone: true,
    });
  }

  /*
   * reset database
   */
  onResetDatabase(e) {
    e.preventDefault();

    var answer = confirm("This will erase all user data from the database. Are you sure you want to proceed?");

    if (answer === true) {
      request({
        url: '/api/resetdb',
        method: 'POST',
        payload: { username: this.username }
      }, (err, res) => {
          if (err) {
            alert('error to reset db!');
            return;
          }

        alert('db is reset');
      });
    } else {
      alert('ok, no change is made');
    }
  }

  /*
   * get all users
   */
  onGetUsers(e) {
    this.setState({
      showUsers: !this.state.showUsers,
    })
  }

  /*
   * get all user activities
   */
  onGetAllUserActivities(e) {
    this.setState({
      showActivities: !this.state.showActivities
    });
  }

  /*
   * get all user files
   */
  onGetAllUserFiles(e) {
    this.setState({
      showFiles: !this.state.showFiles,
    });
  }

  onAddRemoveTeacher(e) {
    this.setState({
      showConfigureTeacher: !this.state.showConfigureTeacher,
    });
  }

  onAddRemoveLocking(e) {
    this.setState({
      showConfigureLocking : !this.state.showConfigureLocking,
    });
  }

  onShowChangePassword(e) {
    this.setState({
      showChangePassword: !this.state.showChangePassword,
    });
  }

  getAllUserFileItems() {
    var result = [];

    this.files.forEach(entry => {
      result.push(
        <h4>{ entry.username }</h4>
      );
      result.push(this.getFileItems(entry.files));
    });

    return result;
  }

  validateChangePasswordInput() {
    if (!this.usernameMap[this.usernameToChangePassword]) {
      alert('Username "' + this.usernameToChangePassword + '" is not found.');
      return false;
    }

    if (!this.newPassword) {
      alert('Please enter a new password');
      return false;
    }

    if (this.newPassword !== this.newPassword2) {
      alert("Passwords don't match.");
      return false;
    }

    if (!this.adminPassword) {
      alert('Please enter the admin passowrd.');
      return false;
    }

    return true;
  }

  validateInput(username) {
    if (!this.usernameMap[username]) {
      alert('Username "' + username + '" is not found.');
      return false;
    }

    return true;
  }

  getAddButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onAdd}>
        Add Teacher Role
      </Button>
    );
  }

  getAddUserForSkippingProgressLockButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onAddUserForSkippingProgressLock}>
        Add User for Skipping Progress Lock
      </Button>
    );
  }

  getChangePasswordButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onSubmitChangePassword}>
        Submit Change
      </Button>
    );
  }

  getRemoveButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onRemove}>
        Remove Teacher Role 
      </Button>
    );
  }

  getRemoveUserForSkipingProgressLockButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onRemoveUserForSkippingProgressLock}>
        Remove User for Skipping Progress Lock
      </Button>
    );
  }

  /*
   * Submit password change request
   */
  onSubmitChangePassword() {
    if (!this.validateChangePasswordInput())
      return;

    console.log(this.newPassword + ';' + this.usernameToChangePassword);

    request({
      url: '/sign_up',
      method: 'POST',
      payload: {
        username: this.usernameToChangePassword,
        password: this.newPassword,
        adminPassword: this.adminPassword,
        updatePassword: 'true',
      }
    }, (err, res) => {
      if (err) {
        alert('Failed to update password.');
        return;
      }

      if (res.user.flash === 'WRONG_PASSWORD') {
        alert("Admin password doesn't match. Please try again.");
        return;
      }

      alert('Password has been updated.');
    });
  } 

  /*
   * Add the teacher permission
   */
  onAdd() {
    if (!this.validateInput(this.teacherUsername))
      return;

    request({
      url: '/api/add_teacher_role',
      method: 'POST',
      payload: {
        username: this.username,
        teacherUsername: this.teacherUsername,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when adding teacher role.");
        return;
      }

      alert(res.status);
    });
  } 

  /*
   * Add the user for skipping progress lock
   */
  onAddUserForSkippingProgressLock() {
    if (!this.validateInput(this.usernameForProgressLock))
      return;

    request({
      url: '/api/add_user_for_skipping_progress_lock',
      method: 'POST',
      payload: {
        username: this.username,
        usernameForProgressLock: this.usernameForProgressLock,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when adding user for skipping progress lock.");
        return;
      }

      var canSkipProgressLockUserMap = this.state.canSkipProgressLockUserMap;
      canSkipProgressLockUserMap.set(this.usernameForProgressLock, this.usernameMap[this.usernameForProgressLock]);
      this.setState({
        canSkipProgressLockUserMap : canSkipProgressLockUserMap,
      });

      alert(res.status);
    });
  } 

  /*
   * Remove the teacher permission
   */
  onRemove() {
    if (!this.validateInput(this.teacherUsername))
      return;

    request({
      url: '/api/remove_teacher_role',
      method: 'POST',
      payload: {
        username: this.username,
        teacherUsername: this.teacherUsername,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when removing teacher role.");
        return;
      }

      alert(res.status);
    });
  } 

  /*
   * Remove the user who could skip progress lock
   */
  onRemoveUserForSkippingProgressLock() {
    if (!this.validateInput(this.usernameForProgressLock))
      return;

    request({
      url: '/api/remove_user_for_skipping_progress_lock',
      method: 'POST',
      payload: {
        username: this.username,
        usernameForProgressLock: this.usernameForProgressLock,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when removing user for skipping progress lock.");
        return;
      }

      var canSkipProgressLockUserMap = this.state.canSkipProgressLockUserMap;
      canSkipProgressLockUserMap.delete(this.usernameForProgressLock);
      this.setState({
        canSkipProgressLockUserMap : canSkipProgressLockUserMap,
      });

      alert(res.status);
    });
  } 

  onTeacherUsername(e) {
    this.teacherUsername = e.target.value;
  }
 
  onChangeUsernameForProgressLocking(e) {
    this.usernameForProgressLock = e.target.value;
  }
 
  onUsernameChange(e) {
    this.usernameToChangePassword = e.target.value;
  }
 
  onNewPasswordChange(e) {
    this.newPassword = e.target.value;
  }
 
  onNewPassword2Change(e) {
    this.newPassword2 = e.target.value;
  }
 
  onAdminPasswordChange(e) {
    this.adminPassword = e.target.value;
  }
 
  getConfigureTeacher() {
    return (
      <div>
        <br />
        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>
              <tr>
                <td style={columnStyle}> Username </td>
                <td>
                  <div>
                    <textarea id="name" rows="1" cols="85" name="teacherUsername" onChange={this.onTeacherUsername}
                    defaultValue={ this.teacherUsername }></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={columnStyle}> </td>
                <td>
                  <div>
                    { this.getAddButton() }
                    { this.getRemoveButton() }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    );
  }

  getConfigureLocking() {
    return (
      <div>
        <br />
        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>
              <tr>
                <td style={columnStyle}> Username </td>
                <td>
                  <div>
                    <textarea id="name" rows="1" cols="85" name="usersForSkippingLocking" onChange={this.onChangeUsernameForProgressLocking}
                    defaultValue=""></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={columnStyle}> </td>
                <td>
                  <div>
                    { this.getAddUserForSkippingProgressLockButton() }
                    { this.getRemoveUserForSkipingProgressLockButton() }
                    { this.getUsersForSkippingProgressLockItems() }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    );
  }

  getChangePassword() {
    return (
      <div>
        <br />
        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>
              <tr>
                <td style={columnStyle}> Username </td>
                <td>
                  <Input
                    style={inputStyle}
                    id="usernameToChangePassword"
                    type="text"
                    name="usernameToChangePassword"
                    onChange={this.onUsernameChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={columnStyle}> Enter new password</td>
                <td>
                  <Input
                    style={inputStyle}
                    id="newPassword"
                    type="password"
                    name="newPassword"
                    onChange={this.onNewPasswordChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={columnStyle}> Verify new password</td>
                <td>
                  <Input
                    style={inputStyle}
                    id="newPassword2"
                    type="password"
                    name="nwePassword2"
                    onChange={this.onNewPassword2Change}
                  />
                </td>
              </tr>
              <tr>
                <td style={columnStyle}> Enter admin password</td>
                <td>
                  <Input
                    style={inputStyle}
                    id="adminPassword"
                    type="password"
                    name="verifyPassword"
                    onChange={this.onAdminPasswordChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={columnStyle}> </td>
                <td>
                  <div>
                    { this.getChangePasswordButton() }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    );
  }

  getName(username) {
    if (!this.usernameMap[username])
      return '';

    return this.usernameMap[username].firstName + ' ' + this.usernameMap[username].lastName;
  }

  getActivityItems() {
    var result = [];
    result.push(<br />);

    var recentActivities = this.activities.slice(0, MAX_ACTIVITY_COUNT);
    recentActivities.forEach(item => {
      var time = item.time;
      var type = item.type;
      var username = item.username;
      var activity = item.activity;

      switch (type) {
       case 'Lesson':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'lesson?id=' + item.id}>
            { activity.lessonName }
            </a>
            {' '}
            (unit { activity.groupId })
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Nugget':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'run?id=' + item.id}>
            { activity.name } {' '}
            </a>
            {
/*
            ({ activity.correctCount }/{ activity.totalCount } answered correctly)
*/
            }
            { 
              activity.error ?
              <span> {' ' + activity.error }</span>
              :
              <span></span>
            }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Challenge':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description } ({ activity.score }/{ activity.total } answered correctly on first try)
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Tutorial':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'File':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description } - { activity.fileName }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Account':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Snippet':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'snippet?id=' + item.id}>
            { activity.title } 
            </a>
            {' '} (passed: { activity.passed ? 'yes' : 'no' }; attempts: { activity.attemptCount })
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Quiz':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'quiz?id=' + item.id}>
            { activity.name } 
            </a>
            {' '} (passed: { activity.passed ? 'yes' : 'no' }; choice: { activity.choice })
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Project':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description } - 
            <a href={'project?id=' + item.id}>
            { activity.title }
            </a>
            {
              activity.error?
              <span>{ activity.error }</span>
              :
              <span></span>
            }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Feedback':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      default:
        console.log('Unknown type ' + type);
      }
    });

    return result;
  }

  getFileItems(files) {
    var result = [];
    const SHOW_EDITOR = false;

    for (var key in files) {
      result.push(
        <div>
          { key }
          {
            SHOW_EDITOR ? 
            <Editor store={this.props.store} code={ files[key] } run='true' style={{"height": "400px"}} />
            :
            <span></span>
          }
        </div>
      );
    }

    return result;
  }

  /*
   * this is used to migrate / update data from the database
   */
  onModifyDatabaseData() {
    request({
      url: '/api/allUserActivities',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
        if (err) {
          console.log('Error to get all user activities!');
          return;
        }
        
        var allActivities = res.activities;
        console.log('total activities: ' + allActivities.length);
        console.dir(allActivities);

        var newActivities = [];
        var totalActivityCount = 0;
        var act = undefined;

        for (var i = 0; i < allActivities.length; i++) {
          // for each user
          var item = allActivities[i];
          var username = item.username;
          var activities = item.activities;

          if (username === 'admin' || username === 'jason' || username === 'wei')
            continue;

          // if (username != 'goldenpig')
          //   continue;

          // if (username != 'l3u0y4ncy')
          //   continue;

          var tutorialMap = new Map();
          var challengeMap = new Map();
          var fileMap = new Map();
          var userActivityCount = 0;

          activities.forEach(activity => {
            switch (activity.type) {
              case 'Lesson':
                act = {
                  type: 'Lesson',
                  username: username,
                  id: activity.lessonId,
                  time: activity.time,
                  activity: {
                    lessonName: activity.lessonName,
                    groupId: activity.groupId,
                    currentSkillIndex: activity.currentSkillIndex,
                    skillList: activity.skillList,
                  },
                };

                newActivities.push(act);
                userActivityCount ++;
                totalActivityCount ++;

                break;

              case 'Nugget': 
                act = {
                  type: 'Nugget',
                  username: username,
                  id: activity.id ? activity.id : activity.funcName,
                  time: activity.time,
                  activity: {
                    name: activity.funcName,
                    passed: activity.passed,
                    correctCount: activity.correctCount,
                    totalCount: activity.totalCount,
                    code: '',
                    error: '',
                    submissionTime: '',
                    duration: activity.duration,
                  },
                };

                newActivities.push(act);
                userActivityCount ++;
                totalActivityCount ++;

                break;

              case 'Tutorial':
                act = {
                  type: 'Tutorial', 
                  username: username,
                  id: activity.description,
                  time: activity.time,
                  activity: {
                    description: activity.description,
                  }
                };

                if (!tutorialMap.get(act.id)) {
                  tutorialMap.set(act.id, act);
                  newActivities.push(act);
                  userActivityCount ++;
                  totalActivityCount ++;
                }

                break;

              case 'Challenge':
                act = {
                  type: 'Challenge',
                  username: username,
                  id: activity.description,
                  time: activity.time,
                  activity: {
                    description: activity.description,
                    score: activity.score,
                    total: activity.total,
                  },
                };

                if (!challengeMap.get(act.id)) {
                  if (act.score === act.total) {
                    challengeMap.set(act.id, act);
                    newActivities.push(act);
                    userActivityCount ++;
                    totalActivityCount ++;
                  }
                }

                break;

              case 'File':
                act = {
                  type: 'File',
                  username: username,
                  id: activity.file,
                  time: activity.time,
                  activity: {
                    description: activity.description,
                    fileName: activity.fileName ? activity.fileName : activity.file,
                  },
                };

                if (!fileMap.get(act.id)) {
                  fileMap.set(act.id, act);
                  newActivities.push(act);
                  userActivityCount ++;
                  totalActivityCount ++;
                }

                break;

              case 'Account':
                act = {
                  type: 'Account',
                  username: username,
                  id: username,
                  time: activity.time,
                  activity: {
                    description: activity.description,
                  }
                };

                newActivities.push(act);
                userActivityCount ++;
                totalActivityCount ++;

                break;

              case 'Snippet':
                act = {
                  type: 'Snippet',
                  username: username,
                  id: activity.id,
                  time: activity.time,
                  activity: {
                    name: activity.name,
                    title: activity.title,
                    passed: activity.passed,
                    attemptCount: activity.attemptCount,
                    submissionTime: activity.submissionTime,
                    duration: activity.duration,
                    error: activity.error,
                    code: activity.code,
                  }
                };

                newActivities.push(act);
                userActivityCount ++;
                totalActivityCount ++;

                break;

              case 'Quiz':
               break;

              case 'Project':
                break;

              default:
                console.log('ERROR: unknown type: ' + activity.type);
            }

            if (newActivities.length > 0 && newActivities.length % 100 === 0) {
              modifyDatabase(username, newActivities);
              newActivities = [];
            }
          });

          if (newActivities.length > 0) {
            modifyDatabase(username, newActivities);
            console.dir(newActivities);
            newActivities = [];
          }

          console.log('User:' + username + ' done => User activity count: ' + userActivityCount);
        }
        console.log('All done. Total activity count: ' + totalActivityCount);

    });
  }

  getUserItems() {
    var result = [];

    result.push(<div><b>Number of Registered Users: </b> { this.users.length } <br /> <br /> </div>);
    this.users.forEach(user => {
      result.push(
        <div>
          <p>
          <b>{user.username} </b>
            [<b>{user.firstName} {user.lastName}</b>];
            personal email: {user.personalEmail};
            parent email: {user.email};
            birthMonth: {user.birthMonth};
            borthYear: {user.birthYear};
            gender: {user.gender};
            parentFirstName: {user.parentFirstName};
            parentLastName: {user.parentLastName};
            parents: {user.parents};
            children: {user.children};
            role: {user.role};
            access: {user.access};
          </p>
        </div>
      );
    });

    return result;
  }

  getUsersForSkippingProgressLockItems() {
    var result = [];

    result.push(<div><br /> <b>Current Users Who Can Skip Progress Lock</b> <br /> <br /></div>);
    this.state.canSkipProgressLockUserMap.forEach((value, key) => {
      result.push(
        <div>
          <p>
          <b>{value.username} </b>
            [<b>{value.firstName} {value.lastName}</b>];
          </p>
        </div>
      );
    });

    return result;
  }

  render() {
    if (!isAdmin(this.user))
      return (<div></div>);

    return (
      <div style={container}>
        <h3> Admin Dashboard </h3>
        <b >Google Analytics:</b>
        <a href="https://analytics.google.com/analytics/web/?authuser=0#report/defaultid/a82389839w121896249p127540453/"> Reporting Dashboard </a>

        <br />
        
        <b >Facebook:</b>
        <a href="https://www.facebook.com/cswonders/"> Facebook </a>

        <b >Github:</b>
        <a href="https://github.com/cswonders"> Github </a>

        <b >Twitter:</b>
        <a href="https://twitter.com/cswonders"> Github </a>

        <hr></hr>

        <button type="button" className="btn btn-primary" onClick={this.onGetAllUserActivities}> Recent Activities </button>
        <br />
        {
          (this.state.processActivitiesDone && this.state.processUsersDone && this.state.showActivities) ? 
          this.getActivityItems()
          :
          <div></div>
        }
        <br />

        <button type="button" className="btn btn-primary" onClick={this.onGetUsers}> All Users </button>
        <br />
        {
          this.state.processUsersDone && this.state.showUsers ? 
          this.getUserItems()
          :
          <div></div>
        }
        <br /> 

        <button type="button" className="btn btn-primary" onClick={this.onGetAllUserFiles}> User Files </button>
        <br />
        {
          (this.state.processFilesDone && this.state.showFiles) ?
          this.getAllUserFileItems()
          :
          <div></div>
        }

        <hr></hr>

        <button type="button" className="btn btn-primary" onClick={this.onAddRemoveTeacher}> Add/Remove Teacher </button>
        <br />
        {
          (this.state.processUsersDone && this.state.showConfigureTeacher) ?
          this.getConfigureTeacher()
          :
          <div></div>
        }
        <br />

        <button type="button" className="btn btn-primary" onClick={this.onAddRemoveLocking}> Add/Remove User for Skipping Progress Lock</button>
        <br />
        {
          (this.state.processUsersDone && this.state.showConfigureLocking) ?
          this.getConfigureLocking()
          :
          <div></div>
        }
        <br />

        <button type="button" className="btn btn-primary" onClick={this.onShowChangePassword}> Change Password </button>
        <br />
        {
          (this.state.processUsersDone && this.state.showChangePassword) ?
          this.getChangePassword()
          :
          <div></div>
        }
        <br />

        {
        // <button type="button" className="btn btn-primary" onClick={this.onModifyDatabaseData}> Modify Database Data</button>
        // <br />
        // <br />
        }

        {
        // <br />
        // <button type="button" className="btn btn-danger" onClick={this.onResetDatabase}> Reset Database </button>
        // <br />
        }
      </div>
    );
  }
}

export default AdminPage;