/*
 * util.js
 */

import userConfig from '../config/admin-config';
import studentConfig from '../config/student-config';
import uniqid from 'uniqid';

function toTwoDigits(value) {
  return value < 10 ? '0' + value : value;
}

function toThreeDigits(value) {
  if (value < 10) {
    return '00' + value;
  }

  if (value < 100) {
    return '0' + value;
  }

  return value;
}

function getUTCOffset(date) {
  let offset = date.getTimezoneOffset();
  const sign = (offset > 0) ? '-' : '+';
  offset = Math.abs(offset);
  const hours = toTwoDigits(Math.floor(offset / 60));
  const minutes = toTwoDigits(offset % 60);
  return sign + hours + minutes;
}

/*
 * getDateTime
 *
 * Convert time according to the format string: 'YYYY-MM-DDTHH:mm:ss.SSSZZ'
 * Output should look like: "2016-06-08T09:07:11.021-0700"
 */
export function getDateTime(d) {
  d = (typeof d !== 'undefined') ? d : new Date();

  var dateTime = d.getFullYear() + '-' + toTwoDigits(d.getMonth() + 1) + '-' + toTwoDigits(d.getDate()) + 'T' +
    toTwoDigits(d.getHours()) + ':' + toTwoDigits(d.getMinutes()) + ':' + toTwoDigits(d.getSeconds()) + '.' +
    toThreeDigits(d.getMilliseconds()) + getUTCOffset(d);

  return dateTime;
}

/*
 * Returns the number of milliseconds since midnight, Jan 1, 1970
 */
export function getTime() {
  var d = new Date();
  return d.getTime();
}

// Input follows the format of "2016-06-08T09:07:11.021-0700"
// Returns local time in US format (PST for Seattle) e.g., 3/18/2018, 7:46:54 PM
// Note: For IE, we only return the UTC time
export function getLocalTime(time) {
  if (!time)
    return 'N/A';

  var result = new Date(Date.parse(time));
  result = result.toLocaleString('en-US');

  if (result !== 'Invalid Date') {
    return result;
  }

  // IE supported format:
  // new Date(year, month, day, hours, minutes, seconds, milliseconds)
  var components = time.split('-');
  var year = components[0];
  // month is zero-based, so off by one
  var month = components[1] - 1;
  var middle = components[2];
  var parts = middle.split(':');
  var dayHour = parts[0].split('T');
  var day = dayHour[0];
  var hour = dayHour[1];
  var min = parts[1];
  var sec = parts[2].split('.')[0];

  result = new Date(year, month, day, hour, min, sec);

  return result.toLocaleString('en-US') + ' (UTC)';
}

/*
 * getRandomIntInclusive
 *
 * Returns a random integer between min (included) and max (included)
 */
export function getRandomIntInclusive(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/*
 * joinJsonObjects
 *
 * Joins two objects and returns the combined object
 */
export function joinJsonObjects(obj1, obj2) {
  var result = {};
  
  for(var key in obj1) {
    result[key] = obj1[key];
  }

  for(var key in obj2) {
    result[key] = obj2[key];
  }

  return result;
}

/*
 * ajax
 *
 * Makes an ajax call, default is 'post' if method is not specified
 * http://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit
 *
 * E.g., post('/contact/', {name: 'Johnny Bravo'});
 */
export function ajax(path, params, method) {
  method = method || "post"; // Set method to post by default if not specified.

  var form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", path);

  for(var key in params) {
    if(params.hasOwnProperty(key)) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);

      form.appendChild(hiddenField);
     }
  }

  document.body.appendChild(form);
  form.submit();
}

export const STUDENT = 1;
export const PARENT = 2;
export const PENDING_TEACHER = 4;
export const TEACHER = 8;
export const ADMIN = 16;

/*
 * Returns true if role number has the pending_teacher credential
 */
export function isPendingTeacherByRole(roleNum) {
  return ((roleNum & PENDING_TEACHER) === PENDING_TEACHER);
}

/*
 * Returns true if user has signed in
 */
export function isUserSignedIn(user) {
  if (!user)
    return false;
  
  if (Object.keys(user).length === 0)
    return false;

  return true;
}

// TODO: Refactor access control related functions to access.js

/*
 * Returns true if user can view solutions.
 * Currently we allow Teacher or Admin to view solutions.
 */
export function canUserViewSolution(user) {
  
  if (isTeacher(user))
    return true;

  if (isAdmin(user))
    return true;

  return false;
}

/*
 * Returns true if user has the teacher credential
 */
export function isTeacher(user) {
  if (!user)
    return false;
  
  if (Object.keys(user).length === 0)
    return false;

  if (userConfig.teachers.indexOf(user.username) === -1) {
    if (isTeacherByRole(user.role))
      return true;

    return false;
  }

  return true;
}

/*
 * Returns true if user can skip lock
 */
export function canSkipLock(user) {
  if (!user)
    return false;
  
  if (Object.keys(user).length === 0)
    return false;

  if (studentConfig.canSkipLock.indexOf(user.username) === -1)
    return false;

  return true;
}

/*
 * Returns true if role number has the teacher credential
 */
export function isTeacherByRole(roleNum) {
  return ((roleNum & TEACHER) === TEACHER);
}

/*
 * Returns true if user has the admin credential
 */
export function isAdmin(user) {
  if (!user)
    return false;

  if (Object.keys(user).length === 0)
    return false;

  if (userConfig.admins.indexOf(user.username) === -1) {
    if (isAdminByRole(user.role))
      return true;

    return false;
  }

  return true;
}

/*
 * Returns true if role number has the admin credential
 */
export function isAdminByRole(roleNum) {
  return ((roleNum & ADMIN) === ADMIN);
}

/*
 * Returns true if role number has the parent credential
 */
export function isParentByRole(roleNum) {
  return ((roleNum & PARENT) === PARENT);
}

/* 
 * Returns true if user has the parent role
 */
export function isParent(user) {
  if (!user)
    return false;
  
  if (Object.keys(user).length === 0)
    return false;

  if (isParentByRole(user.role))
    return true;

  return false;
}

/*
 * Returns true if role number has the student credential
 */
export function isStudentByRole(roleNum) {
  return ((roleNum & STUDENT) === STUDENT);
}

/*
 * Returns the query string from the URL 
 * Example: for http://www.cswonders.com/lesson?id=abcdefgh
 * getQueryValueFromURL('lesson?id=') returns abcdefgh
 */
export function getQueryValueFromURL(base) {
  if (typeof window === 'undefined') {
    return undefined;
  }

  // e.g. http://www.cswonders.com/lesson?id=abcdefgh
  const href = window.location.href;
  const baseLen = base.length;
  
  const index = href.indexOf(base);
  if (index === -1)
    return undefined;

  return href.substring(index + baseLen);
}

/*
 * Infers which programming language is used, based on the syntax of code.
 * Returns 'python' for Python
 * Returns 'java' for Java
 */
export function detectProgrammingLanguage(code) {
  // Current heuristic: if code contains {;}, we infer it's Java, otherwise it's Python
  // We can use https://regexr.com/ to test out the regular expression
  var regex = /\{(.|\n|\r)*;(.|\n|\r)*\}/g;
  
  var result = regex.exec(code);
  if (result) {
    return 'java';
  }

  return 'python';
}

/*
 * Returns true if it's running on Linux host
 */
export function isOnLinux() {
  // Our heuristic: if the path contains 'ubuntu', then it's running on a Linux server
  return process.cwd().indexOf('ubuntu') !== -1;
}

/*
 * Creates and returns a unique file name based on an ID and title (e.g., for Project) as well as username
 */
export function getUniqueFileName(id, title, username) {
  // we follow this convention to create the file name
  // id__title__username
  var fileName = id + '__' + title + '__' + username;

  // the file name cannot contain '.' since it would cause problem when saving to MongoDB, so we convert '.' to '__dot__'
  fileName = fileName.replace(/\./g, '__dot__');

  return fileName;
}

/*
 * getUniqueID
 *
 * Generate an ID that is unique. We use this, e.g. when creating a new challenge.
 */
export function getUniqueID() {
  return uniqid.time();
}

