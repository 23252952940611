/*
 * local-storage.js
 *
 * Utility to handle browser side storage, including localStorage and sessionStorage
 */
import config from '../config/storage-config';

const useLocalStorage = config.useLocalStorage;

function supported() {
  const isBrowser = typeof window !== 'undefined';

  if (!isBrowser) 
    return false;

  var localStorage = null;
  if ('localStorage' in window) {
    try {
      localStorage = window.localStorage;
    } catch(e) {
      // access denied; keep it as null
    }
  }

  return localStorage !== null;
}

// TODO: might want to use try/catch to catch QUOTA_EXCEEDED_ERR exceptions
export function setItem(key, value) {
  if (!supported()) {
    return;
  }

  if (useLocalStorage) {
    localStorage.setItem(key, value);
    return;
  }

  sessionStorage.setItem(key, value);
}

export function getItem(key) {
  if (!supported()) {
    return null;
  }

  if (useLocalStorage) {
    return localStorage.getItem(key);
  }

  return sessionStorage.getItem(key);
}

export function removeItem(key) {
  if (!supported()) {
    return;
  }

  if (useLocalStorage) {
    localStorage.removeItem(key);
    return;
  }

  sessionStorage.removeItem(key);
}

export function clear() {
  if (!supported()) {
    return;
  }

  if (useLocalStorage) {
    localStorage.clear();
    return;
  }

  sessionStorage.clear();
}

export function setObject(key, obj) {
  if (!supported()) {
    return;
  }

  if (useLocalStorage) {
    localStorage.setItem(key, JSON.stringify(obj));
    return;
  }

  sessionStorage.setItem(key, JSON.stringify(obj));
}

export function getObject(key) {
  if (!supported()) {
    return;
  }

  if (useLocalStorage) {
    return JSON.parse(localStorage.getItem(key));
  }

  return JSON.parse(sessionStorage.getItem(key));
}

// function onStorageEvent(storageEvent) {
//   StorageEvent {
//     key;          // name of the property set, changed etc.
//     oldValue;     // old value of property before change
//     newValue;     // new value of property after change
//     url;          // url of page that made the change
//     supported();  // localStorage or sessionStorage
//   }
//   alert("storage event");
// }

// window.addEventListener('storage', onStorageEvent, false);
