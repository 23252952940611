/*
 * Navigation.js
 */
import React, { Component } from 'react';
import Link from '../Link';
import Button from '../Button';
import Img from '../Img';
import Href from '../Href';
import DropDown from '../DropDown';
import { StyleRoot } from 'radium';
import { isTeacher, isAdmin, isUserSignedIn } from '../../util/util';

const icon = {
  base: {
    "display": "none",
    "backgroundColor": "white",

    "marginRight": "5px",
    "fontSize": "36px",
    "border": "none",

    ':focus': {
      "outline" :"0"
    },

    '@media screen and (max-width: 820px)': {
      "display": "block",
      "position": "absolute",
      "right": "0px",
      "top": "0px",
    },
  },
};

const iconClicked = {
  base: {
    // "display": "none",
    "border": "none",
    "backgroundColor": "#333",
    "color": "white",

    "marginRight": "5px",
    "fontSize": "36px",
    "position": "absolute",
    "right": "0px",
    "top": "0px",

    ':focus': {
      "outline" :"0"
    },

    '@media screen and (max-width: 820px)': {
      "display": "block",
      "position": "absolute",
      "right": "0px",
      "top": "0px",
    },
  },
};

const headNav = {
  "marginTop": "9px",
  "fontFamily": '"Open Sans",Helvetica,Arial,sans-serif',
  "fontSize": "17px",
  "float": "right",
  "padding": "0 2%",
};

const navHighlight = {
  base: {
    "padding": "0 1.25em",
    "display": "inline-block",
    "marginRight": "0px",
    "marginLeft": "5px",
    "borderRadius": "100px",
    "fontWeight": "500",
    "letterSpacing": "0.03125em",
    "fontSize": "90%",
    "lineHeight": "2.2",
    "border": "1px solid #ffae1c",
    "color": "#ffae1c",
    "backgroundColor": "white",

    ':hover': {
      "borderColor": "#ffae1c;",
      "backgroundColor": "#ffae1c",
      "color": "#fff",
      "textDecoration": "none"
    },

    ':focus': {
      "outline" :"0"
    },

    '@media screen and (max-width: 820px)': {
      "display": "none",
    },
  }
};

const image = {
  base: {
    "width": "170px",
    "height": "40px",

    ':hover': {
    },

    ':focus': {
      "outline" :"0px"
    }
  }
};

const ulStyle = {
  // base: {
    "padding": "0px",
    "paddingTop": "50px",
    "paddingBottom": "30px",
    "overflow": "hidden",
    "backgroundColor": "#333",
    "color": "white",
    "transition": "0.3s",
    "fontSize": "17px",
    "textDecoration": "none",
    "display": "block",
    "textAlign": "left",

  //   ':hover': {
  //     "backgroundColor": "#111",
  //   },
  // },
};

const liStyle = {
  "display": "block",
  "textAlign": "left",
  "paddingTop": "5px",
  "paddingBottom": "5px",
 };

const linkStyle = {
  base: {
    "display": "block",
    "textAlign": "left",
    "color": "white",
    "textDecoration": "none",

    ':hover': {
      "backgroundColor": "#111",
    },
  },
};

const navLink = {
  base: {
    "padding": "3px 8px",
    "fontWeight": "500",
    "transition": "0.2s ease-in-out",
    "fontSize": "80%",
    "color": "rgba(134, 149, 153, 1)",

    ':hover': {
      // "color": "#393d40",
      "color": "rgba(0, 0, 0, 1)",
      "textDecoration": "none",
    },

    ':active': {
      "color": "rgba(134, 149, 153, 1)",
    },

    '@media screen and (max-width: 820px)': {
      "display": "none",
    },
  },
};

const dropdownStyle = {
  base: {
    "padding": "3px 8px",
    "fontWeight": "500",
    "transition": "0.2s ease-in-out",
    "fontSize": "80%",
    "color": "rgba(134, 149, 153, 1)",
    "backgroundColor": "white",
    "border": "none",

    "position": "relative",
    "display": "inline-block",

    ':hover': {
      // "display": "block",
      "color": "rgba(0, 0, 0, 1)",
      // "textDecoration": "none",
    },

    ':focus': {
      "outline" :"0"
    },

    '@media screen and (max-width: 820px)': {
      "display": "none",
    },
  },
};

const dropdownContentStyle1 = {
  "display": "none",
};

const dropdownContentStyle2 = {
  "display": "block",

  "position": "absolute",
  "listStyle": "none",
  "zIndex": "100",

  "left": "6px",
  "top": "26px",
  "width": "85%",
  "minWidth": "70px",

  "border": "1px solid",
  "borderRadius": "2px",

  "backgroundColor": "#f9f9f9",
  "padding": "4px 4px",
  "textAlign": "left",
};

const dropdownTeacherContentStyle1 = {
  "display": "none",
};

const dropdownTeacherContentStyle2 = {
  "display": "block",

  "position": "absolute",
  "listStyle": "none",
  "zIndex": "100",

  "left": "6px",
  "top": "24px",
  "width": "85%",
  "minWidth": "70px",

  "border": "1px solid",
  "borderRadius": "2px",

  "backgroundColor": "#f9f9f9",
  "padding": "4px 4px",
  "textAlign": "left",
};

const dropdownButtonStyle = {
  // "border": "1px solid black",
  "paddingLeft": "4px",
  "paddingRight": "4px",
  // TODO: paddingBottom is a workaround to make onMouseLeave work for dropdownButton
  // "paddingBottom": "75px",
};

const downArrowStyle = {
  "fontSize": "10px",
};

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleTeacherMouseOver = this.handleTeacherMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleTeacherMouseLeave = this.handleTeacherMouseLeave.bind(this);

    this.state = {
      iconClicked: false,
      userClicked: false,
      dropdownContentStyle: dropdownContentStyle1,
      dropdownTeacherContentStyle: dropdownTeacherContentStyle1,
     };

     this.user = props.user;
     this.username = props.username;
  }

  handleClick() {
    this.setState(
      { iconClicked: !this.state.iconClicked }
    );
  }

  handleUserClick() {
    this.setState(
      { userClicked: !this.state.userClicked }
    );
  }

  handleMouseOver() {
    this.setState(
      { dropdownContentStyle: dropdownContentStyle2 }
    );
  }

  handleTeacherMouseOver() {
    this.setState(
      { dropdownTeacherContentStyle: dropdownTeacherContentStyle2 }
    );
  }

  handleMouseLeave() {
    this.setState(
      { dropdownContentStyle: dropdownContentStyle1 }
    );
  }

  handleTeacherMouseLeave() {
    this.setState(
      { dropdownTeacherContentStyle: dropdownTeacherContentStyle1 }
    );
  }

  // show the sample link for admin
  getSample() {
    if (this.username === 'admin') {
      return ( <Link to="/sample" style={navLink}>Sample</Link> );
    }

    return ( <div></div> );
  }

  getHome() {
    return ( <Link to="/" style={linkStyle}>&nbsp; &nbsp; Home</Link> );
  }

  getNuggets() {
    if (this.state.iconClicked) {
      return ( <Link to="/nuggets" style={linkStyle}>&nbsp; &nbsp; Nuggets</Link> );
    }

    return ( <Link to="/nuggets" style={navLink}>Nuggets</Link> );
  }

  getCurriculum() {
    if (this.state.iconClicked) {
      return ( <Link to="/curriculum" style={linkStyle}>&nbsp; &nbsp; Tutorials </Link> );
    }

    return ( <Link to="/curriculum" style={navLink}>Tutorials </Link> );
  }

  getAccount() {
    if (this.username) {
      if (this.state.iconClicked) {
        return ( <Link to="/logout" style={linkStyle}>&nbsp; &nbsp; Sign Out</Link> );
      }

      // return ( <Link to="/logout" style={navLink}>Sign Out</Link> );
      return <div></div>;
    }

    if (this.state.iconClicked) {
      return ( <Link to="/signup" style={linkStyle}>&nbsp; &nbsp; Create Account</Link> );
    }

    return ( <Link to="/signup" style={navLink}><Button style={navHighlight}>Create Account</Button></Link> );
  }

  // not used at the moment
  getConsole() {
    return ( <Href style={navLink} href="/console.html" target="_blank">Console</Href> );
  }

  getCodePad() {
    if (this.state.iconClicked) {
      // return ( <Href href="/python-pad.html" target="_blank" style={linkStyle}>&nbsp; &nbsp; CodePad</Href> );
      return ( <Link to="/python-pad" style={linkStyle}>&nbsp; &nbsp; CodePad</Link> );
    }

    return ( <Link to="/python-pad" style={navLink}>CodePad</Link> );
  }

  // not used at the moment
  getJSPad() {
    return ( <Href href="/js-pad.html" style={navLink} target="_blank">JSPad</Href> );
  }

  // not used at the moment
  getGallery() {
    if (this.state.iconClicked) {
      return ( <Link to="/gallery" style={linkStyle}>&nbsp; &nbsp; Gallery</Link> );
    }

    return ( <Link to="/gallery" style={navLink}>Gallery</Link> );
  }

  getCheatsheet() {
    if (this.state.iconClicked) {
      return ( <Link to="/cheatsheet" style={linkStyle}>&nbsp; &nbsp; Cheatsheet</Link> );
    }

    return ( <Link to="/cheatsheet" style={navLink}>Cheatsheet</Link> );
  }

  getIcon() {
    const iconStyle = (this.state.iconClicked) ? iconClicked : icon;

    return ( <Button type="button" style={iconStyle} onClick={this.handleClick}> ☰ </Button> );
  }

  getUserIcon() {
    // if (user.gender === "Male")
    //    return <img src="/img/boyicon.svg" width="20px" height="20px"></img>

    // if (user.gender === "Female")
    //   return <img src="/img/girlicon.svg" width="20px" height="20px"></img>

    // return <img src="/img/personicon.svg" width="20px" height="20px"></img>;
    // return <img src="/img/user.svg" width="20px" height="20px"></img>
    return <img src="/img/profileicon.svg" width="20px" height="20px"></img>
  }

  getTeacherKit() {
    if (!isTeacher(this.user))
      return;

    if (this.state.iconClicked)
      return;

    return ( 
      <DropDown style={dropdownStyle} onMouseLeave={this.handleTeacherMouseLeave}>
        <span style={dropdownButtonStyle} onMouseOver={this.handleTeacherMouseOver} >
          TeacherKit
          <span style={downArrowStyle}> { '▼' } </span>
        </span>

        <ul style={this.state.dropdownTeacherContentStyle} onMouseLeave={this.handleTeacherMouseLeave}>
          <li><Link to="/classrooms" style={navLink}>Classrooms</Link></li>
          <li><Link to="/notes" style={navLink}>Notes</Link></li>
          <li><Link to="/nuggets" style={navLink}>Nuggets</Link></li>
          <li><Link to="/quizzes" style={navLink}>Quizzes</Link></li>
          <li><Link to="/projects" style={navLink}>Projects</Link></li>
          <li><Link to="/snippets" style={navLink}>Snippets</Link></li>
          <li><Link to="/lessons" style={navLink}>Lessons</Link></li>
          <li><Link to="/courses" style={navLink}>Courses</Link></li>
        </ul>
      </DropDown>
    );
  }

  getProfile() {
    if (this.username) {
      if (this.state.iconClicked) {
        return ( <Link to="/profile" style={linkStyle}>&nbsp; &nbsp; { this.username }</Link> );
      }

      return ( 
        <DropDown style={dropdownStyle} onMouseLeave={this.handleMouseLeave}>
          <span style={dropdownButtonStyle} onMouseOver={this.handleMouseOver} >
            { this.getUserIcon() }
            &nbsp;
            { this.username }
            <span style={downArrowStyle}> { '▼' } </span>
          </span>

          <ul style={this.state.dropdownContentStyle} onMouseLeave={this.handleMouseLeave}>
            { 
              isAdmin(this.user) ? 
              <li><Link to="/radar" style={navLink}>Radar</Link></li>
              :
              <div></div>
            }
            { 
              isAdmin(this.user) ? 
              <li><Link to="/admin" style={navLink}>Admin</Link></li>
              :
              <div></div>
            }
            <li><Link to="/learn" style={navLink}>Learn</Link></li>
            <li><Link to="/profile" style={navLink}>Profile</Link></li>
            <li><Link to="/logout" style={navLink} onClick={this.handleMouseLeave}>Sign Out</Link></li>
          </ul>
        </DropDown>
      );

      // return ( 
      //     <Button style={dropdownStyle} onClick={this.handleUserClick}>
      //       { this.getUserIcon(user) }
      //       &nbsp;
      //       { username }
      //       &nbsp;
      //       { this.state.userClicked ? '▲' : '▼' }

      //     <ul style={ this.state.userClicked ? dropdownContentStyle2: dropdownContentStyle1 }>
      //       <li><Link to="/learn" style={navLink}>Learn</Link></li>
      //       <li><Link to="/profile" style={navLink}>Profile</Link></li>
      //       <li><Link to="/logout" style={navLink}>Sign Out</Link></li>
      //     </ul>
      //     </Button>
      // );
    }

    if (this.state.iconClicked) {
      return ( <Link to="/login" style={linkStyle}>&nbsp; &nbsp; Sign In</Link> );
    }

    return ( <Link to="/login" style={navLink}>Sign In</Link> );
  }

  getAdminKit() {

  }

  getAbout() {
    // if (isUserSignedIn(this.user))
    //   return;

    return;
    
    if (this.state.iconClicked) {
      return ( <Link to="/about" style={linkStyle}>&nbsp; &nbsp; About</Link> );
    }

    return ( <Link to="/about" style={navLink}>About</Link> );
  }
  
  getClasses() {
    // if (isUserSignedIn(this.user))
    //   return;

    if (this.state.iconClicked) {
      return ( <Link to="/courses" style={linkStyle}>&nbsp; &nbsp; Courses</Link> );
    }

    return ( <Link to="/courses" style={navLink}>Courses</Link> );

    // if (this.state.iconClicked) {
    //   return ( <Link to="/classes" style={linkStyle}>&nbsp; &nbsp; Classes</Link> );
    // }

    // return ( <Link to="/classes" style={navLink}>Classes</Link> );
  }

  getCourseware() {
    if (this.state.iconClicked) {
      return ( <Link to="/learn" style={linkStyle}>&nbsp; &nbsp; Courseware</Link> );
    }

    return ( <Link to="/learn" style={navLink}>Courseware</Link> );
  }

  render() {
    this.username = this.props.username;
    if (!this.username)
      this.username = this.props.store.getState().auth.user.username;

    this.user = this.props.user;
    if (Object.keys(this.user).length === 0)
      this.user = this.props.store.getState().auth.user;

    // for small screens
    if (this.state.iconClicked) {
      return (
        <StyleRoot>
          <ul style={ulStyle}>
            { this.getIcon() }
            <li style={liStyle}>{ this.getHome() }</li>
            <li style={liStyle}>{ this.getCourseware() }</li>
            <li style={liStyle}>{ this.getNuggets() }</li>
            <li style={liStyle}>{ this.getCurriculum() }</li>
            <li style={liStyle}>{ this.getAbout() }</li>
            <li style={liStyle}>{ this.getClasses() }</li>
            <li style={liStyle}>{ this.getCodePad() }</li>
            <li style={liStyle}>{ this.getAccount() }</li>
            <li style={liStyle}>{ this.getProfile() }</li>
          </ul>
        </StyleRoot>
      );
            // <li style={liStyle}>{ this.getCheatsheet() }</li>
    }

    // for large screens
    return (
      <StyleRoot>
        <Link to="/">
          <Img src={"/img/logo-dark.svg"} style={image}></Img>
        </Link>

        <div style={headNav} role="navigation">
          { this.getProfile() }
          { this.getAdminKit() }
          { this.getTeacherKit() }
          { this.getCourseware() }
          { this.getNuggets() }
          { this.getCurriculum() }
          { this.getAbout() }
          { this.getClasses() }
          { this.getCodePad() }
          { this.getAccount() }
          { this.getIcon() }
        </div>
      </StyleRoot>
    );
          // { this.getCheatsheet() }
  }
}

export default Navigation;

// export default function Navigation() {
//   useStyles(s);
//   return (
//     <div className={s.root} role="navigation">
//       <Link className={s.link} to="/about">
//         About
//       </Link>
//       <Link className={s.link} to="/contact">
//         Contact
//       </Link>
//       <span className={s.spacer}> | </span>
//       <Link className={s.link} to="/login">
//         Log in
//       </Link>
//       <span className={s.spacer}>or</span>
//       <Link className={cx(s.link, s.highlight)} to="/signup">
//         Sign up
//       </Link>
//     </div>
//   );
// }
