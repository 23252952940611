/*
 * Button.js
 *
 * Example: <Button style={myStyle} />
 */
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
 * sample style to be passed in
 */
 
// var style = {
//   base: {
//     "padding": "18px",
//     "display": "inline-block",
//     "marginRight": "30px",
//     "borderRadius": "2em",
//     "fontWeight": "500",
//     "letterSpacing": "0.03125em",
//     "fontSize": "100%",
//     "lineHeight": "2.2",
//     "border": "2px solid rgba(135, 204, 2, 1)",
//     "color": "rgba(135, 204, 2, 1)",
//     "marginBottom": "20px",
//     "width": "220px",
//     "textAlign": "center",
//     ':hover': {
//       "borderColor": "rgba(135, 204, 2, 1)",
//       "backgroundColor": "rgba(135, 204, 2, 1)",
//       "color": "white",
//       "textDecoration": "none"
//     }
//   }
// };

class Button extends Component {
  render() {
    var typeMsg = (this.props.type) ? this.props.type : "";
    var value = (this.props.value !== "") ? this.props.value: "";
    var onClickMsg = (this.props.onClick) ? this.props.onClick : null;
    var onMouseDownMsg = (this.props.onMouseDown) ? this.props.onMouseDown : null;

    return (
      <button style={[this.props.style.base]} type={typeMsg} onClick={onClickMsg} onMouseDown={onMouseDownMsg} value={value}>
        {this.props.children}
      </button> 
    )
  }
}

Button.propTypes = {
  style: PropTypes.object.isRequired
};

export default Radium(Button);
