/*
 * admin-config.js
 *
 * user names with administrator privilege
 */

module.exports = {
  teachers: ["admin", "jason", "wei", "annawang7", "master", "teacher"], 
  admins: ["admin", "jason", "wei"], 
};
