/*
 * CreateNotePage.js
 * 
 * Example: <CreateNotePage />
 *
 */
import React, { Component } from 'react';
import Button from '../Button';
import { request } from '../../util/api';

const containerStyle = {
};

const tableStyle = {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "10px",
};

const previewLabelStyle = {
  "fontSize": "130%",
  "marginBottom": "1  5px",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
  "minWidth": "80px",
};

const infoStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "fontSize": "80%",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "width": "620px",
  "lineHeight": "8px",
};

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "width": "620px",
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "20px",
    "width": "70px",
    "marginRight": "20px",
    "marginBottom": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonViewAllStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "20px",
    "width": "120px",
    "marginRight": "20px",
    "marginBottom": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const tdLeftStyle = {
  "verticalAlign": "top",
  "width": "600px",
};
 
const rightContainer = {
  "paddingLeft": "20px",
};

const rightTdStyle = {
  "verticalAlign": "top",
  "width": "600px",
  "paddingLeft": "20px",
};

const descriptionTableStyle = {
  "width": "100%",
};

const tdIconStyle = {
  "width": "70px",
};

const selectorStyle = {
  "lineHeight": "1.5",
  "padding": "10px 5px",
  "width": "100%",
  "height": "40px",
  "fontSize": "14px",
  "marginBottom": "20px",
};

const DEFAULT_NOTETYPE = 'FreeForm';

class CreateNotePage extends Component {
  static defaultProps = {
    id: '',
    username: '',
    name: '',
    noteType: '',
    freeFormContent: '',
    title: '',
    overview: '',
    objectives: '',
    concepts: '',
    takeaways: '',
    materials: '',
    resources: '',
    activities: '',
    tags: '',
    shareWith: '',
  }; 

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onFreeFormContentChange = this.onFreeFormContentChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.onShareWithChange = this.onShareWithChange.bind(this);
    this.onOverviewChange = this.onOverviewChange.bind(this);
    this.onObjectiveChange = this.onObjectiveChange.bind(this);
    this.onConceptChange = this.onConceptChange.bind(this);
    this.onTakeawayChange = this.onTakeawayChange.bind(this);
    this.onMaterialChange = this.onMaterialChange.bind(this);
    this.onResourceChange = this.onResourceChange.bind(this);
    this.onActivityChange = this.onActivityChange.bind(this);
    this.onNoteTypeChange = this.onNoteTypeChange.bind(this);

    this.id = this.props.id ? this.props.id : '';
    this.username = this.props.username ? this.props.username : '';
    this.author = this.props.username ? this.props.username : '';
  }

  componentDidMount() {
    this.setState({
      name: this.props.name ? this.props.name : 'TBD',
      previewMessage: '',
      noteType: this.props.noteType ? this.props.noteType : DEFAULT_NOTETYPE,
      freeFormContent: this.props.freeFormContent ? this.props.freeFormContent: '',
      title: this.props.title ? this.props.title : '',
      overview: this.props.overview ? this.props.overview : '',
      objectives: this.props.objectives ? this.props.objectives : '',
      concepts: this.props.concepts ? this.props.concepts : '',
      takeaways: this.props.takeaways ? this.props.takeaways : '',
      materials: this.props.materials ? this.props.materials : '',
      resources: this.props.resources ? this.props.resources : '',
      activities: this.props.activities ? this.props.activities : '',
      tags: this.props.tags ? this.props.tags : 'topic: TBD; level: TBD; concept: TBD',
      shareWith: this.props.shareWith ? this.props.shareWith : '',
    });
  }

  onSave() {
    if (!this.username)
      return;

    if (!this.state.name) {
      alert('Please add the name of the note.');
      return;
    }

    request({
      url: '/api/save_note',
      method: 'POST',
      payload: {
        username: this.username,
        name: this.state.name,
        noteType: this.state.noteType,
        freeFormContent: this.state.freeFormContent,
        title: this.state.title,
        overview: this.state.overview,
        objectives: this.state.objectives,
        concepts: this.state.concepts,
        takeaways: this.state.takeaways,
        materials: this.state.materials,
        resources: this.state.resources,
        activities: this.state.activities,
        tags: this.state.tags,
        shareWith: this.state.shareWith,
        id: this.id,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your note.");
        return;
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }  

  onNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onFreeFormContentChange(e) {
    this.setState({
      freeFormContent: e.target.value,
    });
  }

  onTitleChange(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onTagChange(e) {
    this.setState({
      tags: e.target.value,
    });
  }

  onShareWithChange(e) {
    this.setState({
      shareWith: e.target.value,
    });
  }

  onOverviewChange(e) {
    this.setState({
      overview: e.target.value,
    });
  }

  onObjectiveChange(e) {
    this.setState({
      objectives: e.target.value,
    });
  }

  onConceptChange(e) {
    this.setState({
      concepts: e.target.value,
    });
  }

  onTakeawayChange(e) {
    this.setState({
      takeaways: e.target.value,
    });
  }

  onMaterialChange(e) {
    this.setState({
      materials: e.target.value,
    });
  }

  onResourceChange(e) {
    this.setState({
      resources: e.target.value,
    });
  }

  onActivityChange(e) {
    this.setState({
      activities: e.target.value,
    });
  }

  onNoteTypeChange(e) {
    this.setState({
      noteType: e.target.value
    });
  }

  showSaveButton() {
    if (!this.username)
      return false;

    return true;
  }

  // TODO: add access control
  getSaveButton() {
    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='notes'>
        <Button style={buttonViewAllStyle} >
        View All Notes
        </Button>
      </a>
    );
  }

  getRunComponents() {
    return (
      <div>
        { this.getSaveButton() }
        { this.getAllButton() }
      </div>
    );
  }

  getInfo() {
    return (
      <div style = {infoStyle} >
        <p> Note Name: { this.state.name } </p>
        <p> Author: { this.author } </p>
        <p> Type: { this.state.noteType } </p>
        <p> Tags: { this.state.tags } </p>
        <p> Shared With: { this.state.shareWith } </p>
      </div>
    );
  }

  getTitle() {
    return (
      <table style={descriptionTableStyle}>
      <tbody>
      <tr>
        <td style={tdIconStyle}>
          <img width="50px" height="50px" src='/img/note.svg' ></img>
        </td>

        <td>
          <h4> { this.state.title } </h4>
        </td>
      </tr>
      </tbody>
      </table>
    );
  }

  getOverview() {
    if (!this.state.overview)
      return <div></div>;

    return (
      <div>
        <h4> Overview </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.overview }}></div>
        <hr></hr>
      </div>
    );
  }

  getObjectives() {
    if (!this.state.objectives)
      return <div></div>;

    return (
      <div>
        <h4> Objectives </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.objectives }}></div>
        <hr></hr>
      </div>
    );
  }

  getConcepts() {
    if (!this.state.concepts)
      return <div></div>;

    return (
      <div>
        <h4> Concepts </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.concepts }}></div>
        <hr></hr>
      </div>
    );
  }

  getTakeaways() {
    if (!this.state.takeaways)
      return <div></div>;

    return (
      <div>
        <h4> Key Takeaways </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.takeaways }}></div>
        <hr></hr>
      </div>
    );
  }

  getMaterials() {
    if (!this.state.materials)
      return <div></div>;

    return (
      <div>
        <h4> Materials </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.materials }}></div>
        <hr></hr>
      </div>
    );
  }

  getResources() {
    if (!this.state.resources)
      return <div></div>;

    return (
      <div>
        <h4> Resources </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.resources }}></div>
        <hr></hr>
      </div>
    );
  }

  getClassActivities() {
    if (!this.state.activities)
      return <div></div>;

    return (
      <div>
        <h4> Activities </h4>
        <div dangerouslySetInnerHTML={{ __html: this.state.activities }}></div>
        <hr></hr>
      </div>
    );
  }

  getNoteTypeSelected(option) {
    if (option === this.state.noteType)
      return 'selected';

    return '';
  }

  getFreeFormContent() {
    if (this.state.noteType !== 'FreeForm')
      return (<div></div>);

    return (
      <tr>
      <td style={columnStyle}> FreeForm Content</td>
      <td>
        <div>
          <textarea id="freeFormContent" rows="40" cols="85" name="freeFormContent" onChange={this.onFreeFormContentChange}
          defaultValue={ this.state.freeFormContent }></textarea>
        </div>
      </td>
      </tr>
    );
  }

  getFreeFormContentPreview() {
    if (this.state.noteType !== 'FreeForm')
      return (<div></div>);

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.state.freeFormContent }}></div>
      </div>
    );
  }

  getLessPlan() {
    if (this.state.noteType !== 'LessonPlan') 
      return (<div></div>);

    var result = [];
    result.push(
      <tr>
      <td style={columnStyle}> Overview </td>
      <td>
        <div>
          <textarea id="overview" rows="2" cols="85" name="overview" onChange={this.onOverviewChange}
          defaultValue={ this.state.overview }></textarea>
        </div>
      </td>
      </tr>
    );

    result.push(
      <tr>
      <td style={columnStyle}> Objectives </td>
      <td>
        <div>
          <textarea id="objectives" rows="5" cols="85" name="objective" onChange={this.onObjectiveChange}
          defaultValue={ this.state.objectives }></textarea>
        </div>
      </td>
      </tr>
    );

    result.push(
      <tr>
      <td style={columnStyle}> Key Takeaways</td>
      <td>
        <div>
          <textarea id="takeaways" rows="5" cols="85" name="takeaway" onChange={this.onTakeawayChange}
          defaultValue={ this.state.takeaways }></textarea>
        </div>
      </td>
      </tr>
    );

    result.push(
      <tr>
      <td style={columnStyle}> Concepts </td>
      <td>
        <div>
          <textarea id="concepts" rows="1" cols="85" name="concepts" onChange={this.onConceptChange}
          defaultValue={ this.state.concepts }></textarea>
        </div>
      </td>
      </tr>
    );

    result.push(
      <tr>
      <td style={columnStyle}> Materials </td>
      <td>
        <div>
          <textarea id="materials" rows="5" cols="85" name="materials" onChange={this.onMaterialChange}
          defaultValue={ this.state.materials }></textarea>
        </div>
      </td>
      </tr>
    );

    result.push(
      <tr>
      <td style={columnStyle}> Resources </td>
      <td>
        <div>
          <textarea id="resources" rows="5" cols="85" name="resources" onChange={this.onResourceChange}
          defaultValue={ this.state.resources }></textarea>
        </div>
      </td>
      </tr>
    );

    result.push(
      <tr>
      <td style={columnStyle}> Activities </td>
      <td>
        <div>
          <textarea id="activities" rows="20" cols="85" name="activities" onChange={this.onActivityChange}
          defaultValue={ this.state.activities }></textarea>
        </div>
      </td>
      </tr>
    );

    return result;
  }

  getLessonPlanPreview() {
    if (this.state.noteType !== 'LessonPlan')
      return (<div></div>);

    var result = [];

    result.push(this.getOverview());
    result.push(this.getObjectives());
    result.push(this.getTakeaways());
    result.push(this.getConcepts());
    result.push(this.getMaterials());
    result.push(this.getResources());
    result.push(this.getClassActivities());

    return result;
  }

  render() {
    if (!this.state)
      return (<div></div>);

    return (
      <div style={containerStyle}>
        <table style={tableStyle}>
          <tbody>
            <tr>
              <td style={tdLeftStyle}>
                <table>
                <tbody>

                <tr>
                  <td style={columnStyle}> </td>
                  <td>
                    <div>
                      { this.getRunComponents() }
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style={columnStyle}>Type</td>
                  <td>
                    <select style={selectorStyle} id="type" name="type" onChange={this.onNoteTypeChange}>
                      <option value="FreeForm" selected={this.getNoteTypeSelected('FreeForm')} >FreeForm</option>
                      <option value="LessonPlan" selected={this.getNoteTypeSelected('LessonPlan')} >Lesson Plan</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td style={columnStyle}> Note Name </td>
                  <td>
                    <div>
                      <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
                      defaultValue={ this.state.name }></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                <td style={columnStyle}> Title </td>
                <td>
                  <div>
                    <textarea id="title" rows="1" cols="85" name="title" onChange={this.onTitleChange}
                    defaultValue={ this.state.title }></textarea>
                  </div>
                </td>
                </tr>

                { this.getFreeFormContent() }

                { this.getLessPlan() }

                <tr>
                  <td style={columnStyle}> Tags </td>
                  <td>
                    <div>
                      <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
                      defaultValue={ this.state.tags }></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td style={columnStyle}> Share With </td>
                  <td>
                    <div>
                      <textarea id="shareWith" rows="1" cols="85" name="shareWith" onChange={this.onShareWithChange}
                      defaultValue={ this.state.shareWith }></textarea>
                    </div>
                  </td>
                </tr>

                </tbody>
                </table>
              </td>

              <td width="20px">
              </td>

              <td style={rightTdStyle}>
                <table style={rightContainer}>
                <tbody>
                  <tr>
                    <td>
                      <div style={previewLabelStyle}>
                        <img src='/img/preview.svg' style={{'width':'40px', 'height':'40px', 'marginRight': '10px'}} ></img>
                        Preview
                      </div>

                      { this.getInfo() }

                      <div style={previewStyle}>

                        { this.getTitle() }

                        <hr></hr>

                        { this.getFreeFormContentPreview() }
                        { this.getLessonPlanPreview() }

                      </div>
                    </td>
                  </tr>
                </tbody>
                </table>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CreateNotePage;
