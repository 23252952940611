/*
 * storage-config.js
 *
 * configuration for local storage
 */

module.exports = {
  // true: use localStorage
  // false: use sessionStorage
  useLocalStorage: true, 
};
