/*
 * Layout.js
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
// import s from './Layout.css';
import s from '../variables.scss';
import Header from '../Header';
import Footer from '../Footer';

function rawHtml() {
  var rawHtml = '<script src="/vendor/ace-builds/src-min-noconflict/ace.js" type="text/javascript" charset="utf-8"></script>';
  return { __html: rawHtml };
}

export default function Layout(props) {
  useStyles(s, normalizeCss);

  return (
    <>
      <link href="/css/vendor/bootstrap/bootstrap.min.css" rel="stylesheet"/>
      <link rel="stylesheet" href="/ace.css"/>

      <Header store={props.store} />
      {props.children}
      <Footer store={props.store} />

      <span dangerouslySetInnerHTML={rawHtml()} />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
