/* 
 * Completion.js
 */
import React, { Component } from 'react';
import Button from '../../components/Button';
import { CircularProgressbar } from 'react-circular-progressbar';
import { getDateTime } from '../../util/util';
import { addLog } from '../../util/api';
import points from '../../config/point-config';

const controlBarStyle = {
  "color": "white",
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "marginBottom": "30px",
  // "borderTopLeftRadius": "10px",
  // "borderTopRightRadius": "10px",
};

const controlBarTableStyle = {
  "width": "100%",
};

const progressContainerStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "width": "100px",
  "height": "100px",
  "borderRadius": "50%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "-20px",
  "marginBottom": "-20px",
  "paddingTop": "10px",
};

const progressStyle = {
  "width": "80px",
  "height": "80px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "2px solid white",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "98px",
    "width": "150px",
    "marginRight": "20px",

    ':hover': {
      "backgroundColor": "hsla(0,0%,100%,.15)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const collectButtonStyle = {
  base: {
    "padding": "4px",
    "marginTop": "50px",
    "marginBottom": "20px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "100",
    "letterSpacing": "0.03125em",
    "fontSize": "110%",
    "lineHeight": "2.3",
    "color": "white",
    "backgroundColor": "rgba(28, 175, 246, 1)",
    "width": "150px",
    "textAlign": "center",
    "border": "none",

    ':hover': {
      "backgroundColor": "rgba(62, 187, 247, 1)",
      "color": "white",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "width": "300px",
  "textAlign": "center",
};

const rightTdStyle = {
  "width": "300px",
  "textAlign": "right",
};

const containerStyle = {
  "marginTop": "35px",
  "marginBottom": "35px",
  "backgroundColor": "#efefef",
  "maxWidth": "1240px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const messageStyle = {
  "textAlign": "center",
  "verticalAlign": "middle",
  "width": "400px",
  "height": "300px",
  "backgroundColor": "white",
  "padding": "50px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "50px",
};

class Completion extends Component {
  constructor(props) {
    super(props);

    this.onContinue = this.onContinue.bind(this);

    this.lessonState = this.props.store.getState().auth.lessonState;

    var audio = new Audio('/sound/yay.mp3');
    audio.play();

    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;
  }

  getProgressBar() {
    return (
      <div style={ controlBarStyle }>
        <table style={ controlBarTableStyle }>
          <tbody>
            <tr>
              <td style={leftTdStyle}>
              </td>
              <td>
                { this.getProgress() }
              </td>
              <td style={rightTdStyle}>
                { this.getContinueButton() }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // We use reat-circular-progressbar to display progress:
  // https://github.com/iqnivek/react-circular-progressbar
  // https://www.npmjs.com/package/react-circular-progressbar
  // color configuration can be controled with the CSS file in src/public/ace.css
  getProgress() {
    var current = this.lessonState.currentSkillIndex;
    if (this.state && this.state.passed) {
      // if the current snippet is passed, we report progress right away
      current += 1;
    }

    var count = 0;
    if (this.lessonState.skillList) {
      count = this.lessonState.skillList.length;
    }

    var percentage = 0;
    if (count > 0) {
      percentage = current/count;
    }

    percentage = percentage*100;
    percentage = percentage.toFixed(0);

    return (
      <div style={progressContainerStyle}>
        <div style={progressStyle}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={12}/>
        </div>
      </div>
    );
  }

  getContinueButton() {
    var buttonText = 'Collect ' + points.lessonPoints + ' points';
    var link = 'lesson?id=' + this.lessonState.lessonId;

    if (this.props.isTest) {
      buttonText = 'Submit test results';
    }

    return (
      <a href={link}>
        <Button style={buttonStyle} onClick={this.onContinue}>
          { buttonText }
        </Button>
      </a>
    );
  }

  getCollectButton() {
    var buttonText = 'Collect ' + points.lessonPoints + ' points';
    var link = 'lesson?id=' + this.lessonState.lessonId;

    if (this.props.isTest)
      buttonText = 'Submit test results';

    return (
      <a href={link}>
        <Button style={collectButtonStyle} onClick={this.onContinue}>
          { buttonText }
        </Button>
      </a>
    );
  }

  onContinue() {
    if (!this.username)
      return;

    // log activity of completing the lesson group
    const activity = {
      type: 'Lesson',
      username: this.username,
      id: this.lessonState.lessonId,
      activity: {
        lessonName: this.lessonState.lessonName,
        groupId: this.lessonState.groupId,
        currentSkillIndex: this.lessonState.currentSkillIndex,
        skillList: this.lessonState.skillList,
      },
      time: getDateTime(), // this field will be overwitten by the serer time if logged to server
    };
    
    var logs = this.props.store.getState().auth.lessonState.logs;
    logs.push(activity);
    addLog(logs);

    this.props.onAddActivities(logs);
  }

  render() {
    return (
      <div style={containerStyle}>

        { this.getProgressBar() }

        <div style={messageStyle}>
          <img src="/img/medal.png"></img>
          {
            this.user.firstName ?
              <h4>{ 'Congrats, ' + this.user.firstName + '!' }</h4>
              :
              <div></div>
          }
          {
            this.props.isTest ?
              <h3> Test complete!</h3>
              :
              <h3> Lesson complete!</h3>
          }
          { this.getCollectButton() }
        </div>

      </div>

    );
  }
}

export default Completion;
