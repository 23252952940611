/* eslint-disable no-shadow */

/*
 * UserSwitcher.js
 * 
 * Container component to handle user change.
 * 
 * See Redux tutorial: https://redux.js.org/basics/usage-with-react.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser, logoutUser, setActivitiesToCache, addActivitiesToCache } from '../../actions/auth';
import { changeLessonStatePass, changeLessonStateFail, changeLessonStateInit } from '../../actions/auth';
import LoginPage from '../LoginPage';
import SignupPage from '../SignupPage';
import Navigation from '../Navigation';
import ProfilePage from '../ProfilePage';
import ClassroomPage from '../ClassroomPage';
import LearnPage from '../LearnPage';
import NuggetsPage from '../NuggetsPage';
import RunPage from '../RunPage';
import CreateNuggetPage from '../CreateNuggetPage';
import CreateNotePage from '../CreateNotePage';
import CreateSnippetPage from '../CreateSnippetPage';
import CreateProjectPage from '../CreateProjectPage';
import CreateQuizPage from '../CreateQuizPage';
import CreateLessonPage from '../CreateLessonPage';
import CreateCoursePage from '../CreateCoursePage';
import CreateClassroomPage from '../CreateClassroomPage';
import Completion from '../Completion';
import Snippet from '../Snippet';
import ProjectPage from '../ProjectPage';
import QuizPage from '../QuizPage';
import LessonNotePage from '../LessonNotePage';
import Nugget from '../Nugget';
import { request } from '../../util/api';
import history from '../../history';

function UserSwitcher({ onLogin, onLogout, onSetActivities, onAddActivities, target, store,
  onPass, onFail, onContinue, lessonState, isTest, canSkip, backendOnly, from }) {

  if (from === 'PRACTICE_PAGE') {
    // What used to be in LessonSwitcher.js. We're merging into UserSwitcher.js so that we can propagate
    // state updates across all pages.

    var id = 'undefined';
    var type = '';
    var skill = lessonState.skillList[lessonState.currentSkillIndex];

    if (skill) {
      id = skill.id;
      type = skill.type;

      if (skill.type === 'Snippet') {
        return(
          <Snippet id={id} onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
        );
      }

      if (skill.type === 'Project') {
        return(
          <ProjectPage id={id} onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} canSkip={canSkip} backendOnly={backendOnly} store={store} />
        );
      }

      if (skill.type === 'Quiz') {
        return(
          <QuizPage id={id} onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
        );
      }

      if (skill.type === 'Note') {
        return(
          <LessonNotePage id={id} onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
        );
      }

      if (skill.type === 'Nugget') {
        return(
          <Nugget id={id} onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
        );
      }
    }

    if (id === 'undefined') {
      return <Completion lessonState={lessonState} isTest={isTest} onAddActivities={onAddActivities} store={store} />;
    }

    if (target === 'LESSON_SNIPPET') {
      return(
        <Snippet onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
      );
    }

    if (target === 'LESSON_PROJECT') {
      return(
        <ProjectPage onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} canSkip={canSkip} backendOnly={backendOnly} store={store} />
      );
    }

    if (target === 'LESSON_QUIZ') {
      return(
        <QuizPage onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
      );
    }

    if (target === 'LESSON_NOTE') {
      return(
        <LessonNotePage onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
      );
    }

    if (target === 'LESSON_NUGGET') {
      return(
        <Nugget onPass={onPass} onFail={onFail} onContinue={onContinue} onAddActivities={onAddActivities} isTest={isTest} store={store} canSkip={canSkip} />
      );
    }

    return (
      <div></div>
    );
  } // from === 'PRACTICE_PAGE'

  var user = {};
  var username = '';

  if (store) {
    user = store.getState().auth.user;
    if (Object.keys(user).length === 0) {
      username = '';
    }
    else {
      username = user.username;
    }
  }

  if (target === 'LOGIN') {
    return (
      <div>
        <LoginPage username={''} onLogin={onLogin} onSetActivities={onSetActivities} onAddActivities={onAddActivities} store={store} />
      </div>
    );
  }

  if (target === 'LOGOUT') {
    if (username) {
      request({
        url: '/log_out',
        method: 'POST',
        payload: {
          username: username,
        }
      }, (err, res) => {
        if (err) {
          alert('Failing to log out');
          return;
        }

        // reset activity cache to empty; by convention, when activities is an empty list, we reset it.
        var activities = []; 
        onSetActivities(activities);

        onLogout();

        history.push('/');
      });
    }

    // return (
    //   <div>
    //     <h2> You have logged out. </h2>
    //   </div>
    // );
  }

  if (target ==='SIGNUP') {
    return (
      <SignupPage username={username} onLogin={onLogin} onSetActivities={onSetActivities} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target ==='UPDATE_SETTINGS') {
    return (
      <SignupPage username={username} onLogin={onLogin} onSetActivities={onSetActivities} onAddActivities={onAddActivities} store={store} update={'true'} />
    );
  }

  if (target === 'NAVIGATION') {
   // if(typeof window !== 'undefined') alert('nav:'+user.username);

    return (
      <Navigation user={user} username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'PROFILE') {
    return (
      <ProfilePage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'ADMIN') {
    return (
      <ProfilePage username={username} onAddActivities={onAddActivities} store={store} isAdmin='true' />
    );
  }

  if (target === 'CLASSROOM') {
    return (
      <ClassroomPage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'LEARN') {
    return (
      <LearnPage onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_NUGGET') {
    return (
      <CreateNuggetPage code={
        'def your_function(your_parameter):\n' +
        '    return your_result'
      } username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_SNIPPET') {
    return (
      <CreateSnippetPage code={
        '# Write your code below.\n'
      } username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_PROJECT') {
    return (
      <CreateProjectPage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_NOTE') {
    return (
      <CreateNotePage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_QUIZ') {
    return (
      <CreateQuizPage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_LESSON') {
    return (
      <CreateLessonPage code={
        'def your_function(your_parameter):\n' +
        '    return your_result'
      } username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_COURSE') {
    return (
      <CreateCoursePage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'CREATE_CLASSROOM') {
    return (
      <CreateClassroomPage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'RUN_PROJECT') {
    return(
      <ProjectPage onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'NUGGETS') {
    return (
      <NuggetsPage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  if (target === 'RUN_NUGGET') {
    return (
      <RunPage username={username} onAddActivities={onAddActivities} store={store} />
    );
  }

  return (
    <div></div>
  );
}

const mapStateToProps = (state) => {
  return {
    lessonState: state.auth.lessonState,
    user: state.auth.user,
    username: state.auth.user.username,
    activities: state.auth.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPass: (lessonState) => dispatch(changeLessonStatePass(lessonState)),
    onFail: (lessonState) => dispatch(changeLessonStateFail(lessonState)),
    onContinue: (lessonState) => dispatch(changeLessonStateInit(lessonState)),
    onLogin: (user) => dispatch(loginUser(user)),
    onLogout: () => dispatch(logoutUser()),
    onSetActivities: (activities) => dispatch(setActivitiesToCache(activities)),
    onAddActivities: (activities) => dispatch(addActivitiesToCache(activities)),
  };
};

// See usage of connect:
// http://redux.js.org/docs/basics/UsageWithReact.html
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSwitcher);
