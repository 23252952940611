/*
 * Editor.js
 * 
 * Interactive editor based on Ace and Skulpt
 *
 * Example: <Editor store={store} run='true' code='hi' />
 *
 * You can optionally add
 * hideCode='true' -- this is to hide the input and just show output
 * style={myStyle}: style for the output
 *
 * save='true': when the user has logged in, the 'Save' and 'Load' buttons will allow the user to save the program
 * name={myFileName}: name of the document to be saved
 */
import React, { Component } from 'react';
import Button from '../Button';
import { joinJsonObjects, getDateTime } from '../../util/util';
import { request } from '../../util/api';
import PropTypes from 'prop-types';

const containerStyle = {
};

const editorContainerStyle = {
  "backgroundColor": "white",
  // "boxShadow": "0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important",
  // "padding": "4px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const editorStyle = {
  "width": "100%",
  "height": "80px",
  "backgroundColor": "white",
  // "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const resultStyle = {
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "textAlign": "center",
  "verticalAlign": "middle",
  // "padding": "10px",
  "width": "100%",
  "height": "200px",
  "border": "none",
  "marginBottom": "16px",
  "marginTop": "5px",
  "display": "none",
};

// const titleStyle = {
//   "fontFamily": '"Segoe UI",Arial,sans-serif',
//   "fontSize": "20px",
//   "fontWeight": "400",
//   "margin": "10px 0",
//   "color": "white",
// };

const buttonStyle = {
  base: {
    "backgroundColor": "#4CAF50",
    // "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "5px",
    "marginBottom": "5px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "paddingLeft": "3px",
    "paddingRight": "3px",
    "paddingTop": "1px",
    "paddingBottom": "1px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "0em",
    "width": "60px",

    ':hover': {
      "color": "#4CAF50",
      "backgroundColor": "rgba(132, 256, 130, 1)",
      // "color": "white",
      // "backgroundColor": "rgba(126, 181, 48, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "5px",
    "marginBottom": "5px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "paddingLeft": "3px",
    "paddingRight": "3px",
    "paddingTop": "1px",
    "paddingBottom": "1px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "0em",
    "width": "90px",
    "marginLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const upFontStyle = {
  "fontSize": "75%",
  "marginLeft": "5px",
};

class Editor extends Component {
  constructor(props) {
    super(props);

    this.onRunIt = this.onRunIt.bind(this);
    this.onSaveIt = this.onSaveIt.bind(this);
    this.onLoadIt = this.onLoadIt.bind(this);
    this.iframeContent = this.iframeContent.bind(this);

    if (this.props.store) {
      this.user = this.props.store.getState().auth.user;
      this.username = this.user.username ? this.user.username : '';
    } else {
      this.username = '';
    }
  }

  /*
   * configure Ace editor
   */
  componentDidMount(){
    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    editor.setTheme("ace/theme/monokai");
    // editor.setTheme("ace/theme/textmate");
    // editor.setTheme("ace/theme/clouds_midnight");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    if (this.props.run !== 'true') {
      editor.setOption("highlightActiveLine", false);
      editor.renderer.$cursorLayer.element.style.display = "none";
    }

    // editor.setOptions({minLines: 25});
    var lineNum = editor.session.getLength();
    editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      runit : true,
    });

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 

      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nfrom browser import document, html\n' + prog;

    // replace 'print("x")' to 'document <= "x"' followed by 'document <= html.BR()'
    // otherwise the output goes to debug console
    var re = /print(\s*)\((.*)\)/g;
    result = result.replace(re, "document <= $2\ndocument <= html.BR()\n");

    result = result + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    var code = this.processProgram(prog);

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + prog +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  onRunIt() {
    var mypre = this.state.resultArea;

    if (!this.state.runit) {
      this.setState({
        runit: !this.state.runit,
      });

      mypre.style.display = "none";
      
      return;
    }

    var prog = this.state.editor.getValue(); 

    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();

    this.setState({
      runit: !this.state.runit,
    });
  }

  onSaveIt() {
    if (!this.username)
      return;

    var file = this.state.editor.getValue();

    request({
      url: '/api/save_file',
      method: 'POST',
      payload: {
        username: this.username,
        file: file,
        fileName: this.props.name,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your code.");
        return;
      }
      
      // TODO: Should we add to cache?

      // var activities = [{
      //   type: 'File',
      //   username: this.username,
      //   id: this.props.name,
      //   activity: {
      //     description: 'Saved',
      //     fileName: this.props.name,
      //   },
      //   time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      // }];

      // this.props.onAddActivities(activities);

      alert("Your code has been saved.");
    });
  }

  onLoadIt() {
    if (!this.username)
      return;

    request({
      url: '/api/load_file',
      method: 'GET',
      payload: {
        username: this.username,
        filename: this.props.name,
        random: Math.random(),
      }
    }, (err, res) => {
        if (err) {
          alert('Oops! Something went wrong when loading your code.');
          return;
        }

        if (!res.file) {
          alert('Your file is empty. Have you saved it?');
          return;
        }

        this.state.editor.setValue(res.file, -1);
    });
  }

  getRunIt() {
    return (
      <span>
      Run  <span style={upFontStyle}>▼</span>
      </span>
    );
  }

  getHideIt() {
    return (
      <span>
      Hide <span style={upFontStyle}>▲</span>
      </span>
    );
  }

  getRunButton() {
    if (this.props.hideCode === 'true')
      return null;

    return (
      <Button style={buttonStyle} onClick={this.onRunIt}>
      {
        //▶ Run it
        !this.state || this.state.runit ? this.getRunIt() : this.getHideIt()
      }
      </Button>
    );
  }

  showSaveButton() {
    if (this.props.save !== 'true')
      return false;

    if (!this.username)
      return false;

    return true;
  }

  getSaveButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSaveIt}>
      Save my code
      </Button>
    );
  }

  getLoadButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onLoadIt}>
      Load my code
      </Button>
    );
  }

  getRunComponents() {
    if (this.props.run !== 'true')
      return null;

    return (
      <div>
        { this.getRunButton() }
        { this.getSaveButton() }
        { this.getLoadButton() }
      </div>
    );
  }

  render() {
    // TODO: should merge style here
    var editorStyle = this.props.style || editorStyle;

    var hideStyle = { "display": "none" };
    var inputStyle = (this.props.hideCode==='true') ? hideStyle : editorContainerStyle;
    
    return (
      <div style={containerStyle}>

        { this.getRunComponents() }

        <div style={inputStyle}>
          <div ref="root" style={editorStyle}>
            {this.props.code}
          </div>
        </div>

        <div className="iframewrapper">
          <iframe ref="result" id="iframeResult" style={joinJsonObjects(resultStyle, this.props.style)}>
          </iframe>
        </div>

      </div>
    );
  }
}

Editor.propTypes = {
  config: PropTypes.object,
  code: PropTypes.string,
  run: PropTypes.string,
};

Editor.defaultProps = {
  config: {},
  code: '',
  run: 'false',
  save: 'false',
  name: 'tmp',
}; 

export default Editor;
