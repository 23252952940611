/*
 * CreateSnippetPage.js
 * 
 * Example: <CreateSnippetPage />
 *
 */
import React, { Component } from 'react';
import Button from '../Button';
import { joinJsonObjects } from '../../util/util';
import { request } from '../../util/api';
import { detectProgrammingLanguage } from '../../util/util';
import PropTypes from 'prop-types';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const containerStyle = {
};

const tdIconStyle = {
  "width": "70px",
  "paddingRight": "20px",
};

const snippetImageStyle = {
  "width": "50px",
  "height": "50px",
};

const tryImageStyle = {
  "width": "50px",
  "height": "50px",
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    "width": "570px",
    "marginLeft": "-20px",
    "marginRight": "-10px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-20px",
  "marginRight": "-20px",
};

const infoStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "fontSize": "80%",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "lineHeight": "8px",
};

const tableStyle = {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "10px",
};

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginBottom": "8px",
};

const editorStyle = {
  "height": "250px",
  "width": "620px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
};

const outputStyle = {
  "height": "60px",
  "width": "100%",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const smallFontStyle = {
  "fontSize": "75%",
};

const errorStyle = {
  "color": "red",
};

const correctMessageStyle = {
  "color" : "green",
  "fontWeight" : "600",
};

const incorrectMessageStyle = {
  "color": "red",
  "fontWeight" : "600",
};

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "white",
  "padding": "20px",
  "marginBottom": "20px",
  "width": "570px",
};

const runTestsStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "padding": "10px",
  "fontSize": "small",
};

const resultStyle = {
  "minHeight": "60px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const resultTableStyle = {
  "borderCollapse": "collapse",
};

const resultTDStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
};

const resultRedStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "red",
  "fontWeight": "600",
};

const resultGreenStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "green",
  "fontWeight": "600",
};

const buttonStyle = {
  base: {
    "marginTop": "-5px",
    "marginBottom": "5px",
    "backgroundColor": "#4CAF50",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    'fontFamily': 'Segoe UI Symbol',

    ':hover': {
      "color": "#4CAF50",
      "backgroundColor": "rgba(132, 256, 130, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "marginTop": "-5px",
    "marginBottom": "5px",
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    "marginLeft": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonViewAllStyle = {
  base: {
    "marginTop": "-5px",
    "marginBottom": "5px",
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "10px",
    "width": "130px",
    "marginLeft": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const tdLeftStyle = {
  "verticalAlign": "top",
  "width": "600px",
};
 
const rightContainer = {
  "paddingLeft": "20px",
};

const tdResultStyle = {
  "minHeight": "100px",
  // "width": "300px",
};

const tdOutputStyle = {
  "height": "100px",
  // "display": "inline-block",
};

const rightTdStyle = {
  "verticalAlign": "top",
  "width": "600px",
  "paddingLeft": "20px",
};

class CreateSnippetPage extends Component {
  constructor(props) {
    super(props);

    this.onRun = this.onRun.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onOutputChange = this.onOutputChange.bind(this);
    this.onOutputRegExChange = this.onOutputRegExChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputRegExChange = this.onInputRegExChange.bind(this);
    this.onInstructionChange = this.onInstructionChange.bind(this);
    this.onTaskChange = this.onTaskChange.bind(this);
    this.onHintChange = this.onHintChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.onCombineChange = this.onCombineChange.bind(this);
    this.onCanSkip = this.onCanSkip.bind(this);
    this.iframeContent = this.iframeContent.bind(this);
    this.onHint = this.onHint.bind(this);
    this.onShowInsight = this.onShowInsight.bind(this);

    this.id = this.props.id ? this.props.id : '';
    this.username = this.props.username ? this.props.username : '';
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    this.setState({
      name: this.props.name ? this.props.name: "TBD",
      title: this.props.title ? this.props.title: "Instructions",
      code: this.props.code ? this.props.code : "",
      instructions: this.props.instructions ? this.props.instructions : 'You can use HTML to format instructions.',
      task: this.props.task ? this.props.task : "",
      hint: this.props.hint ? this.props.hint : "",
      input: this.props.input ? this.props.input : '',
      inputRegEx: this.props.inputRegEx ? this.props.inputRegEx : '',
      output: this.props.output ? this.props.output : '',
      outputRegEx: this.props.outputRegEx ? this.props.outputRegEx : '',
      tags: this.props.tags ? this.props.tags : 'topic: TBD; level: TBD; concept: TBD',
    });

    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    var minCount = 80;
    editor.setOptions({
      fontSize: "8pt",
      minLines: minCount
    });
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      previewMessage: "",
      error: "",
      testResults: [],
      resultTableHeader: "",
      combineOutput: this.props.combineOutput,
      canSkip: this.props.canSkip,
      doHint: true,
      doInsight: false,
    });

    var currentUsername = this.props.store.getState().auth.user.username;
    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: currentUsername,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;
      if (!this.preference) {
        this.preference = {};
      }

      var settings = this.preference.editorSettings;
      if (settings) {
        editor.setTheme("ace/theme/" + settings.theme);
 
        editor.getSession().setMode("ace/mode/" + settings.language);

        if (settings.showLineNumbers) {
          editor.renderer.setShowGutter(true);
        } else {
          editor.renderer.setShowGutter(false);
        }

        if (settings.fontSize === 'small') {
          editor.setOptions({
            fontSize: "8pt",
          });
        }

        if (settings.fontSize === 'medium') {
          editor.setOptions({
            fontSize: "12pt",
          });
        }

        if (settings.fontSize === 'large') {
          editor.setOptions({
            fontSize: "16pt",
          });
        }

        if (settings.tabSize === '2') {
          editor.setOptions({
            tabSize: 2,
          });
        }

        if (settings.tabSize === '4') {
          editor.setOptions({
            tabSize: 4,
          });
        }

        if (settings.showInvisibles) {
          editor.setOption("showInvisibles", true);
        } else {
          editor.setOption("showInvisibles", false);
        }

        if (settings.keyBinding === '') {
          editor.setKeyboardHandler('');
        }

        if (settings.keyBinding === 'vim') {
          editor.setKeyboardHandler('ace/keyboard/vim');
        }

        if (settings.keyBinding === 'emacs') {
          editor.setKeyboardHandler('ace/keyboard/emacs');
        }
      }
      else {
        // no settings
        this.resetOriginal();
      }
    });

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 

      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }

    this.startTime = new Date().getTime(); // milliseconds
    this.duration = 0;
    this.attemptCount = 1;

    if (!this.id)
      return;

    this.setState({
      processActivitiesDone: false,
    });

    this.averageDuration = 0;
    this.userSet = new Set();
    this.submissions = 0;
    this.failureCount = 0;
    this.errorCount = 0;
    this.errorCode = '';
    this.failureCode = '';

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('ERROR: failed to get snippet activities');
        return;
      }

      this.activities = res.activities;

      this.processActivities();

      this.setState({
        processActivitiesDone: true,
      });
    });
  }

  processActivities() {
    var totalDuration = 0;

    this.activities.forEach(item => {
      var activity = item.activity;

      this.submissions ++;
      totalDuration += activity.duration;
      this.userSet.add(item.username);
      if (activity.passed === false) {
        this.failureCount ++;

        if (activity.error === "") {
          this.failureCode += '[' + item.username + '] ';
          this.failureCode += activity.code + '<br/><br/>';
        }
      }
      if (activity.error !== "") {
        this.errorCount ++;
        this.errorCode += '[' + item.username + '] ';
        this.errorCode += activity.error + '<br/>';
        this.errorCode += activity.code + '<br/><br/>';
      }
    });

    if (this.submissions > 0) 
      this.averageDuration = totalDuration / this.submissions / 1000;

    this.insight = '<h3>Snippet ' + this.state.name  + '</h3>' +
      'ID: <a href="snippet?id=' + this.id + '" target="_blank">' + this.id + '</a><br/>' +
      'Tags: ' + this.state.tags + '<br/><br/>' + 
      'Users: ' + this.userSet.size + '<br/>' +
      'Runs: ' + this.submissions + '<br/>' +
      'Failures: ' + this.failureCount  + ' (' + (this.failureCount*100/this.submissions).toFixed(0) + '%)' + '<br/>' +
      'Errors: ' + this.errorCount + '<br/>' +
      'Average duration: ' + this.averageDuration.toFixed(0) + ' secs' + '<br/>' +
      '<h3>Error Cases</h3>' + 
      this.errorCode + '<br/>' + 
      '<h3>Failure Cases</h3>' + 
      this.failureCode; 
  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  runCode() {
    var prog = this.state.editor.getValue(); 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";
    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();
  }

  /*
   * run snippet
   */
  onRun() {
    if (!this.username)
      return;

    this.setState({ error: "", testResults: [], resultTableHeader: "" });

    var code = this.state.editor.getValue();

    this.language = detectProgrammingLanguage(code);

    var content = '';

    if (this.language === 'python') {
      this.runCode();
    } else {
      content = '<div style="color: grey">Running...</div>';
      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close(); 
    }

    var error = "";

    this.duration = new Date().getTime() - this.startTime;
    
    request({
      url: '/api/run_snippet',
      method: 'POST',
      payload: {
        id: this.id,
        username: this.username,
        name: this.state.name,
        title: this.state.title,
        code: code,
        instructions: this.state.instructions,
        task: this.state.task,
        hint: this.state.hint,
        input: this.state.input,
        inputRegEx: this.state.inputRegEx,
        output: this.state.output,
        outputRegEx: this.state.outputRegEx,
        tags: this.state.tags,
      }
    }, (err, res) => {
      if (err) {
        error = "Error: Oops! Something went wrong when running tests.";
        alert(error);
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_FORBIDDEN_JAVA_WORDS') {
        error = 'Error: "' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'NO_TESTS') {
        error = "No input/output requirements. Please add some requirements.";
      }

      if (res.status === 'MISSING_INPUT') {
        error = "Did you forget to include <b>" + res.item + "</b> in your code?";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({ error: error });
        this.attemptCount ++;

        if (this.language === 'java') {
          content = '<div style="color: red"><pre>' + error + '</pre></div>';
          this.setState({
            content: content,
          });
          var mypre = this.state.resultArea;
          mypre.style.display = "block";
          var iframeDoc = mypre.contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(this.state.content);
          iframeDoc.close();
        }

        return;
      }

      // no error
      if (this.language === 'java') {
        content = '<div><pre>' + res.output + '</pre></div>';
        this.setState({
          content: content,
        });
        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(this.state.content);
        iframeDoc.close();
      }

      // var testResults = [ ["flip(True) → False", "False", "OK"], ["flip(False) → True", "True", "X"] ];
      this.setState({ testResults: res.results, resultTableHeader: "<tr><th>Expected</th><th>Run</th><th></th></tr>" });
      
      if (this.state.testResults) {
        // log the snippet submission activity
        this.state.testResults.forEach(entry => {
          if (entry[2] === "OK") {
            this.setState({
              passed: true,
            });
          }
          else {
            this.setState({
              passed: false,
            });

            this.attemptCount ++;
          }
        });
      }
    });
  }

  /*
   * save the nugget
   */
  onSave() {
    if (!this.username)
      return;

    if (!this.state.name) {
      alert('Please add the name of the snippet.');
      return;
    }

    if (!this.state.output && this.state.outputRegEx) {
      alert('Output RegEx is not empty, but Expected Output is empty. Make it consistent.');
      return;
    }

    if (!this.state.input && this.state.inputRegEx) {
      alert('Input RegEx is not empty, but Input Contains is empty. Make it consistent.');
      return;
    }

    // if (!this.state.tags || !this.state.tags.includes('topic') || !this.state.tags.includes('level')) {
    //   alert('Please add tags for "topic" and "level" (easy, medium, or hard).');
    //   return;
    // }

    var code = this.state.editor.getValue();

    request({
      url: '/api/save_snippet',
      method: 'POST',
      payload: {
        username: this.username,
        name: this.state.name,
        title: this.state.title,
        code : code,
        instructions: this.state.instructions,
        task: this.state.task,
        hint: this.state.hint,
        input: this.state.input,
        inputRegEx: this.state.inputRegEx,
        output: this.state.output,
        outputRegEx: this.state.outputRegEx,
        tags: this.state.tags,
        combineOutput: this.state.combineOutput,
        canSkip: this.state.canSkip,
        id: this.id,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your code.");
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        alert('Error: "import ' + res.module + '" is not currently supported by the online editor.');
      }

      if (res.status === 'CONTAINS_EVAL') {
        alert("Error: eval is not supported by the online editor.");
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }  

  /*
   * create the preview message 
   */
  onPreview() {
    var previewMessage = '';

    var code = this.state.editor.getValue();

    previewMessage += "<h4>" + this.state.title + "</h4>";
    previewMessage += "<hr>";
    previewMessage += this.state.instructions;
    previewMessage += "<br />";

    if (this.state.task) {
      previewMessage += "<br /> <h4> Try it Yourself </h4>" + this.state.task;
      previewMessage += "<br />";
    }
    
    if (this.state.hint) {
      previewMessage += "<br /> <b> Hint: </b>" + this.state.hint;
      previewMessage += "<br />";
    }
    
    this.setState({ previewMessage: previewMessage });
  }

  onInputChange(e) {
    this.setState({
      input: e.target.value,
    });
  }

  onInputRegExChange(e) {
    this.setState({
      inputRegEx: e.target.value,
    });
  }

  onOutputChange(e) {
    this.setState({
      output: e.target.value,
    });
  }

  onOutputRegExChange(e) {
    this.setState({
      outputRegEx: e.target.value,
    });
  }

  onInstructionChange(e) {
    this.setState({
      instructions: e.target.value,
    });
  }

  onTaskChange(e) {
    this.setState({
      task: e.target.value,
    });
  }

  onHintChange(e) {
    this.setState({
      hint: e.target.value,
    });
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onTitleChange(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onTagChange(e) {
    this.setState({
      tags: e.target.value,
    });
  }

  onCombineChange(e) {
    this.setState({
      combineOutput: !this.state.combineOutput,
    });
  }

  onCanSkip(e) {
    this.setState({
      canSkip: !this.state.canSkip,
    });
  }

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  onShowInsight() {
    this.setState({
      doInsight: !this.state.doInsight, 
    });
  }

  getRunTestsButton() {
    return (
      <Button style={buttonStyle} onClick={this.onRun}>
      ▶ Run
      </Button>
    );
  }

  showSaveButton() {
    if (!this.username)
      return false;

    return true;
  }

  getSaveButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getPreviewButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onPreview}>
      Preview
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='snippets'>
        <Button style={buttonViewAllStyle} >
        View All Snippets
        </Button>
      </a>
    );
  }

  getRunComponents() {
    return (
      <div>
        { this.getRunTestsButton() }
        { this.getPreviewButton() }
        { this.getSaveButton() }
        { this.getAllButton() }
      </div>
    );
  }

  displayDescription() {
    return (
      <div> { this.state.instructions } </div>
    );
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    var results = [];
    this.state.testResults.forEach(entry => {
      var isOK = entry[2]==="OK";
      var colorStyle = isOK ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  getTitle() {
    return (
      <div>
      <table>
        <tbody>
          <tr>
            <td style={tdIconStyle}>
              <img src='/img/snippet.svg' style={snippetImageStyle}></img>
            </td>
            <td>
              <h4> { this.state.title } </h4>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }

  getDescription() {
    var previewMessage = '';

    if (!this.state.instructions)
      return '';

    // previewMessage += "<h4>Name</h4>";
    // previewMessage += this.state.name;
    // previewMessage += "<div style='font-size: 75%'>author: " + (this.author == "admin" ? "jason" : this.author) + "; " + this.state.tags + "</div>";
    // previewMessage += "<hr>";
    previewMessage += "<hr>";
    previewMessage += this.state.instructions;

    // We don't need to display expected output for snippet. In the future, we may want to optionally enable this.
    // if (this.state.output) {
    //   previewMessage += "<hr>";
    //   previewMessage += "<h4>Expected Output</h4>";
    //   previewMessage += this.state.output;
    // }

    return previewMessage;
  }

  getTask() {
    if (!this.state.task)
      return (<div></div>);

    return (
      <div>
        <hr></hr>
        <table>
          <tbody>
            <tr>
              <td style={tdIconStyle}>
                <img src='/img/try.svg' style={tryImageStyle}></img>
              </td>
              <td>
                <h4> Try it Yourself </h4>
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <div dangerouslySetInnerHTML={{ __html: this.state.task }}></div>

      </div>
    );
  }

  getHint()
  {
    if (!this.state.hint)
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.state.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  canShowContentInsight() {
    if (!this.id)
      return false;

    if (!this.state)
      return false;

    if (!this.state.processActivitiesDone)
      return false;

    return true;
  }

  getContentInsight()
  {
    if (!this.canShowContentInsight())
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onShowInsight}>
          <h5> { 'Get Insight [Teacher Only]' } <span style={arrowStyle}>{ this.state.doInsight? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doInsight ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.insight }}></div> : <div></div>
        }

      </div>
    );
  }

  getDefaultName() {
    if (this.props.name)
      return this.props.name;

    return 'TBD';
  }

  getDefaultTitle() {
    if (this.props.title)
      return this.props.title;

    return 'Instructions';
  }

  getDefaultInstructions() {
    if (this.props.instructions)
      return this.props.instructions;

    return 'You can use HTML to format instructions.';
  }

  getDefaultTask() {
    if (this.props.task)
      return this.props.task;

    return '';
  }

  getDefaultHint() {
    if (this.props.hint)
      return this.props.hint;

    return '';
  }

  getDefaultOutput() {
    if (this.props.output)
      return this.props.output;

    return '';
  }

  getDefaultOutputRegEx() {
    if (this.props.outputRegEx)
      return this.props.outputRegEx;

    return '';
  }

  getDefaultInput() {
    if (this.props.input)
      return this.props.input;

    return '';
  }

  getDefaultInputRegEx() {
    if (this.props.inputRegEx)
      return this.props.inputRegEx;

    return '';
  }

  getDefaultTags() {
    if (this.props.tags)
      return this.props.tags;

    return '';
  }

  getInfo() {
    if (!this.state) {
      return (<div></div>);
    }
    
    return (
      <div style = {infoStyle} >
        <p> Snippet Name: { this.state.name } </p>
        <p> Author: { this.username } </p>
        <p> Input Contains: { this.state.input } </p>
        <p> Input RegEx: { this.state.inputRegEx } </p>
        <p> Expected Output: { this.state.output } </p>
        <p> Output RegEx: { this.state.outputRegEx } </p>
        <p> Tags: { this.state.tags } </p>
        <p> Combine Output: { this.state.combineOutput ? 'true' : 'false' } </p>
        <p> Can Skip: { this.state.canSkip ? 'true' : 'false' } </p>
      </div>
    );
  }

  render() {
    return (
<div style={containerStyle}>
<table style={tableStyle}>
<tbody>
<tr>
  <td style={tdLeftStyle}>
  <table>
  <tbody>

  <tr>
  <td style={columnStyle}> </td>
  <td>
    <div>
      { this.getRunComponents() }
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Code </td>
  <td>
    {
      <div>
        <div ref="root" style={editorStyle}>
          { this.props.code }
        </div>
      </div>
    }
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Name </td>
  <td>
    <div>
      <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
      defaultValue={ this.getDefaultName() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Title </td>
  <td>
    <div>
      <textarea id="title" rows="1" cols="85" name="title" onChange={this.onTitleChange}
      defaultValue={ this.getDefaultTitle() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Instructions </td>
  <td>
    <div>
      <textarea id="instructions" rows="10" cols="85" name="instructions" onChange={this.onInstructionChange}
      defaultValue={ this.getDefaultInstructions() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Task </td>
  <td>
    <div>
      <textarea id="task" rows="8" cols="85" name="task" onChange={this.onTaskChange}
      defaultValue={ this.getDefaultTask() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Hint </td>
  <td>
    <div>
      <textarea id="hint" rows="2" cols="85" name="hint" onChange={this.onHintChange}
      defaultValue={ this.getDefaultHint() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Expected Output </td>
  <td>
    <div>
      <textarea id="output" rows="2" cols="85" name="output" onChange={this.onOutputChange}
      defaultValue={ this.getDefaultOutput() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Output RegEx</td>
  <td>
    <div>
      <textarea id="outputRegEx" rows="2" cols="85" name="outputRegEx" onChange={this.onOutputRegExChange}
      defaultValue={ this.getDefaultOutputRegEx() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Input Contains</td>
  <td>
    <div>
      <textarea id="input" rows="2" cols="85" name="input" onChange={this.onInputChange}
      defaultValue={ this.getDefaultInput() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Input RegEx</td>
  <td>
    <div>
      <textarea id="inputRegEx" rows="2" cols="85" name="inputRegEx" onChange={this.onInputRegExChange}
      defaultValue={ this.getDefaultInputRegEx() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Tags </td>
  <td>
    <div>
      <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
      defaultValue={ this.getDefaultTags() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Combine Output </td>
  <td>
    <div>
      {
        this.state && this.state.combineOutput ? 
        <input type="checkbox" name="combine" checked onChange={this.onCombineChange}></input>
        :
        <input type="checkbox" name="combine" onChange={this.onCombineChange}></input>
      }
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Use Skip Button</td>
  <td>
    <div>
      {
        this.state && this.state.canSkip ? 
        <input type="checkbox" name="canSkip" value="on" checked onChange={this.onCanSkip}></input>
        :
        <input type="checkbox" name="canSkip" value="on" onChange={this.onCanSkip}></input>
      }
    </div>
  </td>
  </tr>

  </tbody>
  </table>
  </td>

  <td width="20px">
  </td>

  <td style={rightTdStyle}>
    <table style={rightContainer}>
    <tbody>
      <tr>
      <td style={tdResultStyle}>

        <div style={labelStyle}>
          Result
        </div>

        <div style={resultStyle}>
        {
          this.state && this.state.error && this.language === 'python' ? 
            <div style={errorStyle} dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
            :
            <div></div>
        }
        {
          this.state && this.state.testResults && this.state.testResults.length > 0 ?
            <div style={runTestsStyle}>
              {
                this.state.passed ?
                  <div>
                  <div style={ correctMessageStyle }> Hooray! </div>
                  <br />
                  <div style={ correctMessageStyle }> You passed with { this.attemptCount } { this.attemptCount === 1 ? 'submission' : 'submissions' }.</div>
                  </div>
                  :
                  <div>
                  <div style={ incorrectMessageStyle }> Output does not match.</div>
                  <br />
                  <div>Keep trying!</div>
                  </div>
              }
              <div style={smallFontStyle}>Time: { (this.duration / 1000 / 60).toFixed(2) } minutes.</div>
              <br />
              <div>
                <table style={resultTableStyle}>
                <tbody>
                  <tr><th>Expected </th><th>Actual </th><th></th></tr>
                  { this.getResults() }
                </tbody>
                </table>
              </div>
            </div>
            :
            <div></div>
        }
        </div>

      <div style={tdOutputStyle}>

        <div style={labelStyle}>
          Output
        </div>
      
        <iframe ref="result" id="iframeResult" style={joinJsonObjects(outputStyle, this.props.style)}>
        </iframe>
      </div>

      </td>
      </tr>

      <tr>
      <td>
      <div style={labelStyle}>
        <img src='/img/preview.svg' style={{'width':'40px', 'height':'40px', 'marginRight': '10px'}} ></img>
        Preview
        { this.getInfo() }
      </div>
      <div style={previewStyle}>
        {
          this.state ?
          <div>
            { this.getTitle() }
            <div dangerouslySetInnerHTML={{ __html: this.getDescription() }}></div>
            { this.getTask() }
            { this.getHint() }
            { this.getContentInsight() }
          </div>
          :
          <div></div>
        }
      </div>
      </td>
      </tr>
    </tbody>
    </table>
  </td>

</tr>
</tbody>
</table>

</div>

    );
  }
}

CreateSnippetPage.propTypes = {
  code: PropTypes.string,
};

CreateSnippetPage.defaultProps = {
  id: '',
  username: '',
  name: '',
  title: '',
  code: '',
  instructions: '',
  task: '',
  hint: '',
  input: '',
  inputRegEx: '',
  output: '',
  outputRegEx: '',
  tags: '',
  combineOutput: true,
  canSkip: false,
}; 

export default CreateSnippetPage;
