/*
 * SignupPage.js
 */
import React, { Component } from 'react';
import Button from '../Button';
import Input from '../Input';
import { request, addLog } from '../../util/api';
import LearnPage from '../LearnPage';
import { STUDENT, PARENT, PENDING_TEACHER, isStudentByRole, isParentByRole, isPendingTeacherByRole, getDateTime } from '../../util/util';
import history from '../../history';

/*
 * styles
 */
const container = {
  "margin": "0 auto",
  "marginTop": "20px",
  "padding": "0 0 40px",
  "maxWidth": "380px",
};

const textH1 = {
  "fontSize": "150%",
  "color": "#575a5b",
  "fontFamily": "'Open Sans',Helvetica,Arial,sans-serif",
  "fontWeight": "100",
  "lineHeight": "1.0",
  "textAlign": "center",
};

const inputStyle = {
  base: {
    "display": "block",
    "boxSizing": "border-box",
    "padding": "10px 16px",
    "width": "100%",
    "height": "40px",
    "outline": "0px",
    "borderWidth": "1px",
    "borderStyle": "solid",
    "borderColor": "#ccc",
    "borderRadius": "0px",
    "background": "#fff",
    "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
    "color": "#616161",
    "fontSize": "14px",
    "lineHeight": "1.3333333",
    "transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
     ":focus": {
       "borderColor": "#0074c2",
       "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 116, 194, 0.6)",
     }
  }
};

const formGroup = {
  "marginBottom": "5px",
};

const label = {
  "display": "inline-block",
  "maxWidth": "100%",
  "fontSize": "100%",
  "fontWeight": "500",
  "textAlign": "left",
  "fontFamily": '"Open Sans",Helvetica,Arial,sans-serif',
  "lineHeight": "1.5",
};

const selectorStyle = {
  // "borderRadius": "6px",
  "lineHeight": "1.5",
  "padding": "5px 10px",
  "width": "100%",
  "fontSize": "12px",
};

const roleSelectStyle = {
  "color": "#21abc7",
  "borderWidth": "1px",
  "borderStyle": "solid",
  "borderColor": "#21abc7",
  "borderRadius": "2em",
  "fontWeight": "500",
  "lineHeight": "2.2",
  "width": "31%",
  "textAlign": "center",
  "marginRight": "2%",
}

const roleCheckedStyle = {
  "color": "#ffffff",
  "background": "#21abc7",
  "borderWidth": "1px",
  "borderStyle": "solid",
  "borderColor": "#21abc7",
  "borderRadius": "2em",
  "fontWeight": "500",
  "lineHeight": "2.2",
  "width": "31%",
  "textAlign": "center",
  "marginRight": "2%",
}

var buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "#1c91a8",
    "border": "0px solid #21abc7",

    "padding": "4px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "500",
    "letterSpacing": "0.03125em",
    "fontSize": "100%",
    "lineHeight": "2.2",
    "marginBottom": "10px",
    "width": "160px",
    "textAlign": "center",

    ':hover': {
      "color": "white",
      "backgroundColor": "#21abc7",
      "borderColor": "#1c91a8",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0px"
    }
  }
};

const buttonLightStyle = {
  base: {
    "textDecoration": "none!important",
    "fontSize": "16px",
    // "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "45px",
    "width": "122px",
    "paddingLeft": "15px",
    "textAlign": "left",
    "color": "rgba(134, 149, 153, 1)",
    "backgroundColor": "#f2f2f2",

    ':hover': {
      "backgroundColor": "rgba(134, 149, 153, 1)",
      "color": "white",
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const buttonDarkStyle = {
  base: {
    "backgroundColor": "rgba(43, 142, 223, 1)",
    "color": "#fff",
    "textDecoration": "none!important",
    "fontSize": "16px",
    // "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "45px",
    "width": "122px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const formStyle = {
  "border": "1px solid rgba(43, 142, 223, 1)",
  "padding": "20px",
};

// .facebook {
//   border-color: #3b5998;
//   background: #3b5998;
//   composes: button;

//   &:hover {
//     background: #2d4373;
//   }
// }

// .google {
//   border-color: #dd4b39;
//   background: #dd4b39;
//   composes: button;

//   &:hover {
//     background: #c23321;
//   }
// }

// .twitter {
//   border-color: #55acee;
//   background: #55acee;
//   composes: button;

//   &:hover {
//     background: #2795e9;
//   }
// }

class SignupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      password2: '',
      email: '',
      personalEmail: '',
      firstName: '',
      lastName: '',
      birthMonth: 0,
      birthYear: 0,
      gender: '',
      parentFirstName: '',
      parentLastName: '',
      flash: '',
      roleAs: STUDENT,
      authenticated: false,
      tried: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePersonalEmailChange = this.handlePersonalEmailChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleBirthMonthChange = this.handleBirthMonthChange.bind(this);
    this.handleBirthYearChange = this.handleBirthYearChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleParentFirstNameChange = this.handleParentFirstNameChange.bind(this);
    this.handleParentLastNameChange = this.handleParentLastNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePassword2Change = this.handlePassword2Change.bind(this);

    this.onAsStudent = this.onAsStudent.bind(this);
    this.onAsParent = this.onAsParent.bind(this);
    this.onAsTeacher = this.onAsTeacher.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // We use submitDisabled to prevent double clicking on account creation
    this.setState({
      submitDisabled : false,
      processUserDone: false,

      showAsStudent: true,
      showAsParent: false,
      showAsTeacher: false,
    });

    if (this.props.update) {
      request({
        url: '/api/get_user',
        method: 'GET',
        payload: {
          username: this.props.username,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error: failed to get user for username ' + this.props.username);
          return;
        }

        this.user = res.user;

        this.currentBirthMonth = this.user.birthMonth;
        this.currentBirthYear = this.user.birthYear;
        this.currentGender = this.user.gender;

        this.setState({
          firstName: res.user.firstName,
          lastName: res.user.lastName,
          email: res.user.email,
          gender: res.user.gender,
          birthMonth: res.user.birthMonth,
          birthYear: res.user.birthYear,
          personalEmail: res.user.personalEmail,
          parentFirstName: res.user.parentFirstName,
          parentLastName: res.user.parentLastName,
          role: res.user.role,
          access: res.user.access,
        });

        if (isParentByRole(this.state.role)) {
          this.setState({
            roleAs: PARENT,
            showAsParent: true,
            showAsStudent: false,
            showAsTeacher: false,
          });
        } else {
          if (isPendingTeacherByRole(this.state.role)) {
            this.setState({
              roleAs: PENDING_TEACHER,
              showAsTeacher: true,
              showAsStudent: false,
              showAsParent: false,
            });
          }
        }

        this.setState({
          processUserDone: true,
        });
      });
    }
  }

  onAsStudent() {
    this.setState({
      showAsStudent: true,
      showAsParent: false,
      showAsTeacher: false,
      roleAs: STUDENT,
    });
  }

  onAsParent() {
    this.setState({
      showAsStudent: false,
      showAsParent: true,
      showAsTeacher: false,
      roleAs: PARENT,
    });
  }

  onAsTeacher() {
    this.setState({
      showAsStudent: false,
      showAsParent: false,
      showAsTeacher: true,
      roleAs: PENDING_TEACHER,
    });
  }

  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }

  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }

  handleBirthMonthChange(e) {
    this.setState({ birthMonth: e.target.value });
  }

  handleBirthYearChange(e) {
    this.setState({ birthYear: e.target.value });
  }

  handleGenderChange(e) {
    this.setState({ gender: e.target.value });
  }

  handleParentFirstNameChange(e) {
    this.setState({ parentFirstName: e.target.value });
  }

  handleParentLastNameChange(e) {
    this.setState({ parentLastName: e.target.value });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePersonalEmailChange(e) {
    this.setState({ personalEmail: e.target.value });
  }

  handleUsernameChange(e) {
    var value = e.target.value;
    var last = value.substr(value.length - 1);
    if (last >= "A" && last <= "Z") {
      alert("Please use lower case for username.");
      return;
    }

    if (last === " " || last === "&" || last === "'" || last === '"' || last === "<" || last === ">") {
      alert("Please avoid " + '"' + last + '"' +  " in username.");
      return;
    }

    this.setState({ username: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handlePassword2Change(e) {
    this.setState({ password2: e.target.value });
  }

  /*
   * Validates input
   * Returns true if the input is valid
   * Returns false otherwise
   */
  isValid() {
    if (this.props.update === 'true') {
      if (!this.state.password) {
        alert("Please enter your password.");
        return false;
      }

      if (!this.state.firstName || !this.state.lastName) {
        alert("Please enter your name.");
        return false;
      }

      if (isStudentByRole(this.state.roleAs)) {
        if (this.state.birthMonth === '0' || this.state.birthYear === '0') {
          alert("Please enter your birth year and birth month.");
          return false;
        }
        
        if (!this.state.gender) {
          alert("Please enter your gender.");
          return false;
        }

        if (!this.state.email) {
          alert("Please enter parent email.");
          return false;
        }

        if (!this.state.parentFirstName || !this.state.parentLastName) {
          alert("Please enter parent name.");
          return false;
        }
      }

      if (isPendingTeacherByRole(this.state.roleAs)) {
        if (!this.state.personalEmail) {
          alert("Please enter email.");
          return false;
        }
      }

      if (isParentByRole(this.state.roleAs)) {
        if (!this.state.personalEmail) {
          alert("Please enter email.");
          return false;
        }
      }

      return true;
    }

    if (this.state.password !== this.state.password2) {
      alert("Your passwords don't match. Please set again.");
      return false;
    }

    var name = this.state.username;
    for (var id = 0; id < name.length - 1; id++) {
      var ch = name.charAt(id);
      if (ch >= "A" && ch <= "Z") {
        alert("Your username is invalid. Please use lower case.");
        return false;
      }

      if (ch === " " || ch === "&" || ch === "'" || ch === '"' || ch === "<" || ch === ">" || ch === ';') {
        alert("Your username is invalid. Please avoid using " + '"' + ch + '"' + ".");
        return false;
      }
    }

    if (!this.state.firstName || !this.state.lastName) {
      alert("Please enter your first name and last name.");
      return false;
    }

    if (isStudentByRole(this.state.roleAs)) {
      if (!this.state.gender) {
        alert("Please enter your gender.");
        return false;
      } 

      if (!this.state.birthMonth || !this.state.birthYear) {
        alert("Please enter your birth year and birth month.");
        return false;
      }

      if (!this.state.parentFirstName || !this.state.parentLastName) {
        alert("Please enter your parent's name.");
        return false;
      } 

      if (!this.state.email) {
        alert("Please enter your parent's email.");
        return false;
      }
    }

    if (isPendingTeacherByRole(this.state.roleAs) || isParentByRole(this.state.roleAs)) {
      if (!this.state.personalEmail) {
        alert("Please enter your email.");
        return false;
      }
    }

    return true;
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.isValid())
      return;

    if (this.state.submitDisabled)
      return;

    this.setState({
      submitDisabled : true,
    });

    request({
      url: '/sign_up',
      method: 'POST',
      payload: {
        username: this.props.update === 'true' ? this.props.username : this.state.username,
        password: this.state.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        birthMonth: this.state.birthMonth,
        birthYear: this.state.birthYear,
        gender: this.state.gender,
        parentFirstName: this.state.parentFirstName,
        parentLastName: this.state.parentLastName,
        email: this.state.email,
        personalEmail: this.state.personalEmail,
        role: this.state.role | this.state.roleAs,
        update: this.props.update === 'true' ? 'true' : '',
      }
    }, (err, res) => {
      this.setState({
        submitDisabled : false,
      });

      if (err) {
        this.setState({ 
          flash: this.props.update === 'true' ? 
            "Failed to update. Password doesn't match." : "Failed to register. User existed.", 
          authenticated: false,
          tried: true,
         });

        return;
      }

      // if (res.user.flash === 'WRONG_PASSWORD') {
      //   this.setState({ 
      //     tried: true,
      //     flash: "Username and password don't match. Please try again.",
      //   });

      //   return;
      // }

      // if (res.user.flash==='USER_EXISTED')
      // {
      //   this.setState({ 
      //     flash: "That username is already taken. Please try another one.",
      //     authenticated: false,
      //     tried: true,
      //   });

      //   return;
      // }

      this.setState({ 
        username: res.user.username,
        email: res.user.email,
        personalEmail: res.user.personalEmail,
        authenticated: true,
        tried: true,
      });

      // add activity logging
      const activities = [{
        type: 'Account',
        username: res.user.username,
        id: res.user.username,
        role: res.user.role,
        activity: {
          description: this.props.update === 'true' ? 'Updated' : 'Created',
        },
        time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      }];

      addLog(activities);
      
      this.props.onSetActivities(activities);

      this.props.onLogin(res.user);

      alert('Your account has been updated.');
      history.push('/profile');
    });
  }

  flashMsg() {
    if (this.state.authenticated && this.props.update !== 'true' || !this.state.tried) {
      return (
        <div></div>
      );
    }  

    return (<div style={{ "color": "red", "marginBottom": "20px" }}> {this.state.flash} </div>);
  }

  requiredSymbol() {
    return(
      <span style={{"color": "red"}}> *</span>
    )
  }

  getMonthSelected(option) {
    if (this.props.update !== 'true') {
      if (option === 'Month')
        return 'selected';

      return '';
    }

    var result = '';
    switch(option) {
      case 'Month':
      break;

      case 'January':
      if (this.currentBirthMonth === '1')
        result = 'selected';

      break;

      case 'February':
      if (this.currentBirthMonth === '2')
        result = 'selected';
      
      break;

      case 'March':
      if (this.currentBirthMonth === '3')
        result = 'selected';
      
      break;

      case 'April':
      if (this.currentBirthMonth === '4')
        result = 'selected';
      
      break;

      case 'May':
      if (this.currentBirthMonth === '5')
        result = 'selected';
      
      break;

      case 'June':
      if (this.currentBirthMonth === '6')
        result = 'selected';
      
      break;

      case 'July':
      if (this.currentBirthMonth === '7')
        result = 'selected';
      
      break;

      case 'August':
      if (this.currentBirthMonth === '8')
        result = 'selected';
      
      break;

      case 'September':
      if (this.currentBirthMonth === '9')
        result = 'selected';
      
      break;

      case 'October':
      if (this.currentBirthMonth === '10')
        result = 'selected';
      
      break;

      case 'November':
      if (this.currentBirthMonth === '11')
        result = 'selected';
      
      break;

      case 'December':
      if (this.currentBirthMonth === '12')
        result = 'selected';
      
      break;

      default:
      break;
    }

    return result;
  }

  getYearSelected(option) {
    if (this.props.update !== 'true') {
      if (option === 'Year')
        return 'selected';

      return '';
    }

    if (option === this.currentBirthYear)
      return 'selected';

    return '';
  }

  getGenderSelected(option) {
    if (this.props.update !== 'true') {
      if (option === 'Gender')
        return 'selected';

      return '';
    }

    if (option === this.currentGender)
      return 'selected';

    return '';
  }

  getBirthInfo() {
    return (
      <div style={{"marginBottom":"10px"}}>
      <table width="100%">
      <tbody>
      <tr>

      <td>
        <select style={selectorStyle} id="birthMonth" name="birthMonth"  onChange={this.handleBirthMonthChange}>
        <option value="0" selected={ this.getMonthSelected('Month') }>Month</option>
        <option value="1" selected={ this.getMonthSelected('January') }>January</option>
        <option value="2" selected={ this.getMonthSelected('February') }>February</option>
        <option value="3" selected={ this.getMonthSelected('March') }>March</option>
        <option value="4" selected={ this.getMonthSelected('April') }>April</option>
        <option value="5" selected={ this.getMonthSelected('May') }>May</option>
        <option value="6" selected={ this.getMonthSelected('June') }>June</option>
        <option value="7" selected={ this.getMonthSelected('July') }>July</option>
        <option value="8" selected={ this.getMonthSelected('August') }>August</option>
        <option value="9" selected={ this.getMonthSelected('September') }>September</option>
        <option value="10" selected={ this.getMonthSelected('October') }>October</option>
        <option value="11" selected={ this.getMonthSelected('November') }>November</option>
        <option value="12" selected={ this.getMonthSelected('December') }>December</option>
        </select>
      </td>

      <td>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </td>

      <td>
        <select style={selectorStyle} id="birthYear" name="birthYear" onChange={this.handleBirthYearChange} >
        <option value="0" selected={ this.getYearSelected('0') }>Year</option>
        <option value="2018" selected={ this.getYearSelected('2018') }>2018</option>
        <option value="2017" selected={ this.getYearSelected('2017') }>2017</option>
        <option value="2016" selected={ this.getYearSelected('2016') }>2016</option>
        <option value="2015" selected={ this.getYearSelected('2015') }>2015</option>
        <option value="2014" selected={ this.getYearSelected('2014') }>2014</option>
        <option value="2013" selected={ this.getYearSelected('2013') }>2013</option>
        <option value="2012" selected={ this.getYearSelected('2012') }>2012</option>
        <option value="2011" selected={ this.getYearSelected('2011') }>2011</option>
        <option value="2010" selected={ this.getYearSelected('2010') }>2010</option>
        <option value="2009" selected={ this.getYearSelected('2009') }>2009</option>
        <option value="2008" selected={ this.getYearSelected('2008') }>2008</option>
        <option value="2007" selected={ this.getYearSelected('2007') }>2007</option>
        <option value="2006" selected={ this.getYearSelected('2006') }>2006</option>
        <option value="2005" selected={ this.getYearSelected('2005') }>2005</option>
        <option value="2004" selected={ this.getYearSelected('2004') }>2004</option>
        <option value="2003" selected={ this.getYearSelected('2003') }>2003</option>
        <option value="2002" selected={ this.getYearSelected('2002') }>2002</option>
        <option value="2001" selected={ this.getYearSelected('2001') }>2001</option>
        <option value="2000" selected={ this.getYearSelected('2000') }>2000</option>
        <option value="1999" selected={ this.getYearSelected('1999') }>1999</option>
        <option value="1998" selected={ this.getYearSelected('1998') }>1998</option>
        <option value="1997" selected={ this.getYearSelected('1997') }>1997</option>
        <option value="1996" selected={ this.getYearSelected('1996') }>1996</option>
        <option value="1995" selected={ this.getYearSelected('1995') }>1995</option>
        <option value="1994" selected={ this.getYearSelected('1994') }>1994</option>
        <option value="1993" selected={ this.getYearSelected('1993') }>1993</option>
        <option value="1992" selected={ this.getYearSelected('1992') }>1992</option>
        <option value="1991" selected={ this.getYearSelected('1991') }>1991</option>
        <option value="1990" selected={ this.getYearSelected('1990') }>1990</option>
        <option value="1989" selected={ this.getYearSelected('1989') }>1989</option>
        <option value="1988" selected={ this.getYearSelected('1988') }>1988</option>
        <option value="1987" selected={ this.getYearSelected('1987') }>1987</option>
        <option value="1986" selected={ this.getYearSelected('1986') }>1986</option>
        <option value="1985" selected={ this.getYearSelected('1985') }>1985</option>
        <option value="1984" selected={ this.getYearSelected('1984') }>1984</option>
        <option value="1983" selected={ this.getYearSelected('1983') }>1983</option>
        <option value="1982" selected={ this.getYearSelected('1982') }>1982</option>
        <option value="1981" selected={ this.getYearSelected('1981') }>1981</option>
        <option value="1980" selected={ this.getYearSelected('1980') }>1980</option>
        <option value="1979" selected={ this.getYearSelected('1979') }>1979</option>
        <option value="1978" selected={ this.getYearSelected('1978') }>1978</option>
        <option value="1977" selected={ this.getYearSelected('1977') }>1977</option>
        <option value="1976" selected={ this.getYearSelected('1976') }>1976</option>
        <option value="1975" selected={ this.getYearSelected('1975') }>1975</option>
        <option value="1974" selected={ this.getYearSelected('1974') }>1974</option>
        <option value="1973" selected={ this.getYearSelected('1973') }>1973</option>
        <option value="1972" selected={ this.getYearSelected('1972') }>1972</option>
        <option value="1971" selected={ this.getYearSelected('1971') }>1971</option>
        <option value="1970" selected={ this.getYearSelected('1970') }>1970</option>
        <option value="1969" selected={ this.getYearSelected('1969') }>1969</option>
        <option value="1968" selected={ this.getYearSelected('1968') }>1968</option>
        <option value="1967" selected={ this.getYearSelected('1967') }>1967</option>
        <option value="1966" selected={ this.getYearSelected('1966') }>1966</option>
        <option value="1965" selected={ this.getYearSelected('1965') }>1965</option>
        <option value="1964" selected={ this.getYearSelected('1964') }>1964</option>
        <option value="1963" selected={ this.getYearSelected('1963') }>1963</option>
        <option value="1962" selected={ this.getYearSelected('1962') }>1962</option>
        <option value="1961" selected={ this.getYearSelected('1961') }>1961</option>
        <option value="1960" selected={ this.getYearSelected('1960') }>1960</option>
        <option value="1959" selected={ this.getYearSelected('1959') }>1959</option>
        <option value="1958" selected={ this.getYearSelected('1958') }>1958</option>
        <option value="1957" selected={ this.getYearSelected('1957') }>1957</option>
        <option value="1956" selected={ this.getYearSelected('1956') }>1956</option>
        <option value="1955" selected={ this.getYearSelected('1955') }>1955</option>
        <option value="1954" selected={ this.getYearSelected('1954') }>1954</option>
        <option value="1953" selected={ this.getYearSelected('1953') }>1953</option>
        <option value="1952" selected={ this.getYearSelected('1952') }>1952</option>
        <option value="1951" selected={ this.getYearSelected('1951') }>1951</option>
        <option value="1950" selected={ this.getYearSelected('1950') }>1950</option>
        <option value="1949" selected={ this.getYearSelected('1949') }>1949</option>
        <option value="1948" selected={ this.getYearSelected('1948') }>1948</option>
        <option value="1947" selected={ this.getYearSelected('1947') }>1947</option>
        <option value="1946" selected={ this.getYearSelected('1946') }>1946</option>
        <option value="1945" selected={ this.getYearSelected('1945') }>1945</option>
        <option value="1944" selected={ this.getYearSelected('1944') }>1944</option>
        <option value="1943" selected={ this.getYearSelected('1943') }>1943</option>
        <option value="1942" selected={ this.getYearSelected('1942') }>1942</option>
        <option value="1941" selected={ this.getYearSelected('1941') }>1941</option>
        <option value="1940" selected={ this.getYearSelected('1940') }>1940</option>
        <option value="1939" selected={ this.getYearSelected('1939') }>1939</option>
        <option value="1938" selected={ this.getYearSelected('1938') }>1938</option>
        <option value="1937" selected={ this.getYearSelected('1937') }>1937</option>
        <option value="1936" selected={ this.getYearSelected('1936') }>1936</option>
        <option value="1935" selected={ this.getYearSelected('1935') }>1935</option>
        <option value="1934" selected={ this.getYearSelected('1934') }>1934</option>
        <option value="1933" selected={ this.getYearSelected('1933') }>1933</option>
        <option value="1932" selected={ this.getYearSelected('1932') }>1932</option>
        <option value="1931" selected={ this.getYearSelected('1931') }>1931</option>
        <option value="1930" selected={ this.getYearSelected('1930') }>1930</option>
        <option value="1929" selected={ this.getYearSelected('1929') }>1929</option>
        <option value="1928" selected={ this.getYearSelected('1928') }>1928</option>
        <option value="1927" selected={ this.getYearSelected('1927') }>1927</option>
        <option value="1926" selected={ this.getYearSelected('1926') }>1926</option>
        <option value="1925" selected={ this.getYearSelected('1925') }>1925</option>
        <option value="1924" selected={ this.getYearSelected('1924') }>1924</option>
        <option value="1923" selected={ this.getYearSelected('1923') }>1923</option>
        <option value="1922" selected={ this.getYearSelected('1922') }>1922</option>
        <option value="1921" selected={ this.getYearSelected('1921') }>1921</option>
        <option value="1920" selected={ this.getYearSelected('1920') }>1920</option>
        <option value="1919" selected={ this.getYearSelected('1919') }>1919</option>
        <option value="1918" selected={ this.getYearSelected('1918') }>1918</option>
      </select>
    </td>

    <td>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </td>

    <td>
      <select style={selectorStyle} id="gender" name="gender" onChange={this.handleGenderChange}>
        <option value="" selected={this.getGenderSelected('Gender')} >Gender</option>
        <option value="Male" selected={this.getGenderSelected('Male')} >Male</option>
        <option value="Female" selected={this.getGenderSelected('Female')} >Female</option>
      </select>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    );
  }

  getParentInfo() {
    return (
      <div>
        <br/>
        <div style={formGroup}>
        <label style={label} htmlFor="parentFirstName">
          Parent name
        </label>
        { this.requiredSymbol() }

        <table>
        <tbody>
          <tr>
            <td>
              <Input
                style={inputStyle}
                id="parentFirstName"
                type="text"
                name="parentFirstName"
                placeholder={ this.props.update === 'true' ? '' : 'First name' }
                value={this.props.update === 'true' ? this.state.parentFirstName: ''}
                onChange={this.handleParentFirstNameChange}
              />
            </td>
            <td>
              &nbsp;&nbsp;
            </td>

            <td>
              <Input
                style={inputStyle}
                id="parentLastName"
                type="text"
                name="parentLastName"
                placeholder={ this.props.update === 'true' ? '' : 'Last name' }
                value={this.props.update === 'true' ? this.state.parentLastName : ''}
                onChange={this.handleParentLastNameChange}
              />
            </td>
          </tr>
        </tbody>
        </table>
      </div>

      <div style={formGroup}>
        <label style={label} htmlFor="email">
          Parent email
          { this.requiredSymbol() }
        </label>
        <Input
          style={inputStyle}
          id="parentEmail"
          type="text"
          name="parentEmail"
          value={this.props.update === 'true' ? this.state.email: ''}
          onChange={this.handleEmailChange}
        />
      </div>
    </div>
    );
  }

  getAsParentInfo() {
    return (
      <div>
        <br/>
        <div style={formGroup}>
        <label style={label} htmlFor="firstName">
          Name
        </label>
        { this.requiredSymbol() }

        <table>
        <tbody>
          <tr>
            <td>
              <Input
                style={inputStyle}
                id="firstName"
                type="text"
                name="firstName"
                placeholder={ this.props.update === 'true' ? '' : 'First name' }
                value={this.props.update === 'true' ? this.state.firstName: ''}
                onChange={this.handleFirstNameChange}
              />
            </td>
            <td>
              &nbsp;&nbsp;
            </td>

            <td>
              <Input
                style={inputStyle}
                id="lastName"
                type="text"
                name="lastName"
                placeholder={ this.props.update === 'true' ? '' : 'Last name' }
                value={this.props.update === 'true' ? this.state.lastName : ''}
                onChange={this.handleLastNameChange}
              />
            </td>
          </tr>
        </tbody>
        </table>
      </div>

      <div style={formGroup}>
        <label style={label} htmlFor="email">
          Email
          { this.requiredSymbol() }
        </label>
        <Input
          style={inputStyle}
          id="personalEmail"
          type="text"
          name="personalEmail"
          value={this.props.update === 'true' ? this.state.personalEmail: ''}
          onChange={this.handlePersonalEmailChange}
        />
      </div>
    </div>
    );
  }

  getFirstAndLastName() {
    return(
      <table>
          <tbody>
            <tr>
              <td>
                <Input
                  style={inputStyle}
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder={ this.props.update === 'true' ? '' : 'First name' }
                  value={this.props.update === 'true' ? this.state.firstName : ''}
                  onChange={this.handleFirstNameChange}
                />
              </td>
              <td>
                &nbsp;&nbsp;
              </td>

              <td>
                <Input
                  style={inputStyle}
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder={ this.props.update === 'true' ? '' : 'Last name' }
                  value={this.props.update === 'true' ? this.state.lastName : ''}
                  onChange={this.handleLastNameChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
    );
  }

  getStudentInfo() {
    return(
      <div>
        <br/>
        <div style={formGroup}>
        <label style={label} htmlFor="firstName">
          Student name 
          { this.requiredSymbol() }
        </label>

        { this.getFirstAndLastName() }
      </div>

      <label style={label}>Student birth month &amp; year </label>
      { this.requiredSymbol() }
      { this.getBirthInfo() }

      <div style={formGroup}>
        <label style={label} htmlFor="email">
          Student email
        </label>
        <Input
          style={inputStyle}
          id="personalEmail"
          type="text"
          name="personalEmail"
          value={this.props.update === 'true' ? this.state.personalEmail: ''}
          onChange={this.handlePersonalEmailChange}
        />
      </div>

      { this.getParentInfo() }
    </div>
    )
  }

  getAsTeacherInfo() {
    return(
      <div>
        <br/>
        <div style={formGroup}>
        <label style={label} htmlFor="firstName">
          Name
        </label>
        { this.requiredSymbol() }

        { this.getFirstAndLastName() }
      </div>

      <div style={formGroup}>
        <label style={label} htmlFor="email">
          Email
        </label>
        { this.requiredSymbol() }
        <Input
          style={inputStyle}
          id="personalEmail"
          type="text"
          name="personalEmail"
          value={this.props.update === 'true' ? this.state.personalEmail: ''}
          onChange={this.handlePersonalEmailChange}
        />
      </div>
    </div>
    )
  }

  getUserInfo() {
    if (isStudentByRole(this.state.roleAs)) {
      return this.getStudentInfo();
    } 

    if (isParentByRole(this.state.roleAs)) {
      return this.getAsParentInfo();
    } 

    if (isPendingTeacherByRole(this.state.roleAs)) {
      return this.getAsTeacherInfo();
    }
  }

  displayUpdateSettings() {
    if (!this.props.username)
      return (<h3>Please sign in first to update account settings.</h3>);

    if (!this.state.processUserDone)
      return (<div></div>);

    return (
      <div style={container}>
        <h1 style={textH1}> Account Settings </h1>
        <br />

        {this.flashMsg()}

        <Button style={ this.state.showAsStudent ? buttonDarkStyle : buttonLightStyle } onClick={this.onAsStudent}>
          As Student
        </Button>
        <Button style={ this.state.showAsParent ? buttonDarkStyle : buttonLightStyle } onClick={this.onAsParent}>
          As Parent
        </Button>
        <Button style={ this.state.showAsTeacher ? buttonDarkStyle : buttonLightStyle } onClick={this.onAsTeacher}>
          As Teacher
        </Button>

        <div style={formStyle}>

        <form method="post">

          <div style={formGroup}>
            <label style={label} htmlFor="username">
              Your username is <b>{this.props.username}</b>
            </label>
          </div>

          <div style={formGroup}>
            <label style={label} htmlFor="password">
              Please verify your password
            </label>
            { this.requiredSymbol() }
            <Input
              style={inputStyle}
              id="verifyPassword"
              type="password"
              name="verifyPassword"
              onChange={this.handlePasswordChange}
            />
          </div>

          { this.getUserInfo() }

          <br />

          <div style={{"textAlign": "Center"}}>
            <Button style={buttonStyle} type="submit" onClick={this.handleSubmit}>
              Update Account
            </Button>
          </div>

        </form>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.update === 'true') {
      return this.displayUpdateSettings();
    }

    if (this.props.username) {
      return <LearnPage username={this.props.username} store={this.props.store}/>;
    }

    return (
      <div style={container}>

        <h1 style={textH1}> Create Free Account</h1>
        <br />

        {this.flashMsg()}

        <Button style={ this.state.showAsStudent ? buttonDarkStyle : buttonLightStyle } onClick={this.onAsStudent}>
          As Student
        </Button>
        <Button style={ this.state.showAsParent ? buttonDarkStyle : buttonLightStyle } onClick={this.onAsParent}>
          As Parent
        </Button>
        <Button style={ this.state.showAsTeacher ? buttonDarkStyle : buttonLightStyle } onClick={this.onAsTeacher}>
          As Teacher
        </Button>

        <div style={formStyle}>
        <form method="post">
          <div style={formGroup}>
            <label style={label} htmlFor="username">
              Choose a username
            </label>
            { this.requiredSymbol() }
            <Input
              style={inputStyle}
              id="username"
              type="text"
              name="username"
              autoFocus="true"
              onChange={this.handleUsernameChange}
            />
          </div>

          <div style={formGroup}>
            <label style={label} htmlFor="password">
              Choose a password
            </label>
            { this.requiredSymbol() }
            <Input
              style={inputStyle}
              id="password"
              type="password"
              name="password"
              onChange={this.handlePasswordChange}
            />
          </div>

          <div style={formGroup}>
            <label style={label} htmlFor="password">
              Verify password
            </label>
            { this.requiredSymbol() }
            <Input
              style={inputStyle}
              id="password2"
              type="password"
              name="password2"
              onChange={this.handlePassword2Change}
            />
          </div>
          { this.getUserInfo() }
  {
              // <label>
              //   <Input className="mrxs js-showPassword-toggle" type="checkbox" value="" />
              //   <span className="tcs tsxs">Show password</span>
              // </label>
  }
          <br />

          <div style={{"textAlign": "center"}}>
            <div>
              <Button style={buttonStyle} type="submit" onClick={this.handleSubmit}>
                Create Free Account
              </Button>
            </div>

            <p>
            Already have an account? <a href="/login">Sign in</a>
            </p>
          </div>

        </form>
      </div>
      </div>
    );
  }
}

export default SignupPage;