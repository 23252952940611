/*
 * CreateLessonPage.js
 * 
 * Example: <CreateLessonPage />
 *
 */
import React, { Component } from 'react';
import Button from '../Button';
import { request } from '../../util/api';

const containerStyle = {
}

const middleContainerStyle = {
  "width": "700px",
  "marginLeft": "auto",
  "marginRight": "auto", 
  "borderRadius": "10px",
  "backgroundColor": "white",
  "textAlign": "left",
};

const titleStyle = {
  "color": "white",
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "borderTopLeftRadius": "10px",
  "borderTopRightRadius": "10px",
  "padding": "20px",

  "fontSize": "18px",
  "fontWeight": "100",
  "lineHeight": "26px",
};

const subTitleStyle = {
  "fontSize": "70%",
};

const tdImageStyle = {
  "width": "50px",
};

const tdButtonStyle = {
  "textAlign": "right",
};

const imageStyle = {
  "marginLeft": "20px",
  "marginRight": "20px",
  "width": "40px",
};

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginBottom": "8px",
}

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
}

const previewContainerStyle = {
  "width": "100%",
  "verticalAlign": "top",
  "textAlign": "left",
  "paddingLeft": "20px",
  "paddingTop": "20px",
}

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
}

const buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "10px",
    "marginBottom": "10px",
    // "marginLeft": "10px",
    "border": "2px solid white",
    // "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    // "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "98px",
    "width": "100px",

    ':hover': {
      "backgroundColor": "hsla(0,0%,100%,.15)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "15px",
    "marginBottom": "15px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "verticalAlign": "top",
};

const tableStyle = {
  // "border": "solid 1px",
  "width": "100%",
};

const innerContainerStyle = {
  "padding": "30px",
  "color": "#999",
};

const h2Style = {
  "color": "#3C3C40",
  "fontSize": "20px",
  "lineHeight": "26px",
};

const hrStyle = {
  "border": "0",
  "borderTop": "2px solid #e9e9e9",
  "height": "0",
};

class CreateLessonPage extends Component {
  static defaultProps = {
    id: '',
    username: '',
    name: '',
    notes: '',
    skills: '',
    lessonPlan: '',
    tags: '',
    isTest: false,
  };

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
    this.onSkillsChange = this.onSkillsChange.bind(this);
    this.onLessonPlanChange = this.onLessonPlanChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.onIsTest = this.onIsTest.bind(this);

    this.id = this.props.id ? this.props.id : '';
    this.username = this.props.username ? this.props.username : '';
    this.name = this.props.name ? this.props.name : 'TBD';
    this.notes = this.props.notes ? this.props.notes : 'You can use HTML to format the notes. Hit the "Preview" button to review.';
    this.skills = this.props.skills ? this.props.skills :
'{ "groups": [ \n' +
'[ {"type": "Snippet", "id": "jd91s5xn"} ]\n' +
'] }';
    this.lessonPlan = this.props.lessonPlan ? this.props.lessonPlan :
'{ "groups": [ \n' +
'[ {"type": "Note", "id": "jd91s5xn"} ]\n' +
'] }';
    this.tags = this.props.tags ? this.props.tags : 'topic: TBD; level: TBD; concept: TBD';
  }

  componentDidMount() {
    this.setState({
      previewMessage: "",
      isTest: this.props.isTest,
    });
  }

  validateSkills() {
    if (!this.skills) {
      alert('Please add skills.');
      return false;
    }

    try {
      var skills = JSON.parse(this.skills);
      if (!skills.groups) {
        alert('Skills should contain a root entry called groups.');
        return false;
      }

      var result = true;
      skills.groups.forEach(group => {
        group.forEach(entry => {
          if (!entry.id) {
            result = false;
            alert('Missing an id entry.');
          }

          if (entry.type !== 'Snippet' && entry.type !== 'Nugget' && entry.type != 'Quiz' && entry.type != 'Project' &&
              entry.type !== 'Note') {
            result = false;
            alert('Please specify type as Snippet, Nugget, Quiz, Note, or Project.');
          }
        });
      });

      // validate lessonPlan, if it exists
      if (this.lessonPlan) {
        var plan = JSON.parse(this.lessonPlan);
        if (!plan.groups) {
          alert('Lesson plan should contain a root entry called groups.');
          return false;
        }
        plan.groups.forEach(group => {
          group.forEach(entry => {
            if (!entry.id) {
              result = false;
              alert('Missing an id entry.');
            }

            if (entry.type !== 'Snippet' && entry.type !== 'Nugget' && entry.type != 'Quiz' && entry.type != 'Project' &&
                entry.type !== 'Note') {
              result = false;
              alert('Please specify type as Snippet,  Nugget, Quiz, Note, or Project.');
            }
          });
        });
      }
    } catch (e) {
      result = false;
      alert('Please ensure the right JSON format for Skills or Lesson Plan.\n\n' + e.message);
    }

    return result;
  }

  /*
   * save the lesson 
   */
  onSave() {
    if (!this.username)
      return;

    if (!this.name) {
      alert('Please add the name for the lesson.');
      return;
    }

    if (!this.validateSkills())
      return;

    // if (!this.tags || !this.tags.includes('topic') || !this.tags.includes('level')) {
    //   alert('Please add tags for "topic" and "level" (easy, medium, or hard).');
    //   return;
    // }

    request({
      url: '/api/save_lesson',
      method: 'POST',
      payload: {
        username: this.username,
        name: this.name,
        notes: this.notes,
        skills: this.skills,
        lessonPlan: this.lessonPlan,
        tags: this.tags,
        isTest: this.state.isTest,
        id: this.id,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving the lesson.");
        return;
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }  

  /*
   * create the preview message 
   */
  onPreview() {
    var previewMessage = '';

    previewMessage += this.name;
    previewMessage += "<div style='font-size: 75%'>author: " + (this.username == "admin" ? "jason" : this.username) + "; " + this.tags + "</div>";
    previewMessage += "<hr>";
    previewMessage += this.notes;
    previewMessage += "<br />";

    previewMessage += this.skills;
    previewMessage += this.lessonPlan;

    this.setState({ previewMessage: previewMessage });
  }

  onNotesChange(e) {
    this.notes = e.target.value;
  }

  onNameChange(e) {
    this.name = e.target.value;
  }

  onLessonPlanChange(e) {
    this.lessonPlan = e.target.value;
  }

  onSkillsChange(e) {
    this.skills = e.target.value;
  }

  onTagChange(e) {
    this.tags = e.target.value;
  }

  onIsTest(e) {
    this.setState({
      isTest: !this.state.isTest,
    });
  }

  showSaveButton() {
    if (!this.username)
      return false;

    return true;
  }

  getSaveButton() {
    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getPreviewButton() {
    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onPreview}>
      Preview
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='lessons'>
        <Button style={buttonSaveStyle} >
        View All
        </Button>
      </a>
    );
  }

  getButtons() {
    return (
      <div>
        { this.getPreviewButton() }
        { this.getSaveButton() }
        { this.getAllButton() }
      </div>
    );
  }

  displayDescription() {
    return (
      <div> { this.notes } </div>
    );
  }

  render() {
    return (
      <div style={containerStyle}>

        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>

              <tr>
              <td style={columnStyle}> </td>
              <td>
                <div>
                  { this.getButtons() }
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Name </td>
              <td>
                <div>
                  <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
                  defaultValue={ this.name}></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Notes </td>
              <td>
                <div>
                  <textarea id="notes" rows="12" cols="85" name="notes" onChange={this.onNotesChange}
                  defaultValue={ this.notes }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Skills </td>
              <td>
                <div>
                  <textarea id="skills" rows="12" cols="85" name="skills" onChange={this.onSkillsChange}
                  defaultValue={ this.skills }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Lesson Plan</td>
              <td>
                <div>
                  <textarea id="skills" rows="8" cols="85" name="skills" onChange={this.onLessonPlanChange}
                  defaultValue={ this.lessonPlan }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Tags </td>
              <td>
                <div>
                  <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
                  defaultValue={ this.tags }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Is Test</td>
              <td style={{'text-align': 'left'}}>
                <div>
                  {
                    this.state && this.state.isTest ? 
                    <input type="checkbox" name="isTest" value="on" checked onChange={this.onIsTest}></input>
                    :
                    <input type="checkbox" name="isTest" value="on" onChange={this.onIsTest}></input>
                  }
                </div>
              </td>
              </tr>

              </tbody>
            </table>
          </td>

          <td style={previewContainerStyle}>

            <div style={containerStyle}>
              <div style={middleContainerStyle}>
                <div style={titleStyle}>
                  <table style={tableStyle}>
                    <tbody>
                      <tr>
                        <td style={tdImageStyle}>
                          <img src='/img/flag.svg' style={imageStyle}></img>
                        </td>

                        <td>
                          <table>
                            <tbody>
                              <tr>
                              <td>
                              { this.name }
                              </td>
                              </tr>
                              <tr style={subTitleStyle}>
                              <td>
                              {
                                // { this.skillList.length } 
                                // { this.skillList.length === 1 ? ' lesson' : ' lessons' }
                                this.skills.split(';').length + ' lessons'
                              }
                              </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>

                        <td style={tdButtonStyle}>
                          <Button style={buttonStyle} onClick={this.onStart}>
                          START
                          </Button>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>

                <div style={innerContainerStyle}>

                  <div style={h2Style}> Tips and notes </div>

                  <hr style={hrStyle}></hr>

                  <div>
                    <div dangerouslySetInnerHTML={{ __html: this.notes }}></div>
                  </div>

                </div>

              </div>
            </div>

          </td>
        </tr>
        </tbody>
        </table>

      </div>
    );
  }
}

export default CreateLessonPage;
