/* 
 * QuizPage.js
 * Implements the Quiz app.
 */
import React, { Component } from 'react';
import Button from '../../components/Button';
import CreateQuizPage from '../../components/CreateQuizPage';
import { CircularProgressbar } from 'react-circular-progressbar';
import { request } from '../../util/api';
import { isAdmin } from '../../util/util';

const controlBarStyle = {
  "color": "white",
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "marginBottom": "30px",
};

const controlBarTableStyle = {
  "width": "100%",
};

const tdIconStyle = {
  "width": "70px",
};

const progressContainerStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "width": "100px",
  "height": "100px",
  "borderRadius": "50%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "-20px",
  "marginBottom": "-20px",
  "paddingTop": "10px",
};

const progressStyle = {
  "width": "80px",
  "height": "80px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const progressStyle2 = {
  "width": "80px",
  "height": "80px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "fontSize": "0",
};

const buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "2px solid white",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "98px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "backgroundColor": "hsla(0,0%,100%,.15)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "width": "500px",
  "textAlign": "center",
};

const rightTdStyle = {
  "width": "500px",
  "textAlign": "right",
};

const containerStyle = {
  "marginTop": "35px",
  "marginBottom": "35px",
  "backgroundColor": "#efefef",
  "maxWidth": "1240px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const buttonReportBugStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "60px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const challengeBackground = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "maxWidth": "1240px",
};

const challengeCard = {
  "marginTop": "8px",
  "marginBottom": "6px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "maxWidth": "660px",
  "backgroundColor": "white",
  "color": "rgba(85, 85, 85, 1)",
  "padding": "2em",
};

const messageStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "10px",
  "paddingLeft": "20px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "maxWidth": "660px",
  "fontSize": "18px",
  "lineHeight": "38px",
};

const challengeRadio = {
  "border": "2px solid #000",
  "boxSizing": "border-box",
  "height": "1.25em",
  "lineHeight": "1.25em",
  "margin": "10px 0 0 10px",
  "width": "2em",
};

const questionStyle = {
  "fontSize": "22px",
  "lineHeight": "38px",
};

const inputStyle1 = {
  "border": "solid 3px green",
};

const inputStyle2 = {
  "border": "solid 3px white",
};

const iconStyle = {
  "width": "50px",
  "height": "50px",
  "marginLeft": "10px",
  "marginRight": "20px",
};

const quizzesIconStyle = {
  "width": "50px",
  "height": "50px",
  "position": "relative",
  "top": "-70px",
  "left": "27px",
};

const insightFontStyle = {
  "fontSize": "70%",
  "marginRight": "10px",
};

const insightContainer = {
  "backgroundColor": "rgba(53, 61, 69, 1)",
  "marginLeft": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "10px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "maxWidth": "660px",
  "fontSize": "22px",
  "lineHeight": "38px",
};

const infoIconStyle = {
  "width": "80px",
  "height": "80px",
  "paddingRight": "20px",
};

class QuizPage extends Component {
  constructor(props) {
    super(props);

    this.lessonState = this.props.store.getState().auth.lessonState;
    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;
    this.logs = this.props.store.getState().auth.lessonState.logs;
    this.canSkip = this.props.canSkip ? true : false;
    this.fromOutsideOfLesson = this.props.fromOutsideOfLesson === 'true' ? true: false;
    this.fromPreview = this.props.fromPreview === 'true' ? true: false;

    this.onContinue = this.onContinue.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.isAnswerRight = this.isAnswerRight.bind(this);

    this.state = {
      current: 0,
      userChoices: [],
      currentChoice: undefined,
      currentMessage : undefined,
      comments: '',
      passed: false,
      quizLoaded: false,
      doEdit: false,
      checked: false,
    };
  }

  componentWillReceiveProps(props) {
    if (!this.fromPreview)
      return;
    
    // use this to trigger update for live preview in CreateQuizPage
    this.setState({
      description: props.description,
      complexQuestion: props.complexQuestion,
      hint: props.hint,
      tags: props.tags,
    });

    this.state = {
      comments: '',
      quizLoaded: true,
      passed: false,
      checked: false,
    };
  }

  componentDidMount() {
    this.id = this.getIdFromURL();

    if (!this.id) {
      // not directly accessing quiz from /quiz?xxx, get quiz id from lesson state
      var skill = this.lessonState.skillList[this.lessonState.currentSkillIndex];
      if (skill && skill.type === 'Quiz') {
        this.id = skill.id;
      }
    }
    
    if (!this.id) {
      // from CreateQuizPage Preview
      this.setState({
        description: this.props.description,
        complexQuestion: this.props.complexQuestion,
        hint: this.props.hint,
        tags: this.props.tags,
        name: this.props.name,
      });

      this.fromPreview = true;

      this.setState({
        quizLoaded: true,
      });

      return;
    }

    request({
      url: '/api/get_quiz',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get quiz!');
        return;
      }

      if (!res.quiz) {
        this.setState({
          description: undefined,
          quizLoaded: true,
        });
        return;
      }

      this.author = res.quiz.username;
      this.setState({
        description: res.quiz.description,
        complexQuestion: res.quiz.complexQuestion,
        hint: res.quiz.hint,
        tags: res.quiz.tags,
        name: res.quiz.name,
      });

      this.setState({
        quizLoaded: true,
      });
    }); 

    this.audioSuccess = new Audio('/sound/c2.mp3');
    this.audioFail = new Audio('/sound/pop.wav');

    this.setState({
      processActivitiesDone: false,
    });

    this.userSet = new Set();
    this.checks = 0;
    this.failureCount = 0;
    this.choiceMap = {};

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('ERROR: failed to get quiz activities');
        return;
      }

      this.activities = res.activities;

      this.processActivities();

      this.setState({
        processActivitiesDone: true,
      });
    });
  }

  processActivities() {
    this.activities.forEach(item => {
      var activity = item.activity;

      this.checks ++;
      this.userSet.add(item.username);
      if (activity.passed === false) {
        this.failureCount ++;
      }
      if (!this.choiceMap[activity.choice]) {
        this.choiceMap[activity.choice] = 1;
      }
      else {
        this.choiceMap[activity.choice] = this.choiceMap[activity.choice] + 1;
      }
    });
  }

  getChoices() {
    var result = [];

    Object.keys(this.choiceMap).forEach(choice => {
      result.push(this.choiceMap[choice]);
      result.push(<span style={insightFontStyle}> choice { choice } </span>);
    });

    return result;
  }

  getInsights() {
    if (!this.state || !this.state.processActivitiesDone || !isAdmin(this.user)) {
      return (<div></div>);
    }

    return (
      <div style={insightContainer}>
      { this.userSet.size } <span style={ insightFontStyle }>users</span>
      { this.checks } <span style={ insightFontStyle }>checks</span>
      { this.failureCount  + ' (' + (this.failureCount*100/this.checks).toFixed(0) + '%)'}
      <span style={ insightFontStyle }>failures</span>
      { this.getChoices() }
      </div>
    );
  }

  getIdFromURL() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // e.g. http://www.cswonders.com/quiz?id=abcdefgh
    const href = window.location.href;
    const PATTERN = 'quiz?id=';
    const PATTERN_LEN = PATTERN.length;
    const index = href.indexOf(PATTERN);

    if (index === -1) {
      // this could happen when loading from /quiz?id=xxx
      return undefined;
    }

    return href.substring(index + PATTERN_LEN);
  }

  getProgressBar() {
    if (this.fromOutsideOfLesson) {
      return (
        <div style={ controlBarStyle }>
          <table style={ controlBarTableStyle }>
            <tbody>
              <tr>
                <td style={leftTdStyle}>
                  { this.getInsights() }
                </td>
                <td>
                  <div style={progressContainerStyle}>
                    <div style={progressStyle2}>
                      <CircularProgressbar value={ 0 } strokeWidth={0}/>
                    </div>
                    <span style={quizzesIconStyle}>
                      <img src="/img/quizzes2.svg" width="60px" height="60px"></img>
                    </span>
                  </div>
                </td>
                <td style={rightTdStyle}>
                  { this.getContinueButton() }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div style={ controlBarStyle }>
        <table style={ controlBarTableStyle }>
          <tbody>
            <tr>
              <td style={leftTdStyle}>
                { this.getInsights() }
              </td>
              <td>
                { this.getProgress() }
              </td>
              <td style={rightTdStyle}>
                { this.getContinueButton() }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // We use reat-circular-progressbar to display progress:
  // https://github.com/iqnivek/react-circular-progressbar
  // https://www.npmjs.com/package/react-circular-progressbar
  // color configuration can be controled with the CSS file in src/public/ace.css
  getProgress() {
    var current = this.props.store.getState().auth.lessonState.currentSkillIndex;
    if (this.state && (this.state.passed || this.state.checked || this.props.isTest)) {
      current += 1;
    }

    if (this.props.isTest) {
      // since test starts with +=1, we decrement it by 1
      current -= 1;
    }

    var count = 0;
    if (this.lessonState.skillList) {
      count = this.lessonState.skillList.length;
    }

    var percentage = 0;
    if (count > 0) {
      percentage = current/count;
    }

    percentage = percentage*100;
    percentage = percentage.toFixed(0);

    return (
      <div style={progressContainerStyle}>
        <div style={progressStyle}>
          <CircularProgressbar value={ percentage } text={`${percentage}%`} strokeWidth={12}/>
        </div>
      </div>
    );
  }

  getContinueButton() {
    var buttonText = 'Continue';

    if (this.fromOutsideOfLesson) {
      buttonText = 'Check';
    }
    
    if (!this.state.checked && !this.props.isTest) {
      buttonText = "Check";
    }

    return (
      <span>
        { this.getEditButton() }
        { this.getDeleteButton() }
        { this.getSkipButton() }
        { this.getReportBug() }
        <Button style={buttonStyle} onClick={this.onContinue}>
          { buttonText }
        </Button>
      </span>
    );
  }

  onEdit() {
    this.setState({ doEdit: true });
  }

  onDelete() {
    var answer = confirm('This will delete quiz ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_quiz',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
        if (err) {
          alert('error to delete quiz');
          return;
        }

        alert('quiz ' + this.id + ' is deleted');
      });
    } else {
      alert('ok, no change is made');
    }
  }

  onSkip() {
    // advance the currentSkillIndex to the next one
    var lessonState = this.props.store.getState().auth.lessonState;
    var newLessonState = {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex + 1,
      currentSkillPassed: false,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: lessonState.logs,
    };
    this.props.onContinue(newLessonState);
  }

  onContinue() {
    if (this.state.userChoices.length === 0) {
      this.setState({
        comments: "Please make your selection.",
      });

      return;
    } 

    var description = undefined;
    try {
      description = JSON.parse(this.state.description);
    } catch (e) {
    }

    if (!description) {
      this.onSkip();
    }

    var message = undefined;
    if (description) {
      message = description.choices[this.state.currentChoice-1].message;
    }

    this.setState({
      currentMessage : message,
    });

    var lessonState = this.props.store.getState().auth.lessonState;
    if (!this.state.checked) {
      if (this.isAnswerRight()) {
        // correct choice
        this.setState({
          passed: true,
          comments: "You are correct!",
        });

        if (!this.fromPreview) {
          if (!this.fromOutsideOfLesson) {
            if (this.props.onPass) {
              this.props.onPass(lessonState);
            }
          }

          if (!this.props.isTest)
            this.audioSuccess.play();

          var userActivity = {
            type: 'Quiz',
            username: this.username,
            id: this.id,
            activity: {
              name: this.state.name,
              passed: true,
              choice: this.getCurrentChoice(),
            },
          };

          this.logs.push(userActivity);

          userActivity = {
            type: 'Lesson',
            username: this.username,
            id: lessonState.lessonId,
            activity: {
              description: 'PASSED_QUIZ',
              quizId: this.id,
            },
          };

          this.logs.push(userActivity);
        }
      }
      else {
        // wrong choice
        this.setState({
          passed: false,
          comments: "That's not right. " + this.state.hint,
        });

        if (!this.fromPreview) {
          if (!this.fromOutsideOfLesson) {
            if(this.props.onFail) {
              this.props.onFail(lessonState);
            }
          }

          if (!this.props.isTest)
            this.audioFail.play();

          var userActivity = {
            type: 'Quiz',
            username: this.username,
            id: this.id,
            activity: {
              name: this.state.name,
              passed: false,
              choice: this.getCurrentChoice(),
            },
          };

          this.logs.push(userActivity);

          userActivity = {
            type: 'Lesson',
            username: this.username,
            id: lessonState.lessonId,
            activity: {
              description: 'FAILED_QUIZ',
              quizId: this.id,
            },
          };

          this.logs.push(userActivity);
        }
      }

      if (!this.fromOutsideOfLesson) {
        this.setState({
          checked: true,
        });
      }
    } 

    if (this.fromOutsideOfLesson)
      return;

    if (this.state.checked || this.props.isTest) {
      // for quiz we keep the progress and move to the next even if the answer was wrong
      // thus we start with "passed" as "true" 
      this.setState({
        passed: true,
      });

      // advance the currentSkillIndex to the next one
      var newLessonState = {
        lessonId: lessonState.lessonId,
        lessonName: lessonState.lessonName,
        currentSkillIndex: lessonState.currentSkillIndex + 1,
        currentSkillPassed: false,
        skillList: lessonState.skillList,
        groupId: lessonState.groupId,
        logs: this.logs,
      };
      if (this.props.onContinue) {
        this.props.onContinue(newLessonState);
      }
    }
  }

  onSelect(event) {
    if (this.state.checked)
      return;

    this.state.userChoices = [];
    this.state.userChoices[event.target.value] = event.target.checked ? "true" : "false";

    var choice = this.getCurrentChoice();
    this.setState({ currentChoice: choice });
  }

  getCurrentChoice() {
    var choices = this.state.userChoices;

    if (choices.length === 0) 
      return undefined;

    var result = undefined;
    Object.keys(choices).forEach(key => {
      if (choices[key] === 'true') {
        result = Number(key) + 1;
      }
    });

    return result;
  }

  isAnswerRight() {
    var result = true;
    var self = this;
    var choices = (JSON.parse(self.state.description)).choices;
    Object.keys(choices).map(function(id) {
      var choice = choices[id];
      if (!self.state.userChoices || (choice.correct != (self.state.userChoices[id] || "false"))) {
        result = false;
      }
    });

    return result;
  }

  getEditButton() {
    if (this.username !== 'admin' && this.username !== 'jason' && this.username != 'wei' && this.username != this.author)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onEdit}>
        Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (this.username !== 'admin' && this.username !== 'jason' && this.username != 'wei' && this.username != this.author)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
        Delete
      </Button>
    );
  }

  showSkipButton() {
    if (this.fromOutsideOfLesson)
      return false;

    if (isAdmin(this.user))
      return true;

    if (this.username === this.author)
      return true;

    if (this.canSkip)
      return true;

    return false;
  }

  getSkipButton() {
    if (!this.showSkipButton()) 
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSkip}>
      Skip
      </Button>
    );
  }

  getReportBug() {
    var location = 'quiz?id=' + this.id;

    return(
      <a href={ 'contact?location=' + location } target='_blank'>
        <Button style={buttonReportBugStyle}>
          Send Feedback
        </Button>
      </a>
    );
  }

  render() {
    if (!this.username) {
      return (
        <div style={containerStyle}>
          <div style={messageStyle}>
            <img src="/img/info.svg" style={infoIconStyle}></img>
            <span> Please <a href='login'>sign in</a> to track your progress. </span>
          </div>
        </div>
      );
    }
    
    if (!this.state || !this.state.quizLoaded) {
      return (<div></div>);
    }

    if (this.state.doEdit) {
      return (
        <div style={containerStyle}>
          <CreateQuizPage
            id={ this.id } 
            username={ this.author }
            name={ this.state.name }
            description={ this.state.description }
            complexQuestion ={ this.state.complexQuestion }
            tags={ this.state.tags }
            hint={ this.state.hint }
            store={ this.props.store }
          />
        </div>
      );
    }

    if (this.id !== this.props.id && this.props.id) {
      // the id is different, indicating we're moving to the next quiz
      // so we need to fetch the new quiz
      this.id = this.props.id;
      this.setState({
        quizLoaded: false,
      });

      request({
        url: '/api/get_quiz',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error to get quiz!');
          return;
        }

        if (!res.quiz) {
          this.setState({
            description: undefined,
            quizLoaded: true,
          });
          return;
        }

        this.author = res.quiz.username;
        this.setState({
          description: res.quiz.description,
          complexQuestion: res.quiz.complexQuestion,
          hint: res.quiz.hint,
          tags: res.quiz.tags,
          name: res.quiz.name,
        });

        this.setState({
          comments: '',
          // passed: false,
          userChoices: [],
          doEdit: false,
          checked: false,
          quizLoaded: true,
        });

      }); 
    }

    return (
      <div style={containerStyle}>
        { this.getProgressBar() }
        { this.renderQuiz() }
      </div>
    );
  }

  renderQuiz() {
    if (!this.state.quizLoaded)
      return <div></div>;

    if (!this.state.description) {
      return (
        <div style={messageStyle}>
          <img src="/img/info.svg" style={infoIconStyle}></img>
          <span> { 'Uh-oh: quiz ID "' + this.id + '" cannot be found on this planet.\n' } </span>
        </div>
      );
    }

    var description = undefined;
    try {
      description = JSON.parse(this.state.description);
    } catch (e) {
    }

    if (!description) {
      return <div></div>;
    }

    var self = this;
    var choices = Object.keys(description.choices).map(function(index) {
      var classType  = "";
      return (
        <div style={ description.choices[index].correct === "true" && !self.fromOutsideOfLesson && self.state.checked ? inputStyle1 : inputStyle2 }>
          <RadioInput 
            key={description.choices[index].answer}
            choice={description.choices[index].answer}
            index={index}
            classType={classType}
            onSelect={self.onSelect}
          />
        </div>
      );
    });

    var question = description.question;

    if (this.state.complexQuestion) {
      question = this.state.complexQuestion;
    }

    return(
      <div style={challengeBackground}>
        <br />
        <div style={challengeCard}>
          <table>
          <tbody>
          <tr>
          <td style={tdIconStyle}>
            <img height="50px" width="50px" src='/img/quiz.svg'></img>
          </td>
          <td>
            <div dangerouslySetInnerHTML={{ __html: question }} style={questionStyle}></div>
          </td>
          </tr>
          </tbody>
          </table>

          <hr></hr>

          { choices }

        </div>
        <br />

        {
          this.state.comments ? 
          <div>
            <div style={hintStyle}> 
              {
                this.state.passed ?
                  <img src='/img/yes.svg' style={iconStyle}></img>
                  :
                  <img src='/img/no.svg' style={iconStyle}></img>
              }
              <span dangerouslySetInnerHTML={{ __html: this.state.comments }} ></span>
            </div>

            {
              this.state.currentMessage ? 
              <div>
                <br />
                <div style={hintStyle}>
                  <img src='/img/info.svg' style={iconStyle}></img>
                  <span dangerouslySetInnerHTML={{ __html: this.state.currentMessage }} ></span>
                </div>
              </div>
              :
              <div></div>
            }
          </div>
          :
          <div></div>
        }
 
        <br />
 
      </div>
    );
  }
}

class RadioInput extends React.Component {
  render() {
    return (
      <div className={RadioInput}>
        <label className={this.props.classType}>
          <input 
            type="radio" 
            name="optionsRadios" 
            id={this.props.index} 
            value={this.props.index} 
            onChange={this.props.onSelect}
            style={challengeRadio}
          />
          {' ' + this.props.choice}
        </label>
      </div>
    );
  }
}

class Comment extends React.Component {
  render() {
    if (this.props.comment) {
      return (<div> <h3> {this.props.comment} </h3> </div>);
    }

    return (<div></div>);
  }
}

export default QuizPage;
