/*
* CreateClassroomPage.js
*
* Example: <CreateClassroomPage />
*
*/
import React, { Component } from 'react';
import Button from '../Button';
import { request } from '../../util/api';
import { getQueryValueFromURL } from '../../util/util';

const containerStyle = {
}

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
};

const selectorStyle = {
  "borderRadius": "6px",
  "lineHeight": "1.5",
  "margin-bottom": "6px",
  "width": "100%",
  "height": "30px",
  "fontSize": "14px",
};

const loadingStyle = {
  "fontSize": "22px",
  "margin": "30px 60px",
  "textAlign": "center",
};

const hourglassStyle = {
  "width": "80px",
  "paddingRight": "20px",
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "5px",
    "marginBottom": "5px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "10px",
    "width": "70px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "verticalAlign": "top",
};

const tableStyle = {
  // "border": "solid 1px",
  "width": "100%",
};

const iconStyle = {
  "textAlign": "left",
  "width": "150px",
  "paddingLeft": "25px",
};

class CreateClassroomPage extends Component {
  constructor(props) {
    super(props);
    this.username = this.props.store.getState().auth.user.username;

    this.id = getQueryValueFromURL('create-classroom?id=');
    if (!this.id) {
      this.id = this.props.id ? this.props.id : '';
    }

    this.users = [];
    this.usernameMap = {};

    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCourseChange = this.onCourseChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onStudentsChange = this.onStudentsChange.bind(this);
    this.onSyllabusChange = this.onSyllabusChange.bind(this);
    this.onClassNotesChange = this.onClassNotesChange.bind(this);
    this.onTeachersChange  = this.onTeachersChange.bind(this);
    this.onNameChange  = this.onNameChange.bind(this);

    this.name = this.props.name ? this.props.name : this.username + "'s classroom";
    this.teachers = this.props.teachers ? this.props.teachers : this.props.username;
    this.description = this.props.description ? this.props.description: '';
    this.author = this.username;
    this.students = this.props.students ? this.props.students : '';
    this.syllabus = this.props.syllabus ? this.props.syllabus: '{ "type": "Note", "id": "jlya0u2z" }';
    this.classNotes = this.props.classNotes ? this.props.classNotes: '{ "groups": [\n [ {"type": "Note", "id": "jlya0u2z"} ]\n ] }';
  }

  componentDidMount() {
    this.setState({
      getClassroomDone: true,
      canEdit: true,
      canDelete: false,
      course: '',
    });

    if (this.id) {
      // edit existing item, wait till it's loaded
      this.setState({
        getClassroomDone: false,
        canEdit: false,
      });

      request({
        url: '/api/get_classroom',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error to get classroom!');
          return;
        }

        this.classroom = res.classroom;

        this.name = this.classroom.name;
        this.teachers = this.classroom.teachers;
        this.description = this.classroom.description;

        this.setState({
          course: this.classroom.course,
        })

        this.author = this.classroom.author;
        this.students = this.classroom.students;
        this.syllabus = this.classroom.syllabus;
        this.classNotes = this.classroom.classNotes;

        // determine if the current user has edit capability
        // only admin, author, and teachers can edit or delete
        var teacherUsernames = this.teachers.split(/\s*;\s*/g);
        if (this.username === 'admin' || this.username === this.author || 
          teacherUsernames.indexOf(this.username) !== -1) {
          this.setState({
            canEdit: true,
            canDelete: true,
          });
        }

        this.setState({
          getClassroomDone: true,
        });
      }); 
    }

    this.courses = {};

    this.setState({
      processCoursesDone: false,
      processUsersDone: false,
    });

    // get courses
    request({
      url: '/api/get_all_courses',
      method: 'GET',
      payload: {
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error when recommending skills.');
        return;
      }

      this.returnedCourses = res.courses;
      this.processCourses();
      this.setState({
        processCoursesDone: true,
      });
    });

    // get users
    request({
      url: '/api/users',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get users');
        return;
      }

      this.users = res.users;
      this.users.reverse();

      this.processUsers();

      this.setState({
        processUsersDone: true,
      });
    });
  }

  validateUsernames(usernames) {
    for (var i = 0; i < usernames.length; i++) {
      var username= usernames[i];

      // this could happen when string ends with ";"
      if (username === "")
        continue;

      if (!this.usernameMap[username]) {
        alert('Username "' + username + '"" does not exist.');
        return false;
      }
    }

    return true;
  }

  /*
   * Valideates the classroom setting input
   * Returns true if input is ok
   * Returns false otherwise
   */
  validateInput() {
    if (!this.name) {
      alert('Please add a classroom name.');
      return false;
    }

    if (!this.students) {
      alert('Please add usernames of students, separated by ";".');
      return false;
    }

    var students = this.students.split(/\s*;\s*/g);
    if (!this.validateUsernames(students))
      return false;

    var teachers = this.teachers.split(/\s*;\s*/g);
    if (!this.validateUsernames(teachers))
      return false;

    if (this.syllabus) {
      try {
        JSON.parse(this.syllabus);
      } catch (e) {
        alert('Please use the correct JSON format for Syllabus.\n\n' + e.message);
        return false;
      }
    }

    if (this.classNotes) {
      try {
        JSON.parse(this.classNotes);
      } catch (e) {
        alert('Please use the correct JSON format for Class Notes.\n\n' + e.message);
        return false;
      }
    }

    return true;
  }

  /*
   * save the classroom
   */
  onSave() {
    if (!this.validateInput())
      return;

    request({
      url: '/api/save_classroom',
      method: 'POST',
      payload: {
        name: this.name,
        description: this.description,
        course: this.state.course,
        teachers: this.teachers,
        students: this.students,
        syllabus: this.syllabus,
        classNotes: this.classNotes,
        author: this.author,
        id: this.id,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving the classroom.");
        return;
      }

      if (!this.id) {
        this.id = res.id;
      }
    
      alert(res.status);
    });
  } 

  /*
   * delete the classroom
   */
  onDelete() {
    var answer = confirm('This will delete classroom ' + this.id + '. Are you sure you want to proceed?');

    if (answer !== true) {
      alert('Ok, no change is made.');
      return;
    }

    request({
      url: '/api/delete_classroom',
      method: 'POST',
      payload: { id: this.id, username: this.username }
    }, (err, res) => {
        if (err) {
          alert('Error to delete classroom!');
          return;
        }

      alert('Classroom ' + this.id + ' is deleted.');
    });
  } 

  onNameChange(e) {
    this.name = e.target.value;
  }
 
  onTeachersChange(e) {
    this.teachers = e.target.value;
  }
 
  onStudentsChange(e) {
    this.students = e.target.value;
  }

  onSyllabusChange(e) {
    this.syllabus = e.target.value;
  }

  onClassNotesChange(e) {
    this.classNotes = e.target.value;
  }

  onDescriptionChange(e) {
    this.description = e.target.value;
  }

  onCourseChange(e) {
    this.setState({
      course: e.target.value,
    });
  }

  processCourses() {
    this.returnedCourses.forEach(course => {
      var courseObj = JSON.parse(course.course);
      if (!courseObj.hide || courseObj.hide !== 'true') {
        this.courses[courseObj.title] = courseObj.items;
      }
    });
  }

  processUsers() {
    this.users.forEach(user => {
      this.usernameMap[user.username] = user;
    });
  }

  getSaveButton() {
    if (!this.state.canEdit)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getDeleteButton() {
    if (!this.state.canDelete)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
      Delete
      </Button>
    );
  }

  getButtons() {
    return (
      <div>
        { this.getSaveButton() }
        { this.getDeleteButton() }
      </div>
    );
  }

  selected(name) {
    return "";
  }

  getCoursesSelector() {
    var result = [];
    var keys = Object.keys(this.courses);
    keys.sort();

    // initialize this.course to select the default
    if (!this.state.course && keys) {
      this.setState({
        course: keys[0],
      });
    }

    keys.forEach(key => {
      result.push(<option value={ key }>{ key }</option>);
    });

    return (
      <select defaultValue={this.state.course} style={selectorStyle} id="course" name="course" onChange={this.onCourseChange} >
        { result }
      </select>
    );
  }

  getUserItems() {
    var result = [];

    this.users.forEach(user => {
      result.push(
        <div>
          {user.firstName} {user.lastName} [{user.username}];
        </div>
      );
    });

    return result;
  }

  render() {
    if (!this.state)
      return (<div></div>);

    if (!this.state.getClassroomDone) {
      return (
        <div style={loadingStyle}>
          <img src="/img/hourglass.svg" style={hourglassStyle}></img>
          <span> Loading classroom information... </span>
        </div>
      );
    }

    if (!this.state.processCoursesDone) {
      return (
        <div style={loadingStyle}>
          <img src="/img/hourglass.svg" style={hourglassStyle}></img>
          <span> Loading available courses... </span>
        </div>
      );
    }

    if (!this.username) {
      return (
        <div style={loadingStyle}>
          <span> To create a classroom, please sign in first. </span>
        </div>
      );
    }

    return (
      <div style={containerStyle}>
        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>

              <tr>
                <td style={columnStyle}>
                  <span style={iconStyle}>
                    <img src="/img/classroom.svg" width="60px" height="60px"></img>
                  </span>
                </td>
                <td>
                  <div>
                    { this.getButtons() }
                  </div>
                </td>
              </tr>

              <tr>
                <td style={columnStyle}> Classroom Name </td>
                <td>
                  <div>
                    <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
                    defaultValue={ this.name }></textarea>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={columnStyle}> Teachers </td>
                <td>
                  <div>
                    <textarea id="name" rows="1" cols="85" name="teachers" onChange={this.onTeachersChange}
                    defaultValue={ this.teachers }></textarea>
                  </div>
                </td>
              </tr>

              <tr>
               <td style={columnStyle}> Course </td>
               <td>
                 { this.getCoursesSelector() }
               </td>
              </tr>

              <tr>
                <td style={columnStyle}> Description </td>
                <td>
                  <div>
                    <textarea id="description" rows="1" cols="85" name="description" onChange={this.onDescriptionChange}
                    defaultValue={ this.description }></textarea>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={columnStyle}> Students </td>
                <td>
                  <div>
                    <textarea id="students" rows="8" cols="85" name="students" onChange={this.onStudentsChange}
                    defaultValue={ this.students }></textarea>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={columnStyle}> Syllabus </td>
                <td>
                  <div>
                    <textarea id="syllabus" rows="1" cols="85" name="syllabus" onChange={this.onSyllabusChange}
                    defaultValue={ this.syllabus }></textarea>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={columnStyle}> Class Notes </td>
                <td>
                  <div>
                    <textarea id="classNotes" rows="8" cols="85" name="classNotes" onChange={this.onClassNotesChange}
                    defaultValue={ this.classNotes }></textarea>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={columnStyle}> Usernames </td>
                <td>
                  <div>
                    { this.getUserItems() }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    );
  }
}

export default CreateClassroomPage;
