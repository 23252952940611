/*
 * api.js
 */
export function request({ url, method = 'GET', payload = {} }, cb = () => {}) {
    const req = new XMLHttpRequest();

    req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
            if (req.status !== 200) {
                cb(`Error (${req.status}): ${req.response}`);
                return;
            }
            let response = req.response;
            try {
                response = JSON.parse(response);
            } catch (e) {}
            cb(undefined, response);
        }
    };

    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        req.open(method, url);
        req.setRequestHeader('Content-Type', 'application/json');
        req.send(JSON.stringify(payload));
    } else if (method === 'GET') {
        const query = [];
        for (const param in payload) {
            if (payload[param]) {
                let paramValue = payload[param];
                if (Array.isArray(paramValue)) {
                    if (paramValue.length === 0) {
                        continue;
                    }
                    paramValue = paramValue.join(',');
                }
                query.push(`${param}=${paramValue}`);
            }
        }
        req.open(method, `${url}?${query.join('&')}`);
        req.send();
    } else {
        req.open(method, url);
        req.send(payload);
    }
}

/*
 * Add an entry to the user log
 *
 * Timestamp will be automatically injected when the entry is added to database
 * 
 * TODO: should log on the server side to avoid roundtrip delay
 */
export function addLog(activities) {
  if (!activities || activities.length === 0)
    return;

  console.log('Add log');
  var data = {
    activities: activities,
  };

  request({
    url: '/api/log_activities',
    method: 'POST',
    payload: data,
  }, (err, res) => {
    if (err) {
      console.log('ERROR: failed to log activities');
      return;
    }
  });
}

export function modifyDatabase(username, activities) {
  if (!username)
    return;

  var data = {
    activities: activities,
    username: username,
  };

  console.log('Transfering for ' + username + '...');

  request({
    url: '/api/transfer_activities',
    method: 'POST',
    payload: data,
  }, (err, res) => {
    if (err) {
      console.log('ERROR: failed to transfer activities for: ' + username);
      return;
    }

    console.log('Finished for ' + username);
  });
}
