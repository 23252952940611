/*
 * Input.js
 *
 * Example: <Input style={myStyle} id="username" type="text" name="username" onChange="onChangeHandler" placeholder="hint"/>
 * We can optionally add autoFocus="true" to set focus.
 */
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
 * sample style to be passed in
 */

// const style = {
//   base: {
//     "display": "block",
//     "boxSizing": "border-box",
//     "padding": "10px 16px",
//     "width": "100%",
//     "height": "46px",
//     "outline": "0",
//     "border": "1px solid #ccc",
//     "border-radius": "0",
//     "background": "#fff",
//     "box-shadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
//     "color": "#616161",
//     "fontSize": "18px",
//     "lineHeight": "1.3333333",
//     "transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
//      ":focus": {
//        "border-color": "#0074c2",
//        "box-shadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 116, 194, 0.6)",
//      }
//   }
// };

class Input extends Component {
  render() {
    var idMsg = (this.props.id) ? this.props.id : "";
    var typeMsg = (this.props.type) ? this.props.type : "";
    var nameMsg = (this.props.name) ? this.props.name : "";
    var placeholderMsg = (this.props.placeholder) ? this.props.placeholder : "";
    var changeHandler = (this.props.onChange) ? this.props.onChange : "";
    var valueMsg = (this.props.value) ? this.props.value : "";

    if (valueMsg) {
      if (this.props.autoFocus==="true") {
        return (
          <input style={[this.props.style.base]} autoFocus="true" id={idMsg} type={typeMsg} name={nameMsg} onChange={changeHandler} placeholder={placeholderMsg} value={valueMsg} >
            {this.props.children}
          </input> 
        );
      }

      return (
        <input style={[this.props.style.base]} id={idMsg} type={typeMsg} name={nameMsg} onChange={changeHandler} placeholder={placeholderMsg} value={valueMsg} >
          {this.props.children}
        </input> 
      );
    }

    if (this.props.autoFocus==="true") {
      return (
        <input style={[this.props.style.base]} autoFocus="true" id={idMsg} type={typeMsg} name={nameMsg} onChange={changeHandler} placeholder={placeholderMsg} >
          {this.props.children}
        </input> 
      );
    }

    return (
      <input style={[this.props.style.base]} id={idMsg} type={typeMsg} name={nameMsg} onChange={changeHandler} placeholder={placeholderMsg} >
        {this.props.children}
      </input> 
    );
  }
}

Input.propTypes = {
  style: PropTypes.object.isRequired
};

export default Radium(Input);