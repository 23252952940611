/*
 * ClassroomPage.js
 *
 * Displays class information such as syllabus, students insights, and content insights
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import { getLocalTime, getQueryValueFromURL, isTeacher } from '../../util/util';
import Button from '../../components/Button';
import NotePage from '../../components/NotePage';

const containerStyle = {
  "margin": "5px 5px",
  "padding": "20px",
  "fontFamily": "LatoWeb,Helvetica Neue,Helvetica,Arial,sans-serif",
  "textAlign": "center",
  "textAlign": "left",
  "backgroundColor": "white",
};

const tdLeft = {
  "verticalAlign": "top",
  "paddingRight": "30px",
};

const tdRight = {
  "verticalAlign": "top",
  "paddingTop": "10px",
  "paddingLeft": "10px",
};

const buttonStyle = {
  base: {
    "backgroundColor": "white",
    "textDecoration": "none!important",
    "fontSize": "16px",
    "color": "rgba(134, 149, 153, 1)",
    "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "45px",
    "width": "150px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
      "color": "rgba(0, 0, 0, 1)",
      "backgroundColor": "#f2f2f2",
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const buttonDarkStyle = {
  base: {
    "backgroundColor": "rgba(43, 142, 223, 1)",
    "color": "#fff",
    "textDecoration": "none!important",
    "fontSize": "16px",
    "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "45px",
    "width": "150px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const headerSyle = {
  "fontSize": "1.8em",
  "lineHeight": "1",
  "fontWeight": "normal",
  "marginBottom": "20px",
};

const classNotesLeft = {
  "paddingRight": "30px",
};

const descriptionTableStyle = {
  "width": "100%",
};

const tdIconStyle = {
  "width": "70px",
};

const tdButtonStyle = {
  "textAlign": "right",
};

const greetingStyle = {
  "textAlign": "left",
  "fontSize": "180%",
};

const hrStyle = {
  "borderTop": "2px solid #e9e9e9",
};

const loadingStyle = {
  "fontSize": "22px",
  "margin": "30px 60px",
  "textAlign": "center",
};

const hourglassStyle = {
  "width": "80px",
  "paddingRight": "20px",
};

const smallFontStyle = {
  "fontSize": "70%",
};

const timeStyle = {
  "color": "grey",
  "fontSize": "75%",
  "marginLeft": "10px",
};

const studentTable = {
  "border": "1px solid",
};

const tdStyle = {
  "border": "1px solid",
  "padding": "5px 20px",
  "maxWidth": "600px",
};

const tdTitleStyle = {
  "border": "1px solid",
  "padding": "5px 20px",
  "fontWeight": "bold",
  "maxWidth": "600px",
};

const tdSkillStyle = {
  "border": "1px solid",
  "padding": "5px 20px",
  "maxWidth": "600px",
  "textAlign": "right",
};

const spaceStyle = {
  "marginRight": "10px",
};

const insightFontStyle = {
  "fontSize": "70%",
  "marginRight": "10px",
};

const insightChoiceFontStyle = {
  "fontSize": "70%",
};

class ClassroomPage extends Component {
  constructor(props) {
    super(props);

    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;

    this.id = getQueryValueFromURL('classroom?id=');
    if (!this.id) {
      this.id = this.props.id ? this.props.id : '';
    }

    this.onSyllabus = this.onSyllabus.bind(this);
    this.onClassNotes = this.onClassNotes.bind(this);
    this.onStudentInsight = this.onStudentInsight.bind(this);
    this.onCourseInsight = this.onCourseInsight.bind(this);
    this.onLiveActivities = this.onLiveActivities.bind(this);

    this.studentUsernames = [];
    this.usernameMap = {};
  }

  componentDidMount() {
    if (!this.id)
      return;

    this.setState({
      getClassroomDone: false,
      getAllNotesDone: false,
      processUsersDone: false,
      processActivitiesDone: false,
      processCourseDone: false,
      processLessonsDone: false, 
      processProjectsDone: false,
      canEdit: false,

      showSyllabus: true,
      showClassNotes: false,
      showStudentInsight: false,
      showCourseInsight: false,
      showLiveActivities: false,
    });

    this.syllabusObj = null;
    this.classNotesObj = null;
    this.noteMap = {};
    this.passedNuggetMap = {};
    this.passedQuizMap = {};
    this.savedProjectMap = {};
    this.lessonIdMap = {};
    this.projectIdMap = {};
    this.passedLessonMap = {};
    this.snippetMap = {};

    this.snippetIdUserMap = {};
    this.snippetIdTotalDurationMap = {};
    this.snippetIdSubmissionsMap = {};
    this.snippetIdFailureCountMap = {};
    this.snippetIdErrorCountMap = {};

    this.quizIdUserMap = {};
    this.quizIdChecksMap = {};
    this.quizIdFailureCountMap = {};
    this.quizIdChoiceMap = {};

    this.projectIdUserMap = {};
    this.projectIdSavesMap = {};
    this.projectIdRunsMap = {};
    this.projectIdErrorsMap = {};

    request({
      url: '/api/get_classroom',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error to get classroom!');
        return;
      }

      this.classroom = res.classroom;
      this.teachers = this.classroom.teachers;
      this.students = this.classroom.students;
      this.syllabus = this.classroom.syllabus;
      this.syllabusObj = this.syllabus ? JSON.parse(this.syllabus) : null;
      this.classNotes = this.classroom.classNotes;
      this.classNotesObj = this.classNotes ? JSON.parse(this.classNotes) : null;
      this.description = this.classroom.description;

      this.courseName = this.classroom.course;

      // determine if the current user has view capability
      // only admin, author, and teachers can view dashboard
      this.teacherUsernames = this.teachers.split(/\s*;\s*/g);
      this.teacherUsernames = this.teacherUsernames.filter(item => item !== '');

      if (isTeacher(this.user) || this.username === this.author || 
        this.teacherUsernames.indexOf(this.username) !== -1) {
        this.setState({
          canEdit: true,
        });
      }

      this.studentUsernames = this.students.split(/\s*;\s*/g);
      this.studentUsernames = this.studentUsernames.filter(item => item !== '');
      this.studentUsernames.sort();

      if (this.syllabusObj) {
        request({
          url: '/api/get_note',
          method: 'GET',
          payload: {
            id: this.syllabusObj.id,
            random: Math.random(),
          }
        }, (err, res) => {
          if (err) {
            console.log('Error to get note id: ' + this.syllabusObj.id);
            return;
          }

          var note = res.note;
          this.noteId = note ? note.id : '';

          this.setState({
            noteLoaded: true,
          });
        }); 
      }

      this.setState({
        getClassroomDone: true,
      });

      request({
        url: '/api/get_all_courses',
        method: 'GET',
        payload: {
          username: this.username,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error to get all courses!');
          return;
        }

        this.courses = res.courses;

        this.processCourses();

        this.courseObj = this.courseObjArray.find(item => {
          return item.title === this.courseName;
        });

        this.processCourse();

        this.setState({
          processCourseDone: true,
        });

        // get lessons
        request({
          url: '/api/get_all_lessons',
          method: 'GET',
          payload: {
            username: this.username,
            random: Math.random(),
          }
        }, (err, res) => {
          if (err) {
            console.log('Error: failed to get all lessons');
            return;
          }

          this.lessons = res.lessons;
          this.processLessons();

          this.processSkills();

          this.setState({
            processLessonsDone: true,
          });

          // get all projects
          request({
            url: '/api/get_all_projects',
            method: 'GET',
            payload: {
              username: this.username,
              random: Math.random(),
            }
          }, (err, res) => {
            if (err) {
              console.log('Error: failed to get all projects');
              return;
            }

            this.projects = res.projects;
            this.processProjects();
            this.setState({
              processProjectsDone: true,
            });

            request({
              url: '/api/bulk_activities_with_count',
              method: 'GET',
              payload: {
                usernames: this.studentUsernames,
                count: undefined,
                random: Math.random(),
              }
            }, (err, res) => {
              if (err) {
                console.log('Error: failed to get activities');
                return;
              }
              
              this.activities = res.activities;

              this.processActivities();

              this.setState({
                processActivitiesDone: true,
              });
            });
          });
        });
      });
    }); 

    request({
      url: '/api/users',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get users');
        return;
      }

      this.users = res.users;
      this.users.reverse();

      this.processUsers();

      this.setState({
        processUsersDone: true,
      });
    });

    request({
      url: '/api/get_all_notes',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
        if (err) {
          console.log('Error to get all notes!');
          return;
        }
        
        this.allNotesObj = res.notes;
        this.processAllNotes();

        this.setState({
          getAllNotesDone: true,
        });
    });

  }

  getLessonEntries(username) {
    if (!this.state.processActivitiesDone) {
      return (<span>Loading...</span>);
    }

    var result = [];
    for (var key in this.passedLessonMap[username]) {
      // handle the special case where name has been changed since the user logged activity
      if (!this.lessonIdMap[key])
        continue;

      result.push(
        <span style={{"display":"inline-block"}}> 
          <a href={ '/lesson?id=' + key } >{this.lessonIdMap[key].name};</a>
          <span style={spaceStyle}></span>
        </span>
      );
    }
    return result;
  }

  getProjectEntries(username) {
    if (!this.state.processActivitiesDone)
      return (<span>Loading...</span>);

    if (this.savedProjectMap.size === 0 || !this.savedProjectMap[username])
      return (<span>None</span>);

    var result = [];
    for (var key in this.savedProjectMap[username]) {
      result.push(
        <span style={{"display":"inline-block"}}> 
          <a href={ '/project?id=' + key } >{ this.savedProjectMap[username][key].activity.title }</a>
          <span style={spaceStyle}></span>
        </span>
      );
    }
    return result;
  }

  // Pre-condition: this.allNotesObj is set up
  processAllNotes() {
    if (!this.allNotesObj)
      return;

    this.allNotesObj.forEach(note => {
      this.noteMap[note.id] = note;
    });
  }

  processCourses() {
    this.courseObjArray = [];
    this.courses.forEach(item => {
      this.courseObjArray.push(JSON.parse(item.course));
    });
  }

  processLessons() {
    if (!this.lessons)
      return;

    this.lessons.forEach(lesson => {
      this.lessonIdMap[lesson.id] = lesson;
    });
  }

  processProjects() {
    if (!this.projects)
      return;

    this.projects.forEach(project => {
      this.projectIdMap[project.id] = project;
    });
  }

  // Constructs courseSkillIdSet, which contains the IDs of snippets, quizes, and nuggets of the course
  processSkills() {
    if (!this.courseIdSet)
      return;

    this.courseSkillIdSet = new Set();

    this.courseIdSet.forEach(id => {
      if (this.lessonIdMap[id]) {
        var lessonObj = this.lessonIdMap[id];
        if (lessonObj.skills) {
          var skillsObj = null;
          try {
            skillsObj = JSON.parse(lessonObj.skills);
          } catch (e) {
            console.log('Error in parsing skills.\n\n' + e.message);
            return;
          }

          var groups = skillsObj.groups;
          if (!groups)
            return;

          groups.forEach(group => {
            group.forEach(item => {
              this.courseSkillIdSet.add(item.id);
            });
          });
        }
      }
    });
  }

  // Constructs courseIdSet, which contains the IDs of lessons, projects, and tests of the course
  processCourse() {
    if (!this.courseObj)
      return;

    if (!this.courseObj.items)
      return;

    this.courseIdSet = new Set();
    this.idTypeMap = {};

    this.courseObj.items.forEach(row => {
      if (row) {

        row.forEach(item => {
          if (item.id) {
            this.courseIdSet.add(item.id);
          }

          switch(item.type) {
            case 'Lesson':
            this.idTypeMap[item.id] = 'Lesson';
            break;

            case 'Project':
            this.idTypeMap[item.id] = 'Project';
            break;

            case 'Test':
            this.idTypeMap[item.id] = 'Test';
            break;

            default:
            break;
          }
        });
      }
    });
  }

  processUsers() {
    this.users.forEach(user => {
      this.usernameMap[user.username] = user;
    });
  }

  processActivities() {
    var activities = this.activities;

    for (var i = 0; i < activities.length; i++) {
      var item = activities[i];
      if (!item)
        continue;

      var id = item.id;

      // Collect student progress related to the course of the class
      if (!this.courseIdSet.has(id) && !this.courseSkillIdSet.has(id))
        continue;

      var username = item.username;
      var type = item.type;
      var activity = item.activity;

      switch (type) {
        case 'Lesson':
          if (!this.passedLessonMap[username]) {
            this.passedLessonMap[username] = {};
          }
          if (!this.passedLessonMap[username][id]) {
            this.passedLessonMap[username][id] = new Set();
          }
          this.passedLessonMap[username][id].add(activity.groupId);
          break;

        case 'Project':
          if (!this.projectIdUserMap[id]) {
            this.projectIdUserMap[id] = new Set();
          }
          this.projectIdUserMap[id].add(username);

          if (activity.description === 'Saved') {
            if (!this.projectIdSavesMap[id]) {
              this.projectIdSavesMap[id] = 0;
            }
            this.projectIdSavesMap[id] = this.projectIdSavesMap[id] + 1;

            if (!this.savedProjectMap[username]) {
              this.savedProjectMap[username] = {};
            }
            this.savedProjectMap[username][id] = item;
          } 

          if (activity.description === 'Run') {
            if (!this.projectIdRunsMap[id]) {
              this.projectIdRunsMap[id] = 0;
            }
            this.projectIdRunsMap[id] = this.projectIdRunsMap[id] + 1;
          }

          if (activity.error !== '') {
            if (!this.projectIdErrorsMap[id]) {
              this.projectIdErrorsMap[id] = 0;
            }
            this.projectIdErrorsMap[id] = this.projectIdErrorsMap[id] + 1;
          }
          break;

        case 'Nugget':
          if (activity.correctCount === activity.totalCount) {
            if (id) {
              if (!this.passedNuggetMap[username]) {
                this.passedNuggetMap[username] = {};
              }
              this.passedNuggetMap[username][activity.name] = true;
            }
          }
          break;

        case 'Quiz':
          if (!this.quizIdUserMap[id]) {
            this.quizIdUserMap[id] = new Set();
          }
          this.quizIdUserMap[id].add(username);

          if (!this.quizIdChecksMap[id]) {
            this.quizIdChecksMap[id] = 0;
          }
          this.quizIdChecksMap[id] = this.quizIdChecksMap[id] + 1;

          if (!this.quizIdChoiceMap[id]) {
            this.quizIdChoiceMap[id] = {};
          }
          if (!this.quizIdChoiceMap[id][activity.choice]) {
            this.quizIdChoiceMap[id][activity.choice] = 1;
          } else {
            this.quizIdChoiceMap[id][activity.choice] = this.quizIdChoiceMap[id][activity.choice] + 1;
          }

          if (activity.passed === false) {
            if (!this.quizIdFailureCountMap[id]) {
              this.quizIdFailureCountMap[id] = 0;
            }
            this.quizIdFailureCountMap[id] = this.quizIdFailureCountMap[id] + 1;
          }

          if (activity.score === activity.total) {
            if (id) {
              if (!this.passedQuizMap[username]) {
                this.passedQuizMap[username] = {};
              }
              this.passedQuizMap[username][id] = true;
            }
          }
          break;

        case 'Snippet':
          if (id) {
            if (!this.snippetMap[username]) {
              this.snippetMap[username] = {};
            }
            this.snippetMap[username][id] = true;

            if (!this.snippetIdUserMap[id]) {
              this.snippetIdUserMap[id] = new Set();
            }
            this.snippetIdUserMap[id].add(username);

            if (!this.snippetIdSubmissionsMap[id]) {
              this.snippetIdSubmissionsMap[id] = 0;
            }
            this.snippetIdSubmissionsMap[id] = this.snippetIdSubmissionsMap[id] + 1;

            if (!this.snippetIdTotalDurationMap[id]) {
              this.snippetIdTotalDurationMap[id] = 0;
            }
            this.snippetIdTotalDurationMap[id] = this.snippetIdTotalDurationMap[id] + activity.duration;

            if (activity.passed === false) {
              if (!this.snippetIdFailureCountMap[id]) {
                this.snippetIdFailureCountMap[id] = 0;
              }
              this.snippetIdFailureCountMap[id] = this.snippetIdFailureCountMap[id] + 1;
            }

            if (activity.error !== '') {
              if (!this.snippetIdErrorCountMap[id]) {
                this.snippetIdErrorCountMap[id] = 0;
              }
              this.snippetIdErrorCountMap[id] = this.snippetIdErrorCountMap[id] + 1;
            }
          }
          break;

        case 'Challenge':
        break;

        case 'Tutorial': 
        break;

        case 'File':
        break;

        case 'Account':
        break;

        default:
        break;
      }
    }
  }

  onSyllabus() {
    this.setState({
      showClassNotes: false,
      showStudentInsight: false,
      showCourseInsight: false,
      showLiveActivities: false,
    });

    this.setState({
      showSyllabus: true,
    });
  }

  onClassNotes() {
    this.setState({
      showSyllabus: false,
      showStudentInsight: false,
      showCourseInsight: false,
      showLiveActivities: false,
    });

    this.setState({
      showClassNotes: true,
    });
  }

  onStudentInsight() {
    this.setState({
      showSyllabus: false,
      showClassNotes: false,
      showCourseInsight: false,
      showLiveActivities: false,
    });

    this.setState({
      showStudentInsight: true,
    });
  }

  onCourseInsight() {
    this.setState({
      showSyllabus: false,
      showClassNotes: false,
      showStudentInsight: false,
      showLiveActivities: false,
    });

    this.setState({
      showCourseInsight: true,
    });
  }

  onLiveActivities() {
    this.setState({
      showSyllabus: false,
      showClassNotes: false,
      showStudentInsight: false,
      showCourseInsight: false,
    });

    this.setState({
      showLiveActivities: true,
    });
  }

  getMenu() {
    return (
      <div>
      {
        // <Button style={buttonStyle} onClick={this.onAnnouncements}>
        //   Announcements
        // </Button>
        // <br />
      }
        <Button style={ this.state.showSyllabus ? buttonDarkStyle : buttonStyle } onClick={this.onSyllabus}>
          Syllabus
        </Button>
        <br />
        <Button style={ this.state.showClassNotes ? buttonDarkStyle : buttonStyle } onClick={this.onClassNotes}>
          Class Notes
        </Button>
        <br />
        <Button style={ this.state.showStudentInsight ? buttonDarkStyle : buttonStyle } onClick={this.onStudentInsight}>
          Student Insight
        </Button>
        <br />
        <Button style={ this.state.showCourseInsight ? buttonDarkStyle : buttonStyle } onClick={this.onCourseInsight}>
          Course Insight
        </Button>
        <br />
        <Button style={ this.state.showLiveActivities ? buttonDarkStyle : buttonStyle } onClick={this.onLiveActivities}>
          Live Activities
        </Button>
      </div>
    );
  }

  displaySyllabus() {
    if (!this.state.showSyllabus)
      return (<div></div>);

    if (!this.syllabusObj)
      return (<div> No syllabus </div>);

    if (!this.state.noteLoaded)
      return (<div></div>);

    return (
      <div style={{'marginTop': '-40px', 'marginLeft': '-20px'}}>
        <NotePage id={this.noteId} store={this.props.store} />
      </div>
    );
  }

  displayClassNotes() {
    if (!this.state.showClassNotes)
      return (<div></div>);

    if (!this.state.getAllNotesDone)
      return (<div></div>);

    var result = [];

    result.push(<div style={headerSyle}>Class Notes</div>);

    if (!this.classNotesObj || !this.classNotesObj.groups) {
      result.push(<div>No class notes</div>);
      return result;
    } 

    var groups = this.classNotesObj.groups;

    var count = 1;

    groups.forEach(items => {
      var link = 'note?id=';

      // result.push(<span style={classNotesLeft}>{ 'Session ' + count + ':'}</span>);

      items.forEach(item => {
        var title = this.noteMap[item.id].title;
        if (!title) {
          title = item.type;
        }

        result.push(
          <span style={{'paddingRight':'20px'}}>
            { item.type + ': ' }
            <a href={ link + item.id } target={ '_blank' } >{ title }</a>
          </span>
        );
      });

      count ++;
      result.push(<br />);
    });

    return result;
  }

  getStudentTableEntries() {
    if (!this.state.processLessonsDone || !this.state.processUsersDone)
      return (<div></div>);

    var result = [];

    this.studentUsernames.forEach(username => {
      if (username) {
        result.push(
          <tr>
          <td style={tdStyle}>
            <a href={ 'profile?user=' + username }>{ username }</a>
          </td>
          <td style={tdStyle}>
            { this.usernameMap[username].firstName + ' ' + this.usernameMap[username].lastName }
          </td>
          <td style={tdStyle}>
            { this.getLessonEntries(username) }
          </td>
          <td style={tdStyle}>
            { this.getProjectEntries(username) }
          </td>
          </tr>
        );
      }
    });

    return result;
  }

  getCourseTableEntries() {
    if (!this.state.processCourseDone)
      return (<div></div>);

    var result = [];

    this.courseIdSet.forEach(id => {
      var name = '';
      switch (this.idTypeMap[id]) {
        case 'Lesson':
        name = this.lessonIdMap[id] ? this.lessonIdMap[id].name : '';
        break;

        case 'Project':
        name = this.projectIdMap[id] ? this.projectIdMap[id].name : '';
        break;

        case 'Test':
        name = this.lessonIdMap[id] ? this.lessonIdMap[id].name : '';
        break;

        default:
        console.log('Unexpected unit: ' + id);
        break;
      }

      result.push(
        <tr>
        <td style={tdStyle}>
          <a href={this.getLink(this.idTypeMap[id], id)} target='_blank'>
            { name }
          </a>
        </td>
        <td style={tdStyle}>
          { this.idTypeMap[id] }
        </td>
        <td style={tdStyle}>
            { this.getProgress(this.idTypeMap[id], id) }
        </td>
        </tr>
      );

      if (this.idTypeMap[id] === 'Lesson') {
        result.push(this.getSkillEntries(this.lessonIdMap[id]));
      }
    });

    return result;
  }

  getLink(type, id) {
    var link = '';

    switch (type) {
      case 'Snippet':
      link = 'snippet?id=' + id;
      break;

      case 'Quiz':
      link = 'quiz?id=' + id;
      break;

      case 'Nugget':
      link = 'run?id=' + id;
      break;

      case 'Lesson':
      link = 'lesson?id=' + id;
      break;

      case 'Project':
      link = 'project?id=' + id;
      break;

      case 'Test':
      link = 'lesson?id=' + id;
      break;

      default:
      break;
    }

    return link;
  }

  getSkillEntries(lessonObj) {
    if (!lessonObj)
      return (<div></div>);

    if (!lessonObj.skills) 
      return (<div></div>);

    var skillsObj = null;
    try {
      skillsObj = JSON.parse(lessonObj.skills);
    } catch (e) {
      console.log('Error in parsing skills.\n\n' + e.message);
      return (<div></div>);
    }

    var result = [];

    var groups = skillsObj.groups;
    if (!groups)
      return (<div></div>);

    groups.forEach(group => {
      group.forEach(item => {
        result.push(
          <tr>
          <td style={tdSkillStyle}>
            <a href={ this.getLink(item.type, item.id) } target='_blank' >
            { item.id } 
            </a>
          </td>
          <td style={tdStyle}>
            { item.type }
          </td>
          <td style={tdStyle}>
            { this.getProgress(item.type, item.id) }
          </td>
          </tr>
        );
      });
    });

    return result;
  }

  getChoices(id) {
    if (!this.quizIdChoiceMap[id])
      return;

    var result = [];

    Object.keys(this.quizIdChoiceMap[id]).forEach(choice => {
      result.push(<span style={insightChoiceFontStyle}> choice { choice }: </span>);
      result.push(this.quizIdChoiceMap[id][choice]);
    });

    return result;
  }

  getProgress(type, id) {
    if (!this.state.processActivitiesDone) 
      return (<div></div>);

    var result = [];

    switch(type) {
      case 'Snippet':
      result.push(
        <div>
          { this.snippetIdUserMap[id] ? this.snippetIdUserMap[id].size : 0 } <span style={insightFontStyle}>students</span>
          { this.snippetIdSubmissionsMap[id] ? this.snippetIdSubmissionsMap[id] : 0 } <span style={insightFontStyle}>checks</span>
          { this.snippetIdFailureCountMap[id] ? 
            this.snippetIdFailureCountMap[id] + ' (' + (this.snippetIdFailureCountMap[id]*100/this.snippetIdSubmissionsMap[id]).toFixed(0) + '%) ' 
            : '0 '} <span style={insightFontStyle}>failures</span>
          { this.snippetIdErrorCountMap[id] ? this.snippetIdErrorCountMap[id] : 0 } <span style={insightFontStyle}>errors</span>
          { this.snippetIdSubmissionsMap[id] && this.snippetIdSubmissionsMap[id] !== 0 ? 
            (this.snippetIdTotalDurationMap[id]/this.snippetIdSubmissionsMap[id]/1000).toFixed(1)
            : 
            0 } <span style={insightFontStyle}>secs</span>
        </div>
      );
      break;

      case 'Quiz':
      result.push(
         <div>
          { this.quizIdUserMap[id] ? this.quizIdUserMap[id].size : 0 } <span style={ insightFontStyle }>students</span>
          { this.quizIdChecksMap[id] ? this.quizIdChecksMap[id] : 0} <span style={ insightFontStyle }>checks</span>
          { this.quizIdFailureCountMap[id] ? this.quizIdFailureCountMap[id] + ' (' + (this.quizIdFailureCountMap[id]*100/this.quizIdChecksMap[id]).toFixed(0) + '%)'
          : '0'} <span style={ insightFontStyle }>failures</span>
          { this.getChoices(id) }
        </div> 
      );
      break;

      case 'Project':
      result.push(
        <div>
          { this.projectIdUserMap[id] ? this.projectIdUserMap[id].size : 0 } <span style={insightFontStyle}>students</span>
          { this.projectIdSavesMap[id] ? this.projectIdSavesMap[id] : 0 } <span style={insightFontStyle}>saves</span>
          { this.projectIdRunsMap[id] ? this.projectIdRunsMap[id] : 0 } <span style={insightFontStyle}>runs</span>
          { this.errors ? this.projectIdErrorsMap[id]: 0 } <span style={insightFontStyle}>errors</span>
        </div>
      );
    }

    return result;
  }

  displayStudentInsight() {
    if (!this.state.showStudentInsight)
      return (<div></div>);

    var result = [];
    result.push(<div style={headerSyle}>Student Insight</div>);
    result.push(<div>{ 'Number of students: ' + this.studentUsernames.length } </div>);
    result.push(<br/>);


    result.push(
      <table style={studentTable}>
        <tbody>
          <tr>
            <td style={tdTitleStyle}>
              Username
            </td>
            <td style={tdTitleStyle}>
              Name
            </td>
            <td style={tdTitleStyle}>
              Lessons Completed
            </td>
            <td style={tdTitleStyle}>
              Projects Saved
            </td>
          </tr>
          { this.getStudentTableEntries() }
        </tbody>
      </table>
    );

    return result;
  }

  displayCourseInsight() {
    if (!this.state.showCourseInsight)
      return (<div></div>);

    var result = [];
    result.push(<div style={headerSyle}>Course Insight</div>);
    result.push(<div>{ 'Number of units: ' + this.courseIdSet.size } </div>);
    result.push(<br/>);
    
    result.push(
      <table style={studentTable}>
        <tbody>
          <tr>
            <td style={tdTitleStyle}>
              Name
            </td>
            <td style={tdTitleStyle}>
              Type
            </td>
            <td style={tdTitleStyle}>
              Progress 
            </td>
          </tr>

          { this.getCourseTableEntries() }
        </tbody>
      </table>
    );

    return result;
  }

  displayLiveActivities() {
    if (!this.state.showLiveActivities)
      return (<div></div>);

    if (!this.state.processActivitiesDone)
      return (<div></div>);

    var result = [];
    result.push(<div style={headerSyle}>Recent Activities</div>);

    result.push(this.getActivityItems());

    return result;
  }

  getName(username) {
    if (!this.usernameMap[username])
      return '';

    return this.usernameMap[username].firstName + ' ' + this.usernameMap[username].lastName;
  }

  getActivityItems() {
    var result = [];

    var recentActivities = this.activities;
    recentActivities.forEach(item => {
      var time = item.time;
      var type = item.type;
      var username = item.username;
      var activity = item.activity;

      switch (type) {
       case 'Lesson':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'lesson?id=' + item.id}>
            { activity.lessonName }
            </a>
            {' '}
            (unit { activity.groupId })
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Nugget':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'run?id=' + item.id}>
            { activity.name } {' '}
            </a>
            {
/*
            ({ activity.correctCount }/{ activity.totalCount } answered correctly)
            { 
              activity.error ?
              <span> {' ' + activity.error }</span>
              :
              <span></span>
            }
*/
            }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Challenge':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description } ({ activity.score }/{ activity.total } answered correctly on first try)
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Tutorial':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'File':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description } - { activity.fileName }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Account':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Snippet':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'snippet?id=' + item.id}>
            { activity.title } 
            </a>
            {' '} (passed: { activity.passed ? 'yes' : 'no' }; attempts: { activity.attemptCount })
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Quiz':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            <a href={'quiz?id=' + item.id}>
            { activity.name } 
            </a>
            {' '} (passed: { activity.passed ? 'yes' : 'no' }; choice: { activity.choice })
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      case 'Project':
        result.push(
          <div>
            <b style={{'color': 'green'}}>[ { this.getName(username) } <span style={smallFontStyle}> { username } </span> { '] ' } </b>
            <b> { type } { ' ' } </b>
            { activity.description } - 
            <a href={'project?id=' + item.id}>
            { activity.title }
            </a>
            {
              activity.error?
              <span>{ activity.error }</span>
              :
              <span></span>
            }
            <span style={timeStyle}>{ getLocalTime(time) }</span> 
          </div>
        );
        break;

      default:
        console.log('Unknown type ' + type);
      }
    });

    return result;
  }

  render() {
    if (!this.state)
      return (<div></div>);

    if (!this.id) {
      return (
        <div style={loadingStyle}>
          <span> { "Can't find classroom id '" + this.id + "'" } </span>
        </div>
      );
    }

    if (!this.state.getClassroomDone) {
      return (
        <div style={loadingStyle}>
          <img src="/img/hourglass.svg" style={hourglassStyle}></img>
          <span> Loading your classroom... </span>
        </div>
      );
    }

    if(!this.state.canEdit) {
      return (
        <div style={loadingStyle}>
          <span> You don't have permission to view the classroom dashboard. </span>
        </div>
      );
    }

    return (
      <div style={containerStyle}>
        <div style={ greetingStyle }>
          { this.classroom.name } 
        </div>
        <div>
          { (this.teacherUsernames.length === 1 ? 'Teacher: ' : 'Teachers: ') + this.teacherUsernames }
        </div>
        <div>
          { 'Number of students: ' + this.studentUsernames.length }
        </div>

        <hr style={hrStyle}></hr>

        <table>
          <tbody>
            <tr>
              <td style={tdLeft}>
                { this.getMenu() }
              </td>
              <td style={tdRight}>
                { this.displaySyllabus() }
                { this.displayClassNotes() }
                { this.displayStudentInsight() }
                { this.displayCourseInsight() }
                { this.displayLiveActivities() }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ClassroomPage;
