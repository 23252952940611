/*
 * CreateQuizPage.js
 * 
 * Example: <CreateQuizPage />
 *
 */
import React, { Component } from 'react';
import Button from '../Button';
import { request } from '../../util/api';
import QuizPage from '../../components/QuizPage';

const containerStyle = {
  "paddingLeft": "20px",
  "paddingRight": "20px",
}

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
}

const infoStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "fontSize": "80%",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "lineHeight": "8px",
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "15px",
    "marginBottom": "15px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonViewAllStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "15px",
    "marginBottom": "15px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "12px",
    "color": "white",
    "borderRadius": "10px",
    "width": "120px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "verticalAlign": "top",
};

const tableStyle = {
  // "border": "solid 1px",
  "width": "100%",
};

const labelStyle = {
  "fontSize": "16px",
  "fontWeight": "500",
};

class CreateQuizPage extends Component {
  static defaultProps = {
    id: '',
    username: '',
    name: '',
    description: '',
    complexQuestion: '',
    hint: '',
    tags: '',
  };

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onHintChange = this.onHintChange.bind(this);
    this.onComplexQuestionChange = this.onComplexQuestionChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);

    this.id = this.props.id ? this.props.id : '';
    this.username = this.props.username ? this.props.username : '';
  }

  componentDidMount() {
    this.setState({
      name: this.props.name ? this.props.name : 'TBD',
      complexQuestion: this.props.complexQuestion ? this.props.complexQuestion : '',
      description: this.props.description ? this.props.description:
'{\n' + 
'  "question": "What is 1 + 1?",\n' + 
'  "choices": [\n' + 
'    {\n' + 
'      "answer": "1",\n' + 
'      "correct": "false"\n' + 
'    },\n' + 
'    {\n' + 
'      "answer": "2",\n' + 
'      "correct": "true"\n' + 
'    },\n' + 
'    {\n' + 
'      "answer": "3",\n' + 
'      "correct": "false"\n' + 
'    }\n' + 
'  ]\n' + 
'}',
    hint: this.props.hint ? this.props.hint : '',
    tags: this.props.tags ? this.props.tags : 'topic: TBD; level: TBD; concept: TBD',
    doRefresh: true,
    });
  }

  validateDescription() {
    if (!this.state.description) {
      alert('Please add the quiz description.');
      return false;
    }

    try {
      var description= JSON.parse(this.state.description);
      if (!description.question && !this.state.complexQuestion) {
        alert('Quiz should contain an entry called "question"');
        return false;
      }

      if (!description.choices) {
        alert('Quiz should contain an entry called "choices"');
        return false;
      }

      var result = true;
      description.choices.forEach(choice => {
        if (!choice.answer) {
          result = false;
          alert('Missing an "answer" field in "choices"');
        }

        if (!choice.correct) {
          result = false;
          alert('Missing a "correct" field in "choices"');
        }
      });
    } catch (e) {
      result = false;
      alert('Please ensure the right JSON format for Skills.\n\n' + e.message);
    }

    return result;
  }

  /*
   * save the quiz 
   */
  onSave() {
    if (!this.username)
      return;

    if (!this.state.name) {
      alert('Please add the name for the quiz.');
      return;
    }

    if (!this.validateDescription())
      return;

    // if (!this.state.tags || !this.state.tags.includes('topic') || !this.state.tags.includes('level')) {
    //   alert('Please add tags for "topic" and "level" (easy, medium, or hard).');
    //   return;
    // }

    request({
      url: '/api/save_quiz',
      method: 'POST',
      payload: {
        username: this.username,
        name: this.state.name,
        description: this.state.description,
        complexQuestion: this.state.complexQuestion,
        tags: this.state.tags,
        hint: this.state.hint,
        id: this.id,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving the quiz.");
        return;
      }

      if (!this.id) {
        this.id = res.id;
      }

      alert(res.status);
    });
  }  

  onDescriptionChange(e) {
    this.setState({
      description: e.target.value,
    });
    this.refresh();
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value,
    });
    this.refresh();
  }

  onComplexQuestionChange(e) {
    this.setState({
      complexQuestion: e.target.value,
    });
    this.refresh();
  }

  onHintChange(e) {
    this.setState({
      hint: e.target.value,
    });
    this.refresh();
  }

  onTagChange(e) {
    this.setState({
      tags: e.target.value,
    });
    this.refresh();
  }

  showSaveButton() {
    if (!this.username)
      return false;

    return true;
  }

  getSaveButton() {
    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='quizzes'>
        <Button style={buttonViewAllStyle} >
        View All Quizzes
        </Button>
      </a>
    );
  }

  getButtons() {
    return (
      <div>
        { this.getSaveButton() }
        { this.getAllButton() }
      </div>
    );
  }

  displayDescription() {
    return (
      <div> { this.state.description } </div>
    );
  }

  getDefaultName() {
   return this.props.name ? this.props.name : 'TBD'  
  }

  getDefaultDescription() {
    if(this.props.description)
      return this.props.description;

    return (
'{\n' + 
'  "question": "What is 1 + 1?",\n' + 
'  "choices": [\n' + 
'    {\n' + 
'      "answer": "1",\n' + 
'      "correct": "false"\n' + 
'    },\n' + 
'    {\n' + 
'      "answer": "2",\n' + 
'      "correct": "true"\n' + 
'    },\n' + 
'    {\n' + 
'      "answer": "3",\n' + 
'      "correct": "false"\n' + 
'    }\n' + 
'  ]\n' + 
'}'
    );
  }

  getDefaultComplexQuestion() {
    return this.props.complexQuestion ? this.props.complexQuestion : '';
  }

  getDefaultHint() {
    return this.props.hint? this.props.hint : '';
  }

  getDefaultTags() {
    if (this.props.tags)
      return this.props.tags;

    return ('topic: TBD; level: TBD; concept: TBD');
  }

  refresh() {
    this.setState({
      doRefresh: !this.state.doRefresh,
    });
  }

  getPreview() {
    if (!this.state)
      return (<div></div>);

    return (
      <div>
        <div style={labelStyle}>
          <br />
          <img src='/img/preview.svg' style={{'width':'40px', 'height':'40px', 'marginRight': '10px'}} ></img>
          Preview
        </div>

        { this.getInfo() }

        <QuizPage name={ this.state.name } hint={ this.state.hint } tags={ this.state.tags }
          description={ this.state.description } complexQuestion={ this.state.complexQuestion }
          store={ this.props.store } refresh={ this.state.doRefresh } fromPreview='true' fromOutsideOfLesson='true' />
      </div>
    );
  }

  getInfo() {
    if (!this.state) {
      return (<div></div>);
    }
    
    return (
      <div style = {infoStyle} >
        <p> Quiz Name: { this.state.name } </p>
        <p> Author: { this.username } </p>
        <p> Tags: { this.state.tags } </p>
      </div>
    );
  }

  render() {
    return (
      <div style={containerStyle}>

        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={tableStyle}>
              <tbody>

              <tr>
              <td style={columnStyle}> </td>
              <td>
                <div>
                  { this.getButtons() }
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Name </td>
              <td>
                <div>
                  <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
                  defaultValue={ this.getDefaultName() }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Description </td>
              <td>
                <div>
                  <textarea id="description" rows="18" cols="85" name="description" onChange={this.onDescriptionChange}
                  defaultValue={ this.getDefaultDescription() }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Complex Question </td>
              <td>
                <div>
                  <textarea id="complexQuestion" rows="4" cols="85" name="complexQuestion" onChange={this.onComplexQuestionChange}
                  defaultValue={ this.getDefaultComplexQuestion() }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Hint </td>
              <td>
                <div>
                  <textarea id="hint" rows="2" cols="85" name="hint" onChange={this.onHintChange}
                  defaultValue={ this.getDefaultHint() }></textarea>
                </div>
              </td>
              </tr>

              <tr>
              <td style={columnStyle}> Tags </td>
              <td>
                <div>
                  <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
                  defaultValue={ this.getDefaultTags() }></textarea>
                </div>
              </td>
              </tr>

              </tbody>
            </table>
          </td>

        </tr>
        </tbody>
        </table>

        {
          this.getPreview()
        }

      </div>

    );
  }
}

export default CreateQuizPage;
