/*
 * ProfilePage.js
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import { getLocalTime, getQueryValueFromURL, isParent, isAdmin, getUniqueFileName } from '../../util/util';
import AdminPage from '../AdminPage';
import ParentProfilePage from '../ParentProfilePage';
import points from '../../config/point-config';
import Button from '../Button';
import Editor from '../Editor';
import { MAX_PROFILE_ACTIVITY_COUNT } from '../../config/constant-config';

const baseContainerStyle = {
  "fontFamily": "museo-sans-rounded,sans-serif",
  "textAlign": "center",
};

const tableStyle = {
  "verticalAlign": "top",
  "width": "1000px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const trStyle = {
  "verticalAlign": "top",
};

const tdStyle = {
  "verticalAlign": "top",
};

const infoContainerStyle = {
  "width": "300px",
  "paddingBottom": "30px",
}

const achievementsStyle = {
  "marginTop": "35px",
  "padding": "20px 20px",
  "borderRadius": "10px",
  "backgroundColor": "rgba(255, 255, 255, 1)",
};

const publicAchievementsStyle = {
  "marginTop": "35px",
  "padding": "20px 20px",
  "borderRadius": "10px",
  "backgroundColor": "rgba(255, 255, 255, 1)",
  "border": "1pt solid",
};

const tdButtonStyle = {
  "textAlign": "right",
};

const containerStyle = {
  "padding": "30px",
  "borderRadius": "20px",
  "backgroundColor": "white",
  "margin": "35px 35px",
  "textAlign": "left",
};

const greetingStyle = {
  "textAlign": "center",
  "fontSize": "180%",
};

const itemStyle = {
  "paddingLeft": "5px",
  "paddingRight": "5px",
  "paddingBottom": "2px",
  "color": "white",
  // "backgroundColor": "#ffae1c",
  "backgroundColor": "rgba(17, 129, 58, 1)",
  "marginTop": "30px",
};

const leaderBoardStyle = {
  "marginTop": "35px",
  "padding": "20px 20px",
  "borderRadius": "10px",
  "backgroundColor": "rgba(255, 255, 255, 1)",
};

const leaderBoardTableStyle = {
  "marginTop": "20px",
  "width": "100%",
};

const leaderboardTitleStyle = {
  "marginLeft": "20px",
};

const spaceStyle = {
  "marginRight": "20px",
};

const hrStyle = {
  "border": "0",
  "borderTop": "2px solid #e9e9e9",
  "height": "0",
  "margin": "40px 0",
};

const nameBlock = {
  "display": "inline-table",
};

const nameStyle = {
  "display": "grid",
  "verticalAlign": "bottom",
  "marginLeft": "20px",
  "textAlign": "left",
};

const hrefStyle= {
  "marginTop": "10px",
  "fontSize": "60%",
  "textAlign": "left",
};

const demoStyle = {
  "display": "inline-block",
  "marginLeft": "20px",
  "textAlign": "left",
  "verticalAlign": "bottom",
};

const publicNameStyle = {
  "display": "grid",
  "verticalAlign": "bottom",
  "fontSize": "70%",
  "marginTop": "10px",
  "textAlign": "left",
  "display": "block",
};

const pointsStyle = {
  "fontSize": "33px",
  "paddingLeft": "10px"
};

const bigPointsStyle = {
  "fontSize": "28px",
};

const pointsLabelStyle = {
  "fontSize": "11px",
};

const completionTableStyle = {
  "width": "100%",
  "marginTop": "20px",
  "fontSize": "20px",
  "textAlign": "center",
};

const pointsCardStyle = {
  "marginLeft": "-10px",
  "textAlign": "center",
};

const pointsContainer = {
  "textAlign": "center",
};

const pointsInnerContainer = {
  "maxWidth": "600px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const resultContainer = {
  // "paddingRight": "50px",
  "marginTop": "20px",
  "marginBottom": "-20px",
};

const timeStyle = {
  "color": "grey",
  "fontSize": "75%",
  "marginLeft": "10px",
};

const leaderNameStyle = {
  // "color": "rgba(28, 175, 245, 1)",
  // "fontWeight": "500",
};

const leaderPointStyle = {
  "color": "rgba(166, 166, 166, 1)",
  "textAlign": "right",
  "paddingRight": "10px",
};

const loadingStyle = {
  "fontSize": "22px",
  "margin": "30px 60px",
  "textAlign": "center",
};

const hourglassTyle = {
  "width": "80px",
  "paddingRight": "20px",
};

const innerTableStyle = {
  "width": "100%",
};

const projectImageStyle = {
  "textAlign": "center",
  "width": "35px",
  "height": "35px",
  "marginBottom": "10px",
};

const lessonButtonStyle = {
  base: {
    "padding": "10px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "200",
    "fontSize": "100%",
    "color": "white",
    "backgroundColor": "rgba(28, 175, 246, 1)",
    "width": "160px",
    "textAlign": "center",
    "border": "none",
    "marginBottom": "20px",

    ':hover': {
      "backgroundColor": "rgba(62, 187, 247, 1)",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0"
    }
  }
};

const projectTitleStyle = {
  "marginLeft": "20px",
  "fontSize": "140%",
};

const editorStyle = {

};

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.onRunIt = this.onRunIt.bind(this);
  }

  componentDidMount() {
    this.user = this.props.store.getState().auth.user;

    this.username = this.props.username ? this.props.username : 
      this.user.username;

    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;

    if (!this.firstName)
      this.firstName = this.username;

    if (!this.lastName)
      this.lastName = '';

    this.publicUsername = getQueryValueFromURL('profile?user=');
    if (this.publicUsername) {
      this.username = this.publicUsername;
    }

    this.passedNuggetMapOfUser = {};
    this.passedLessonMapOfUser = {};
    this.passedQuizMapOfUser = {};
    this.passedChallengeMapOfUser = {};
    this.snippetMapOfUser = {};
    this.savedProjectMapOfUser = {};
    this.tutorialMapOfUser = {};
    this.feedbackMapOfUser = {};

    this.passedNuggetMap = {};
    this.passedLessonMap = {};
    this.passedQuizMap = {};
    this.passedChallengeMap = {};

    this.snippetMap = {};
    this.savedProjectMap = {};
    this.tutorialMap = {};
    this.fileMap = {};

    this.lessonsIdMap = {};
    this.nuggetsIdMap = {};
    this.projectsNameMap = {};
    this.savedFileMap = {};

    this.usernamePointsArray = [];

    this.usernameMap = {};

    this.setState({
      processAllActivitiesDone: false,
      processUserActivitiesDone: false,
      processLessonsDone: false,
      processProjectsDone: false,
      processNuggetsDone: false,
      processUsersDone: false,
      fileLoadedMap: {},
      selectedTitle: '',
    });

    this.getAllActivitiesByUser();

    request({
      url: '/api/users',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('Error: failed to get users');
        return;
      }

      this.users = res.users;

      this.processUsers();

      if (this.props.username) {
        this.user = this.usernameMap[this.props.username];
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
      }

      this.setState({
        processUsersDone: true,
      });
    });

    request({
      url: '/api/get_user_points',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('ERROR: failed to get user points');
        return;
      }

      this.usernamePointsArray = res.usernamePointsArray ? res.usernamePointsArray : null;

      this.setState({
        processAllActivitiesDone: true,
      });
    });

    // get lessons
    request({
      url: '/api/get_all_lessons',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('Error: failed to get all lessons');
        return;
      }

      this.lessons = res.lessons;

      this.processLessons();

      this.setState({
        processLessonsDone: true,
      });
    });

    // get all nuggets
    request({
      url: '/api/get_all_nuggets',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('Error: failed to get all nuggets');
        return;
      }

      this.nuggets = res.nuggets;

      this.processNuggets();

      this.setState({
        processNuggetsDone: true,
      });
    });

    // get all projects
    request({
      url: '/api/get_all_projects',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('Error: failed to get all projects');
        return;
      }

      this.projects = res.projects;

      this.processProjects();

      this.setState({
        processProjectsDone: true,
      });
    });
  }

  getAllActivitiesByUser () {
    this.userActivities = this.props.store.getState().auth.activities;

    if (this.userActivities.length !== 0) {
      // cache hit
      console.log('Profile cache hit');

      this.processUserActivities(this.userActivities);

      this.setState({
        processUserActivitiesDone: true,
      });

      this.getSavedFiles();
      return;
    }

    // cache miss; get activities for this user
    request({
      url: '/api/all_activities_by_user',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('ERROR: failed to get activities for user: ' + this.username);
        return;
      }

      console.log('Profile cache miss');

      this.userActivities = res.activities ? res.activities : null;

      // update cache
      this.props.onAddActivities(this.userActivities);

      this.processUserActivities(this.userActivities);

      this.setState({
        processUserActivitiesDone: true,
      });

      this.getSavedFiles();
    });
  }

  processUsers() {
    this.users.forEach(user => {
      this.usernameMap[user.username] = user;
    });
  }

  getSavedFiles() {
    if (!this.publicUsername)
      return;

    console.log('here');
    console.dir(this);
    console.dir(this.projectsNameMap);

    for (var id in this.savedProjectMapOfUser) {
      var activity = this.savedProjectMapOfUser[id];
      var fileName = getUniqueFileName(activity.id, activity.activity.title, this.publicUsername);

      request({
        url: '/api/load_file',
        method: 'GET',
        payload: {
          username: this.publicUsername,
          filename: fileName,
          title: activity.activity.title,
          random: Math.random(),
        }
      }, (err, res) => {
          if (err) {
            alert('Oops! Something went wrong when loading your code.');
            return;
          }

          if (!res.file) {
            console.log('Loaded file is empty.');
          }

          this.savedFileMap[res.title] = res.file;

          var fileLoadedMap = this.state.fileLoadedMap;
          fileLoadedMap[res.title] = true;
          this.setState({
            fileLoadedMap: fileLoadedMap,
          });
      });
    }
  }

  onRunIt(e) {
    this.setState({
      selectedTitle: e.target.innerText,
    });
  }

  /*
   * getPoints()
   */
  getPoints() {
    var totalPoints = 0;
    var numNugget = 0;
    var numQuiz = 0;
    var numSnippet = 0;
    var numLesson = 0;
    var numTutorial = 0;
    var numChallenge = 0;
    var numProject = 0;
    var numFeedback = 0;

    if (this.passedLessonMapOfUser) {
      Object.keys(this.passedLessonMapOfUser).forEach(key => {
        numLesson += this.passedLessonMapOfUser[key].size;
      });
      totalPoints += numLesson * points.lessonPoints;
    }

    if (this.passedNuggetMapOfUser) {
      numNugget = Object.keys(this.passedNuggetMapOfUser).length;
      totalPoints += numNugget * points.nuggetPoints;
    }

    if (this.savedProjectMapOfUser) {
      numProject = Object.keys(this.savedProjectMapOfUser).length;
      totalPoints += numProject * points.projectPoints;
    }

    if (this.passedQuizMapOfUser) {
      numQuiz = Object.keys(this.passedQuizMapOfUser).length;
      totalPoints += numQuiz * points.quizPoints;
    }

    if (this.snippetMapOfUser) {
      numSnippet = Object.keys(this.snippetMapOfUser).length;
      totalPoints += numSnippet * points.snippetPoints;
    }

    if (this.tutorialMapOfUser) {
      numTutorial = Object.keys(this.tutorialMapOfUser).length;
      totalPoints += numTutorial * points.tutorialPoints;
    }

    if (this.feedbackMapOfUser) {
      if (this.feedbackMapOfUser[this.username]) {
        numFeedback = this.feedbackMapOfUser[this.username].size;
        totalPoints += numFeedback * points.feedbackPoints;
      }
    }

    if (this.passedChallengeMapOfUser) {
      numChallenge = Object.keys(this.passedChallengeMapOfUser).length;
      totalPoints += numChallenge * points.challengePoints;
    }

    return (
      <div style={pointsContainer}>
      <div style={pointsInnerContainer}>
        <div style={pointsCardStyle}>
          <img src='/img/medal.png'></img>
          <span style={pointsStyle}>{ totalPoints }</span> <span style={pointsLabelStyle}>points</span>
        </div>

        <table style={completionTableStyle}>
          <tbody>
            <tr>
              <td>
                { numLesson } <span style={pointsLabelStyle}> { numLesson === 1 ? 'lesson' : 'lessons' }</span>
              </td>
              {
              // <td>
              //   { numSnippet } <span style={pointsLabelStyle}>{ numSnippet === 1 ? 'snippet' : 'snippets' }</span>
              // </td>
              // <td>
              //   { numQuiz } <span style={pointsLabelStyle}>{ numQuiz === 1 ? 'quiz' : 'quizzes' }</span>
              // </td>
              }
              <td>
                { numNugget } <span style={pointsLabelStyle}> { numNugget === 1 ? 'nugget' : 'nuggets' }</span>
              </td>
              <td>
                { numProject } <span style={pointsLabelStyle}> { numProject === 1 ? 'project' : 'projects' }</span>
              </td>
              <td>
                { numTutorial } <span style={pointsLabelStyle}> { numTutorial === 1 ? 'tutorial' : 'tutorials' }</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    );
  }

  getActivityItems() {
    var activities = this.userActivities;

    if (!activities || 
        !this.state.processLessonsDone ||
        !this.state.processNuggetsDone ||
        !this.state.processProjectsDone) {
      return (<div></div>);
    }

    activities.sort((x, y) => {
      if (!y || !y.time) {
        return -1;
      }

      if (!x || !x.time) {
        return 1;
      }

      return y.time.localeCompare(x.time);
    });

    activities = activities.slice(0, MAX_PROFILE_ACTIVITY_COUNT);

    var result = [];

    activities.forEach(item => {
      var type = item.type;
      var activity = item.activity;
      var id = item.id;

      if (type === 'Project') {
        result.push(
          <div>
            { type }: 
            <a href={ '/project?id=' + id }> { activity.name } </a>
            ( { activity.description})
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }

      if (type === 'Lesson' && activity.lessonName) {
        result.push(
          <div>
            { type }: 
            <a href={ '/lesson?id=' + id }> { activity.lessonName } </a>
            (unit { activity.groupId })
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }

      if (type === 'Nugget') {
        result.push(
          <div> 
            { type }: 
            <a href={ '/run?id=' + id}> { activity.name } </a>
            {
/*
            ({ activity.correctCount }/{ activity.totalCount } answered correctly)
*/
            }
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }

      // if (type === 'Challenge') {
      //   result.push(
      //     <div> 
      //       <span style={timeStyle}> { getLocalTime(item.time) } </span> { type }: { activity.description } ({ activity.score }/{ activity.total } answered correctly on first try)
      //     </div>
      //   );
      // }

      if (type === 'Tutorial') {
        result.push(
          <div> 
            { type }: 
            <a href={ 'courseware/' + activity.description + '/tutorial'} > { activity.description } </a> 
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }

      if (type === 'File') {
        result.push(
          <div> 
            {type }: { activity.description }: { activity.fileName } 
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }

      if (type === 'Account') {
        result.push(
          <div> 
            {type}: { activity.description } 
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }

      if (type === 'Feedback') {
        result.push(
          <div> 
            {type}: { activity.description } 
            <span style={timeStyle}> { getLocalTime(item.time) } </span> 
          </div>
        );
      }
    });

    return result;
  }

  processLessons() {
    if (!this.lessons)
      return;

    this.lessons.forEach(lesson => {
      this.lessonsIdMap[lesson.id] = lesson;
    });
  }

  processProjects() {
    if (!this.projects)
      return;

    this.projects.forEach(project => {
      this.projectsNameMap[project.name] = project;
    });
  }

  processNuggets() {
    if (!this.nuggets)
      return;

    this.nuggets.forEach(nugget => {
      this.nuggetsIdMap[nugget.funcName] = nugget;
    });
  }

  getLessonEntries() {
    var result = [];
    for (var key in this.passedLessonMapOfUser) {
      // handle the special case where name has been changed since the user logged activity
      if (!this.lessonsIdMap[key])
        continue;

      result.push(
        <span style={{"display":"inline-block"}}> 
          <a href={ '/lesson?id=' + key } >{this.lessonsIdMap[key].name};</a>
          <span style={spaceStyle}></span>
        </span>
      );
    }
    return result;
  }

  getProjectEntries() {
    // <Button style={lessonButtonStyle} onClick={this.onRunIt}> { this.savedProjectMapOfUser[key].activity.title } </Button>
    var result = [];

    if (!this.publicUsername) {
      for (var key in this.savedProjectMapOfUser) {
        result.push(
          <span style={{"display":"inline-block"}}> 
            <a href={ '/project?id=' + key } >{this.savedProjectMapOfUser[key].activity.title};</a>
            <span style={spaceStyle}></span>
          </span>
        );
      }
      return result;
    }

    console.log('h1');
    console.dir(this.savedProjectMapOfUser);
    
    for (var key in this.savedProjectMapOfUser) {
      result.push(
        <div style={{"text-align": "left"}}>
          <img src='/img/saved.svg' style={projectImageStyle}></img>
          <span style={projectTitleStyle}> { this.savedProjectMapOfUser[key].activity.title } </span>
          { this.getDemo(this.savedProjectMapOfUser[key].activity.title) }
          <br/>
        </div>
      );
    }
    return result;
  }

  getProjectInfo() {
    if (!this.state.processProjectsDone)
      return (<div></div>);

    var length = Object.keys(this.savedProjectMapOfUser).length;

    if (length === 0)
      return (
        <div>
          <h4>No saved projects.</h4>
          <hr style={hrStyle}></hr>
        </div>
      );

    var result = [];

    if (!this.publicUsername) {
      result.push(
        <div>
          <span style={bigPointsStyle}>{ 'Saved Projects  ' }</span> 
          <span style={pointsLabelStyle}>{ length } { length === 1 ? 'project' : 'projects ' }</span>
        </div>
      );
    }

    result.push(<div style={ resultContainer }> { this.getProjectEntries() } </div>);

    result.push(<hr style={hrStyle}></hr>);
    return result;
  }

  getLessonInfo() {
    if (!this.state.processLessonsDone)
      return (<div></div>);

    var length = Object.keys(this.passedLessonMapOfUser).length;

    if (length === 0)
      return (<div></div>);

    var result = [];

    result.push(
      <div>
        <span style={bigPointsStyle}>{ 'Completed Lessons  ' }</span> 
        <span style={pointsLabelStyle}>{ length } { length === 1 ? 'lesson' : 'lessons' }</span>
      </div>
    );

    result.push(<div style={ resultContainer }> { this.getLessonEntries() } </div>);

    result.push(<hr style={hrStyle}></hr>);
    return result;
  }

  getNuggetEntries() {
    var result = [];
    for (var key in this.passedNuggetMapOfUser) {
      result.push(
        <span style={{"display":"inline-block"}}> 
        {
          this.nuggetsIdMap[key] ?
          <a href={ '/run?id=' + this.nuggetsIdMap[key].id } >{this.nuggetsIdMap[key].funcName};</a>
          :
          key
        }
          <span style={spaceStyle}></span>
        </span>
      );
    }

    return result;
  }

  getNuggetInfo() {
    if (!this.state.processNuggetsDone)
      return <div></div>;

    var length = Object.keys(this.passedNuggetMapOfUser).length;

    if (length === 0) 
      return (<div></div>);

    var result = [];

    result.push(
      <div>
        <span style={bigPointsStyle}> { 'Completed Nuggets  ' }</span>
        <span style={pointsLabelStyle}> { length } { length === 1 ? 'nnugget' : 'nuggets' } </span> 
      </div>
    );

    result.push(<div style={ resultContainer }> { this.getNuggetEntries() } </div>);

    result.push(<hr style={hrStyle}></hr>);
    return result;
  }

  getTutorialEntries() {
    var result = [];
    for (var key in this.tutorialMapOfUser) {
      result.push(
        <span style={{"display":"inline-block"}}> 
          <a href={ 'courseware/' + key + '/tutorial'} >{ key };</a> 
          <span style={spaceStyle}></span> 
        </span>
      );
    }

    return result;
  }

  getTutorialInfo() {
    var length = Object.keys(this.tutorialMapOfUser).length;

    if (length === 0) 
      return (<div></div>);

    var result = [];

    result.push(
      <div>
        <span style={bigPointsStyle}> { 'Completed Tutorials  ' } </span>
        <span style={pointsLabelStyle}> { length } { length === 1 ? 'tutorial' : 'tutorials' } </span>
      </div>
    );

    result.push(<div style={ resultContainer }> { this.getTutorialEntries() } </div>);

    result.push(<hr style={hrStyle}></hr>);
    return result;
  }

  getFileInfo() {
    var length = Object.keys(this.fileMap).length;

    if (length === 0) 
      return (<div></div>);

    var result = [];
    result.push(
      <div>
        <span style={bigPointsStyle}> { 'Saved Projects  ' } </span>
        <span style={pointsLabelStyle}> { length } { (length === 1) ? 'project' : 'projects' }</span>
      </div>
    );

    for (var key in this.fileMap) {
      result.push(<span style={itemStyle}>{ key }</span>);
      result.push(<span style={spaceStyle}></span>);
    }
    
    result.push(<hr style={hrStyle}></hr>);
    return result;
  }

  /*
   * getLeaderBoard()
   */
  getLeaderBoard() {
    if (!this.state.processAllActivitiesDone) {
      return (
        <div>Constructing leaderboard...</div>
      );
    }

    const MAX_LEADERS_COUNT = 150;

    var result = [];
    this.usernamePointsArray.slice(0, MAX_LEADERS_COUNT).forEach(entry => {
      if (entry[0] && entry[0] !== 'unknown') {
        result.push(
          <tr>
            <td style={leaderNameStyle}>
              <a href={ '/profile?user=' + entry[0] } target='_blank'>{ entry[0] }</a>
            </td>
            <td style={leaderPointStyle}>
              { entry[1] } 
            </td>
          </tr>
        );
      }
    });

    return result;
  }

  // process current user activities
  processUserActivities(activities) {
    for (var i = 0; i < activities.length; i++) {
      var item = activities[i];
      if (!item)
        continue;

      var username = item.username;
      var type = item.type;
      var activity = item.activity;
      var id = item.id;

      switch (type) {
        case 'Lesson':
          if (!this.passedLessonMapOfUser[id]) {
            this.passedLessonMapOfUser[id] = new Set();
          }
          this.passedLessonMapOfUser[id].add(activity.groupId);
          break;

        case 'Project':
          if (activity.description === 'Saved') {
            this.savedProjectMapOfUser[id] = item;
          } 
          break;

        case 'Feedback':
          if (activity.description === 'Sent') {
            if (!this.feedbackMapOfUser[id]) {
              this.feedbackMapOfUser[id] = new Set();
            }
            this.feedbackMapOfUser[id].add(activity.location);
          } 
          break;

        case 'Nugget':
          if (activity.correctCount === activity.totalCount) {
            if (id) {
              this.passedNuggetMapOfUser[activity.name] = true;
            }
          }
          break;

        case 'Quiz':
          if (activity.score === activity.total) {
            if (id) {
              this.passedQuizMapOfUser[id] = true;
            }
          }
          break;

        case 'Challenge':
          if (activity.score === activity.total) {
            if (id) {
              this.passedChallengeMapOfUser[id] = true;
            }
          }
        break;

        case 'Snippet':
          if (id) {
            this.snippetMapOfUser[id] = true;
          }
          break;

        case 'Tutorial': 
          if (id) {
            this.tutorialMapOfUser[id] = true;
          }
        break;

        case 'File':
        break;

        case 'Account':
        break;

        default:
        break;
      }
    }
  }

  getDemo(title) {
    if (!this.state.fileLoadedMap[title])
      return (<div></div>);

    return(
      <div style={editorStyle}>
          <Editor store={this.props.store} code={
            this.savedFileMap[title]
          } run="true" style={{"height": "600px"}} />
      </div>
    );
  }

  render() {
    if (!this.state || !this.state.processUsersDone) {
      return <div style={loadingStyle}><img src="/img/hourglass.svg" style={hourglassTyle}></img> <span> Loading your profile... </span> </div>
    }

    if (this.props.isAdmin === 'true') {
      if (isAdmin(this.user)) {
        return <AdminPage store={this.props.store} username={this.username} />;
      }

      return (
        <div><h2>You need admin permission to access this page.</h2></div>
      );
    }

    if (isParent(this.user) && !this.props.showLearningProfile) {
      return (<ParentProfilePage store={this.props.store} />);
    }

    if (!this.state.processUserActivitiesDone) {
      return <div style={loadingStyle}><img src="/img/hourglass.svg" style={hourglassTyle}></img> <span> Loading your profile... </span> </div>
    }

    if (!this.username) {
      return (<div> <h2>Please sign in to view your profile.</h2> </div>);
    }

    if (this.publicUsername) {
      return (
        <div style={baseContainerStyle}>
          <table style={tableStyle}>
            <tbody>
              <tr style={trStyle}>
                <td style={tdStyle}>
                  <div style={containerStyle}>
                  <table style={innerTableStyle}>
                  <tbody>
                  <tr>
                  <td width="540px">
                    <div style={ greetingStyle }> 
                      <img width='100px' height='100px' src='/img/demo.svg'></img> 

                      <span style={demoStyle}> 
                        { 'Project Demo' } 
                        <span style={publicNameStyle}>
                          { this.usernameMap[this.publicUsername].firstName + ' ' + 
                            this.usernameMap[this.publicUsername].lastName }
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div style={publicAchievementsStyle}>
                      { this.getPoints() }
                    </div>
                  </td>
                  </tr>
                  </tbody>
                  </table>
                    <hr style={hrStyle}></hr>
                    <div style={{"text-align": "center"}}>
                      { this.getProjectInfo() }
                    </div>

                  </div>

                </td>

              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    var latestActivities = this.props.store.getState().auth.activities;
    if (latestActivities.length !== this.userActivities.length) {
      this.setState({
        userActivities: latestActivities,
      });
      this.processUserActivities(latestActivities);
    }

    return (
      <div style={baseContainerStyle}>
        <table style={tableStyle}>
          <tbody>
            <tr style={trStyle}>
              <td style={tdStyle}>
                <div style={containerStyle}>
                  <div style={ greetingStyle }> 
                    <img width='100px' height='100px' src='/img/profile.svg'></img> 
                    <span style={nameBlock}>
                      <span style={nameStyle}>
                        <span> 
                          { 
                            this.firstName + ' ' + this.lastName 
                          } 
                        </span>
                        {
                          this.props.showChildLearningProfile ?
                          <div> 
                            <a style={hrefStyle} href={ '/profile?user=' + this.username} target='_blank'>View Public Profile</a>
                          </div> 
                          :
                          <div> 
                            <a style={hrefStyle} href={ '/update-settings' } >Update Settings</a>
                            &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;
                            <a style={hrefStyle} href={ '/profile?user=' + this.username} target='_blank'>View Public Profile</a>
                          </div> 
                        }
                      </span>
                    </span>
                  </div>
                  <hr style={hrStyle}></hr>
                  { this.getProjectInfo() }
                  { this.getLessonInfo() }
                  { this.getNuggetInfo() }
                  { this.getTutorialInfo() }
                  { this.getFileInfo() }
                  <h3> Recent Activities </h3>
                  { this.getActivityItems() }
                </div>

              </td>

              <td style={tdStyle}>
                <div style={infoContainerStyle}>
                  <div style={achievementsStyle}>
                    <h4>Achievements</h4>

                    { this.getPoints() }
                    
                  </div>

                  <div style={leaderBoardStyle}>
                  <table>
                  <tbody>
                  <tr>
                    <td>
                      <img src="/img/trophy.svg" width="30px" height="30px"></img>
                    </td>
                    <td>
                      <h4 style={leaderboardTitleStyle}>Leaderboard</h4>
                    </td>
                  </tr>
                  </tbody>
                  </table>

                  <table style={leaderBoardTableStyle}>
                    <tbody>
                      { this.getLeaderBoard() }
                    </tbody>
                  </table>

                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    );
  }
}

export default ProfilePage;