/*
 * constant-config.js
 *
 * Configuration for global constants
 */

module.exports = {
  DEFAULT_NUGGET_LANGUAGE: 'python',
  MAX_PROFILE_ACTIVITY_COUNT: 500,
  MAX_ACTIVITY_COUNT: 5000,
  DEFAULT_COURSE_ORDER: 999,
};
