/* 
 * Snippet.js
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import Button from '../../components/Button';
import { joinJsonObjects, getDateTime, isTeacher, isAdmin } from '../../util/util';
import CreateSnippetPage from '../../components/CreateSnippetPage';
import { CircularProgressbar } from 'react-circular-progressbar';
import { detectProgrammingLanguage } from '../../util/util';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const controlBarStyle = {
  "color": "white",
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "marginBottom": "30px",
  // "borderTopLeftRadius": "10px",
  // "borderTopRightRadius": "10px",
};

const controlBarTableStyle = {
  "width": "100%",
};

const bugIconStyle = {
  "width": "30px",
  "height": "30px",
  "float": "left",
  "marginLeft": "20px",
};

const tdIconStyle = {
  "width": "70px",
  "paddingRight": "20px",
};

const progressContainerStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "width": "100px",
  "height": "100px",
  "borderRadius": "50%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "-20px",
  "marginBottom": "-20px",
  "paddingTop": "10px",
};

const progressStyle = {
  "width": "80px",
  "height": "80px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "2px solid white",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "98px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "backgroundColor": "hsla(0,0%,100%,.15)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "width": "500px",
  "textAlign": "center",
};

const rightTdStyle = {
  "width": "500px",
  "textAlign": "right",
};

const containerStyle = {
  "marginTop": "35px",
  "marginBottom": "35px",
  "backgroundColor": "#efefef",
  "maxWidth": "1240px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const tableStyle = {
  "marginLeft": "auto",
  "marginRight": "auto",
};

const smallFontStyle = {
  "fontSize": "75%",
};

const errorStyle = {
  "color": "red",
};

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginTop": "4px",
  "marginBottom": "2px",
  "color": "white",
};

const labelStyle2 = {
  "display": "none",
};

const editorStyle = {
  "height": "250px",
  "width": "610px",
  "marginBottom": "5px",
};

const previewContainerStyle = {
  "width": "610px",
  "verticalAlign": "top",
  "backgroundColor": "white",
  "padding": "20px",
};

const tdMiddleStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "minWidth": "20px",
};

const tdMiddleResultStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "minWidth": "10px",
};

const editorContainerStyle = {
  "verticalAlign": "top",
};

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "minWidth": "200px",
};

const commandsStyle = {
  "textAlign": "right",
};

const runTestsStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "padding": "10px",
  "fontSize": "small",
};

const outputStyle = {
  "height": "250px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const outputStyle2 = {
  "width": "610px",
  "height": "600px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const resultStyle = {
  "height": "250px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const resultStyle2 = {
  "display": "none",
};

const correctMessageStyle = {
  "color" : "green",
  "fontWeight" : "600",
};

const incorrectMessageStyle = {
  "color": "red",
  "fontWeight" : "600",
};

const resultTableStyle = {
  "borderCollapse": "collapse",
};

const resultTDStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
};

const resultRedStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "red",
  "fontWeight": "600",
};

const resultGreenStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "green",
  "fontWeight": "600",
};

const buttonReportBugStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "60px",
    "marginRight": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    "width": "570px",
    "marginLeft": "-20px",
    "marginRight": "-20px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-20px",
  "marginRight": "-20px",
};

const innerContainer = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "padding": "20px",
};

const snippetImageStyle = {
  "width": "50px",
  "height": "50px",
};

const tryImageStyle = {
  "width": "50px",
  "height": "50px",
};

const insightFontStyle = {
  "fontSize": "70%",
  "marginRight": "10px",
};

const insightContainer = {
  "backgroundColor": "rgba(53, 61, 69, 1)",
  "marginLeft": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
};

class Snippet extends Component {
  getProgressBar() {
    return (
      <div style={ controlBarStyle }>
        <table style={ controlBarTableStyle }>
          <tbody>
            <tr>
              <td style={leftTdStyle}>
              { this.getInsights() }
              </td>
              <td>
                { this.getProgress() }
              </td>
              <td style={rightTdStyle}>
                { this.getContinueButton() }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // We use reat-circular-progressbar to display progress:
  // https://github.com/iqnivek/react-circular-progressbar
  // https://www.npmjs.com/package/react-circular-progressbar
  // color configuration can be controled with the CSS file in src/public/ace.css
  getProgress() {
    var lessonState = this.props.store.getState().auth.lessonState;
    var current = lessonState.currentSkillIndex;
    if (this.state && this.state.passed) {
      // if the current snippet is passed, we report progress right away
      current += 1;
    }

    var count = 0;
    if (lessonState.skillList) {
      count = lessonState.skillList.length;
    }

    var percentage = 0;
    if (count > 0) {
      percentage = current/count;
    }

    percentage = percentage*100;
    percentage = percentage.toFixed(0);

    return (
      <div style={progressContainerStyle}>
        <div style={progressStyle}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={12}/>
        </div>
      </div>
    );
  }

  getRunButton() {
    if (this.snippetCanSkip)
      return null;

    return(
      <span>
        <Button style={buttonStyle} onClick={this.onRun}>
          { 'Run' }
        </Button>
        {
          this.state && this.state.passed ?
          <Button style={buttonStyle} onClick={this.onSubmit}>
            { 'Next' }
          </Button>
          :
          <div></div>
        }
      </span>
    );
  }

  getContinueButton() {
    return (
      <span>
        { this.getEditButton() }
        { this.getDeleteButton() }
        { this.getSkipButton() }
        { this.getReportBug() }
        { this.getRunButton() }
      </span>
    );
  }

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onRun = this.onRun.bind(this);
    this.onHint = this.onHint.bind(this);
    this.onShowInsight = this.onShowInsight.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.iframeContent = this.iframeContent.bind(this);

    this.user = this.props.store.getState().auth.user;
    this.username = this.props.store.getState().auth.user.username;
  }

  initializeState() {
    this.setState({
      previewMessage: "",
      error: "",
      testResults: [],
      resultTableHeader: "",
      loaded: false,
      doEdit: false,
      passed: false,
    });
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    this.id = this.props.id;
    this.logs = this.props.store.getState().auth.lessonState.logs;
    this.canSkip = this.props.canSkip ? this.props.canSkip : false;
    this.insight = '';

    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    var minCount = 60;
    editor.setOptions({
      fontSize: "8pt",
      minLines: minCount
    });
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      doHint: false,
      doInsight: false,
      content: '',
    });

    this.initializeState();

    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;
      if (!this.preference) {
        this.preference = {};
      }

      var settings = this.preference.editorSettings;
      if (settings) {
        editor.setTheme("ace/theme/" + settings.theme);

        editor.getSession().setMode("ace/mode/" + settings.language);

        if (settings.showLineNumbers) {
          editor.renderer.setShowGutter(true);
        } else {
          editor.renderer.setShowGutter(false);
        }

        if (settings.fontSize === 'small') {
          editor.setOptions({
            fontSize: "8pt",
          });
        }

        if (settings.fontSize === 'medium') {
          editor.setOptions({
            fontSize: "12pt",
          });
        }

        if (settings.fontSize === 'large') {
          editor.setOptions({
            fontSize: "16pt",
          });
        }

        if (settings.tabSize === '2') {
          editor.setOptions({
            tabSize: 2,
          });
        }

        if (settings.tabSize === '4') {
          editor.setOptions({
            tabSize: 4,
          });
        }

        if (settings.showInvisibles) {
          editor.setOption("showInvisibles", true);
        } else {
          editor.setOption("showInvisibles", false);
        }

        if (settings.keyBinding === '') {
          editor.setKeyboardHandler('');
        }

        if (settings.keyBinding === 'vim') {
          editor.setKeyboardHandler('ace/keyboard/vim');
        }

        if (settings.keyBinding === 'emacs') {
          editor.setKeyboardHandler('ace/keyboard/emacs');
        }
      }
      else {
        // no settings
        this.resetOriginal();
      }
    });

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 
      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }

    this.audioSuccess = new Audio('/sound/c2.mp3');
    this.audioFail = new Audio('/sound/pop.wav');

    request({
      url: '/api/get_snippet',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error to get snippet!');
        return;
      }

      var snippet = res.snippet;

      this.code = snippet.code;
      this.name = snippet.name;
      this.title = snippet.title;
      this.instructions = snippet.instructions;
      this.task = snippet.task;
      this.input = snippet.input;
      this.inputRegEx = snippet.inputRegEx;
      this.output = snippet.output;
      this.outputRegEx = snippet.outputRegEx;
      this.hint = snippet.hint;
      this.tags = snippet.tags;
      this.combineOutput = snippet.combineOutput;
      this.author = snippet.username;
      this.snippetCanSkip = snippet.canSkip;

      this.state.editor.setValue(this.code, -1);

      // var i = this.state.editor.session.getLength() - 1;
      // var currentLine = this.state.editor.session.getLine(i);

      var Range = ace.require("ace/range").Range;
      var range = new Range(1, 4, 1, 4);
      this.state.editor.selection.setSelectionRange(range, false);
      this.state.editor.focus();

      this.startTime = new Date().getTime(); // milliseconds
      this.duration = 0;
      this.attemptCount = 0;
      this.setState({
        loaded: true,
      });
    }); 

    this.setState({
      processActivitiesDone: false,
    });

    this.averageDuration = 0;
    this.userSet = new Set();
    this.submissions = 0;
    this.failureCount = 0;
    this.errorCount = 0;
    this.errorCode = '';
    this.failureCode = '';

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('ERROR: failed to get snippet activities');
        return;
      }

      this.activities = res.activities;

      this.processActivities();

      this.setState({
        processActivitiesDone: true,
      });
    });
  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processActivities() {
    var totalDuration = 0;

    this.activities.forEach(item => {
      var activity = item.activity;

      this.submissions ++;
      totalDuration += activity.duration;
      this.userSet.add(item.username);
      if (activity.passed === false) {
        this.failureCount ++;

        if (activity.error === "") {
          this.failureCode += '[' + item.username + '] ';
          this.failureCode += activity.code + '<br/><br/>';
        }
      }
      if (activity.error !== "") {
        this.errorCount ++;
        this.errorCode += '[' + item.username + '] ';
        this.errorCode += activity.error + '<br/>';
        this.errorCode += activity.code + '<br/><br/>';
      }
    });

    if (this.submissions > 0) 
      this.averageDuration = totalDuration / this.submissions / 1000;

    this.insight = '<h3>Snippet ' + this.name  + '</h3>' +
      'ID: <a href="snippet?id=' + this.id + '" target="_blank">' + this.id + '</a><br/>' +
      'Tags: ' + this.tags + '<br/><br/>' + 
      'Users: ' + this.userSet.size + '<br/>' +
      'Runs: ' + this.submissions + '<br/>' +
      'Failures: ' + this.failureCount  + ' (' + (this.failureCount*100/this.submissions).toFixed(0) + '%)' + '<br/>' +
      'Errors: ' + this.errorCount + '<br/>' +
      'Average duration: ' + this.averageDuration.toFixed(0) + ' secs' + '<br/>' +
      '<h3>Error Cases</h3>' + 
      this.errorCode + '<br/>' + 
      '<h3>Failure Cases</h3>' + 
      this.failureCode; 
  }

  getInsights() {
    if (!this.canShowContentInsight())
      return (<div></div>);

    return (
      <div style={insightContainer}>
      { this.userSet.size } <span style={insightFontStyle}>users</span>
      { this.submissions } <span style={insightFontStyle}>checks</span>
      { this.failureCount  + ' (' + (this.failureCount*100/this.submissions).toFixed(0) + '%)'} <span style={insightFontStyle}>failures</span>
      { this.errorCount } <span style={insightFontStyle}>errors</span>
      { this.averageDuration.toFixed(0) } <span style={insightFontStyle}>secs</span>
      </div>
    );
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  setPythonCode() {
    var prog = this.state.editor.getValue(); 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";

    var content = this.iframeContent(prog, mypre);
    
    this.setState({
      content: content,
    });
  }

  clearOutput() {
    var prog = ''; 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);

    this.setState({
      content: content,
    });

    iframeDoc.write(content);
    iframeDoc.close();
  }

  onRun() {
    if (!this.username)
      return;

    this.setState({ error: "", testResults: [], resultTableHeader: "" });

    this.code = this.state.editor.getValue();
    this.language = detectProgrammingLanguage(this.code);

    var content = '';
    if (this.language === 'python') {
      this.setPythonCode();
    } else {
      content = '<div style="color: grey">Running...</div>';
      this.setState({
        content: content,
      });

      var mypre = this.state.resultArea;
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
    }

    var error = "";
    this.duration = new Date().getTime() - this.startTime;

    // TODO: consider to use server side time for logging to be more consistent
    this.submissionTime = getDateTime();

    request({
      url: '/api/run_snippet',
      method: 'POST',
      payload: {
        id: this.id,
        username: this.username,
        name: this.name,
        title: this.title,
        code: this.code,
        instructions: this.instructions,
        task: this.task,
        hint: this.hint,
        input: this.input,
        inputRegEx: this.inputRegEx,
        output: this.output,
        outputRegEx: this.outputRegEx,
        tags: this.tags,
      }
    }, (err, res) => {
      if (err) {
        error = "Error: Oops! Something went wrong when running tests.";
        alert(error);
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_FORBIDDEN_JAVA_WORDS') {
        error = 'Error: "' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'MISSING_INPUT') {
        error = "Did you forget to include <b>" + res.item + "</b> in your code?";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({
          error: error,
          passed: false,
        });

        content = '';
        if (this.combineOutput) {

          if (this.language === 'python') {
            content = this.state.content + '<div style="color: red">' + this.state.error + '</div>';
          } else {
            content = '<div style="color: red"><pre>' + this.state.error + '</pre></div>';
          }
          this.setState({
            content: content,
          });

          var mypre = this.state.resultArea;
          mypre.style.display = "block";
          var iframeDoc = mypre.contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(content);
          iframeDoc.close();
        }

        if (this.language === 'java') {
          content = '<div style="color: red"><pre>' + error + '</pre></div>';
          this.setState({
            content: content,
          });

          var mypre = this.state.resultArea;
          var iframeDoc = mypre.contentWindow.document;
          iframeDoc.open();
          iframeDoc.write(content);
          iframeDoc.close();
        }

        this.attemptCount ++;

        const userActivity = {
          type: 'Snippet',
          username: this.username,
          id: this.id,
          activity: {
            name: this.name,
            title: this.title,
            passed: this.state.passed,
            error: error,
            attemptCount: this.attemptCount,
            submissionTime: res.submissionTime,
            duration: this.duration,
            code: this.code,
          },
        };

        this.logs.push(userActivity);
        return;
      }

      // No errors
      this.attemptCount ++;

      if (this.language === 'java') {
        content = '<div><pre>' + res.output + '</pre></div>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
      }

      // var testResults = [ ["flip(True) → False", "False", "OK"], ["flip(False) → True", "True", "X"] ];
      this.setState({ testResults: res.results, resultTableHeader: "<tr><th>Expected</th><th>Snippet</th><th></th></tr>" });

      if (this.state.testResults) {
        this.state.testResults.forEach(entry => {
          if (entry[2] === "OK") {
            this.setState({
              passed: true,
            });

            this.props.onPass(this.props.store.getState().auth.lessonState);

          }
          else {
            this.setState({
              passed: false,
            });

            this.props.onFail(this.props.store.getState().auth.lessonState);
          }
        });

        var message = '';

        if (this.combineOutput) {
          if (this.state && this.state.testResults && this.state.testResults.length > 0) {
            message += '<div style={runTestsStyle}>';
            if (this.state.passed) {
              message += '<div style="color: green">';
              message += '<b>';
              message += '<div > Hooray! </div>';
              message += '<br />';
              message += '<div> You passed with ' + this.attemptCount;
              message += (this.attemptCount === 1) ? ' submission.</div>' : ' submissions.</div>';
              message += '</b>';
              message += '</div>';
            } else {
              message += '<div><div style="color: red"><b> Output doesn\'t match. </b></div><br /><div>Keep trying!</div></div>';
            }
            message += '<div style="font-size: 75%">' + 'Time: ' + (this.duration / 1000 / 60).toFixed(2);
            message += ' minutes.</div><br />';
            message += this.getResultsAsString();
            message += '</tbody></table></div>';
          }
        }
        this.setState({
          content: this.state.content + message,
        });
      }

      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      
      iframeDoc.write(this.state.content);
      iframeDoc.close();

      if (this.state.passed) {
        this.audioSuccess.play();
      } else {
        this.audioFail.play();
      }

      const userActivity = {
        type: 'Snippet',
        username: this.username,
        id: this.id,
        activity: {
          name: this.name,
          title: this.title,
          passed: this.state.passed,
          error: '',
          attemptCount: this.attemptCount,
          submissionTime: res.submissionTime,
          duration: this.duration,
          code: this.code,
        },
      };

      this.logs.push(userActivity);
    });
  }

  /*
   * submit snippet
   */
  onSubmit() {
    if (!this.username)
      return;

    if (this.state.passed) {
      // when the snippet is passed, the button is in the "Next" mode
      this.initializeState();
      this.clearOutput();

      // advance the currentSkillIndex to the next one
      var lessonState = this.props.store.getState().auth.lessonState;
      var newLessonState = {
        lessonId: lessonState.lessonId,
        lessonName: lessonState.lessonName,
        currentSkillIndex: lessonState.currentSkillIndex + 1,
        currentSkillPassed: false,
        skillList: lessonState.skillList,
        groupId: lessonState.groupId,
        logs: this.logs,
      };
      this.props.onContinue(newLessonState);

      return;
    }

    this.onRun();
  }

  onEdit() {
    this.setState({ doEdit: true });
  }

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  onShowInsight() {
    this.setState({
      doInsight: !this.state.doInsight, 
    });
  }

  onDelete() {
    var answer = confirm('This will delete snippet ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_snippet',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('error to delete snippet');
            return;
          }

        alert('snippet ' + this.id + ' is deleted');
      });
    } else {
      alert('ok, no change is made');
    }
  }

  onSkip() {
    this.initializeState();
    this.clearOutput();

    // advance the currentSkillIndex to the next one
    var lessonState = this.props.store.getState().auth.lessonState;
    var newLessonState = {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex + 1,
      currentSkillPassed: false,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: lessonState.logs,
    };
    this.props.onContinue(newLessonState);
  }

  canShowContentInsight() {
    if (!isAdmin(this.user))
      return false;

    if (!this.state)
      return false;

    if (!this.state.processActivitiesDone)
      return false;

    return true;
  }

  getEditButton() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        return null;
      }
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onEdit}>
      Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        return null;
      }
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
      Delete
      </Button>
    );
  }

  showSkipButton() {
    if (isTeacher(this.user))
      return true;

    if (this.username === this.author)
      return true;

    if (this.canSkip)
      return true;

    if (this.snippetCanSkip)
      return true;

    return false;
  }

  getSkipButton() {
    if (!this.showSkipButton()) 
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSkip}>
      Skip
      </Button>
    );
  }

  getReportBug() {
    var location = 'snippet?id=' + this.id;

    return(
      <a href={ 'contact?location=' + location } target='_blank'>
        <Button style={buttonReportBugStyle}>
          Send Feedback
        </Button>
      </a>
    );
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    // For Turtle code, don't need to print Expected / Actual since we don't run on server
    if (this.code.indexOf('Turtle') !== -1)
      return;

    var results = [];
    results.push(<tr><th>Expected </th><th>Actual </th><th></th></tr>);
    this.state.testResults.forEach(entry => {
      var colorStyle = entry[2]==="OK" ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  getResultsAsString() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    // For Turtle code, don't need to print Expected / Actual since we don't run on server
    if (this.code.indexOf('Turtle') !== -1)
      return '';

    var results = '';
    results += '<div><table style="border-collapse: collapse"><tbody><tr><th>Expected </th><th>Actual </th><th></th></tr>';
    this.state.testResults.forEach(entry => {
      var colorStyle = entry[2]==="OK" ? "green" : "red";
      results += '<tr><td style="border: 2px solid black; padding: 4px">' + entry[0] + '</td>' +
        '<td style="border: 2px solid black; padding: 4px">' + entry[1] + '</td>' +
        '<td style="border: 2px solid black; padding: 4px; color: ' + colorStyle + '">' + entry[2] + '</td></tr>';
    });

    return results;
  }

  getTitle() {
    return (
      <div>
      <table>
        <tbody>
          <tr>
            <td style={tdIconStyle}>
              <img src='/img/snippet.svg' style={snippetImageStyle}></img>
            </td>
            <td>
              <h4> { this.title } </h4>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    );
  }

  getDescription() {
    var previewMessage = '';

    if (!this.instructions)
      return '';

    // previewMessage += "<h4>Name</h4>";
    // previewMessage += this.name;
    // previewMessage += "<div style='font-size: 75%'>author: " + (this.author == "admin" ? "jason" : this.author) + "; " + this.tags + "</div>";
    // previewMessage += "<hr>";
    previewMessage += "<hr>";
    previewMessage += this.instructions;

    // We don't need to display expected output for snippet. In the future, we may want to optionally enable this.
    // if (this.output) {
    //   previewMessage += "<hr>";
    //   previewMessage += "<h4>Expected Output</h4>";
    //   previewMessage += this.output;
    // }

    return previewMessage;
  }

  getTask() {
    if (!this.task)
      return (<div></div>);

    return (
      <div>
        <hr></hr>
        <table>
          <tbody>
            <tr>
              <td style={tdIconStyle}>
                <img src='/img/try.svg' style={tryImageStyle}></img>
              </td>
              <td>
                <h4> Try it Yourself </h4>
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        <div dangerouslySetInnerHTML={{ __html: this.task }}></div>

      </div>
    );
  }

  getHint()
  {
    if (!this.hint)
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  getContentInsight()
  {
    if (!this.canShowContentInsight())
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onShowInsight}>
          <h5> { 'Get Insight [Teacher Only]' } <span style={arrowStyle}>{ this.state.doInsight? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doInsight ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.insight }}></div> : <div></div>
        }

      </div>
    );
  }

  render() {
    // when the id is passed in, it means we want to edit the nugget
    // to preserve the original author, we pass in this.author as the username
    if (this.state && this.state.doEdit) {
      return (
        <div style={containerStyle}>
          <CreateSnippetPage
            id={ this.id } 
            username={ this.author }
            name={ this.name }
            title={ this.title }
            code={ this.code }
            instructions = { this.instructions }
            task = { this.task }
            hint={ this.hint }
            input  ={ this.input }
            inputRegEx  ={ this.inputRegEx }
            output ={ this.output }
            outputRegEx ={ this.outputRegEx }
            tags={ this.tags }
            combineOutput={ this.combineOutput }
            canSkip={ this.snippetCanSkip }
            store={ this.props.store }
          />
        </div>
      );
    }

    if (this.id !== this.props.id) {
      // the lesson state has switched to another snippet
      // fetch the snippet again
      this.id = this.props.id;

      request({
        url: '/api/get_snippet',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error to get snippet!');
          return;
        }

        var snippet = res.snippet;

        this.code = snippet.code;
        this.name = snippet.name;
        this.title = snippet.title;
        this.instructions = snippet.instructions;
        this.task = snippet.task;
        this.input = snippet.input;
        this.inputRegEx = snippet.inputRegEx;
        this.output = snippet.output;
        this.outputRegEx = snippet.outputRegEx;
        this.hint = snippet.hint;
        this.tags = snippet.tags;
        this.combineOutput = snippet.combineOutput;
        this.author = snippet.username;
        this.snippetCanSkip = snippet.canSkip;

        this.state.editor.setValue(this.code, -1);

        // var i = this.state.editor.session.getLength() - 1;
        // var currentLine = this.state.editor.session.getLine(i);

        var Range = ace.require("ace/range").Range;
        var range = new Range(1, 4, 1, 4);
        this.state.editor.selection.setSelectionRange(range, false);
        this.state.editor.focus();

        this.startTime = new Date().getTime(); // milliseconds
        this.duration = 0;
        this.attemptCount = 0;
        this.setState({
          loaded: true,
        });
      }); 

      this.averageDuration = 0;
      this.userSet = new Set();
      this.submissions = 0;
      this.failureCount = 0;
      this.errorCount = 0;
      this.errorCode = '';
      this.failureCode = '';

      request({
        url: '/api/all_activities_by_id',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          alert('ERROR: failed to get snippet activities');
          return;
        }

        this.activities = res.activities;

        this.processActivities();

        this.setState({
          processActivitiesDone: true,
        });
      });
    }

    var outStyle = this.combineOutput ? outputStyle2 : outputStyle;
    var finalResultStyle = this.combineOutput ? resultStyle2 : resultStyle;
    var finalLabelStyle = this.combineOutput ? labelStyle2: labelStyle;

    return (
      <div style={containerStyle}>

        { this.getProgressBar() }

        <div style={innerContainer}>
        <table style={tableStyle}>
        <tbody>
        <tr height="100%">
          <td style={previewContainerStyle}>
            <div style={previewStyle}>
              {
                this.state && this.state.loaded ?
                <div>
                  { this.getTitle() }
                  <div dangerouslySetInnerHTML={{ __html: this.getDescription() }}></div>
                  { this.getTask() }
                  { this.getHint() }
                  { this.getContentInsight() }
                </div>
                :
                <div></div>
              }
            </div>

          </td>

          <td style={tdMiddleStyle}>
          </td>

          <td style={editorContainerStyle}>
            <div ref="root" style={editorStyle}>
              {this.props.code}
            </div>

            <div>
              <td width="300px">
                <div style={labelStyle}>
                  Output
                </div>
                <iframe ref="result" id="iframeResult" style={joinJsonObjects(outStyle, this.props.style)}>
                </iframe>
              </td>

              <td style={tdMiddleResultStyle}>
              </td>

              <td width="300px">
                <div style={finalLabelStyle}>
                  Result
                </div>
                <div style={finalResultStyle}>
                  {
                    this.state && this.state.error && this.language === 'python' ? 
                      <div style={errorStyle} dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                      :
                      <div></div>
                  }

                  {
                    this.state && this.state.testResults && this.state.testResults.length > 0 ?
                      <div style={runTestsStyle}>
                        {
                          this.state.passed ?
                            <div>
                            <div style={ correctMessageStyle }> Hooray! </div>
                            <br />
                            <div style={ correctMessageStyle }> You passed with { this.attemptCount } { this.attemptCount === 1? 'submission' : 'submissions' }.</div>
                            </div>
                            :
                            <div>
                            <div style={ incorrectMessageStyle }> Output doesn't match.</div>
                            <br />
                            <div>Keep trying!</div>
                            </div>
                        }
                        <div style={smallFontStyle}>Time: { (this.duration / 1000 / 60).toFixed(2) } minutes.</div>
                        <br />
                        <div>
                          <table style={resultTableStyle}>
                          <tbody>
                            { this.getResults() }
                          </tbody>
                          </table>
                        </div>
                      </div>
                      :
                      <div></div>
                  }
                </div>
              </td>

            </div>

          </td>
        </tr>
        </tbody>
        </table>
        </div>
      </div>

    );
  }
}

export default Snippet;
