/*
 * LearnPage.js
 * Implements the learning page.
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import Link from '../Link';
import Button from '../Button';
import { isTeacher, isUserSignedIn, canSkipLock, getQueryValueFromURL } from '../../util/util';
import { DEFAULT_COURSE_ORDER } from '../../config/constant-config';

const DEFAULT_COURSE_ID = 'jgbm9n7z'; // 'Introduction to Programming with Python: Level 1'

const courseTitleStyle = {
  "fontSize": "23px",
  "fontWeight": "300",
  "lineHeight": "38px",
  "paddingBottom": "20px",
  "textAlign": "center",
};

const baseContainerStyle = {
  "fontFamily": "museo-sans-rounded,sans-serif",
  "textAlign": "center",
};

const tableStyle = {
  "verticalAlign": "top",
  "width": "1000px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const lessonTableStyle = {
  "textAlign": "center",
  "width": "100%",
};

const lessonTdStyle = {
  "padding": "20px",
};

const lessonCardStyle = {
  "borderRadius": "10px",
  "textAlign": "center",
  "minWidth": "150px",
};

const lessonTrStyle = {
  "display": "inline-block",
};

const trStyle = {
  "verticalAlign": "top",
};

const tdStyle = {
  "verticalAlign": "top",
};

const leftTdStyle = {
  "width": "20%",
  "textAlign": "left",
};

const middleTdStyle = {
  "width": "80%",
  "textAlign": "center",
  "verticalAlign": "top",
};

const rightTdStyle = {
  "width": "20%",
  "textAlign": "right",
};

const learningStatsStyle = {
  "backgroundColor": "rgba(255, 255, 255, 1)",
  "margin": "35px 35px",
  "padding": "20px 20px",
  "borderRadius": "10px",
};

const learningContainerStyle = {
  "backgroundColor": "rgba(255, 255, 255, 1)",
  "margin": "35px 35px",
  "padding": "20px 20px",
  "borderRadius": "10px",
};

const selectorStyle = {
  // "borderRadius": "6px",
  "lineHeight": "1.5",
  "padding": "5px 10px",
  "width": "100%",
  "height": "35px",
  "fontSize": "14px",
  "outline": "0",
};

const tileStyle = {
  "textAlign": "center",
};

const projectImageStyle = {
  "textAlign": "center",
  "width": "35px",
  "height": "35px",
  "marginBottom": "10px",
};

const titleImageStyle = {
  "textAlign": "center",
  "width": "35px",
  "height": "35px",
};

const titleTestImageStyle = {
  "textAlign": "center",
  "width": "35px",
  "height": "35px",
  "marginBottom": "10px",
};

const lockedLessonButtonStyle = {
  "marginTop": "5px",
  "padding": "10px",
  "display": "inline-block",
  "borderRadius": "2em",
  "fontWeight": "500",
  "color": "Black",
  "backgroundColor": "rgba(192, 192, 192, 1)",
  "width": "160px",
  "textAlign": "center",
  "border": "none",
};

const lessonButtonStyle = {
  base: {
    "padding": "10px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "500",
    "fontSize": "110%",
    "color": "white",
    "backgroundColor": "rgba(28, 175, 246, 1)",
    "width": "160px",
    "textAlign": "center",
    "border": "none",

    ':hover': {
      "backgroundColor": "rgba(62, 187, 247, 1)",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0"
    }
  }
};

const viewMoreStyle = {
  base: {
    "padding": "4px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "100",
    "letterSpacing": "0.03125em",
    "fontSize": "90%",
    "lineHeight": "2.3",
    "color": "white",
    "backgroundColor": "rgba(28, 175, 246, 1)",
    "width": "120px",
    "textAlign": "center",
    "border": "none",

    ':hover': {
      "backgroundColor": "rgba(62, 187, 247, 1)",
      "color": "white",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0"
    }
  }
};

const loadingStyle = {
  "fontSize": "22px",
  "margin": "30px 60px",
  "textAlign": "center",
};

const hourglassTyle = {
  "width": "80px",
  "paddingRight": "20px",
};

class LearnPage extends Component {
  constructor(props) {
    super(props);

    this.user = this.props.store.getState().auth.user;
    this.isUserSignedIn = isUserSignedIn(this.user);

    this.username = this.user.username;
    this.firstName = this.user? this.user.firstName : this.username;

    this.onCourseChange = this.onCourseChange.bind(this);
  }

  componentDidMount() {
    this.courses = {};
    this.passedLessonMap = {};
    this.savedProjectMap = {};
    this.preference = null;

    // TODO: consider to use id instead of name for lesson and project maps
    this.lessonsNameMap = {};
    this.projectsNameMap = {};

    this.allActivities = [];

    this.setState({
      processActivitiesDone: false,
      processLessonsDone: false,
      processProjectsDone: false,
      processCoursesDone: false,
      processPreferenceDone: false,
    });

    // Note: need to initialize courseSelected to false first before setting courseId.
    this.courseSelected = false;

    this.setState({
      courseId: this.getCurrentCourseId(),
    });

    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;

      this.setState({
        courseId: this.getCurrentCourseId(),
      });

      this.courseSelected = true;

      this.setState({
        processPreferenceDone: true,
      });
    });

    // get courses
    request({
      url: '/api/get_all_courses',
      method: 'GET',
      payload: {
        user: this.user,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error when recommending skills.');
        return;
      }

      this.returnedCourses = res.courses;
      this.processCourses();
    });

    // get activities
    if (this.isUserSignedIn) {

      // check client side cache first
      this.allActivities = this.props.store.getState().auth.activities;

      if (this.allActivities.length !== 0) {
        console.log('Learn Page Cache hit');
        // cache hit
        this.processActivities();
        this.setState({
          processActivitiesDone: true,
        });
      } else {
        // cache miss; fetch from server
        request({
          url: '/api/all_activities_by_user',
          method: 'GET',
          payload: {
            username: this.username,
            random: Math.random(),
          }
        }, (err, res) => {
          if (err) {
            console.log('ERROR: failed to get activities for user: ' + this.username);
            return;
          }

          console.log('Learn Page Cache miss.');

          this.allActivities = res.activities ? res.activities : [];

          // update cache
          this.props.onAddActivities(this.allActivities);

          this.processActivities();

          this.setState({
            processActivitiesDone: true,
          });
        });
      }

    } else {
      // user not signed in
      this.setState({
        processActivitiesDone: true,
      });
    }

    // get lessons
    request({
      url: '/api/get_all_lessons',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get all lessons');
        return;
      }

      this.lessons = res.lessons;
      this.processLessons();
      this.setState({
        processLessonsDone: true,
      });
    });

    // get all projects
    request({
      url: '/api/get_all_projects',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get all projects');
        return;
      }

      this.projects = res.projects;
      this.processProjects();
      this.setState({
        processProjectsDone: true,
      });
    });
  }

  processCourses() {
    this.returnedCourses.forEach(course => {
      if (!course.hide) {
        this.courses[course.id] = course;
      }
    });

    this.setState({
      processCoursesDone: true,
    });
  }

  processLessons() {
    if (!this.lessons)
      return;

    this.lessons.forEach(lesson => {
      this.lessonsNameMap[lesson.name] = lesson;
    });
  }

  processProjects() {
    if (!this.projects)
      return;

    this.projects.forEach(project => {
      this.projectsNameMap[project.name] = project;
    });
  }

  // getLessonId(name) {
  //   if (!this.state.processLessonsDone)
  //     return '';

  //   var lesson = this.lessonsNameMap[name];

  //   if (!lesson) {
  //     // this means the lesson doesn't exist in the database yet
  //     return '';
  //   }

  //   return lesson.id;
  // }

  // getProjectId(name) {
  //   if (!this.state.processProjectsDone)
  //     return '';

  //   var project = this.projectsNameMap[name];

  //   if (!project) {
  //     // this means the project doesn't exist in the database yet
  //     return '';
  //   }

  //   return project.id;
  // }

  // process all activities
  processActivities() {
    var allActivities = this.allActivities;

    for (var i = 0; i < allActivities.length; i++) {
      var item = allActivities[i];
      if (!item)
        continue;

      var username = item.username;
      var type = item.type;
      var activity = item.activity;
      var id = item.id;

      switch (type) {
        case 'Lesson':
          if (!this.passedLessonMap[id]) {
            this.passedLessonMap[id] = new Set();
          }
          this.passedLessonMap[id].add(activity.groupId);
          break;

        case 'Nugget':
          break;

        case 'Quiz':
          break;

        case 'Challenge':
          break;

        case 'Snippet':
          break;

        case 'Project':
          if (id) {
            if (item.activity.description === 'Saved') {
              this.savedProjectMap[id] = true;
            }
          }
          break;

        case 'Tutorial': 
          break;

        case 'File':
          break;

        case 'Account':
          break;

        default:
          break;
      }
    }
  }

  getRow(entry) {
    var result = [];

    entry.forEach(item => {
      var newLink = '';

      switch(item.type) {
        case 'Note':
          newLink = '/note?id=' + item.id;

          var noteImg = '/img/note.svg';
          result.push(
            <td style={lessonTdStyle}>
              <div style={lessonCardStyle}>
                <div>
                  <img src={noteImg} style={projectImageStyle}></img>
                </div>
                <Link to={ newLink }>
                  <Button style={lessonButtonStyle}> { item.name } </Button>
                </Link>
              </div>
            </td>
          );
          break;

        case 'Project':
          // id = this.getProjectId(item.name);
          newLink = '/project?id=' + item.id;

          var projectImg = '/img/project.svg';
          if (this.savedProjectMap[item.id]) {
            projectImg = '/img/saved.svg';
          }
          result.push(
            <td style={lessonTdStyle}>
              <div style={lessonCardStyle}>
                <div>
                  <img src={projectImg} style={projectImageStyle}></img>
                </div>
                <Link to={ newLink }>
                  <Button style={lessonButtonStyle}> { item.name } </Button>
                </Link>
              </div>
            </td>
          );
          break;

        case 'Test':
        case 'Lesson':
          var passedCount = 0;
          var skills = [];
          var totalCount = 0;

          // id =  this.getLessonId(item.name);
          newLink = '/lesson?id=' + item.id;
          
          var passedLessonMap = this.passedLessonMap;
          if (passedLessonMap && passedLessonMap[item.id]) {
            passedCount = passedLessonMap[item.id].size;
          }

          if (!this.lessonsNameMap[item.name] && !this.projectsNameMap[item.name])
            return result;
          
          skills = this.lessonsNameMap[item.name] ? this.lessonsNameMap[item.name].skills : [];
          totalCount = JSON.parse(skills).groups.length;

          var image = '';
          if (passedCount === 0) {
            if (item.type === 'Test') {
              image = '/img/test1.svg';
            } else {
              image = '/img/battery1.svg';
            }
          }
          else if (passedCount >= totalCount) {
            if (item.type === 'Test') {
              image = '/img/test2.svg';
            } else {
              image = '/img/battery3.svg';
            }
            this.current = this.count;
          } else {
            if (item.type === 'Test') {
              image = '/img/test1.svg';
            } else {
              image = '/img/battery2.svg';
            }
          }

          if (this.isLocked(item.lock === 'true' ? true : false, item.type)) {
            image = '/img/lock-1.svg';
            result.push(
              <td style={lessonTdStyle}>
                <div style={lessonCardStyle}>
                <div>
                  <img src={ image } style={titleImageStyle}></img>
                </div>
                  <div style={lockedLessonButtonStyle}> { item.name } </div>
                </div>
              </td>
            );
          }
          else {
            result.push(
              <td style={lessonTdStyle}>
                <div style={lessonCardStyle}>
                  <div>
                  <img src={ image } 
                    style={ item.type === 'Test' ? titleTestImageStyle : titleImageStyle}></img>
                  </div>
                  <Link to={ newLink }>
                    <Button style={lessonButtonStyle}> { item.name } </Button>
                  </Link>
                </div>
              </td>
            );
          }
        break;

        default:
          console.log('Error: unexpected type: ' + item.type);
          break;
      }

      this.count ++;
    });

    return <tr style={lessonTrStyle}> { result } </tr>
  }

  isLocked(lockForStudent, type) {
    if (this.user.access && this.user.access.canSkipProgressLock == 'true')
      return false;

    if (isTeacher(this.user))
      return false;

    if (canSkipLock(this.user))
      return false;

    if (type === 'Test') {
      return lockForStudent;
    }

    if (this.count - this.current > 3 || lockForStudent) {
      return true;
    }

    return false;
  }

  getSkillsTiles(course) {
    if (!course || !course.course)
      return [];

    var result = [];
    this.count = 0;
    this.current = 0;

    var courseObj = undefined;

    try {
      courseObj = JSON.parse(course.course);
    } catch (e) {
      console.log('Error: failed to parse course');
    }

    if (!courseObj || !courseObj.items) 
      return [];

    courseObj.items.forEach((entry) => {
      try {
        result.push(this.getRow(entry));
        result.push(<br/>);
      } catch (e) {
        console.log('Error: failed to getRow');
      }
    });

    return result;
  }

  /*
   * the learning launch pad
   */
  getSkills() {
    if (!this.state ||
        !this.courses ||
        !this.state.processCoursesDone ||
        !this.state.processLessonsDone ||
        !this.state.processProjectsDone ||
        !this.state.processActivitiesDone)
    {
      // return <div> { 'Loading skills for you, ' + this.firstName + '.' } </div>
      return <div> </div>
    }

    var course = this.courses[this.state.courseId];
    if (!course) {
      // this could happen with invalid course Id; in this case, we reset course to default
      this.setDefaultCourse(DEFAULT_COURSE_ID);
      course = this.courses[DEFAULT_COURSE_ID];
    }

    var title = '';
    if (this.courses[this.state.courseId]) {
      title = this.courses[this.state.courseId].title;
    }

    return (
      <div>
        <div style={courseTitleStyle}> { title } </div>

        <div style={tileStyle}>
          <table style={lessonTableStyle}>
            <tbody>
              { this.getSkillsTiles(course) }
            </tbody>
          </table>
        </div>

      </div>
    );
  }

  setDefaultCourse(courseId) {
    this.setState({
      courseId: courseId
    });

    if (this.preference) {
      this.preference.currentCourse = courseId;
    } else {
      this.preference = {};
      this.preference.currentCourse = courseId;
    }

    request({
      url: '/post_user_preference',
      method: 'POST',
      payload: {
        username: this.username,
        preference: this.preference,
        type: 'CourseChange',
      }
    }, (err, res) => {
      if (err) {
        console.log("Oops! Something went wrong when saving user preference.");
        return;
      }
    });
  }

  onCourseChange(e) {
    this.setDefaultCourse(e.target.value);
  }

  getCurrentCourseId() {
    var id = getQueryValueFromURL('learn?id=');

    if (id && !this.courseSelected) {
      return id;
    }

    var currentCourseId = DEFAULT_COURSE_ID;

    if (this.preference && this.preference.currentCourse) {
      currentCourseId = this.preference.currentCourse;
    }

    return currentCourseId;
  }

  selected(id) {
    if (this.state.courseId === id) {
      return "selected";
    }

    return "";
  }

  getSelector() {
    if (!this.state.processCoursesDone || !this.courses || !this.state.processPreferenceDone)
      return (<div></div>);

    var result = [];
    var keys = Object.keys(this.courses);

    keys.sort((x, y) => {
      var xOrder = this.courses[x].order ? this.courses[x].order : DEFAULT_COURSE_ORDER;
      var yOrder = this.courses[y].order ? this.courses[y].order : DEFAULT_COURSE_ORDER;
      return xOrder - yOrder;
    });

    keys.forEach(key => {
      if (this.courses[key]) {
        result.push(<option value={ key } selected={ this.selected(key) }>{ this.courses[key].title }</option>);
      }
    });

    return (
      <select style={selectorStyle} id="course" name="course" onChange={this.onCourseChange} >
        { result }
      </select>
    );
  }

  getGreeting() {
    if (!this.isUserSignedIn) {
      return (
        <div>
          <h3> Personalized CS Wonders Courseware </h3>
        </div>
      );
    }

    return (
      <div>
        <h3> Hello { this.firstName }! </h3>
      </div>
    );
  }

  render() {
    if (!this.state) {
      return (
        <div style={loadingStyle}>
          <img src="/img/hourglass.svg" style={hourglassTyle}></img>
          <span>Recommending your learning path...</span>
        </div>
      );
    }

    return (
      <div style={baseContainerStyle}>
        <table style={tableStyle}>
          <tbody>
            <tr style={trStyle}>
              <td style={tdStyle}>

                <div style={learningStatsStyle}>
                  <table>
                  <tbody>
                  <tr>
                    <td style={leftTdStyle}>
                      <img src="/img/path.svg" width="60px" height="60px"></img>
                    </td>
                    <td style={middleTdStyle}>
                      { this.getGreeting() }
                    </td>
                    <td style={rightTdStyle}>
                      {
                        this.isUserSignedIn ? 
                        // Note: should use <Link to> instead of <a href> to propagate state
                        <Link to='/profile'>
                          <Button style={viewMoreStyle}> My Profile </Button>
                        </Link>
                        :
                        <div style={{"width": "120px"}}></div>
                      }
                    </td>
                  </tr>
                  </tbody>
                  </table>

                  <hr></hr>
                  <h4 style={{"textAlign": "left"}}>Select Personalized Learning Path</h4>
                  { this.getSelector() }
                </div>

                <div style={learningContainerStyle}>
                  { 
                    this.getSkills()
                  }
                </div>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default LearnPage;