/*
 * CreateProjectPage.js
 * 
 * Example: <CreateProjectPage />
 *
 */
import React, { Component } from 'react';
import Button from '../Button';
import { joinJsonObjects, isAdmin } from '../../util/util';
import { request } from '../../util/api';
import { detectProgrammingLanguage } from '../../util/util';
import PropTypes from 'prop-types';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  language: 'python',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const containerStyle = {
};

const descriptionStyle = {
  "backgroundColor": "white",
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "marginTop": "20px",
};

const descriptionTableStyle = {
  "width": "100%",
};

const infoStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "fontSize": "80%",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "width": "620px",
  "lineHeight": "8px",
};

const projectTitleStyle = {
  "paddingLeft": "7px",
  "marginBottom": "-8px",
};

const descButtonStyle = {
  "textAlign": "left",
};

const instructionsStyle = {
  "marginTop": "-10px",
  "paddingBottom": "10px",
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    // "width": "110%",
    // "marginLeft": "-20px",
    "marginRight": "-20px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-20px",
  "marginRight": "-20px",
};

const instructionButtonStyle = {
  base: {
    "backgroundColor": "white",
    "verticalAlign": "top",
    "border": "none",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "rgba(134, 149, 153, 1)",

    ':hover': {
      "color": "rgba(0, 0, 0, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const tdIconStyle = {
  "width": "70px",
};

const tableStyle = {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "10px",
};

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginBottom": "8px",
};

const editorStyle = {
  "height": "250px",
  "width": "620px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
};

const outputStyle = {
  "height": "150px",
  "width": "100%",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const smallFontStyle = {
  "fontSize": "75%",
};

const errorStyle = {
  "color": "red",
};

const correctMessageStyle = {
  "color" : "green",
  "fontWeight" : "600",
};

const incorrectMessageStyle = {
  "color": "red",
  "fontWeight" : "600",
};

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "width": "620px",
};

const runTestsStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "padding": "10px",
  "fontSize": "small",
};

const resultStyle = {
  "height": "150px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "none",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
  // "display": "inline-grid",
};

const resultTableStyle = {
  "borderCollapse": "collapse",
};

const resultTDStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
};

const resultRedStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "red",
  "fontWeight": "600",
};

const resultGreenStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "green",
  "fontWeight": "600",
};

const buttonStyle = {
  base: {
    "marginTop": "-5px",
    "marginBottom": "5px",
    "backgroundColor": "#4CAF50",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "20px",
    "width": "90px",
    'fontFamily': 'Segoe UI Symbol',

    ':hover': {
      "color": "#4CAF50",
      "backgroundColor": "rgba(132, 256, 130, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "marginTop": "-5px",
    "marginBottom": "5px",
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "20px",
    "width": "90px",
    "marginLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonViewAllStyle = {
  base: {
    "marginTop": "-5px",
    "marginBottom": "5px",
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "20px",
    "width": "120px",
    "marginLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const tdLeftStyle = {
  "verticalAlign": "top",
  "width": "600px",
};
 
const rightContainer = {
  "paddingLeft": "20px",
};

const tdResultStyle = {
  "height": "250px",
  // "width": "300px",
};

const tdOutputStyle = {
  // "height": "600px",
  // "display": "inline-block",
};

const rightTdStyle = {
  "verticalAlign": "top",
  "width": "600px",
  "paddingLeft": "20px",
};

class CreateProjectPage extends Component {
  constructor(props) {
    super(props);

    this.onRun = this.onRun.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onOutputChange = this.onOutputChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInstructionChange = this.onInstructionChange.bind(this);
    this.onHintChange = this.onHintChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.onCanSkip = this.onCanSkip.bind(this);
    this.onBackendOnly = this.onBackendOnly.bind(this);
    this.iframeContent = this.iframeContent.bind(this);
    this.onShowInstructions = this.onShowInstructions.bind(this);
    this.onHint = this.onHint.bind(this);
    this.onShareWithChange = this.onShareWithChange.bind(this);

    this.id = this.props.id ? this.props.id : '';
    this.username = this.props.username ? this.props.username : '';
    this.code = this.props.code ? this.props.code : "";
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    var minCount = 80;
    editor.setOptions({
      fontSize: "8pt",
      minLines: minCount
    });
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      previewMessage: "",
      error: "",
      testResults: [],
      resultTableHeader: "",
      doInstructions: true,
      doHint: true,
    });

    this.setState({
      name: this.props.name ? this.props.name: "TBD",
      title: this.props.title ? this.props.title: "Instructions",
      instructions: this.props.instructions ? this.props.instructions : 'You can use HTML to format instructions.\nHit the "Preview" button to review.',
      hint: this.props.hint ? this.props.hint : "",
      input: this.props.input ? this.props.input: '',
      output: this.props.output ? this.props.output: '',
      tags: this.props.tags ? this.props.tags : 'topic: TBD; level: TBD; concept: TBD',
      canSkip: this.props.canSkip,
      backendOnly: this.props.backendOnly,
      shareWith: this.props.shareWith ? this.props.shareWith : '',
      solution: this.props.solution ? this.props.solution : '',
    });

    var currentUserName = this.props.store.getState().auth.user.username;

    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: currentUserName,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;
      if (!this.preference) {
        this.preference = {};
      }

      var settings = this.preference.editorSettings;

      if (settings) {
        editor.setTheme("ace/theme/" + settings.theme);

        editor.getSession().setMode("ace/mode/" + settings.language);

        if (settings.showLineNumbers) {
          editor.renderer.setShowGutter(true);
        } else {
          editor.renderer.setShowGutter(false);
        }

        if (settings.fontSize === 'small') {
          editor.setOptions({
            fontSize: "8pt",
          });
        }

        if (settings.fontSize === 'medium') {
          editor.setOptions({
            fontSize: "12pt",
          });
        }

        if (settings.fontSize === 'large') {
          editor.setOptions({
            fontSize: "16pt",
          });
        }

        if (settings.tabSize === '2') {
          editor.setOptions({
            tabSize: 2,
          });
        }

        if (settings.tabSize === '4') {
          editor.setOptions({
            tabSize: 4,
          });
        }

        if (settings.showInvisibles) {
          editor.setOption("showInvisibles", true);
        } else {
          editor.setOption("showInvisibles", false);
        }

        if (settings.keyBinding === '') {
          editor.setKeyboardHandler('');
        }

        if (settings.keyBinding === 'vim') {
          editor.setKeyboardHandler('ace/keyboard/vim');
        }

        if (settings.keyBinding === 'emacs') {
          editor.setKeyboardHandler('ace/keyboard/emacs');
        }
      }
      else {
        // no settings
        this.resetOriginal();
      }
    });

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 

      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }

    this.startTime = new Date().getTime(); // milliseconds
    this.duration = 0;
    this.attemptCount = 1;

    if (!this.id)
      return;

    this.setState({
      processActivitiesDone: false,
    });

    this.userSet = new Set();
    this.saves = 0;
    this.runs = 0;
    this.errors = 0;
    this.errorMessage = '';

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('ERROR: failed to get project activities');
        return;
      }

      this.activities = res.activities;
      this.processActivities();

      this.setState({
        processActivitiesDone: true,
      });
    });
  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  runCode() {
    var prog = this.state.editor.getValue(); 
    var mypre = this.state.resultArea;
    mypre.style.display = "block";
    var iframeDoc = mypre.contentWindow.document;

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();
  }

  onRun() {
    if (!this.username)
      return;

    var code = this.state.editor.getValue();
    this.language = detectProgrammingLanguage(code);

    var content = '';
    if (this.language === 'python') {
      this.runCode();
    } else {
      content = '<div style="color: grey">Running...</div>';
      this.setState({
        content: content,
      });

      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
    }

    this.setState({ error: "", testResults: [], resultTableHeader: "" });
    var error = "";

    this.duration = new Date().getTime() - this.startTime;
    
    request({
      url: '/api/run_project',
      method: 'POST',
      payload: {
        id: this.id,
        username: this.username,
        name: this.state.name,
        title: this.state.title,
        code: code,
        instructions: this.state.instructions,
        hint: this.state.hint,
        input: this.state.input,
        output: this.state.output,
        tags: this.state.tags,
        backendOnly: this.state.backendOnly,
      }
    }, (err, res) => {
      if (err) {
        error = "Error: Oops! Something went wrong when running tests.";
        alert(error);
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_JAVA_WORDS') {
        error = 'Error: "' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'NO_TESTS') {
        error = "No input/output requirements. Please add some requirements.";
      }

      if (res.status === 'MISSING_INPUT') {
        error = "Did you forget to include <b>" + res.item + "</b> in your code?";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({ error: error });
        this.attemptCount ++;

        var content = '';
        if (this.language === 'python') {
          content = this.state.content + '<div style="color: red">' + this.state.error + '</div>';
        } else {
          content = '<div style="color: red"><pre>' + this.state.error + '</pre></div>';
        }

        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();

        return;
      }

      // no errors
      if (this.language === 'java') {
        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();

        iframeDoc.write('<div><pre>' + res.output + '</pre></div>');
        iframeDoc.close();
      }

      if (this.state.backendOnly && res && res.results && res.results.length > 0) {
        var content = '<pre>' + res.results[0] + '</pre>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();

        iframeDoc.write(content);
        iframeDoc.close();
      } else {
        // var testResults = [ ["flip(True) → False", "False", "OK"], ["flip(False) → True", "True", "X"] ];
        this.setState({ testResults: res.results, resultTableHeader: "<tr><th>Expected</th><th>Run</th><th></th></tr>" });

        if (this.state.testResults) {
          // log the submission activity
          this.state.testResults.forEach(entry => {
            if (entry[2] === "OK") {
              this.setState({
                passed: true,
              });
            }
            else {
              this.setState({
                passed: false,
              });

              this.attemptCount ++;
            }
          });
        }
      }

    });
  }

  /*
   * save the project
   */
  onSave() {
    if (!this.username)
      return;

    if (!this.state.name) {
      alert('Please add the name of the project.');
      return;
    }

    // if (!this.state.tags || !this.state.tags.includes('topic') || !this.state.tags.includes('level')) {
    //   alert('Please add tags for "topic" and "level" (easy, medium, or hard).');
    //   return;
    // }

    var code = this.state.editor.getValue();

    request({
      url: '/api/save_project',
      method: 'POST',
      payload: {
        username: this.username,
        name: this.state.name,
        title: this.state.title,
        code : code,
        instructions: this.state.instructions,
        hint: this.state.hint,
        input: this.state.input,
        output: this.state.output,
        tags: this.state.tags,
        canSkip: this.state.canSkip,
        backendOnly: this.state.backendOnly,
        shareWith: this.state.shareWith,
        id: this.id,
        solution: this.solution,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your code.");
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        alert('Error: "import ' + res.module + '" is not currently supported by the online editor.');
      }

      if (res.status === 'CONTAINS_EVAL') {
        alert("Error: eval is not supported by the online editor.");
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }  

  /*
   * create the preview message 
   */
  onPreview() {
    var previewMessage = '';

    var code = this.state.editor.getValue();

    previewMessage += "<h4>" + this.state.title + "</h4>";
    previewMessage += "<hr>";
    previewMessage += this.state.instructions;
    previewMessage += "<br />";

    if (this.state.hint) {
      previewMessage += "<br /> <b> Hint: </b>" + this.state.hint;
      previewMessage += "<br />";
    }
    
    this.setState({ previewMessage: previewMessage });
  }

  onInputChange(e) {
    this.setState({
      input: e.target.value,
    });
  }

  onOutputChange(e) {
    this.setState({
      output: e.target.value,
    });
  }

  onInstructionChange(e) {
    this.setState({
      instructions: e.target.value,
    });
  }

  onHintChange(e) {
    this.setState({
      hint: e.target.value,
    });
  }

  onNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onTitleChange(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onTagChange(e) {
    this.setState({
      tags: e.target.value,
    });
  }

  onCanSkip(e) {
    this.setState({
      canSkip: !this.state.canSkip,
    });
  }

  onBackendOnly(e) {
    this.setState({
      backendOnly: !this.state.backendOnly,
    });
  }

  onShowInstructions() {
    this.setState({
      doInstructions: !this.state.doInstructions, 
    });
  }

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  onShareWithChange(e) {
    this.setState({
      shareWith: e.target.value,
    });
  }

  getRunTestsButton() {
    return (
      <Button style={buttonStyle} onClick={this.onRun}>
      ▶ Run
      </Button>
    );
  }

  showSaveButton() {
    if (!this.username)
      return false;

    return true;
  }

  getSaveButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSave}>
      Save
      </Button>
    );
  }

  getPreviewButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onPreview}>
      Preview
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='projects'>
        <Button style={buttonViewAllStyle} >
        View All Projects
        </Button>
      </a>
    );
  }

  getRunComponents() {
    return (
      <div>
        { this.getRunTestsButton() }
        { this.getPreviewButton() }
        { this.getSaveButton() }
        { this.getAllButton() }
      </div>
    );
  }

  displayDescription() {
    return (
      <div> { this.state.instructions } </div>
    );
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    var results = [];
    this.state.testResults.forEach(entry => {
      var isOK = entry[2]==="OK";
      var colorStyle = isOK ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  processActivities() {
    this.activities.forEach(item => {
      this.userSet.add(item.username);

      var activity = item.activity;
      if (activity.description === 'Saved')
        this.saves ++;

      if (activity.description === 'Run')
        this.runs ++;

      if (activity.error !== "") {
        this.errors ++;
        this.errorMessage += '[' + item.username + ']';
        this.errorMessage += activity.error + '\n';
      }
    });
  }

  getInsights() {
    if (!this.state || !this.state.processActivitiesDone || !isAdmin(this.user))
      return (<div></div>);

    return (
      <div style={insightContainer}>
        <div style={insightStyle}>
          { this.userSet.size } <span style={insightFontStyle}>users</span>
          { this.saves } <span style={insightFontStyle}>saves</span>
          { this.runs } <span style={insightFontStyle}>runs</span>
          { this.errors } <span style={insightFontStyle}>errors</span>
        </div>
      </div>
    );
  }

  getDescription() {
    return (
      <div style={descriptionStyle}> 
        <table style={descriptionTableStyle}>
          <tbody>
            <tr>
              <td style={tdIconStyle}>
              <img width="50px" height="50px" src='/img/project1.svg' ></img>
              </td>

              <td>
              <div style={projectTitleStyle}><h4> { "Project: " + this.state.title ? this.state.title : '' } </h4></div>

              <span style={descButtonStyle}>
                <Button style={instructionButtonStyle} onClick={this.onShowInstructions}>
                  <h5> 
                    { this.state && this.state.doInstructions ? 'Hide Instructions' : 'Show Instructions' } 
                    <span style={arrowStyle}>{ this.state && this.state.doInstructions ? '▲' : '▼' }</span>
                  </h5>
                </Button>
              </span>
              </td>
              <td>
                { this.getInsights() }
              </td>
            </tr>

          </tbody>
        </table>

        {
          (this.state && this.state.doInstructions) ? 
          <div>
            <div style={instructionsStyle}>
              <hr></hr>
              <div dangerouslySetInnerHTML={{ __html: this.state.instructions }}></div>
            </div>
            { this.getHint() }
          </div>
          :
          <div></div>
        }
      </div>
    );
  }

  getHint()
  {
    if (!this.state.hint)
      return <div></div>;

    return (
      <div>
        <hr></hr>

        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.state.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  getInfo() {
    if (!this.state) {
      return (<div></div>);
    }
    
    return (
      <div style = {infoStyle} >
        <p> Project Name: { this.state.name } </p>
        <p> Author: { this.username } </p>
        <p> Input Contains: { this.state.input } </p>
        <p> Expected Output: { this.state.output } </p>
        <p> Tags: { this.state.tags } </p>
        <p> Can Skip: { this.state.canSkip ? 'true' : 'false' } </p>
        <p> Backend Only: { this.state.backendOnly ? 'true' : 'false' } </p>
        <p> Shared With: { this.state.shareWith } </p>
      </div>
    );
  }

  getDefaultName() {
    if (this.state)
      return this.state.name;

    if (this.props.name)
      return this.props.name;

    return 'TBD';
  }

  getDefaultTitle() {
    if (this.state)
      return this.state.title;

    if (this.props.title)
      return this.props.title;

    return '';
  }

  getDefaultInstructions() {
    if (this.state)
      return this.state.instructions;

    if (this.props.instructions)
      return this.props.instructions;

    return '';
  }

  getDefaultHint() {
    if (this.state)
      return this.state.hint;

    if (this.props.hint)
      return this.props.hint;

    return '';
  }

  getDefaultOutput() {
    if (this.state)
      return this.state.output;

    if (this.props.output)
      return this.props.output;

    return '';
  }

  getDefaultInput() {
    if (this.state)
      return this.state.input;

    if (this.props.input)
      return this.props.input;

    return '';
  }

  getDefaultTags() {
    if (this.state)
      return this.state.tags;

    if (this.props.tags)
      return this.props.tags;

    return 'topic: TBD; level: TBD; concept: TBD';
  }

  getDefaultShareWith() {
    if (this.state)
      return this.state.shareWith;

    if (this.props.shareWith)
      return this.props.shareWith;

    return '';
  }

  render() {
    return (
<div style={containerStyle}>
<table style={tableStyle}>
<tbody>
<tr>
  <td style={tdLeftStyle}>
  <table>
  <tbody>

  <tr>
  <td style={columnStyle}> </td>
  <td>
    <div>
      { this.getRunComponents() }
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Code </td>
  <td>
    {
      <div>
        <div ref="root" style={editorStyle}>
          { this.props.code }
        </div>
      </div>
    }
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Name </td>
  <td>
    <div>
      <textarea id="name" rows="1" cols="85" name="name" onChange={this.onNameChange}
      defaultValue={ this.getDefaultName() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Title </td>
  <td>
    <div>
      <textarea id="title" rows="1" cols="85" name="title" onChange={this.onTitleChange}
      defaultValue={ this.getDefaultTitle() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Instructions </td>
  <td>
    <div>
      <textarea id="instructions" rows="10" cols="85" name="instructions" onChange={this.onInstructionChange}
      defaultValue={ this.getDefaultInstructions() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Hint </td>
  <td>
    <div>
      <textarea id="hint" rows="5" cols="85" name="hint" onChange={this.onHintChange}
      defaultValue={ this.getDefaultHint() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Expected Output </td>
  <td>
    <div>
      <textarea id="output" rows="2" cols="85" name="output" onChange={this.onOutputChange}
      defaultValue={ this.getDefaultOutput() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Input Contains</td>
  <td>
    <div>
      <textarea id="input" rows="2" cols="85" name="input" onChange={this.onInputChange}
      defaultValue={ this.getDefaultInput() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Tags </td>
  <td>
    <div>
      <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
      defaultValue={ this.getDefaultTags() }></textarea>
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Can Skip </td>
  <td>
    <div>
      {
        this.state && this.state.canSkip ? 
        <input type="checkbox" name="canSkip" checked onChange={this.onCanSkip}></input>
        :
        <input type="checkbox" name="canSkip" onChange={this.onCanSkip}></input>
      }
    </div>
  </td>
  </tr>

  <tr>
  <td style={columnStyle}> Backend Only </td>
  <td>
    <div>
      {
        this.state && this.state.backendOnly ? 
        <input type="checkbox" name="backendOnly" checked onChange={this.onBackendOnly}></input>
        :
        <input type="checkbox" name="backendOnly" onChange={this.onBackendOnly}></input>
      }
    </div>
  </td>
  </tr>

  <tr>
    <td style={columnStyle}> Share With </td>
    <td>
      <div>
        <textarea id="shareWith" rows="1" cols="85" name="shareWith" onChange={this.onShareWithChange}
        defaultValue={ this.getDefaultShareWith() }></textarea>
      </div>
    </td>
  </tr>
  </tbody>
  </table>
  </td>

  <td width="20px">
  </td>

  <td style={rightTdStyle}>
    <table style={rightContainer}>
    <tbody>
      <tr>
      <td style={tdResultStyle}>

        <div style={labelStyle}>
          Result
        </div>

        <div style={resultStyle}>
        {
          this.state && this.state.error && this.language === 'python' ? 
            <div style={errorStyle} dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
            :
            <div></div>
        }
        {
          this.state && this.state.testResults && this.state.testResults.length > 0 ?
            <div style={runTestsStyle}>
              {
                this.state.passed ?
                  <div>
                  <div style={ correctMessageStyle }> Hooray! </div>
                  <br />
                  <div style={ correctMessageStyle }> You passed with { this.attemptCount } { this.attemptCount === 1 ? 'submission' : 'submissions' }.</div>
                  </div>
                  :
                  <div>
                  <div style={ incorrectMessageStyle }> Output does not match.</div>
                  <br />
                  <div>Keep trying!</div>
                  </div>
              }
              <div style={smallFontStyle}>Time: { (this.duration / 1000 / 60).toFixed(2) } minutes.</div>
              <br />
              <div>
                <table style={resultTableStyle}>
                <tbody>
                  <tr><th>Expected </th><th>Actual </th><th></th></tr>
                  { this.getResults() }
                </tbody>
                </table>
              </div>
            </div>
            :
            <div></div>
        }
        </div>

      <div style={tdOutputStyle}>

        <div style={labelStyle}>
          Output
        </div>

        <iframe ref="result" id="iframeResult" style={outputStyle}>
        </iframe>

        <div style={labelStyle}>
          <br />
          <img src='/img/preview.svg' style={{'width':'40px', 'height':'40px', 'marginRight': '10px'}} ></img>
          Preview
        </div>

        { this.getInfo() }

        <div style={previewStyle}>
          {
            this.state ?
            this.getDescription() 
            :
            <div></div>
          }
        </div>
      </div>

      </td>
      </tr>

    </tbody>
    </table>
  </td>

</tr>
</tbody>
</table>

</div>

    );
  }
}

CreateProjectPage.propTypes = {
  code: PropTypes.string,
};

CreateProjectPage.defaultProps = {
  id: '',
  username: '',
  name: '',
  title: '',
  code: '',
  instructions: '',
  hint: '',
  input: '',
  output: '',
  tags: '',
  canSkip: false,
  backendOnly: false,
  shareWith: '',
  solution: '',
}; 

export default CreateProjectPage;
