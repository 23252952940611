/* 
 * RunPage.js
 * 
 * Run the Nugget when a nugget isselected from /nuggets.
 * 
 * TODO: can consolidate this with NuggetPage.js.
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import Button from '../../components/Button';
import { joinJsonObjects, getDateTime, isAdmin } from '../../util/util';
import CreateNuggetPage from '../../components/CreateNuggetPage';
import { DEFAULT_NUGGET_LANGUAGE } from '../../config/constant-config';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const outerContainerStyle = {
  "backgroundColor": "#efefef",
  "textAlign": "center",
};

const containerStyle = {
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "paddingTop": "5px",
  "paddingBottom": "10px",
  "backgroundColor": "#efefef",
  // "maxWidth": "1200px",
  // "marginLeft": "auto",
  // "marginRight": "auto",
}

const errorStyle = {
  "color": "red",
}

const smallFontStyle = {
  "fontSize": "75%",
}

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginBottom": "8px",
}

const editorStyle = {
  "height": "250px",
  "width": "610px",
  // "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
}

const previewContainerStyle = {
  "width": "610px",
  "verticalAlign": "top",
  "paddingRight": "20px",
  "paddingTop": "10px",
}

const editorContainerStyle = {
  "verticalAlign": "top",
}

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
  "minWidth": "200px",
  "minHeight": "565px",
}

const runTestsStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  // "backgroundColor": "white",
  "padding": "10px",
  "fontSize": "small",
  "paddingBottom": "20px",
}

const resultStyle = {
  "minHeight": "250px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "width": "100%",
  "border": "none",
  // "marginLeft": "-20px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const correctMessageStyle = {
  "color" : "green",
  "fontWeight" : "600",
}                        

const incorrectMessageStyle = {
  "fontWeight" : "600",
}                        

const resultTableStyle = {
  "borderCollapse": "collapse",
}

const resultTDStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
}

const resultRedStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "red",
  "fontWeight": "600",
}

const resultGreenStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "green",
  "fontWeight": "600",
}

const buttonStyle = {
  base: {
    "backgroundColor": "#4CAF50",
    "marginTop": "10px",
    "marginBottom": "5px",
    // "marginLeft": "10px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "90px",
    'fontFamily': 'Segoe UI Symbol',

    ':hover': {
      "color": "#4CAF50",
      "backgroundColor": "rgba(132, 256, 130, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonReportBugStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "10px",
    "marginBottom": "5px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "10px",
    "marginBottom": "5px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "90px",
    "marginLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const outputStyle = {
  "minHeight": "250px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "border": "1px solid rgba(204, 204, 204, 1)",
};

const tdMiddleStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "minWidth": "20px",
};

const tdMiddleResultStyle = {
  "backgroundColor": "#efefef",
  "minWidth": "10px",
};

const outputContainerStyle = {
  "width": "300px",
  "verticalAlign": "top",
};

const messageStyle = {
  "textAlign": "center",
  "fontSize": "150%",
  "backgroundColor": "wheat",
  "width": "600px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "30px",
  "marginBottom": "30px",
};

const infoIconStyle = {
  "width": "80px",
  "height": "80px",
  "paddingRight": "20px",
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    "width": "570px",
    "marginLeft": "-10px",
    "marginRight": "-10px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-10px",
  "marginRight": "-10px",
};

class RunPage extends Component {
  constructor(props) {
    super(props);

    this.onRunIt = this.onRunIt.bind(this);
    this.onEditIt = this.onEditIt.bind(this);
    this.onDeleteIt = this.onDeleteIt.bind(this);
    this.iframeContent = this.iframeContent.bind(this);
    this.onHint = this.onHint.bind(this);

    this.user = null;
    this.inSubmission = false;

    if (this.props.store) {
      this.user = this.props.store.getState().auth.user;
      this.username = this.user.username ? this.user.username : 'unknown';
    } else {
      this.username = 'unknown';
    }
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    this.id = this.getIdFromURL();

    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    var minCount = 80;
    editor.setOptions({minLines: minCount});
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      previewMessage: "",
      error: "",
      testResults: [],
      resultTableHeader: "",
      loaded: false,
      doEdit: false,
      correctCount: 0,
      totalCount: 0,
      doHint: false,
    });

    request({
      url: '/get_user_preference',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error: failed to get user preference for username ' + this.username);
        return;
      }

      this.preference = res.preference;
      if (!this.preference) {
        this.preference = {};
      }

      var settings = this.preference.editorSettings;
      if (settings) {
        editor.setTheme("ace/theme/" + settings.theme);

        if (settings.showLineNumbers) {
          editor.renderer.setShowGutter(true);
        } else {
          editor.renderer.setShowGutter(false);
        }

        if (settings.fontSize === 'small') {
          editor.setOptions({
            fontSize: "8pt",
          });
        }

        if (settings.fontSize === 'medium') {
          editor.setOptions({
            fontSize: "12pt",
          });
        }

        if (settings.fontSize === 'large') {
          editor.setOptions({
            fontSize: "16pt",
          });
        }

        if (settings.tabSize === '2') {
          editor.setOptions({
            tabSize: 2,
          });
        }

        if (settings.tabSize === '4') {
          editor.setOptions({
            tabSize: 4,
          });
        }

        if (settings.showInvisibles) {
          editor.setOption("showInvisibles", true);
        } else {
          editor.setOption("showInvisibles", false);
        }

        if (settings.keyBinding === '') {
          editor.setKeyboardHandler('');
        }

        if (settings.keyBinding === 'vim') {
          editor.setKeyboardHandler('ace/keyboard/vim');
        }

        if (settings.keyBinding === 'emacs') {
          editor.setKeyboardHandler('ace/keyboard/emacs');
        }
      }
      else {
        // no settings
        this.resetOriginal();
      }
    });

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 

      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }

    request({
      url: '/api/get_nugget',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error to get nugget!');
        return;
      }

      var nugget = res.nugget;

      this.tests = nugget.tests;
      this.code = nugget.code;
      this.description = nugget.description;
      this.hint = nugget.hint;
      this.tags = nugget.tags;
      this.funcName = nugget.funcName;
      this.author = nugget.username;
      this.language = nugget.language;
      if (!this.language) {
        this.language = DEFAULT_NUGGET_LANGUAGE;
      }

      const editor = ace.edit(this.refs.root);

      editor.getSession().setMode("ace/mode/" + this.language);

      var initialCode = this.extractFunctionLine(this.code);

      // append the indentation from the next line as well
      if (this.language === 'java') {
        initialCode += '\n    \n';
      } else {
        initialCode += '\n    \n\n';
      }

      // add the first test case
      var testHarness = this.extractFirstTest();
      if (testHarness) {
        initialCode += testHarness;
      }

      this.state.editor.setValue(initialCode, -1);

      // var i = this.state.editor.session.getLength() - 1;
      // var currentLine = this.state.editor.session.getLine(i);

      var Range = ace.require("ace/range").Range;
      var range = new Range(1, 4, 1, 4);
      this.state.editor.selection.setSelectionRange(range, false);
      this.state.editor.focus();
      this.setState( { loaded: true });

      this.startTime = new Date().getTime(); // milliseconds
      this.duration = 0;
    }); 

  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  onRunIt() {
    if (this.inSubmission)
      return;

    this.inSubmission = true;

    var prog = this.state.editor.getValue(); 

    // var mypre = document.getElementById("iframeResult");
    var mypre = this.state.resultArea;

    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;
    // alert(iframeDoc);

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();

    this.setState({ error: "", testResults: [], resultTableHeader: "" });
    var error = "";

    var code = this.state.editor.getValue();
    this.extractFunctionName(code);

    this.duration = new Date().getTime() - this.startTime;

    // TODO: consider to use server side time for logging to be more consistent
    this.submissionTime = getDateTime();

    if (this.language === 'java') {
      content = '<div style="color: grey">Crunching result...</div>';
      this.setState({
        content: content,
      });

      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
    }
      
    request({
      url: '/api/run_nugget',
      method: 'POST',
      payload: {
        username: this.username,
        code: code,
        funcName: this.funcName,
        description: this.description,
        hint: this.hint,
        tests: this.tests,
        tags: this.tags,
        language: this.language,
        id: this.id,
      }
    }, (err, res) => {
      this.inSubmission = false;

      if (this.language === 'java') {
        content = '<div></div>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
      }

      if (err) {
        error = "Error: Oops! Something went wrong when running tests.";
      }
      
      if (res.status === 'MISSING_FUNCTION_NAME') {
        error = "Missing function name.";
      }

      if (res.status === 'MISSING_RETURN') {
        error = "Did you forget to have a return statement in your function?";
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({ error: error });
        return;
      }

      // var testResults = [ ["flip(True) → False", "False", "OK"], ["flip(False) → True", "True", "X"] ];
      this.setState({ testResults: res.results, resultTableHeader: "<tr><th>Expected</th><th>Run</th><th></th></tr>" });

      // log the nugget submission activity
      var totalCount = 0;
      var correctCount = 0;

      this.state.testResults.forEach(entry => {
        totalCount ++;
        if (entry[2] === "OK")
          correctCount ++;
      });

      this.setState({ totalCount: totalCount, correctCount: correctCount });

      const activities = [{
        type: 'Nugget',
        username: this.username,
        id: this.id,
        activity: {
          name: this.funcName,
          passed: true,
          correctCount: correctCount,
          totalCount: totalCount,
          code: code,
          error: '',
          submissionTime: this.submissionTime,
          duration: new Date().getTime() - this.startTime,
        },
        time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      }];

      this.props.onAddActivities(activities);
    });
  }

  /*
   * save the nugget
   */
  onEditIt() {
    this.setState({ doEdit: true });
  }

  /*
   * delete a nugget
   */
  onDeleteIt() {
    var answer = confirm('This will delete nugget ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_nugget',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('error to delete nugget!');
            return;
          }

        alert('nugget ' + this.id + ' is deleted');
      });
    } else {
      alert('ok, no change is made');
    }
  }

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  getRunButton() {
    return (
      <Button style={buttonStyle} onClick={this.onRunIt}>
      ▶ Run
      </Button>
    );
  }

  getEditButton() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        return null;
      }
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onEditIt}>
      Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (!isAdmin(this.user)) {
      if (this.username != this.author) {
        return null;
      }
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onDeleteIt}>
      Delete
      </Button>
    );
  }

  getReportBug() {
    var location = 'run?id=' + this.id;

    return(
      <a href={ 'contact?location=' + location } target='_blank'>
        <Button style={buttonReportBugStyle}>
          Send Feedback
        </Button>
      </a>
    );
  }

  getRunComponents() {
    return (
      <div>
        { this.getRunButton() }
        { this.getEditButton() }
        { this.getDeleteButton() }
        { this.getReportBug() }
      </div>
    );
  }

  displayDescription() {
    return (
      <div> { this.description } </div>
    );
  }

  // extract the function name from the last "def funcName()" pattern
  extractFunctionName(code) {
    var re = /def(\s+)(\w+)(\s*)\(/g;
    var result = code.match(re);

    if (!result)
      return;

    var name = result[result.length - 1];

    name = name.replace(/def(\s+)/, '');
    name = name.replace(/(\s*)\(/, '');

    this.funcName = name;
  }

  // extract the function line
  extractFunctionLine() {
    if (this.language === 'java') {
      return this.extractFunctionLineForJava();
    }

  // extract the function line from the last "def funcName()" pattern for Python
    var re = /def(\s+)(\w+)(\s*)\((.*)/g;
    var result = this.code.match(re);

    if (!result)
      return;

    return result[result.length - 1];
  }

  extractFunctionLineForJava() {
    var code = this.code;
    if (!code.match('public')) {
      code = 'public ' + code;
    }

    if (!code.match('static')) {
      code = code.replace('public', 'public static');
    }

    // extract the function line from the last "public static returnType funcName()" pattern for Java
    // TODO: improve regEx
    var re = /public(\s+)static(\s+)(\w+)(\s+)(\w+)(\s*)\((.*)/g;
    var result = code.match(re);
    
    if (!result) {
      // Match: public static int[] myFunction(int[] nums) {
      re = /public(\s+)static(\s+)(\w+)\[\](\s+)(\w+)(\s*)\(.*\)/g;
      result = code.match(re);

      if (!result)
        return;
    } 

    return result[result.length - 1];
  }

  extractFirstTest() {
    if (this.language === 'java') {
      // For Java, we'll not add initial test case
      return '}\n';
    }

    if (!this.tests)
      return undefined;

    var lines = this.tests.split(/\r\n|\r|\n|\n\r/g);

    if (!lines)
      return undefined;

    var line = lines[0];
    var items = line.split(";");
    
    if (items.length == 0)
      return undefined;

    var output = items[items.length - 1];

    items.pop();

    return 'print(' + this.funcName + '(' + items + '))';
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    var results = [];
    this.state.testResults.forEach(entry => {
      var colorStyle = entry[2]==="OK" ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  getNuggetsInfo() {
    if (!this.state)
      return;

    var results = [];
    var nuggets = this.state.nuggets;
    nuggets.forEach(nugget => {
      var link = "nugget?id=" + nugget.id;
      results.push(<div> <a href={link} > {nugget.id} </a> </div>);
    });

    return results;
  }

  getIdFromURL() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // e.g. http://www.cswonders.com/run?id=abcdefgh
    const href = window.location.href;
    const PATTERN = 'run?id=';
    const PATTERN_LEN = PATTERN.length;
    const index = href.indexOf(PATTERN);

    return href.substring(index + PATTERN_LEN);
  }

  getHint()
  {
    if (!this.hint)
      return <div></div>;

    return (
      <div>
        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  isArrayType(output) {
    var re = /(\{)(.*)(\})/g;
    var matched = String(output).match(re);
    return matched;
  }

  getDescription() {
    var previewMessage = '';

    this.extractFunctionName(this.code);

    previewMessage += "<table><tbody><tr>";
    // previewMessage += "<td style='width: 70px; height: 70px; padding-right: 20px'><img src='/img/nugget.svg' width='70px' height='70px'></img></td>";
    if (this.language === 'java') {
      previewMessage += "<td style='width: 70px; height: 70px; padding-right: 20px'><img src='/img/java.svg' width='70px' height='70px'></img></td>";
    } else {
      previewMessage += "<td style='width: 70px; height: 70px; padding-right: 20px'><img src='/img/python.svg' width='70px' height='70px'></img></td>";
    }

    previewMessage += "<td><h4>" + 'Define function ' + "<b>" + this.funcName + "</b>" + "</h4></td>";
    previewMessage += "</tr></tbody></table>";

    previewMessage += "<div style='font-size: 75%; margin-top: 10px'>author: " + this.author + "; " + this.tags + "</div>";
    previewMessage += "<hr>";
    previewMessage += "<h4>Problem Description</h4>";
    previewMessage += this.description;
    previewMessage += "<br />";

    return previewMessage;
  }

  getExamples() {
    var previewMessage = '';

    if (this.tests) {
      previewMessage += "<hr>";

      previewMessage += "<h4>Examples</h4>";

      var lines = this.tests.split(/\r\n|\r|\n|\n\r/g);

      // max number of test cases to show as part of description
      var maxNum= 3;
      for (var i = 0; i < lines.length && i < maxNum; i++) {
        var line = lines[i];
        var items = line.split(";");
        
        if (items.length == 0)
          continue;

        var output = items[items.length - 1];
        // skip empty lines
        if (output === '') {
          maxNum ++;
          continue;
        }

        items.pop();

        if (this.language == 'java' && this.isArrayType(items)) {
          previewMessage += items + " → " + output + "<br />";
        } else {
          previewMessage += this.funcName + "(" + items + ") → " + output + "<br />";
        }
      }
    }

    return previewMessage;
  }

  render() {
    if (this.username === 'unknown') {
      return (
        <div style={outerContainerStyle}>
          <div style={containerStyle}>
            <div style={messageStyle}>
              <img src="/img/info.svg" style={infoIconStyle}></img>
              <span> Please <a href='login'>sign in</a> to track your progress. </span>
            </div>
          </div>
        </div>
      );
    }
    
    // when the id is passed in, it means we want to edit the nugget
    // to preserve the original author, we pass in this.author as the username
    if (this.state && this.state.doEdit) {
      return (
        <CreateNuggetPage
          id={ this.id } 
          username={ this.author }
          code={ this.code }
          description={ this.description }
          hint={ this.hint }
          tests={ this.tests }
          tags={ this.tags }
          funcName={ this.funcName }
          language={ this.language }
          store={ this.props.store }
        />
      );
    }

    return (
    <div sytle={outerContainerStyle} >
      <div style={containerStyle}>

        <table>
        <tbody>
        <tr>
          <td style={previewContainerStyle}>
            <div style={previewStyle}>
              {
                this.state && this.state.loaded ?
                <div>
                  <div dangerouslySetInnerHTML={{ __html: this.getDescription() }}></div>
                  <br />
                  { this.getHint() }
                  <div dangerouslySetInnerHTML={{ __html: this.getExamples() }}></div>
                </div>
                :
                <div></div>
              }
            </div>

          </td>

          <td style={editorContainerStyle}>
            <div>
              { this.getRunComponents() }
            </div>

            <div>
              <div ref="root" style={editorStyle}>
                {this.props.code}
              </div>
            </div>

            <table>
            <tbody>
            <tr>
              <td style={outputContainerStyle}>
                <div style={labelStyle}>
                  Output
                </div>
                <iframe ref="result" id="iframeResult" style={joinJsonObjects(outputStyle, this.props.style)}>
                </iframe>
              </td>

              <td style={tdMiddleResultStyle}>
              </td>

              <td style={outputContainerStyle}>
                <div style={labelStyle}>
                  Result
                </div>
                <div style={resultStyle}>
                  {
                    this.state && this.state.error ? 
                      <div style={errorStyle} dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                      :
                      <div></div>
                  }

                  {
                    this.state && this.state.testResults && this.state.testResults.length > 0 ?
                      <div style={runTestsStyle}>
                        {
                          this.state.correctCount === this.state.totalCount ?
                            <div style={ correctMessageStyle }> Hooray! You passed { this.state.correctCount } out of { this.state.totalCount } tests.</div>
                            :
                            <div style={ incorrectMessageStyle }> You passed { this.state.correctCount } out of { this.state.totalCount } tests.</div>
                        }
                        <div style={smallFontStyle}>Time: { (this.duration / 1000 / 60).toFixed(2) } minutes.</div>
                        <br />
                        <div>
                          <table style={resultTableStyle}>
                          <tbody>
                            <tr><th>Expected </th><th>Actual </th><th></th></tr>
                            { this.getResults() }
                          </tbody>
                          </table>
                        </div>
                      </div>
                      :
                      <div></div>
                  }
              </div>
              </td>
              </tr>
              </tbody>
              </table>


          </td>
        </tr>
        </tbody>
        </table>

      </div>
    </div>
    );
  }
}

export default RunPage;
