/*
 * NuggetsPage.js
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import Button from '../../components/Button';
import Link from '../../components/Link';
import NotePage from '../../components/NotePage';

const NUGGET_WRAP_COUNT = 5;

const topicStyle = {
  "marginTop": "10px",
}

const passedNuggetStyle = {
  "marginRight": "8px",
  "fontSize": "90%",
  "color": "rgba(134, 149, 153, 1)",
}

const notPassedNuggetStyle = {
  "marginRight": "8px",
  "fontSize": "90%",
  "color": "rgba(0, 0, 0, 1)",
}

const alertStyle = {
  "background": "#fff8c4",
  "border": "1px solid #f2c779",
  "padding": "10px 10px",
  "marginTop": "0",
  "fontFamily": "Tahoma,Geneva,Arial,sans-serif",
  "fontSize": "11px",
  "width": "100%",
  "textAlign": "left",
  "fontWeight": "400",
}

const outerContainerStyle = {
  "padding": "5px",
  "backgroundColor": "#efefef",
};

const hrStyle = {
  "borderTop": "2px solid #e9e9e9",
};

const createButtonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(255, 174, 27, 1)",
    "border": "0px solid rgba(255, 174, 27, 1)",
    "padding": "6px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "400",
    "letterSpacing": "0.03125em",
    "fontSize": "55%",
    // "lineHeight": "2.2",
    "marginBottom": "10px",
    "marginLeft": "20px",
    "width": "150px",
    "textAlign": "center",
    "height": "35px",
    "verticalAlign": "textTop",

    ':hover': {
      "color": "white",
      "backgroundColor": "rgba(249, 160, 0)",
      "borderColor": "rgba(249, 160, 0)",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0px"
    }
  }
};

const containerStyle = {
  "margin": "5px 5px",
  "padding": "20px",
  "fontFamily": "LatoWeb,Helvetica Neue,Helvetica,Arial,sans-serif",
  "textAlign": "center",
  "textAlign": "left",
  "backgroundColor": "white",
};

const tdLeft = {
  "verticalAlign": "top",
  "paddingTop": "5px",
  "paddingRight": "20px",
};

const tdRight = {
  "verticalAlign": "top",
  "paddingTop": "-10px",
  "paddingLeft": "10px",
};

const buttonStyle = {
  base: {
    "backgroundColor": "white",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "rgba(134, 149, 153, 1)",
    "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "35px",
    "width": "130px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
      "color": "rgba(0, 0, 0, 1)",
      "backgroundColor": "#f2f2f2",
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const buttonDarkStyle = {
  base: {
    "backgroundColor": "rgba(43, 142, 223, 1)",
    "color": "#fff",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "35px",
    "width": "130px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const greetingStyle = {
  "textAlign": "left",
  "fontSize": "180%",
  "marginBottom": "-10px",
  "display": "inline-block",
};

const iconStyle = {
  "textAlign": "left",
  "width": "150px",
  "paddingLeft": "25px",
};

const selectorStyle = {
  "width": "120px",
  "height": "32px",
  "fontSize": "12px",
  "paddingLeft": "8px",
  "borderStyle": "solid",
  // "borderRadius": "2px",
  // "borderWidth": "2px",
};

const languageStyle = {
  "verticalAlign": "middle",
  "fontSize": "70%",
  "fontWeight": "500",
  "paddingLeft": "70px",
  "paddingRight": "20px",
};

const helpStyle = {
  "verticalAlign": "sub",
  "fontSize": "55%",
  "fontWeight": "400",
  "marginLeft": "20px",
};

class NuggetsPage extends Component {
  componentDidMount() {
    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;

    this.onByTopics = this.onByTopics.bind(this);
    this.onByLevels = this.onByLevels.bind(this);
    this.onByTags = this.onByTags.bind(this);
    this.onHelp = this.onHelp.bind(this);
    this.onCreateNugget = this.onCreateNugget.bind(this);
    this.onAllNuggets = this.onAllNuggets.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);

    this.setState( {
      showByTopics: true,
      showByLevels: false,
      showByTags: false,
      showHelp: false,
      showCreateNugget: false,
      showAllNuggets: false,
      language: 'python',
      doneProcessing: false,
    });

    request({
      url: '/api/get_all_nuggets',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
        if (err) {
          console.log('Error to get all nuggets!');
          return;
        }
        
        this.setState({ nuggets: res.nuggets });

        this.processTags();
    });

    request({
      url: '/api/all_activities_by_user',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
        if (err) {
          console.log('Error to get user activity!');
          return;
        }
        
        // if (this.state.passedNuggetMap) {
        //   for (var member in this.state.passedNuggetMap) {
        //     delete this.state.passedNuggetMap[member];
        //   }
        // }

        this.setState({ passedNuggetMap : {} });

        var passedNuggetMap = {};
        if (res.activities) {
          res.activities.forEach(item => {
            if (item.type === 'Nugget') {
              if (item.activity.correctCount === item.activity.totalCount) {
                passedNuggetMap[item.activity.name] = true;
              }
            }
          });
        }

        this.setState({ passedNuggetMap : passedNuggetMap });
    });
  }

  /*
   * process tags and classify them with various maps
   * 
   * case sensitive, seperated by ','
   * e.g. key1: value1, key2: value2, 
   * 
   * common keys:
   * topic, level, tag
   * hide: true (don't show yet)
   * showsolution: 
   * showpost:
   */
  processTags() {
    if (!this.state)
      return;

    if (!this.state.nuggets)
      return;

    var languageMap = {};

    this.state.nuggets.forEach(nugget => {
      var tags = nugget.tags;
      var id = nugget.id;
      var language = nugget.language ? nugget.language : 'python';

      if (!languageMap[language]) {
        languageMap[language] = {};
        languageMap[language]['idMap'] = {};
        languageMap[language]['topicMap'] = {};
        languageMap[language]['tagMap'] = {};
        languageMap[language]['levelMap'] = {};
      }

      if (!languageMap[language]['idMap'][id]) {
        languageMap[language]['idMap'][id] = {};
      }
      languageMap[language]['idMap'][id]['funcName'] = nugget.funcName;

      if (tags) {
        var tagPairs = tags.split(';');
        tagPairs.forEach(pair => {
          var items = pair.split(':');
          if (items[0] && items[1]) {
            var key = items[0];
            key = key.trim();
            var value = items[1];
            value = value.trim();

            if (key === 'topic') {
              languageMap[language]['idMap'][id]['topic'] = value;
              if (!languageMap[language]['topicMap'][value]) {
                languageMap[language]['topicMap'][value] = [];
              }
              languageMap[language]['topicMap'][value].push(id);
            }

            if (key === 'tag') {
              if (!languageMap[language]['tagMap'][value]) {
                languageMap[language]['tagMap'][value] = [];
              }
              languageMap[language]['tagMap'][value].push(id);
            }

            if (key === 'level') {
              if (!languageMap[language]['levelMap'][value]) {
                languageMap[language]['levelMap'][value] = [];
              }
              languageMap[language]['levelMap'][value].push(id);
            }
          }
        });
      }
    });

    this.setState( { languageMap: languageMap, doneProcessing: true });
  }

  getNuggetsByTopic() {
    if (!this.state.showByTopics) {
      return (<div></div>);
    }

    if (!this.state || !this.state.doneProcessing)
      return <div>Loading nuggets...</div>;

    var languageMap = this.state.languageMap[this.state.language];
    var result = [];

    result.push(<h4>Nuggets by Topics ({ Object.keys(languageMap['idMap']).length })</h4>);
    var i = 0;
    for (var topic in languageMap['topicMap']) {
      result.push(<div style={topicStyle}><b>{ topic }</b> ({ languageMap['topicMap'][topic].length }):</div>);

      for (i = 0; i < languageMap['topicMap'][topic].length; i++) {
        var id = languageMap['topicMap'][topic][i];
        var link = 'run?id=' + id;
        var funcName = languageMap['idMap'][id]['funcName'];
        if (this.state.passedNuggetMap && funcName in this.state.passedNuggetMap) {
          result.push(<Link to={'/' + link}> <span style={ passedNuggetStyle }> { funcName } </span> </Link>);
        }
        else {
          result.push(<Link to={'/' + link}> <span style={ notPassedNuggetStyle }> { funcName } </span> </Link>);
        }

        if ((i+1) % NUGGET_WRAP_COUNT == 0) {
          result.push(<br />);
        }
      }

      if (i % NUGGET_WRAP_COUNT != 0) {
        result.push(<br />);
      }
    }

    return result;
  }

  getNuggetsByTags() {
    if (!this.state.showByTags) {
      return (<div></div>);
    }

    if (!this.state || !this.state.doneProcessing)
      return <div>Loading nuggets...</div>;

    var languageMap = this.state.languageMap[this.state.language];
    var result = [];

    result.push(<h4>Nuggets by Tags ({ Object.keys(languageMap['idMap']).length })</h4>);
    var i = 0;
    for (var tag in languageMap['tagMap']) {
      result.push(<div style={topicStyle}><b>{ tag }</b> ({ languageMap['tagMap'][tag].length }):</div>);

      for (i = 0; i < languageMap['tagMap'][tag].length; i++) {
        var id = languageMap['tagMap'][tag][i];
        var link = 'run?id=' + id;
        var funcName = languageMap['idMap'][id]['funcName'];
        if (this.state.passedNuggetMap && funcName in this.state.passedNuggetMap) {
          result.push(<Link to={'/' + link}> <span style={ passedNuggetStyle }> { funcName } </span> </Link>);
        }
        else {
          result.push(<Link to={'/' + link}> <span style={ notPassedNuggetStyle }> { funcName } </span> </Link>);
        }

        if ((i+1) % NUGGET_WRAP_COUNT == 0) {
          result.push(<br />);
        }
      }
      
      if (i % NUGGET_WRAP_COUNT != 0) {
        result.push(<br />);
      }
    }

    return result;
  }

  getHelp() {
    if (!this.state.showHelp) {
      return (<div></div>);
    }

    const helpId = 'jmwwjxu0';
    return <NotePage id={helpId} store={this.props.store} />
  }

  getNuggetsByLevels() {
    if (!this.state.showByLevels) {
      return (<div></div>);
    }

    if (!this.state || !this.state.doneProcessing)
      return <div>Loading nuggets...</div>;

    var languageMap = this.state.languageMap[this.state.language];
    var i = 0;
    var result = [];
    result.push(<h4>Nuggets by Levels ({ Object.keys(languageMap['idMap']).length })</h4>);
    for (var level in languageMap['levelMap']) {
      result.push(<div style={topicStyle}><b>{ level }</b> ({ languageMap['levelMap'][level].length }):</div>);

      for (i = 0; i < languageMap['levelMap'][level].length; i++) {
        var id = languageMap['levelMap'][level][i];
        var link = 'run?id=' + id;
        var funcName = languageMap['idMap'][id]['funcName'];
        if (this.state.passedNuggetMap && funcName in this.state.passedNuggetMap) {
          result.push(<Link to={'/' + link}> <span style={ passedNuggetStyle }> { funcName } </span> </Link>);
        }
        else {
          result.push(<Link to={'/' + link}> <span style={ notPassedNuggetStyle }> { funcName } </span> </Link>);
        }

        if ((i+1) % NUGGET_WRAP_COUNT == 0) {
          result.push(<br />);
        }
      }
      
      if (i % NUGGET_WRAP_COUNT != 0) {
        result.push(<br />);
      }
    }

    return result;
  }

  getAllNuggets() {
    if (!this.state.showAllNuggets) {
      return (<div></div>);
    }

    if (!this.state || !this.state.doneProcessing)
      return <div>Loading nuggets...</div>;

    var languageMap = this.state.languageMap[this.state.language];
    var i = 0;
    var result = [];

    result.push(<h4>All Nuggets ({ Object.keys(languageMap['idMap']).length })</h4>);
    for (i = 0; i < this.state.nuggets.length; i++) {
      var nugget = this.state.nuggets[i];
      if (!nugget)
        continue;

      var id = nugget.id;
      if (!languageMap['idMap'][id]) {
        continue;
      }

      var link = 'run?id=' + id;
      var funcName = languageMap['idMap'][id]['funcName'];
      if (this.state.passedNuggetMap && funcName in this.state.passedNuggetMap) {
        result.push(<Link to={'/' + link}> <span style={ passedNuggetStyle }> { funcName } </span> </Link>);
      }
      else {
        result.push(<Link to={'/' + link}> <span style={ notPassedNuggetStyle }> { funcName } </span> </Link>);
      }

      if ((i+1) % NUGGET_WRAP_COUNT == 0) {
        result.push(<br />);
      }
    }
    
    if (i % NUGGET_WRAP_COUNT != 0) {
      result.push(<br />);
    }

    return result;
  }

  getMenu() {
    return (
      <div>
        <Button style={ this.state.showByTopics ? buttonDarkStyle : buttonStyle } onMouseDown={this.onByTopics}>
          By Topics
        </Button>
        <br />
        <Button style={ this.state.showByLevels ? buttonDarkStyle : buttonStyle } onMouseDown={this.onByLevels}>
          By Levels
        </Button>
        <br />
        <Button style={ this.state.showByTags? buttonDarkStyle : buttonStyle } onMouseDown={this.onByTags}>
          By Tags
        </Button>
        <br />
        <Button style={ this.state.showAllNuggets ? buttonDarkStyle : buttonStyle } onMouseDown={this.onAllNuggets}>
          All Nuggets
        </Button>
        <br />
        <hr></hr>
        <Button style={ this.state.showHelp ? buttonDarkStyle : buttonStyle } onMouseDown={this.onHelp}>
          Help
        </Button>
        <br />
        <a href="create-nugget">
          <Button style={ this.state.showCreateNugget ? buttonDarkStyle : buttonStyle } onMouseDown={this.onCreateNugget}>
            Create Nugget
          </Button>
        </a>
        <br />
      </div>
    );
  }

  onByTopics() {
    this.setState({
      showByLevels: false,
      showByTags: false,
      showHelp: false,
      showCreateNugget: false,
      showAllNuggets: false,
    });

    this.setState({
      showByTopics: true,
    });
  }

  onByLevels() {
    this.setState({
      showByTopics: false,
      showByTags: false,
      showHelp: false,
      showCreateNugget: false,
      showAllNuggets: false,
    });

    this.setState({
      showByLevels: true,
    });
  }

  onByTags() {
    this.setState({
      showByTopics: false,
      showByLevels: false,
      showAllNuggets: false,
      showHelp: false,
      showCreateNugget: false,
    });

    this.setState({
      showByTags: true,
    });
  }

  onCreateNugget() {
    this.setState({
      showByTopics: false,
      showByLevels: false,
      showAllNuggets: false,
      showByTags: false,
      showHelp: false,
    });

    this.setState({
      showCreateNugget: true,
    });
  }

  onHelp() {
    this.setState({
      showByTopics: false,
      showByLevels: false,
      showAllNuggets: false,
      showByTags: false,
      showCreateNugget: false,
    });

    this.setState({
      showHelp: true,
    });
  }

  onAllNuggets() {
    this.setState({
      showByTopics: false,
      showByLevels: false,
      showByTags: false,
      showHelp: false,
      showCreateNugget: false,
    });

    this.setState({
      showAllNuggets: true,
    });
  }

  onLanguageChange(e) {
    var value = e.target.value;
    this.setState({ language: value });
  }

  getLanguageSelected(option) {
    if (!this.state) {
      if (option === 'python')
        return 'selected';

      return '';
    }

    if (option === this.state.language)
      return 'selected';

    return '';
  }

  render() {
    if (!this.state) {
      return (<div></div>);
    }

    return (
      <div style={outerContainerStyle}>
        <div style={containerStyle}>

        <div style={ greetingStyle }>
        {
          /*
          <span style={{'verticalAlign': 'middle'}}> Nugget Explorer</span>
          */
        }

          <span style={iconStyle}>
            <img src="/img/gold.svg" width="60px" height="60px"></img>
          </span>

          <span style={languageStyle}> Language </span>

          <span style={{"paddingTop": "20px"}}>
            <select style={selectorStyle} id="language" name="language" onChange={this.onLanguageChange}>
              <option value="python" selected={this.getLanguageSelected('python')} >Python</option>
              <option value="java" selected={this.getLanguageSelected('java')} >Java</option>
            </select>
          </span>

        </div>

        <hr style={hrStyle}></hr>

          <table>
            <tbody>
              <tr>
                <td style={tdLeft}>
                  { this.getMenu() }
                </td>
                <td style={tdRight}>
                  {
                    this.getNuggetsByTopic()
                  }
                  {
                    this.getNuggetsByLevels()
                  }
                  {
                    this.getNuggetsByTags()
                  }
                  {
                    this.getAllNuggets()
                  }
                  {
                    this.getHelp()
                  }
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    );
  }
}

export default NuggetsPage;
