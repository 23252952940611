/*
 * CreateNuggetPage.js
 * 
 * Example: <CreateNuggetPage run='true' save='true' code='hi' />
 *
 */
import React, { Component } from 'react';
import Button from '../Button';
import { joinJsonObjects } from '../../util/util';
import { request } from '../../util/api';
import PropTypes from 'prop-types';

const PYTHON_FUNCTION_TEMPLATE = 
  'def your_function(your_parameter):\n' +
  '    return your_result';

const JAVA_FUNCTION_TEMPLATE = 
  'public static yourReturnType yourFunction(yourParameterType yourParameter) {\n' +
  '    return yourResult;\n' +
  '}';

const DEFAULT_EDITOR_SETTINGS = {
  theme: 'monokai',
  fontSize: 'small',
  tabSize: '4',
  showLineNumbers: true,
  showInvisibles: false,
  keyBinding: '',
};

const errorStyle = {
  "color": "red",
}

const containerStyle = {
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "backgroundColor": "#efefef",
}

const labelStyle = {
  "fontSize": "14px",
  "fontWeight": "500",
  "marginBottom": "8px",
}

const editorStyle = {
  "height": "250px",
  "width": "618px",
  // "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  "textAlign": "right",
  "fontWeight": "500",
}

const previewContainerStyle = {
  "width": "100%",
  "verticalAlign": "top",
  "paddingLeft": "20px",
}

const previewStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "white",
  "padding": "10px",
  "marginBottom": "10px",
}

const runTestsStyle = {
  "fontFamily": "verdana, arial, helvetica, sans-serif",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "padding": "10px",
  "fontSize": "small",
  "minHeight": "60px",
}

const resultStyle = {
  "height": "60px",
  "backgroundColor": "rgba(245, 222, 179, 1)",
  "width": "100%",
  "border": "none",
  // "marginLeft": "-20px",
  "border": "1px solid rgba(204, 204, 204, 1)",
  "marginBottom": "5px",
};

const resultTableStyle = {
  "borderCollapse": "collapse",
}

const resultTDStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
}

const resultRedStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "red",
  "fontWeight": "600",
}

const resultGreenStyle = {
  "border": "2px solid black",
  "padding": "4px",
  "verticalAlign": "top",
  "color": "green",
  "fontWeight": "600",
}

const buttonStyle = {
  base: {
    "backgroundColor": "#4CAF50",
    "marginTop": "10px",
    "marginBottom": "10px",
    // "marginLeft": "10px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    'fontFamily': 'Segoe UI Symbol',

    ':hover': {
      "color": "#4CAF50",
      "backgroundColor": "rgba(132, 256, 130, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "10px",
    "width": "90px",
    "marginLeft": "10px",
    'fontFamily': 'Segoe UI Symbol',

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonViewAllStyle = {
  base: {
    "backgroundColor": "grey",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "10px",
    "width": "130px",
    "marginLeft": "10px",
    'fontFamily': 'Segoe UI Symbol',

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftContainerStyle = {
  "verticalAlign": "top",
};

const hintButtonStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "textAlign": "left",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    // "borderRadius": "5px",
    "marginBottom": "10px",
    "width": "570px",
    "marginLeft": "-10px",
    "marginRight": "-10px",
    "paddingLeft": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const arrowStyle = {
  "marginLeft": "6px",
  "fontSize": "75%",
};

const hintStyle = {
  "backgroundColor": "rgba(252, 234, 154, 1)",
  "padding": "20px",
  "marginLeft": "-10px",
  "marginRight": "-10px",
};

const selectorStyle = {
  "lineHeight": "1.0",
  "width": "100%",
  "height": "30px",
  "fontSize": "12px",
  "marginBottom": "5px",
};

class CreateNuggetPage extends Component {
  constructor(props) {
    super(props);

    this.onRunIt = this.onRunIt.bind(this);
    this.onRunTests = this.onRunTests.bind(this);
    this.onSaveIt = this.onSaveIt.bind(this);
    this.onTestCaseChange = this.onTestCaseChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onHintChange = this.onHintChange.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.iframeContent = this.iframeContent.bind(this);
    this.onHint = this.onHint.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);

    this.user = null;
    if (this.props.store) {
      this.user = this.props.store.getState().auth.user;
    }

    this.id = this.props.id ? this.props.id : '';
    this.username = this.props.username ? this.props.username : '';
    this.passed = false;
    this.funcName = this.props.funcName ? this.props.funcName : '';
  }

  /*
   * configure Ace editor
   */
  componentDidMount() {
    const node = this.refs.root;
    const editor = ace.edit(node);

    // editor.setTheme("ace/theme/clouds");
    // editor.setTheme("ace/theme/monokai");

    editor.getSession().setMode("ace/mode/python");
    // editor.getSession().setMode("ace/mode/javascript");

    editor.setShowPrintMargin(false);
    // editor.renderer.setShowGutter(false);

    var minCount = 80;
    editor.setOptions({minLines: minCount});
    // var lineNum = editor.session.getLength();
    // editor.setOptions({maxLines: lineNum});

    this.setState({
      description: this.props.description ? this.props.description : 'Describe your problem here. You can use HTML to format it.',
      hint: this.props.hint ? this.props.hint : "",
      tests: this.props.tests ? this.props.tests : 'Add your test cases here, one per line. Use ";" to separate parameter values. Last one is return value.',
      tags: this.props.tags ? this.props.tags : 'Use ":" to separate key/value of each tag. Use ";" to separate tags. Required tags: topic, level.',
      author: this.props.username ? this.props.username : this.username,
      language: this.props.language? this.props.language: 'python',
      doHint: true,
    });

    this.setState({
      editor: editor,
      resultArea: this.refs.result,
      error: "",
      testResults: [],
      resultTableHeader: "",
    });
    
    if (this.user) {
      request({
        url: '/get_user_preference',
        method: 'GET',
        payload: {
          username: this.user.username,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error: failed to get user preference for username ' + this.username);
          return;
        }

        this.preference = res.preference;
        if (!this.preference) {
          this.preference = {};
        }

        var settings = this.preference.editorSettings;
        if (settings) {
          editor.setTheme("ace/theme/" + settings.theme);

          if (settings.showLineNumbers) {
            editor.renderer.setShowGutter(true);
          } else {
            editor.renderer.setShowGutter(false);
          }

          if (settings.fontSize === 'small') {
            editor.setOptions({
              fontSize: "8pt",
            });
          }

          if (settings.fontSize === 'medium') {
            editor.setOptions({
              fontSize: "12pt",
            });
          }

          if (settings.fontSize === 'large') {
            editor.setOptions({
              fontSize: "16pt",
            });
          }

          if (settings.tabSize === '2') {
            editor.setOptions({
              tabSize: 2,
            });
          }

          if (settings.tabSize === '4') {
            editor.setOptions({
              tabSize: 4,
            });
          }

          if (settings.showInvisibles) {
            editor.setOption("showInvisibles", true);
          } else {
            editor.setOption("showInvisibles", false);
          }

          if (settings.keyBinding === '') {
            editor.setKeyboardHandler('');
          }

          if (settings.keyBinding === 'vim') {
            editor.setKeyboardHandler('ace/keyboard/vim');
          }

          if (settings.keyBinding === 'emacs') {
            editor.setKeyboardHandler('ace/keyboard/emacs');
          }
        }
        else {
          // no settings
          this.resetOriginal();
        }
      });
    }

    if (this.props.hideCode==='true')
    {
      var prog = editor.getValue(); 

      var mypre = this.refs.result;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;

      iframeDoc.open();
      var content = this.iframeContent(prog, mypre);
      iframeDoc.write(content);
      iframeDoc.close();
    }
  }

  resetOriginal() {
    const editor = ace.edit(this.refs.root);
    editor.setTheme("ace/theme/" + DEFAULT_EDITOR_SETTINGS.theme);

    editor.renderer.setShowGutter(DEFAULT_EDITOR_SETTINGS.showLineNumbers);

    editor.setOptions({
      fontSize: "8pt",
      tabSize: 4,
    });

    editor.setOption("showInvisibles", DEFAULT_EDITOR_SETTINGS.showInvisibles);

    editor.setKeyboardHandler(DEFAULT_EDITOR_SETTINGS.keyBinding);
  }

  processProgram(prog) {
    // import some libs by default
    var result = '\nimport turtle\nturtle.Screen().setup(600,600)\n' + prog + '\n';
    
    return result;
  }

  iframeContent(prog, mypre) { 
    // var code = this.processProgram(prog);
    var code = prog;

    var content = (
'<html> ' + 
'<head> ' +
'<script src="\/vendor\/jquery\/jquery-2.1.4.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt.min.js" type="text\/javascript"><\/script> ' +
'<script src="\/vendor\/skulpt\/skulpt-stdlib.js" type="text\/javascript"><\/script> ' +
'</head> ' +
'<body> ' +
'<script type="text/javascript"> ' +
'function outf(text) { ' +
'    var mypre = document.getElementById("output"); ' +
'    mypre.innerHTML = mypre.innerHTML + text; ' +
'} ' +
'function builtinRead(x) { ' +
'    if (Sk.builtinFiles === undefined || Sk.builtinFiles["files"][x] === undefined) ' +
'            throw "File not found: \'" + x + "\'"; ' +
'    return Sk.builtinFiles["files"][x]; ' +
'} ' +
'function runit() { ' +
'   var prog = ' + 'document.getElementById("yourcode").value' + ';' +
'   var mypre = document.getElementById("output"); ' +
'   mypre.innerHTML = ""; ' +
'   Sk.pre = "output";' +
'   Sk.configure({output:outf, read:builtinRead}); ' +
'   (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas";' +
'   var myPromise = Sk.misceval.asyncToPromise(function() {' +
'       return Sk.importMainWithBody("<stdin>", false, prog, true);' +
'   });' +
'   myPromise.then(function(mod) {' +
'       console.log("success");' +
'   },' +
'       function(err) {' +
'       console.log(err.toString());' +
'   });' +
'} ' +
'<\/script> ' +
'<form> ' +
'<textarea id="yourcode" style="display:none;">' + code +
'</textarea>' +
'</form> ' +
'<pre id="output" ></pre> ' + 
'<div id="mycanvas"></div> ' + 
'</body> ' +
'<script>runit()<\/script>' +
'</html> '
  );
  return content;
}

  onRunIt() {
    // alert('hi');
    var prog = this.state.editor.getValue(); 
    this.extractFunctionName(prog);

    // var mypre = document.getElementById("iframeResult");
    var mypre = this.state.resultArea;

    mypre.style.display = "block";

    var iframeDoc = mypre.contentWindow.document;
    // alert(iframeDoc);

    iframeDoc.open();
    var content = this.iframeContent(prog, mypre);
    iframeDoc.write(content);
    iframeDoc.close();
  }

  /*
   * run all tests  
   */
  onRunTests() {
    if (!this.username)
      return;

    this.setState({ error: "", testResults: [], resultTableHeader: "" });
    var error = "";

    var code = this.state.editor.getValue();
    this.extractFunctionName(code);

    if (this.state.language === 'java') {
      var content = '<div style="color: grey">Crunching result...</div>';
      this.setState({
        content: content,
      });

      var mypre = this.state.resultArea;
      mypre.style.display = "block";
      var iframeDoc = mypre.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(content);
      iframeDoc.close();
    }
      
    request({
      url: '/api/run_nugget',
      method: 'POST',
      payload: {
        username: this.username,
        code: code,
        funcName: this.funcName,
        description: this.state.description,
        hint: this.state.hint,
        tests: this.state.tests,
        tags: this.state.tags,
        language: this.state.language,
        id: this.id,
      }
    }, (err, res) => {
      if (this.state.language === 'java') {
        var content = '<div></div>';
        this.setState({
          content: content,
        });

        var mypre = this.state.resultArea;
        mypre.style.display = "block";
        var iframeDoc = mypre.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
      }
      
      if (err) {
        error = "Error: Oops! Something went wrong when running tests.";
      }

      if (res.status === 'MISSING_RETURN') {
        error = "Did you forget to have a return statement in your function?";
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        error = 'Error: "import ' + res.module + '" is not currently supported by the online editor.';
      }

      if (res.status === 'CONTAINS_EVAL') {
        error = "Error: eval is not supported by the online editor.";
      }

      if (res.status === 'NO_TESTS') {
        error = "No tests to run. Please add some test cases.";
      }

      if (res.status === 'EXECUTION_ERROR') {
        error = res.error;
      }

      if (error) {
        this.setState({ error: error });
        return;
      }

      // var testResults = [ ["flip(True) → False", "False", "OK"], ["flip(False) → True", "True", "X"] ];
      this.setState({ testResults: res.results, resultTableHeader: "<tr><th>Expected</th><th>Run</th><th></th></tr>" });
    });
  }

  /*
   * save the nugget
   */
  onSaveIt() {
    if (!this.username)
      return;

    // if (!this.state.tags || !this.state.tags.includes('topic') || !this.state.tags.includes('level')) {
    //   alert('Please add tags for "topic" and "level" (easy, medium, or hard).');
    //   return;
    // }

    if (!this.passed) {
      alert('Please hit "Run Tests" and ensure all tests are passed before saving this.');
      return;
    }

    var code = this.state.editor.getValue();
    this.extractFunctionName(code);

    request({
      url: '/api/save_nugget',
      method: 'POST',
      payload: {
        username: this.username,
        code : code,
        funcName: this.funcName,
        description: this.state.description,
        hint: this.state.hint,
        tests: this.state.tests,
        tags: this.state.tags,
        language: this.state.language,
        id: this.id,
      }
    }, (err, res) => {
      if (err) {
        alert("Oops! Something went wrong when saving your code.");
        return;
      }

      if (res.status === 'CONTAINS_FORBIDDEN_WORDS') {
        alert('Error: "import ' + res.module + '" is not currently supported by the online editor.');
      }

      if (res.status === 'CONTAINS_EVAL') {
        alert("Error: eval is not supported by the online editor.");
      }

      if (!this.id) {
        this.id = res.id;
      }
      
      alert(res.status);
    });
  }  

  onHint() {
    this.setState({
      doHint: !this.state.doHint, 
    });
  }

  onLanguageChange(e) {
    var value = e.target.value;
    this.setState({ language: value });

    const editor = ace.edit(this.refs.root);
    editor.getSession().setMode("ace/mode/" + value);

    // If the nugget id exists, it indicates that we're editting an existing Nugget.
    // Otherwise, we populate a code template.
    if (!this.id) {
      if (value === 'python') {
        editor.setValue(PYTHON_FUNCTION_TEMPLATE); 
      } else {
        editor.setValue(JAVA_FUNCTION_TEMPLATE); 
      }
    }
  }

  getDescription() {
    if (!this.state)
      return (<div></div>);

    var previewMessage = '';

    var code = this.state.editor.getValue();
    this.extractFunctionName(code);

    previewMessage += "<table><tbody><tr>";
    if (this.state && this.state.language === 'java') {
      previewMessage += "<td style='width: 70px; height: 70px; padding-right: 20px'><img src='/img/java.svg' width='70px' height='70px'></img></td>";
    } else {
      previewMessage += "<td style='width: 70px; height: 70px; padding-right: 20px'><img src='/img/python.svg' width='70px' height='70px'></img></td>";
    }
    previewMessage += "<td><h4>" + 'Define function ' + "<b>" + this.funcName + "</b>" + "</h4></td>";
    previewMessage += "</tr></tbody></table>";

    previewMessage += "<div style='font-size: 75%; margin-top: 10px'>author: " + this.state.author + "; tags: " + this.state.tags + "</div>";
    previewMessage += "<hr>";
    previewMessage += "<h4>Problem Description</h4>";
    previewMessage += this.state.description;
    previewMessage += "<br />";

    return previewMessage;
  }

  isArrayType(output) {
    var re = /(\{)(.*)(\})/g;
    var matched = String(output).match(re);

    return matched;
  }

  getPreview() {
    if (!this.state || !this.state.editor)
      return(<div></div>);

    var previewMessage = '';

    if (this.state.tests) {
      previewMessage += "<hr>";

      previewMessage += "<h4>Examples</h4>";

      var lines = this.state.tests.split(/\r\n|\r|\n|\n\r/g);

      // max number of test cases to show as part of description
      var maxNum= 3;
      for (var i = 0; i < lines.length && i < maxNum; i++) {
        var line = lines[i];
        var items = line.split(";");
        
        if (items.length == 0)
          continue;

        var output = items[items.length - 1];
        // skip empty lines
        if (output === '') {
          maxNum ++;
          continue;
        }

        items.pop();

        if (this.isArrayType(items)) {
          previewMessage += items + " → " + output + "<br />";
        } else {
          previewMessage += this.funcName + "(" + items + ") → " + output + "<br />";
        }
      }
    }

    return previewMessage;
  }

  onTestCaseChange(e) {
    this.setState({
      tests: e.target.value,
    });
  }

  onDescriptionChange(e) {
    this.setState({
      description: e.target.value,
    });
  }

  onHintChange(e) {
    this.setState({
      hint: e.target.value,
    });
  }

  onTagChange(e) {
    this.setState({
      tags: e.target.value,
    });
  }

  getRunButton() {
    if (this.state && this.state.language === 'java') {
      return;
    }

    return (
      <Button style={buttonSaveStyle} onClick={this.onRunIt}>
      ▶ Run Code
      </Button>
    );
  }

  getRunTestsButton() {
    return (
      <Button style={buttonStyle} onClick={this.onRunTests}>
      ▶ Run Tests
      </Button>
    );
  }

  showSaveButton() {
    if (!this.username)
      return false;

    return true;
  }

  getSaveButton() {
    if (this.props.hideCode === 'true')
      return null;

    if (!this.showSaveButton())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onSaveIt}>
      Save
      </Button>
    );
  }

  getAllButton() {
    return (
      <a href='nuggets'>
        <Button style={buttonViewAllStyle} >
        View All Nuggets
        </Button>
      </a>
    );
  }

  getLanguageSelected(option) {
    if (!this.state) {
      if (option === 'python')
        return 'selected';

      return '';
    }

    if (option === this.state.language)
      return 'selected';

    return '';
  }

  getRunComponents() {
    return (
      <div>
        { this.getRunTestsButton() }
        { this.getRunButton() }
        { this.getSaveButton() }
        { this.getAllButton() }
      </div>
    );
  }

  // extract the function name
  extractFunctionName(code) {
    if (this.state && this.state.language === 'java') {
      this.extractFunctionNameForJava(code);
      return;
    }

    // extract Python function name from the last "def funcName()" pattern
    var re = /def(\s+)(\w+)(\s*)\(/g;
    var result = code.match(re);

    if (!result)
      return;

    var name = result[result.length - 1];

    name = name.replace(/def(\s+)/, '');
    name = name.replace(/(\s*)\(/, '');

    this.funcName = name;
  }

  // extract Java function name from the last "public yourReturnTpe yourFunction()" pattern
  extractFunctionNameForJava(code) {
    if (!code.match('public')) {
      code = 'public ' + code;
    }

    if (!code.match('static')) {
      code = code.replace('public', 'public static');
    }

    var re = /public(\s+)static(\s+)(\w+)(\s+)(\w+)(\s*)\(.*\)/g;

    var result = code.match(re);

    if (!result) {
      // Match: public static int[] myFunction(int[] nums) {
      re = /public(\s+)static(\s+)(\w+)\[\](\s+)(\w+)(\s*)\(.*\)/g;
      result = code.match(re);

      if (!result)
        return;
    }

    var name = result[result.length - 1];

    // TODO: make function/parameter parsing robust
    name = name.replace(/public(\s+)static(\s+)(\S+)(\s+)/, '');
    name = name.replace(/(\s*)\(.*\)/, '');

    this.funcName = name;
  }

  getResults() {
    if (!this.state)
      return;

    if (!this.state.testResults)
      return;

    var results = [];
    this.passed = true;
    this.state.testResults.forEach(entry => {
      var isOK = entry[2]==="OK";
      if (!isOK)
        this.passed = false;

      var colorStyle = isOK ? resultGreenStyle : resultRedStyle;
      results.push(<tr><td style={resultTDStyle}>{ entry[0] }</td>
        <td style={resultTDStyle}>{ entry[1] }</td>
        <td style={colorStyle}>{ entry[2] }</td>
        </tr>);
    });

    return results;
  }

  getDefaultDescription() {
    if (this.state) 
      return this.state.description;

    if (this.props.description)
      return this.props.description;

    return 'Describe your problem here. You can use HTML to format it.';
  }

  getDefaultHint() {
    if (this.props.hint)
      return this.props.hint;

    return 'Provide some hint here (this is optional).';
  }

  getDefaultTests() {
    if (this.props.tests)
      return this.props.tests;

    return 'Add your test cases here, one per line. Use ";" to separate parameter values. Last one is return value.';
  }

  getDefaultTags() {
    if (this.props.tags)
      return this.props.tags;

    return 'Use ":" to separate key/value of each tag. Use ";" to separate tags. Required tags: topic, level.';
  }

  getHint()
  {
    if (!this.state.hint)
      return <div></div>;

    return (
      <div>
        <br />
    
        <Button style={hintButtonStyle} onClick={this.onHint}>
          <h5> { 'Get a Hint' } <span style={arrowStyle}>{ this.state.doHint ? '▲' : '▼' }</span></h5>
        </Button>

        {
          this.state.doHint ? <div style={hintStyle} dangerouslySetInnerHTML={{ __html: this.state.hint }}></div> : <div></div>
        }

      </div>
    );
  }

  render() {
    return (
      <div style={containerStyle}>

        <table>
        <tbody>
        <tr>
          <td style={leftContainerStyle}>
          <table>
          <tbody>

          <tr>
          <td style={columnStyle}> </td>
          <td>
            <div>
              { this.getRunComponents() }
            </div>
          </td>
          </tr>

          <tr>
            <td style={columnStyle}>Language</td>
            <td>
              <select style={selectorStyle} id="language" name="language" onChange={this.onLanguageChange}>
                <option value="python" selected={this.getLanguageSelected('python')} >Python</option>
                <option value="java" selected={this.getLanguageSelected('java')} >Java</option>
              </select>
            </td>
          </tr>

          <tr>
          <td style={columnStyle}> Code </td>
          <td>
            {
              <div>
                <div ref="root" style={editorStyle}>
                  {this.props.code}
                </div>
              </div>
            }
          </td>
          </tr>

          <tr>
          <td style={columnStyle}> Description </td>
          <td>
            <div>
              <textarea id="description" rows="4" cols="85" name="description" onChange={this.onDescriptionChange}
              defaultValue={ this.getDefaultDescription() }></textarea>
            </div>
          </td>
          </tr>

          <tr>
          <td style={columnStyle}> Hint </td>
          <td>
            <div>
              <textarea id="hint" rows="2" cols="85" name="hint" onChange={this.onHintChange}
              defaultValue={ this.getDefaultHint() }></textarea>
            </div>
          </td>
          </tr>

          <tr>
          <td style={columnStyle}> Tests </td>
          <td>
            <div>
              <textarea id="testcase" rows="10" cols="85" name="testcase" onChange={this.onTestCaseChange}
              defaultValue={ this.getDefaultTests() }></textarea>
            </div>
          </td>
          </tr>

          <tr>
          <td style={columnStyle}> Tags </td>
          <td>
            <div>
              <textarea id="tags" rows="1" cols="85" name="tags" onChange={this.onTagChange}
              defaultValue={ this.getDefaultTags() }></textarea>
            </div>
          </td>
          </tr>

          </tbody>
          </table>
          </td>

          <td style={previewContainerStyle}>
            <div style={labelStyle}>
              <img src='/img/preview.svg' style={{'width':'40px', 'height':'40px', 'marginRight': '10px'}} ></img>
              Preview
            </div>
            <div style={previewStyle}>
              {
                this.state ?
                <div>
                  <div dangerouslySetInnerHTML={{ __html: this.getDescription() }}></div>
                  { this.getHint() }
                  <div dangerouslySetInnerHTML={{ __html: this.getPreview() }}></div>
                  <br />
                </div>
                :
                <div></div>
              }
            </div>

            <div style={labelStyle}> Result </div>
            <div style={runTestsStyle}>
              {
                this.state && this.state.error ? <div style={errorStyle}> { this.state.error } </div> : <div></div>
              }
              {
                this.state && this.state.testResults.length > 0 ?
                  <div>
                    <table style={resultTableStyle}>
                    <tbody>
                      <tr><th>Expected</th><th>Actual</th><th></th></tr>
                      { this.getResults() }
                    </tbody>
                    </table>
                  </div>
                  :
                  <div></div>
              }
            </div>

            <div className="iframewrapper">
              <div style={labelStyle}> Output </div>
              <iframe ref="result" id="iframeResult" style={joinJsonObjects(resultStyle, this.props.style)}>
              </iframe>
            </div>

          </td>
        </tr>
        </tbody>
        </table>

      </div>


    );
  }
}

CreateNuggetPage.propTypes = {
  code: PropTypes.string,
};

CreateNuggetPage.defaultProps = {
  id: '',
  username: '',
  code: '',
  description: '',
  hint: '',
  tests: '',
  tags: '',
  funcName: '',
  language: '',
}; 

export default CreateNuggetPage;
