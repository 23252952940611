/*
 * DropDown.js
 *
 * Example: <DropDown style={myStyle} onMouseOver={myOnMouseOver} onMouseLeave={myOnMouseLeave} />
 */
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DropDown extends Component {
  render() {
    return (
      <span style={[this.props.style.base]} onMouseOver={this.props.onMouseOver} onMouseLeave={this.props.onMouseLeave}>
        {this.props.children}
      </span> 
    );
  }
}

DropDown.propTypes = {
  style: PropTypes.object.isRequired,
};

export default Radium(DropDown);