/*
 * Href.js
 *
 * Example: <Href style={myStyle} />
 */
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Href extends Component {
  render() {
    var link = this.props.href;
    var target = (this.props.target) ? this.props.target : "";

    return (
      <a style={[this.props.style.base]} href={link} target={target}>
        {this.props.children}
      </a> 
    )
  }
}

Href.propTypes = {
  style: PropTypes.object.isRequired
};

export default Radium(Href);