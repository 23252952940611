/*
 * point-config.js
 *
 * Configures the point system for various activities
 */

module.exports = {
  lessonPoints: 300,
  snippetPoints: 0,
  quizPoints: 0,
  projectPoints: 0,
  nuggetPoints: 100,
  challengePoints: 100,
  tutorialPoints: 300,
  feedbackPoints: 100,
};
