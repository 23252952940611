/*
 * ParentProfilePage.js
 */
import React, { Component } from 'react';
import { getDateTime } from '../../util/util';
import { request, addLog } from '../../util/api';
import Button from '../Button';
import ProfilePage from '../ProfilePage';
import Input from '../Input';
import UserSwitcher from '../UserSwitcher';

const outerContainerStyle = {
  "padding": "5px",
  "backgroundColor": "#efefef",
};

const containerStyle = {
  "padding": "30px",
  "borderRadius": "20px",
  "backgroundColor": "white",
  "margin": "35px 35px",
  "textAlign": "left",
};

const headStyle = {
  "fontSize": "180%",
  "marginBottom": "20px",
};

const greetingStyle = {
  "textAlign": "center",
  "fontSize": "180%",
};

const loadingStyle = {
  "fontSize": "22px",
  "margin": "30px 60px",
  "textAlign": "center",
};

const hourglassTyle = {
  "width": "80px",
  "paddingRight": "20px",
};

const tdLeft = {
  "verticalAlign": "top",
  "paddingRight": "30px",
};

const tdRight = {
  "verticalAlign": "top",
  "paddingTop": "-10px",
  "paddingLeft": "10px",
};

const buttonStyle = {
  base: {
    "backgroundColor": "white",
    "textDecoration": "none!important",
    "fontSize": "16px",
    "color": "rgba(134, 149, 153, 1)",
    "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "45px",
    "width": "150px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
      "color": "rgba(0, 0, 0, 1)",
      "backgroundColor": "#f2f2f2",
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const buttonDarkStyle = {
  base: {
    "backgroundColor": "rgba(43, 142, 223, 1)",
    "color": "#fff",
    "textDecoration": "none!important",
    "fontSize": "16px",
    "border-radius": "3px",
    "border": "0",
    "fontWeight": "400",
    "height": "45px",
    "width": "150px",
    "paddingLeft": "15px",
    "textAlign": "left",

    ':hover': {
    },

    ':focus': {
      "outline" :"0",
      "backgroundColor": "rgba(43, 142, 223, 1)",
      "color": "#fff",
    }
  }
};

const leftTdStyle = {
  "verticalAlign": "top",
};

const mytableStyle = {
  // "border": "solid 1px",
  "width": "100%",
};

const columnStyle = {
  "verticalAlign": "top",
  "paddingRight": "6px",
  // "textAlign": "right",
  "fontWeight": "500",
};

const inputStyle = {
  base: {
    "marginBottom": "10px",
    "display": "block",
    "boxSizing": "border-box",
    "padding": "10px 16px",
    "width": "300px",
    "height": "40px",
    "outline": "0px",
    "borderWidth": "1px",
    "borderStyle": "solid",
    "borderColor": "#ccc",
    "borderRadius": "0px",
    "background": "#fff",
    "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
    "color": "#616161",
    "fontSize": "14px",
    "lineHeight": "1.3333333",
    "transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
     ":focus": {
       "borderColor": "#0074c2",
       "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 116, 194, 0.6)",
     }
  }
};

const buttonSaveStyle = {
  base: {
    "marginTop": "10px",
    "color": "white",
    "backgroundColor": "rgba(255, 174, 27, 1)",
    "border": "0px solid rgba(255, 174, 27, 1)",
    "padding": "6px",
    "display": "inline-block",
    "borderRadius": "2em",
    "fontWeight": "400",
    "letterSpacing": "0.03125em",
    "fontSize": "12px",
    "width": "120px",
    "textAlign": "center",
    "height": "35px",
    "verticalAlign": "textTop",

    ':hover': {
      "color": "white",
      "backgroundColor": "rgba(249, 160, 0)",
      "borderColor": "rgba(249, 160, 0)",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0px"
    }
  }
};

class ParentProfilePage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.user = this.props.store.getState().auth.user;

    this.username = this.props.username ? this.props.username : 
      this.user.username;

    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;

    if (!this.firstName)
      this.firstName = this.username;

    if (!this.lastName)
      this.lastName = '';

    this.usernameMap = {};
    this.childUsername = '';
    this.childPassword = '';

    this.setState({
      processUsersDone: false,
    });

    this.onShowMyChild = this.onShowMyChild.bind(this);
    this.onShowAddingChild = this.onShowAddingChild.bind(this);
    this.onShowMyLearning = this.onShowMyLearning.bind(this);
    this.onShowAccountSetup = this.onShowAccountSetup.bind(this);
    this.onChildUsernameChange = this.onChildUsernameChange.bind(this);
    this.onChildPasswordChange = this.onChildPasswordChange.bind(this);
    this.onSubmitAddLink = this.onSubmitAddLink.bind(this);
    this.onSubmitRemoveLink = this.onSubmitRemoveLink.bind(this);

    this.setState({
      showMyChild: true,
      showAddingChild: false,
      showMyLearning: false,
      showAccountSetup: false,
    });

    request({
      url: '/api/users',
      method: 'GET',
      payload: {
        username: this.username,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        alert('Error: failed to get users');
        return;
      }

      this.users = res.users;

      this.processUsers();

      this.setState({
        processUsersDone: true,
      });
    });
  }

  processUsers() {
    this.users.forEach(user => {
      this.usernameMap[user.username] = user;
    });

    this.me = this.usernameMap[this.username];

    this.setState({
      children: [],
    });

    var children = [];
    if (this.me.children) {
      children = this.me.children.split(/\s*;\s*/g);
      children = children.filter(item => item !== '');
    }

    this.setState({
      children: children,
    });
  }

  onShowMyChild() {
    this.setState({
      showAddingChild: false,
      showMyLearning: false,
      showAccountSetup: false,
    });

    this.setState({
      showMyChild: true,
    });
  }

  onShowAddingChild() {
    this.setState({
      showMyChild: false,
      showMyLearning: false,
      showAccountSetup: false,
    });

    this.setState({
      showAddingChild: true,
    });
  }

  onShowMyLearning() {
    this.setState({
      showMyChild: false,
      showAddingChild: false,
      showAccountSetup: false,
    });

    this.setState({
      showMyLearning: true,
    });
  }

  onShowAccountSetup() {
    this.setState({
      showMyChild: false,
      showAddingChild: false,
      showMyLearning: false,
    });

    this.setState({
      showAccountSetup: true,
    });
  }

  onChildUsernameChange(e) {
    this.childUsername = e.target.value;
  }
 
  onChildPasswordChange(e) {
    this.childPassword = e.target.value;
  }
 
  validateAddLinkInput() {
    if (!this.childUsername || !this.childPassword) {
      alert('Please fill in all fields.');
      return false;
    }

    if (!this.usernameMap[this.childUsername]) {
      alert('Username "' + this.childUsername+ '" cannot be found.');
      return false;
    }

    if (this.state.children && this.state.children.includes(this.childUsername)) {
      alert('Child ' + this.childUsername + ' has already been linked.');
      return false;
    }

    if (this.childUsername === this.username) {
      alert('Are you sure you and your child are the same person?');
      return false;
    }

    return true;
  }

  validateRemoveLinkInput() {
    if (!this.childUsername || !this.childPassword) {
      alert('Please fill in all fields.');
      return false;
    }

    if (!this.usernameMap[this.childUsername]) {
      alert('Username "' + this.childUsername+ '" cannot be found.');
      return false;
    }

    if (this.state.children && !this.state.children.includes(this.childUsername)) {
      alert('Child ' + this.childUsername + ' has not been linked.');
      return false;
    }

    return true;
  }

  onSubmitAddLink() {
    if (!this.validateAddLinkInput())
      return;

    request({
      url: '/sign_up',
      method: 'POST',
      payload: {
        username: this.username,
        password: 'na',
        childUsername: this.childUsername,
        childPassword: this.childPassword,
        addChildLink: 'true',
      }
    }, (err, res) => {
      if (err) {
        alert("Failed to add link. Password doesn't match.");
        return;
      }

      // if (res.user.flash === 'WRONG_PASSWORD') {
      //   alert("Password doesn't match. Please try again.");
      //   return;
      // }

      alert('Child link has been added.');

      var children = this.state.children;

      if (!children) {
        children = [];
      }

      children.push(this.childUsername);
      this.setState({
        children: children,
      });

      this.setState({
        showAddingChild: false,
      });

      this.setState({
        showMyChild: true,
      });

      // add activity logging
      const activities = [{
        type: 'Account',
        username: this.username,
        id: this.username,
        activity: {
          description: 'Added Link (' + this.childUsername +')',
        },
        time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      }];

      // TODO: Should we add to cache?
      addLog(activities);
    });
  } 

  onSubmitRemoveLink() {
    if (!this.validateRemoveLinkInput())
      return;

    request({
      url: '/sign_up',
      method: 'POST',
      payload: {
        username: this.username,
        password: 'na',
        childUsername: this.childUsername,
        childPassword: this.childPassword,
        removeChildLink: 'true',
      }
    }, (err, res) => {
      if (err) {
        alert("Failed to remove link. Password doesn't match.");
        return;
      }

      // if (res.user.flash === 'WRONG_PASSWORD') {
      //   alert("Password doesn't match. Please try again.");
      //   return;
      // }

      alert('Child link has been removed.');

      var children = this.state.children;
      children.splice(children.indexOf(this.childUsername), 1);
      this.setState({
        children: children,
      });

      this.setState({
        showAddingChild: false,
      });

      this.setState({
        showMyChild: true,
      });

      // add activity logging
      const activities = [{
        type: 'Account',
        username: this.username,
        id: this.username,
        activity: {
          description: 'Removed link (' + this.childUsername + ')',
        },
        time: getDateTime(), // this field will be overwitten by the serer time if logged to server
      }];

      // TODO: Should we add to cache?
      addLog(activities);
    });
  } 

  getMenu() {
    return (
      <div>
        <Button style={ this.state.showMyChild ? buttonDarkStyle : buttonStyle } onMouseDown={this.onShowMyChild}>
          My Child
        </Button>
        <br />
        <Button style={ this.state.showAddingChild ? buttonDarkStyle : buttonStyle } onMouseDown={this.onShowAddingChild}>
          Adding Child
        </Button>
        <br />
        <Button style={ this.state.showMyLearning ? buttonDarkStyle : buttonStyle } onMouseDown={this.onShowMyLearning}>
          My Learning 
        </Button>
        <br />
        <Button style={ this.state.showAccountSetup ? buttonDarkStyle : buttonStyle } onMouseDown={this.onShowAccountSetup}>
          Account Setup
        </Button>
      </div>
    );
  }

  getMyChild() {
    if (!this.state.showMyChild) {
      return (<div></div>);
    }

    if (!this.state.children || this.state.children.length === 0) {
      return (
        <div>
          <p>Please add the account of your child using "Adding Child".</p>

          <p>Once you link to his or her account, you will be able to view the progress of your child.</p>

        </div>
      );
    }

    var result = [];
    this.state.children.forEach(child => {
      result.push(<ProfilePage username={child} store={this.props.store} showChildLearningProfile='true' />);
      result.push(<hr/>);
    })

    return (
      <div style={{'marginTop': '-60px', 'marginLeft': '-50px'}}>
        { result }
      </div>
    );
  }

  requiredSymbol() {
    return(
      <span style={{"color": "red"}}> *</span>
    )
  }

  getAddingChildEntries() {
    return (
      <div>
        <br />
        <table>
        <tbody>
        <tr>
          <td style={leftTdStyle}>
            <table style={mytableStyle}>
              <tbody>
              <tr>
                <td style={columnStyle}>
                  Child username
                  { this.requiredSymbol() }
                </td>
                <td>
                  <Input
                    style={inputStyle}
                    id="childUsername"
                    type="text"
                    name="childUsername"
                    onChange={this.onChildUsernameChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={columnStyle}>
                  Child password
                  { this.requiredSymbol() }
                </td>
                <td>
                  <Input
                    style={inputStyle}
                    id="childPassword"
                    type="password"
                    name="childPassword"
                    onChange={this.onChildPasswordChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={columnStyle}>
                  <div>
                    { this.getAddButton() }
                  </div>
                </td>
                <td>
                  <div>
                    { this.getRemoveButton() }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        </table>
      </div>
    );
  }

  getChildAdded() {
    if (!this.state.children || this.state.children.length === 0) {
      return (
        <div>
          <div>None</div>
          <br />
          <br />
        </div>
      );
    }

    var result = [];
    this.state.children.forEach(child => {
      result.push(<p> { this.usernameMap[child].firstName + ' ' + this.usernameMap[child].lastName + ' (username: ' + child + ')' }</p>);
    });

    return (
      <div>
        { result }
        <br />
      </div>
    );
  }

  getAddingChild() {
    if (!this.state.showAddingChild) {
      return (<div></div>);
    }

    return (
      <div>
        <div style={headStyle}>
          { this.state.children && this.state.children.length > 1 ? 'Children Currently Linked' : 'Child Currently Linked' }
        </div>

        { this.getChildAdded() }

        <div style={headStyle}>Adding/Removing Child</div>

        <p> If your child has already signed up, please work with him or her to fill in the account information.</p>

        { this.getAddingChildEntries() }

        <br />

        <p> If your child does not have an account, please help him or her create one first. </p>

      </div>
    );

  }

  getMyLearning() {
    if (!this.state.showMyLearning) {
      return (<div></div>);
    }

    return (
      <div style={{'marginTop': '-60px', 'marginLeft': '-50px'}}>
        <ProfilePage username={this.username} store={this.props.store} showLearningProfile='true' />
      </div>
    );
  }
  
  getAccountSetup() {
    if (!this.state.showAccountSetup) {
      return (<div></div>);
    }

    return <UserSwitcher target='UPDATE_SETTINGS' store={this.props.store} />
  }

  getParentPage() {
    return (
      <div style={outerContainerStyle}>
        <div style={containerStyle}>

          <table>
            <tbody>
              <tr>
                <td style={tdLeft}>
                  { this.getMenu() }
                </td>
                <td style={tdRight}>
                  {
                    this.getMyChild()
                  }
                  {
                    this.getAddingChild()
                  }
                  {
                    this.getMyLearning()
                  }
                  {
                    this.getAccountSetup()
                  }
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    );
  }

  getAddButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onSubmitAddLink}>
        Add Link
      </Button>
    );
  }

  getRemoveButton() {
    return (
      <Button style={buttonSaveStyle} onClick={this.onSubmitRemoveLink}>
        Remove Link
      </Button>
    );
  }

  render() {
    if (!this.state || !this.state.processUsersDone) {
      return <div style={loadingStyle}><img src="/img/hourglass.svg" style={hourglassTyle}></img> <span> Loading your profile... </span> </div>
    }

    return this.getParentPage();
  }
}

export default ParentProfilePage;