/* 
 * LessonNotePage.js
 * Implements the Note app within a lesson.
 */
import React, { Component } from 'react';
import Button from '../../components/Button';
import { CircularProgressbar } from 'react-circular-progressbar';
import { request } from '../../util/api';
import { isAdmin } from '../../util/util';
import CreateNotePage from '../../components/CreateNotePage';

const controlBarStyle = {
  "color": "white",
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "marginBottom": "30px",
};

const controlBarTableStyle = {
  "width": "100%",
};

const tdIconStyle = {
  "width": "70px",
};

const progressContainerStyle = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "width": "100px",
  "height": "100px",
  "borderRadius": "50%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "-20px",
  "marginBottom": "-20px",
  "paddingTop": "10px",
};

const progressStyle = {
  "width": "80px",
  "height": "80px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "rgba(126, 181, 48, 1)",
    "marginTop": "10px",
    "marginBottom": "10px",
    "border": "2px solid white",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "98px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "backgroundColor": "hsla(0,0%,100%,.15)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const leftTdStyle = {
  "width": "500px",
  "textAlign": "center",
};

const rightTdStyle = {
  "width": "500px",
  "textAlign": "right",
};

const containerStyle = {
  "backgroundColor": "#efefef",
  "maxWidth": "1240px",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "40px",
};

const buttonReportBugStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "100px",
    "marginRight": "20px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "boxShadow": "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "60px",
    "marginRight": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const innerContainer = {
  "backgroundColor": "rgba(126, 181, 48, 1)",
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "paddingTop": "1px",
  "paddingBottom": "20px",
};

const infoIconStyle = {
  "width": "80px",
  "height": "80px",
  "paddingRight": "20px",
};

const messageStyle = {
  "margin": "30px 60px",
  "textAlign": "center",
  "fontSize": "150%",
  "backgroundColor": "wheat",
};

const descriptionTableStyle = {
  "width": "100%",
};

const descriptionStyle = {
  "backgroundColor": "white",
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "marginTop": "-20px",
};

const tdButtonStyle = {
  "textAlign": "right",
};

class LessonNotePage extends Component {
  constructor(props) {
    super(props);

    this.lessonState = this.props.store.getState().auth.lessonState;
    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;
    this.logs = this.props.store.getState().auth.lessonState.logs;
    this.canSkip = this.props.canSkip ? true : false;
    this.fromOutsideOfLesson = this.props.fromOutsideOfLesson === 'true' ? true: false;

    this.onContinue = this.onContinue.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.id = this.getIdFromURL();
    this.sharedUsernames = [];

    if (!this.id) {
      // not directly accessing note from /note?xxx, get note id from lesson state
      var skill = this.lessonState.skillList[this.lessonState.currentSkillIndex];
      if (skill && skill.type === 'Note') {
        this.id = skill.id;
      }
    }
    
    this.setState({
      previewMessage: '',
      noteLoaded: false,
      doEdit: false,
    });

    request({
      url: '/api/get_note',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error getting note.');
        return;
      }

      this.note = res.note;
      if (!this.note) {
        this.setState({
          noteLoaded: true,
        });
        return;
      }

      this.name = this.note.name;
      this.noteType = this.note.noteType;
      this.freeFormContent = this.note.freeFormContent;
      this.title = this.note.title;
      this.overview = this.note.overview;
      this.objectives = this.note.objectives;
      this.concepts = this.note.concepts;
      this.takeaways = this.note.takeaways;
      this.noteType = this.note.noteType;
      this.materials = this.note.materials;
      this.resources = this.note.resources;
      this.activities = this.note.activities;
      this.tags = this.note.tags;
      this.shareWith = this.note.shareWith;
      this.author = this.note.username;

      if (this.shareWith) {
        this.sharedUsernames = this.shareWith.split(/\s*;\s*/g);
        this.sharedUsernames = this.sharedUsernames.filter(item => item !== '');
      }

      this.setState({
        noteLoaded: true,
      });
    }); 

    this.setState({
      processActivitiesDone: false,
    });

    request({
      url: '/api/all_activities_by_id',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('ERROR: failed to get activities.');
        return;
      }

      this.activities = res.activities;

      this.processActivities();

      this.setState({
        processActivitiesDone: true,
      });
    });
  }

  processActivities() {
    // TODO
  }

  getInsights() {
    if (!this.state || !this.state.processActivitiesDone || !isAdmin(this.user)) {
      return (<div></div>);
    }

    // TODO
    return (<div></div>);
  }

  getProgressBar() {
    return (
      <div style={ controlBarStyle }>
        <table style={ controlBarTableStyle }>
          <tbody>
            <tr>
              <td style={leftTdStyle}>
                { this.getInsights() }
              </td>
              <td>
                { this.getProgress() }
              </td>
              <td style={rightTdStyle}>
                { this.getContinueButton() }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // We use reat-circular-progressbar to display progress:
  // https://github.com/iqnivek/react-circular-progressbar
  // https://www.npmjs.com/package/react-circular-progressbar
  // color configuration can be controled with the CSS file in src/public/ace.css
  getProgress() {
    var current = this.props.store.getState().auth.lessonState.currentSkillIndex;
    if (this.state && this.state.checked ) {
      current += 1;
    }

    var count = 0;
    if (this.lessonState.skillList) {
      count = this.lessonState.skillList.length;
    }

    var percentage = 0;
    if (count > 0) {
      percentage = current/count;
    }

    percentage = percentage*100;
    percentage = percentage.toFixed(0);

    return (
      <div style={progressContainerStyle}>
        <div style={progressStyle}>
          <CircularProgressbar value={ percentage } text={`${percentage}%`} strokeWidth={12}/>
        </div>
      </div>
    );
  }

  onContinue() {
    // TODO: fromOutSideOfLesson is not needed in Note app
    if (this.fromOutsideOfLesson)
      return;

    var lessonState = this.props.store.getState().auth.lessonState;

    // advance the currentSkillIndex to the next one
    var newLessonState = {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex + 1,
      currentSkillPassed: false,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: this.logs,
    };
    this.props.onContinue(newLessonState);
  }

  getReportBug() {
    var location = 'note?id=' + this.id;

    return(
      <a href={ 'contact?location=' + location } target='_blank'>
        <Button style={buttonReportBugStyle}>
          Send Feedback
        </Button>
      </a>
    );
  }

  onEdit() {
    this.setState({ doEdit: true });
  }

  onDelete() {
    var answer = confirm('This will delete note ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_note',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('Error deleting note ' + this.id + '.');
            return;
          }

        alert('Note ' + this.id + ' is deleted.');
      });
    } else {
      alert('Ok, no change is made.');
    }
  }

  canEdit() {
    if (this.username === this.author)
      return true;

    if (this.sharedUsernames.includes(this.username))
      return true;

    if (isAdmin(this.user))
      return true;

    return false;
  }

  getEditButton() {
    if (!this.canEdit())
      return null;

    if (this.fromOutsideOfLesson)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onEdit}>
        Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (!this.canEdit())
      return null;

    if (this.fromOutsideOfLesson)
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
        Delete
      </Button>
    );
  }

  getContinueButton() {
    var buttonText = 'Continue';
    
    return (
      <span>
        { this.getEditButton() }
        { this.getDeleteButton() }
        { this.getReportBug() }
        <Button style={buttonStyle} onClick={this.onContinue}>
          { buttonText }
        </Button>
      </span>
    );
  }

  getIdFromURL() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // e.g. http://www.cswonders.com/note?id=abcdefgh
    const href = window.location.href;
    const PATTERN = 'note?id=';
    const PATTERN_LEN = PATTERN.length;
    const index = href.indexOf(PATTERN);

    if (index === -1) {
      // this could happen when loading from /note?id=xxx
      return undefined;
    }

    return href.substring(index + PATTERN_LEN);
  }

  getTitle() {
    return (
      <table style={descriptionTableStyle}>
      <tbody>
      <tr>
        <td style={tdIconStyle}>
          <img width="50px" height="50px" src='/img/note.svg' ></img>
        </td>

        <td>
          <h4> { this.title } </h4>
        </td>

      </tr>
      </tbody>
      </table>
    );
  }

  getOverview() {
    if (!this.overview)
      return <div></div>;

    return (
      <div>
        <h4> Overview </h4>
        <div dangerouslySetInnerHTML={{ __html: this.overview }}></div>
        <hr></hr>
      </div>
    );
  }

  getObjectives() {
    if (!this.objectives)
      return <div></div>;

    return (
      <div>
        <h4> Objectives </h4>
        <div dangerouslySetInnerHTML={{ __html: this.objectives }}></div>
        <hr></hr>
      </div>
    );
  }

  getConcepts() {
    if (!this.concepts)
      return <div></div>;

    return (
      <div>
        <h4> Concepts </h4>
        <div dangerouslySetInnerHTML={{ __html: this.concepts }}></div>
        <hr></hr>
      </div>
    );
  }

  getTakeaways() {
    if (!this.takeaways)
      return <div></div>;

    return (
      <div>
        <h4> Key Takeaways </h4>
        <div dangerouslySetInnerHTML={{ __html: this.takeaways }}></div>
        <hr></hr>
      </div>
    );
  }

  getMaterials() {
    if (!this.materials)
      return <div></div>;

    return (
      <div>
        <h4> Materials </h4>
        <div dangerouslySetInnerHTML={{ __html: this.materials }}></div>
        <hr></hr>
      </div>
    );
  }

  getResources() {
    if (!this.resources)
      return <div></div>;

    return (
      <div>
        <h4> Resources </h4>
        <div dangerouslySetInnerHTML={{ __html: this.resources }}></div>
        <hr></hr>
      </div>
    );
  }

  getClassActivities() {
    if (!this.activities)
      return <div></div>;

    return (
      <div>
        <h4> Activities </h4>
        <div dangerouslySetInnerHTML={{ __html: this.activities }}></div>
        <hr></hr>
      </div>
    );
  }

  getFreeFormContent() {
    if (!this.freeFormContent)
      return <div></div>;

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.freeFormContent }}></div>
        <hr></hr>
      </div>
    );
  }

  getLessonPlan() {
    if (this.noteType !== "LessonPlan")
      return (<div></div>);

    var result = [];

    result.push(this.getOverview());
    result.push(this.getObjectives());
    result.push(this.getTakeaways());
    result.push(this.getConcepts());
    result.push(this.getMaterials());
    result.push(this.getResources());
    result.push(this.getClassActivities());

    return result;
  }


  render() {
    if (!this.username) {
      return (
        <div style={containerStyle}>
          <div style={messageStyle}>
            <img src="/img/info.svg" style={infoIconStyle}></img>
            <span> Please <a href='login'>sign in</a> to track your progress. </span>
          </div>
        </div>
      );
    }
    
    if (!this.state || !this.state.noteLoaded) {
      return (<div></div>);
    }

    if (this.state.doEdit) {
      return (
        <div style={containerStyle}>
          <CreateNotePage
            id = { this.id } 
            username = { this.author }
            name = { this.name }
            noteType = { this.noteType }
            freeFormContent = { this.freeFormContent }
            title = { this.title }
            overview = { this.overview }
            objectives = { this.objectives }
            concepts = { this.concepts }
            takeaways = { this.takeaways }
            materials = { this.materials }
            resources = { this.resources }
            activities = { this.activities }
            tags = { this.tags }
            shareWith = { this.shareWith }
            store={ this.props.store }
          />
        </div>
      );
    }

    if (this.id !== this.props.id && this.props.id) {
      // the id is different, indicating we're moving to the next note
      // so we need to fetch the new note
      this.id = this.props.id;
      this.setState({
        noteLoaded: false,
      });

      request({
        url: '/api/get_note',
        method: 'GET',
        payload: {
          id: this.id,
          random: Math.random(),
        }
      }, (err, res) => {
        if (err) {
          console.log('Error getting note.');
          return;
        }

        this.note = res.note;
        if (!this.note) {
          this.setState({
            noteLoaded: true,
          });
          return;
        }

        this.name = this.note.name;
        this.noteType = this.note.noteType;
        this.freeFormContent = this.note.freeFormContent;
        this.title = this.note.title;
        this.overview = this.note.overview;
        this.objectives = this.note.objectives;
        this.concepts = this.note.concepts;
        this.takeaways = this.note.takeaways;
        this.noteType = this.note.noteType;
        this.materials = this.note.materials;
        this.resources = this.note.resources;
        this.activities = this.note.activities;
        this.tags = this.note.tags;
        this.shareWith = this.note.shareWith;
        this.author = this.note.username;

        if (this.shareWith) {
          this.sharedUsernames = this.shareWith.split(/\s*;\s*/g);
          this.sharedUsernames = this.sharedUsernames.filter(item => item !== '');
        }

        this.setState({
          noteLoaded: true,
        });
      }); 
    }

    return (
      <div style={containerStyle}>
        { this.getProgressBar() }
        <div style={innerContainer}>
          { this.renderNote() }
        </div>
      </div>
    );
  }

  renderNote() {
    if (!this.state || !this.state.noteLoaded) {
      return <div></div>;
    }

    if (!this.note) {
      return (
        <div style={messageStyle}>
          <img src="/img/info.svg" style={infoIconStyle}></img>
          <span> { 'Uh-oh: note ID "' + this.id + '" cannot be found on this planet.\n' } </span>
        </div>
      );
    }

    return (
      <div style={containerStyle}>
        <div style={descriptionStyle}>

          { this.getTitle() }
          
          <hr></hr>

          { this.getFreeFormContent() }
          { this.getLessonPlan() }
        </div>
      </div>
    );
  }
}

export default LessonNotePage;
