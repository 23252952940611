/*
 * LoginPage.js
 */
import React, { Component } from 'react';
import history from '../../history';
import Button from '../Button';
import Input from '../Input';
// import ProfilePage from '../ProfilePage';
import UserSwitcher from '../UserSwitcher';
import { request } from '../../util/api';
import { setItem } from '../../util/local-storage';

/*
 * styles
 */

const container = {
  "margin": "0 auto",
  "marginTop": "20px",
  "padding": "0 0 40px",
  "maxWidth": "380px",
};

const textH1 = {
  "fontSize": "180%",
  "color": "#575a5b",
  "fontFamily": "'Open Sans',Helvetica,Arial,sans-serif",
  "fontWeight": "100",
  "lineHeight": "1.0",
  "marginBottom": "0.3125em",
  "marginTop": "0px",
  "textAlign": "center",
};

const inputStyle = {
  base: {
    "display": "block",
    "boxSizing": "border-box",
    "padding": "10px 16px",
    "width": "100%",
    "height": "46px",
    "outline": "0px",
    "border": "1px solid #ccc",
    "borderRadius": "0px",
    "background": "#fff",
    "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
    "color": "#616161",
    "fontSize": "18px",
    "lineHeight": "1.3333333",
    "transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",

     ":focus": {
       "borderColor": "#0074c2",
       "boxShadow": "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 116, 194, 0.6)",
     }
  }
};

const formGroup = {
  "marginBottom": "15px",
};

const label = {
  "display": "inline-block",
  "marginBottom": "5px",
  "maxWidth": "100%",
  "fontSize": "110%",
  "fontWeight": "500",
  "textAlign": "left",
  "fontFamily": '"Open Sans",Helvetica,Arial,sans-serif',
  "lineHeight": "1.5",
};

var buttonStyle = {
  base: {
    "color": "white",
    "backgroundColor": "#21abc7",
    "border": "0px solid #21abc7",

    "padding": "4px",
    "display": "inline-block",
    "marginRight": "30px",
    "borderRadius": "2em",
    "fontWeight": "500",
    "letterSpacing": "0.03125em",
    "fontSize": "100%",
    "lineHeight": "2.2",
    "marginBottom": "20px",
    "width": "220px",
    "textAlign": "center",

    ':hover': {
      "color": "white",
      "backgroundColor": "#1c91a8",
      "borderColor": "#1c91a8",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0px"
    }
  }
};

// .facebook {
//   border-color: #3b5998;
//   background: #3b5998;
//   composes: button;

//   &:hover {
//     background: #2d4373;
//   }
// }

// .google {
//   border-color: #dd4b39;
//   background: #dd4b39;
//   composes: button;

//   &:hover {
//     background: #c23321;
//   }
// }

// .twitter {
//   border-color: #55acee;
//   background: #55acee;
//   composes: button;

//   &:hover {
//     background: #2795e9;
//   }
// }

class LoginPage extends Component {

  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      tried: false,
      authenticated: false,
      flash: '',
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    request({
      url: '/log_in',
      method: 'POST',
      payload: {
        username: this.state.username,
        password: this.state.password,
      }
    }, (err, res) => {
      if (err) {
        this.setState({
          authenticated: false,
          tried: true,
          flash: "Username doesn't exist, or username and password don't match. Please try again.",
        });
        setItem('username', '');

        return;
      }

      // if (res.user.flash === 'USER_NOT_FOUND') {
      //   this.setState({ 
      //     authenticated: false, 
      //     tried: true,
      //     flash: "Username doesn't exist. Please sign up first.",
      //   });
      //   setItem('username', '');

      //   return;
      // }

      // if (res.user.flash === 'WRONG_PASSWORD') {
      //   this.setState({ 
      //     authenticated: false, 
      //     tried: true,
      //     flash: "Username and password don't match. Please try again.",
      //   });
      //   setItem('username', '');

      //   return;
      // }

      const activities = [];
      this.props.onSetActivities(activities);
      
      this.props.onLogin(res.user);

      setItem('username', res.user.username);

      this.setState({
        username: res.user.username,
        authenticated: true,
        tried: true,
      });

      history.push('/');
    });
  }

  flashMsg() {
    if (this.state.authenticated || !this.state.tried) {
      return (
        <div> </div>
      );
    }  

    return (
      <div style={{ "color": "red" }}> 
        {this.state.flash} 
      </div>
    );
  } 

  render() {
    var self = this;

    var currentUser = self.props.username;

    if (!currentUser) {
      currentUser = this.props.store ? this.props.store.getState().auth.user.username : '';
      // TODO: somehow the change might not be effective right away
      if (!currentUser) {
        currentUser = self.state.username;
      }
    }

    if (self.state.authenticated) {
      // return <ProfilePage username={currentUser} store={self.props.store} />;
      // return <UserSwitcher target='PROFILE' username={currentUser} store={self.props.store} />;
      return <UserSwitcher target='LEARN' store={self.props.store} />;
    }

    return (
      <div style={container}>
          <h1 style={textH1}> Sign In</h1>

          {this.flashMsg()}

          <br />

          <form method="post">
            <div style={formGroup}>
              <label style={label} htmlFor="usernameOrEmail">
              Username 
              </label>
              <Input
                style={inputStyle}
                id="usernameOrEmail"
                type="text"
                name="usernameOrEmail"
                autoFocus="true"
                onChange={this.handleUsernameChange}
              />
            </div>

            <div style={formGroup}>
              <label style={label} htmlFor="password">
                Password
              </label>
              <Input
                style={inputStyle}
                id="password"
                type="password"
                name="password"
                onChange={this.handlePasswordChange}
              />
            </div>

            <br />

            <div style={{"textAlign": "center"}}>
              <div>
                <Button style={buttonStyle} type="submit" onClick={self.handleSubmit}>
                  Sign In
                </Button>
              </div>

              <p>
                Don't have an account? <a href="/signup">Create a free account</a>
              </p>
            </div>

          </form>
      </div>
    );
  }
}

export default LoginPage;