/*
 * Header.js
 */
import React from 'react';
import UserSwitcher from '../UserSwitcher';

const headRoot = {
  "marginTop": "8px",
  "marginBottom": "8px",
  "background": "#fff",
};

export default function Header(props) {
  return (
    <div style={headRoot}>
      <UserSwitcher store={ props.store } target='NAVIGATION' />
    </div>
  );
}
