/*
 * actions/auth.js
 *
 * Action creators that create and return the action objects.
 */
import { setItem } from '../util/local-storage';
import { CHANGE_LESSON_STATE_PASS, CHANGE_LESSON_STATE_FAIL, CHANGE_LESSON_STATE_INIT } from '../constants';
import { CHANGE_USER_ACTION } from '../constants';
import { SET_ACTIVITIES_TO_CACHE, ADD_ACTIVITIES_TO_CACHE } from '../constants';

export function changeLessonStatePass(lessonState) {
  return {
    type: CHANGE_LESSON_STATE_PASS,
    lessonState: {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex,
      currentSkillPassed: true,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: lessonState.logs,
    },
  };
}

export function changeLessonStateFail(lessonState) {
  return {
    type: CHANGE_LESSON_STATE_FAIL,
    lessonState: {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex,
      currentSkillPassed: false,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: lessonState.logs,
    },
  };
}

export function changeLessonStateInit(lessonState) {
  return {
    type: CHANGE_LESSON_STATE_INIT,
    lessonState: {
      lessonId: lessonState.lessonId,
      lessonName: lessonState.lessonName,
      currentSkillIndex: lessonState.currentSkillIndex,
      currentSkillPassed: lessonState.currentSkillPassed,
      skillList: lessonState.skillList,
      groupId: lessonState.groupId,
      logs: lessonState.logs,
    },
  };
}

export function loginUser(user) {
  console.log('actions/loginUser:' + user.username);
  
  setItem('username', user.username);

  return {
    type: CHANGE_USER_ACTION,
    user: {
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      role: user.role,
      access: user.access,
    },
  };
}

export function logoutUser() {
  console.log('actions/logoutUser...');
  
  setItem('username', '');

  return {
    type: CHANGE_USER_ACTION,
    user: {},
  };
}

export function setActivitiesToCache(activities) {
  return {
    type: SET_ACTIVITIES_TO_CACHE,
    payload: {
      activities: activities,
    },
  };
}

export function addActivitiesToCache(activities) {
  return {
    type: ADD_ACTIVITIES_TO_CACHE,
    payload: {
      activities: activities,
    },
  };
}
