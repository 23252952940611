/* 
 * NotePage.js
 */
import React, { Component } from 'react';
import { request } from '../../util/api';
import { isAdmin } from '../../util/util';
import Button from '../../components/Button';
import CreateNotePage from '../../components/CreateNotePage';

const containerStyle = {
  "marginTop": "20px",
  "marginBottom": "20px",
  "backgroundColor": "#efefef",
  "maxWidth": "1200px",
  "marginLeft": "auto",
  "marginRight": "auto",
};

const tdIconStyle = {
  "width": "70px",
};

const instructionsStyle = {
  "marginTop": "-10px",
  "paddingBottom": "10px",
};

const buttonSaveStyle = {
  base: {
    "backgroundColor": "grey",
    "border": "0px solid #4CAF50",
    "transition": "background-color .3s,color .15s,box-shadow .3s,opacity 0.3s",
    "padding": "4px",
    "verticalAlign": "middle",
    "textDecoration": "none!important",
    "fontFamily": "fira-sans",
    "fontSize": "14px",
    "color": "white",
    "borderRadius": "5px",
    "width": "60px",
    "marginLeft": "10px",

    ':hover': {
      "color": "black",
      "backgroundColor": "rgba(192, 192, 192, 1)",
    },

    ':focus': {
      "outline" :"0"
    }
  }
};

const infoIconStyle = {
  "width": "80px",
  "height": "80px",
  "paddingRight": "20px",
};

const messageStyle = {
  "margin": "30px 60px",
  "textAlign": "center",
  "fontSize": "150%",
  "backgroundColor": "wheat",
};

const descriptionTableStyle = {
  "width": "100%",
};

const descriptionStyle = {
  "backgroundColor": "white",
  "paddingLeft": "20px",
  "paddingRight": "20px",
  "paddingTop": "10px",
  "paddingBottom": "10px",
  "marginTop": "20px",
};

const tdButtonStyle = {
  "textAlign": "right",
};

class NotePage extends Component {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.user = this.props.store.getState().auth.user;
    this.username = this.user.username;
  }

  componentDidMount() {
    this.id = this.props.id;
    this.sharedUsernames = [];
      
    if (!this.id) {
      this.id = this.getIdFromURL();
    }

    this.setState({
      previewMessage: '',
      doEdit: false,
      noteLoaded: false,
    });

    request({
      url: '/api/get_note',
      method: 'GET',
      payload: {
        id: this.id,
        random: Math.random(),
      }
    }, (err, res) => {
      if (err) {
        console.log('Error getting note.');
        return;
      }

      this.note = res.note;
      if (!this.note) {
        this.setState({
          noteLoaded: true,
        });
        return;
      }

      this.name = this.note.name;
      this.noteType = this.note.noteType;
      this.freeFormContent = this.note.freeFormContent;
      this.title = this.note.title;
      this.overview = this.note.overview;
      this.objectives = this.note.objectives;
      this.concepts = this.note.concepts;
      this.takeaways = this.note.takeaways;
      this.noteType = this.note.noteType;
      this.materials = this.note.materials;
      this.resources = this.note.resources;
      this.activities = this.note.activities;
      this.tags = this.note.tags;
      this.shareWith = this.note.shareWith;
      this.author = this.note.username;

      if (this.shareWith) {
        this.sharedUsernames = this.shareWith.split(/\s*;\s*/g);
        this.sharedUsernames = this.sharedUsernames.filter(item => item !== '');
      }

      this.setState({
        noteLoaded: true,
      });
    }); 
  }

  onEdit() {
    this.setState({ doEdit: true });
  }

  onDelete() {
    var answer = confirm('This will delete note ' + this.id + '. Are you sure you want to proceed?');

    if (answer === true) {
      request({
        url: '/api/delete_note',
        method: 'POST',
        payload: { id: this.id, username: this.username }
      }, (err, res) => {
          if (err) {
            alert('Error deleting note ' + this.id +'.');
            return;
          }

        alert('Note ' + this.id + ' is deleted.');
      });
    } else {
      alert('Ok, no change is made.');
    }
  }

  canEdit() {
    if (this.username === this.author)
      return true;

    if (this.sharedUsernames.includes(this.username))
      return true;

    if (isAdmin(this.user))
      return true;

    return false;
  }

  getEditButton() {
    if (!this.canEdit())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onEdit}>
        Edit
      </Button>
    );
  }

  getDeleteButton() {
    if (!this.canEdit())
      return null;

    return (
      <Button style={buttonSaveStyle} onClick={this.onDelete}>
        Delete
      </Button>
    );
  }

  getRunComponents() {
    return (
      <div>
        { this.getDeleteButton() }
        { this.getEditButton() }
      </div>
    );
  }

  getIdFromURL() {
    if (typeof window === 'undefined') {
      return undefined;
    }

    // e.g. http://www.cswonders.com/note?id=abcdefgh
    const href = window.location.href;
    const PATTERN = 'note?id=';
    const PATTERN_LEN = PATTERN.length;
    const index = href.indexOf(PATTERN);

    if (index === -1) {
      // this could happen when loading from /note?id=xxx
      return undefined;
    }

    return href.substring(index + PATTERN_LEN);
  }

  getTitle() {
    return (
      <table style={descriptionTableStyle}>
      <tbody>
      <tr>
        <td style={tdIconStyle}>
          <img width="50px" height="50px" src='/img/note.svg' ></img>
        </td>

        <td>
          <h4> { this.title } </h4>
        </td>

        <td style={tdButtonStyle}>
          { this.getRunComponents() }
        </td>
      </tr>
      </tbody>
      </table>
    );
  }

  getOverview() {
    if (!this.overview)
      return <div></div>;

    return (
      <div>
        <h4> Overview </h4>
        <div dangerouslySetInnerHTML={{ __html: this.overview }}></div>
        <hr></hr>
      </div>
    );
  }

  getObjectives() {
    if (!this.objectives)
      return <div></div>;

    return (
      <div>
        <h4> Objectives </h4>
        <div dangerouslySetInnerHTML={{ __html: this.objectives }}></div>
        <hr></hr>
      </div>
    );
  }

  getConcepts() {
    if (!this.concepts)
      return <div></div>;

    return (
      <div>
        <h4> Concepts </h4>
        <div dangerouslySetInnerHTML={{ __html: this.concepts }}></div>
        <hr></hr>
      </div>
    );
  }

  getTakeaways() {
    if (!this.takeaways)
      return <div></div>;

    return (
      <div>
        <h4> Key Takeaways </h4>
        <div dangerouslySetInnerHTML={{ __html: this.takeaways }}></div>
        <hr></hr>
      </div>
    );
  }

  getMaterials() {
    if (!this.materials)
      return <div></div>;

    return (
      <div>
        <h4> Materials </h4>
        <div dangerouslySetInnerHTML={{ __html: this.materials }}></div>
        <hr></hr>
      </div>
    );
  }

  getResources() {
    if (!this.resources)
      return <div></div>;

    return (
      <div>
        <h4> Resources </h4>
        <div dangerouslySetInnerHTML={{ __html: this.resources }}></div>
        <hr></hr>
      </div>
    );
  }

  getClassActivities() {
    if (!this.activities)
      return <div></div>;

    return (
      <div>
        <h4> Activities </h4>
        <div dangerouslySetInnerHTML={{ __html: this.activities }}></div>
        <hr></hr>
      </div>
    );
  }

  getFreeFormContent() {
    if (!this.freeFormContent)
      return <div></div>;

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.freeFormContent }}></div>
        <hr></hr>
      </div>
    );
  }

  getLessonPlan() {
    if (this.noteType !== "LessonPlan")
      return (<div></div>);

    var result = [];

    result.push(this.getOverview());
    result.push(this.getObjectives());
    result.push(this.getTakeaways());
    result.push(this.getConcepts());
    result.push(this.getMaterials());
    result.push(this.getResources());
    result.push(this.getClassActivities());

    return result;
  }

  render() {
    if (this.state && this.state.doEdit) {
      return (
        <div style={containerStyle}>
          <CreateNotePage
            id = { this.id } 
            username = { this.author }
            name = { this.name }
            noteType = { this.noteType }
            freeFormContent = { this.freeFormContent }
            title = { this.title }
            overview = { this.overview }
            objectives = { this.objectives }
            concepts = { this.concepts }
            takeaways = { this.takeaways }
            materials = { this.materials }
            resources = { this.resources }
            activities = { this.activities }
            tags = { this.tags }
            shareWith = { this.shareWith }
          />
        </div>
      );
    }

    if (this.state && this.state.noteLoaded)
    {
      if (!this.note) {
        return (
          <div style={messageStyle}>
            <img src="/img/info.svg" style={infoIconStyle}></img>
            <span> { 'Uh-oh: note ID "' + this.id + '" cannot be found on this planet.\n' } </span>
          </div>
        );
      }

      return (
        <div style={containerStyle}>
          <div style={descriptionStyle}>

            { this.getTitle() }
            
            <hr></hr>

            { this.getFreeFormContent() }
            { this.getLessonPlan() }
          </div>
        </div>
      );
    }

    return <div></div>;
  }
}

export default NotePage;
